.playDownload{
  position: relative;
  padding: 0 7.813vw;
  margin-top: 2vw;

}
.topWrapper{
  display: flex;
  margin-top: -5vw;
  justify-content: space-between;
  align-items: flex-start;
  & img{
    width: 42.917vw;
    height: 35.677vw;
    margin-top: 11vw;
  }
  & h3{
    margin-top: 2vw;
    padding-top: 15.104vw;
    width: 39.792vw;
    position: relative;
    &:after{
      content: "2";
      position: absolute;
      background: #FFC328;
      border-radius: 50%;
      font-size: 7.500vw;
      color: #000000;
      width: 9.948vw;
      height: 9.948vw;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0.470vw solid #FFFFFF;
      box-shadow: inset 4.50879px 3.89038px 1.1272px 5.5264px #B37E00;
      top: 2vw;
      left: 13vw;
      font-weight: 400;
    }
    &:before{
      content: "";
      position: absolute;
      background-image: url("../../../../Image/playNowLines2.svg");
      width: 43.438vw;
      height: 4.063vw;
      bottom: -2vw;
      right: -7.813vw;
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }
}
.buttons{
  display: flex;
  justify-content: space-around;
  text-align: center;
  margin-top: -5vw;
  & h5{
    font-size: 1.406vw;
    margin-bottom: .6vw;
    text-transform: uppercase;
  }
  & p{
    font-size: 1.250vw;
  }
  & a{
    color: #FFFFFF;
    text-decoration: none;
  }
}
@media (min-width: 1024px) {
  .buttons{
    & a{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: .8vw;
      width: 13.533vw;
      height: 3.906vw;
      background: url("../../../../Image/learnMoreButtonBorder.svg") no-repeat;
      background-size: 100% 100%;
      & img{
        display: none ;
      }
      &:hover{
        background: url("../../../../Image/learnMoreButtonBorderHover.svg");
        background-size: 100% 100%;
        color: #FFC328;
      }
      &:active{
        background: url("../../../../Image/learnMoreButtonBorderActive.svg") no-repeat 100% 100%;
        background-size: 100% 100%;
        color: #F8A300;
      }

    }
    & div:nth-child(4){
      & a{
        pointer-events: none;
        position: relative;
        color: #6F6F6F;
        background: url("../../../../Image/learnMoreButtonBorderDisable.svg") no-repeat;
        //&:before{
        //  content: 'after beta';
        //  text-transform: uppercase;
        //  font-family: 'Permanent Marker', cursive;
        //  text-align: center;
        //  font-weight: 400;
        //  font-size: 1.927vw;
        //  width: 10.625vw;
        //  transform: rotate(15deg);
        //  position: absolute;
        //  right: -30%;
        //  line-height: 85%;
        //  top: -30%;
        //  color: #FFC328;
        //}
      }
    }
  }
}
@media (max-width: 1024px) {
  .playDownload{
    padding: 0 50px;
    margin-top: 60px;
  }
  .topWrapper{
    margin-top: 0;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;
    & img{
      width: 663.5px;
      height: 523.39px;
      margin-top: 90px;
    }
    & h3{
      align-self: flex-end;
      margin-top: 2vw;
      padding-top: 15.104vw;
      width: 452.32px;
      position: relative;
      text-align: left;
      &:after{
        font-size: 61.1125px;
        color: #000000;
        width: 81.06px;
        height: 81.06px;
        border: 3.82699px solid #FFFFFF;
        box-shadow: inset 1.9135px 3.34862px 0.478374px 5.74049px #B37E00;
        top: 2vw;
        left: 25vw;
      }
      &:before{
        content: "";
        position: absolute;
        background-image: url("../../../../Image/playNowLines2.svg");
        width: 498.91px;
        height: 60.88px;
        bottom: -30px;
        right: -80px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .buttons{
    justify-content: space-between;
    text-align: center;
    margin-top: -5vw;
    flex-wrap: wrap;
    & div{
      margin-bottom: 30px;
      width: 286.83px;
      height: 108.33px;
      background: #121212;
      padding: 5px;

    }

    & h5{
      font-size: 20px;
      margin-bottom: 5px;
      margin-top: 5px;
    }
    & p{
      font-size: 18px;
      display: inline;
    }
    & a{
      margin-top: 5px;
      font-weight: 700;
      font-size: 18px;
      color: #FFC328;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;
    }
    & div:nth-child(4){
      background: #121212;
      color: #6F6F6F !important;
      pointer-events: none;
      position: relative;
      &:before{
        content: 'after beta';
        text-transform: uppercase;
        font-family: 'Permanent Marker', cursive;
        text-align: center;
        font-weight: 400;
        font-size: 37.1019px;
        width: 143.02px;
        transform: rotate(15deg);
        position: absolute;
        right: -10%;
        line-height: 85%;
        top: -30%;
        color: #FFC328;
      }
      & a{
        color: #6F6F6F !important;
        & img{
          filter: grayscale(.9) brightness(0.4);
        }
      }
    }
  }
}
@media (max-width: 576px) {
  .playDownload{
    padding: 0;
    margin-top: 60px;
  }
  .topWrapper{
    margin-top: 0;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;
    & img{
      width: 343.22px;
      height: 285.32px;
      margin-top: 90px;
    }
    & h3{
      align-self: flex-start;
      margin-top: 2vw;
      padding-top: 60px !important;
      width: 317.82px;
      &:after{
        font-size: 40px;
        color: #000000;
        width: 53.06px;
        height: 53.06px;
        border: 2.53431px solid #FFFFFF;
        box-shadow: inset 1.26715px 2.21752px 0.316789px 3.80146px #B37E00;
        top: -20px;
        left: 16vw;
      }
      &:before{
        content: "";
        position: absolute;
        background-image: url("../../../../Image/playNowLines1.svg");
        width: 258.94px;
        height: 12.15px;
        right: auto;
        left: -20px;
      }
    }
  }
  .buttons{

    & div{
      margin-bottom: 30px;
      width: 100vw;
      position: relative;
      left: 0;
      height: 108.33px;
      background: #121212;
      padding: 5px;
    }
    & div:nth-child(4) {
      &:before {
        font-size: 24px;
        right: 0;
        top: -15%;
      }
    }
    & h5{
      font-size: 18px;
      margin-bottom: 5px;
      margin-top: 5px;
    }
    & p{
      font-size: 16px;

    }
    & a{
      font-size: 16px;
    }
  }
}