.background {
  background-image: url("../../Image/Landsale-bg.svg");
  background-size: contain;
  position: relative;
  overflow: hidden;
  margin-top: -40px;
}
  .habitat {
    position: absolute;
    right: -430px;
    top: 200px;
    width: 1303px;
  }

  .pageTitle__info {
    padding-left: 155px;
    margin-bottom: 97px;
    position: relative;
    z-index: 5;
    .pageTitle {
      font-size: 120px;
      margin-bottom: 56px;
      span {
        color: #ffc328;
      }
    }
    .pageInfo {
      width: 54%;
      font-size: 24px;
      p {
        margin-bottom: 48px;
        &:nth-of-type(2) {
          width: 85%;
        }
        &:last-of-type {
          width: 80%;
        }
      }
    }
  }
  .landsSoldContainer {
    width: 100%;
    border-top: 2px solid #fff;
    background-color: #000000;
    padding-top: 46px;
    padding-bottom: 100px;
    position: relative;
    z-index: 5;
    .landsSold {
      width: 100%;
      padding: 20px 0;
      background: linear-gradient(
                      90deg,
                      rgba(3, 3, 3, 0.78) 0%,
                      rgba(31, 31, 31, 0.78) 8.85%,
                      rgba(31, 31, 31, 0.78) 49.48%,
                      rgba(31, 31, 31, 0.78) 90.1%,
                      rgba(0, 0, 0, 0.78) 100%
      );
      h3 {
        padding-left: 154px;
        font-size: 64px;
        margin-bottom: 31px;
      }
      .progressBarContainer {
        width: 100%;
        padding-left: 154px;
        background: linear-gradient(
                        90deg,
                        #030303 0%,
                        #3e3415 8.85%,
                        #322a23 49.48%,
                        #4e382d 90.1%,
                        #000000 100%
        );
        .progressBar {
          width: 80%;
          border: 1px solid #ffc328;
          padding: 13px;
          height: 60px;
          .progressBarArrows {
            position: relative;
            background-color: #7e635c;
            width: 100%;
            height: 100%;
            span {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translateX(-50%) translateY(-42%);
              font-size: 29px;
              font-weight: 700;
            }
            .gradient {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              height: 60px;
              width: 100%;
              background-image: linear-gradient(
                              270deg,
                              #ff2e00 4.15%,
                              #ffc700 96.14%
              ),
              linear-gradient(
                              90deg,
                              #ad00ff 3.86%,
                              #00a3ff 21.79%,
                              #26ff03 40.09%,
                              #db00ff 61.93%,
                              #ff0000 78.8%,
                              #ffe600 95.85%
              );
            }
            &::after {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 60px;
              background-image: url("../../Image/progress_bar_arrow_landSale.svg");
              background-repeat: space;
            }
            &::before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 60px;
              background: linear-gradient(90deg, #cac3a8 0%, #7c605a 100%);
            }
          }
        }
      }
    }
  }
  button.formControl {
    width: 370px;
    height: 75px;
    font-family: Poppins, sans-serif;
    margin: 0 auto;
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    position: relative;
    &::after,
    &::before {
      display: none;
      content: "";
      position: absolute;
      top: -12px;
      left: -10px;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      background-repeat: no-repeat;
      background-image: url("../../Image/btn-apply-guildHover.svg");
    }
    &::after {
      transform: rotate(180deg);
    }
    &::before {
      top: 9px;
      left: 7px;
    }
    &:not(:disabled):hover:not(:active) {
      &::after,
      &::before {
        display: block;
      }
    }
    &:disabled {
      position: relative;
      color: #ffc328;
      &::after {
        background-image: url("../../Image/sold-out.svg");
        position: absolute;
        top: -133px;
        left: 196px;
        height: 255px;
        z-index: 10;
        transform: rotate(0deg);
        background-size: 292px 276px;
        display: inline-block;
        content: "";
      }
    }
  }
  .applyGuild {
    position: relative;
    z-index: 4;
    background-size: cover;
    width: 100%;
    background-image: url("../../Image/apply-guild-bg.svg");
    padding: 67px 0 93px 0;
    text-align: center;
    background-repeat: no-repeat;
    color: #000000;
    margin-bottom: 110px;
    .guildBrands {
      display: flex;
      align-items: center;
      height: 142px;
      width: 100%;
      .guildBrandsLogos {
        background-color: #000000;
        width: calc(83% - 90px);
        position: relative;
        opacity: 0.81;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-repeat: no-repeat;
        padding-left: 143px;
        padding-right: 100px;
        height: 142px;
        &::after {
          content: "";
          position: absolute;
          top: 0;
          right: -89px;
          background-color: #000000;
          width: 90px;
          height: 142px;
          clip-path: polygon(100% 0, 0 0, 0 100%, 20% 100%);
        }
      }
      .guildBrandsOhters {
        align-self: flex-end;
        background-color: #000000;
        opacity: 0.64;
        color: #ffc328;
        font-size: 58px;
        line-height: 58px;
        font-weight: 800;
        font-style: italic;
        width: 19%;
        clip-path: polygon(95px 0, 100% 0, 100% 100%, 25px 100%);
        height: 142px;
        display: flex;
        align-items: center;
        padding-left: 90px;
      }
    }
    h3 {
      font-size: 97px;
      margin-bottom: 25px;
    }
    p {
      font-size: 24px;
      margin-bottom: 45px;
    }
  }
  .timer {
    position: relative;
    background-repeat: no-repeat;
    width: 100%;
    color: #000000;
    background-size: cover;
    background-image: url("../../Image/timer-bg.svg");
    padding-top: 50px;
    padding-bottom: 80px;
    text-align: center;
    h3 {
      font-size: 97px;
    }
    h2 {
      font-size: 150px;
      span {
        margin-right: 35px;
      }
    }
    p {
      font-size: 29px;
      font-weight: 700;
    }
    img[alt="mirror"] {
      position: absolute;
      top: 13px;
      width: 103%;
      object-fit: cover;
      left: 13px;
      min-height: 480px;
      max-height: 480px;
      pointer-events: none;
    }
    img[alt="lock"] {
      position: absolute;
      top: 0;
      right: 185px;
    }
    button.formControl {
      margin-top: 35px;
    }
    &.landSaleEnded {
      padding: 167px 0;
    }
    &.connectWalet {
      padding-top: 80px;
      h3 {
        margin-bottom: 26px;
      }
      .walletNumber {
        background-image: url("../../Image/walletConnected.svg");
        width: 725px;
        height: 75px;
        font-size: 24px;
        margin: 0 auto;
        margin-top: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        span {
          color: #ffc328;
          margin-right: 5px;
        }
      }
    }
  }
  .purchaseLands {
    width: 100%;
    padding-left: 154px;
    padding-top: 130px;
    padding-right: 129px;
    box-sizing: border-box;
    &.topPurchase {
      padding-left: 0;
      padding-top: 35px;
      .randomKingdom {
        @media (min-width:1024px) {
          background-color: #000;
          width: fit-content;
        }
        h4 {
          margin-left: 10%;
        }
        .purchaseLands__actions {
          margin-left: 0;
        }
      }
    }
    @media(min-width: 1024px) {
      h3.purchaseLandsTitle::after {
        background-color: #000;
        z-index: -1;
      }
      .content {
        padding-top: 45px;
        padding-left: 30px;
        background-color: #000;
      }
    }
    h3 {
      font-size: 120px;
      margin-bottom: 20px;
      z-index: 10;
      position: relative;
      span {
        color: #ffc328;
      }
      &.purchaseLandsTitle {
        &::after {
          content: "";
          position: absolute;
          bottom: -22px;
          left: -154px;
          width: 816px;
          height: 65px;
          background-repeat: no-repeat;
          background-image: url("../../Image/moreInfoUnderLineDesktop.svg");

        }
      }
    }
    .content {
      width: 26%;
      margin-bottom: 82px;
      margin-left: 20px;
    }
    h4 {
      font-size: 36px;
      margin: 0 auto;
      margin-bottom: 17px;
      width: fit-content;
      &.randomLand {
        margin: 0;
        margin-left: 23%;
        margin-bottom: 17px;
      }
    }
    .specificKingdom {
      width: fit-content;
      margin-left: auto;
      @media (min-width: 1024px) {
        margin-bottom: 60px;
        padding-bottom: 35px;
        background-color: #000;
      }
      .input_block {
        margin-top: 35px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      h3 {
        color: #ffc328;
        font-size: 53px;
        text-align: start;
        margin: 65px 0;
        margin-right: auto;
        position: relative;
        &::after,&::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 100px;
          transform: translateY(-50%);
          width: 210px;
          height: 5px;
          background-color: #FFC328;
        }
        &::before {
          left: -253px;
        }
      }
      & > span {
        display: block;
        color: #ffc328;
        width: fit-content;
        margin: 10px auto;
        margin-bottom: 58px;
        font-size: 24px;
      }
    }
    .purchaseLands__actions {
      &.purchaseLands__actionsRandom {
        margin-left: 10%;
      }
      width: 777px;
      height: 77px;
      display: flex;
      align-items: center;
      background-image: url("../../Image/purchaseLandActions.svg");
      background-repeat: no-repeat;
      .purchaseActions button,
      .purchaseActions__buy button {
        position: relative;
        &:disabled {
          color: #bfbfbf;
        }
        &::after,
        &::before {
          display: none;
          content: "";
          position: absolute;
          top: -7px;
          left: 7px;
          width: calc(100% + 2px);
          height: calc(100% + 2px);
          background-repeat: no-repeat;
        }
        &::before {
          top: 7px;
          left: -7px;
        }
        &:not(:disabled):hover:not(:active) {
          &::after,
          &::before {
            display: block;
          }
        }
      }
      .purchaseActions {
        display: flex;
        align-items: center;
        span {
          display: flex;
          background-repeat: no-repeat;
        }
        .purchaseActions__decrement,
        .purchaseActions__increment {
          width: 72px;
          height: 75px;
          &::after,
          &::before {
            background-image: url("../../Image/incrementHover.svg");
          }
          justify-content: center;
          align-items: center;
          font-size: 24px;
          font-weight: 500;
          color: #5b0d00;
          cursor: pointer;
        }
        .purchaseActions__decrement {
          &::after,
          &::before {
            background-image: url("../../Image/decrementHower.svg");
          }
        }
        .purchaseActions__landNumber {
          height: 75px;
          width: 162px;
          align-items: center;
          justify-content: center;
          font-size: 24px;
          font-weight: 500;
          color: #ffc328;
        }
        .purchaseActions__max {
          width: 97px;
          height: 75px;
          align-items: center;
          font-size: 24px;
          justify-content: center;
          padding-left: 20px;
          cursor: pointer;
          &::after,
          &::before {
            background-image: url("../../Image/MaxHover.svg");
            background-size: contain;
          }
          color: #000000;
          margin-right: 16px;
        }
      }
    }
    .purchaseActions__buy {
      width: 358px;
      height: 77px;
      display: flex;
      align-items: center;
      justify-content: center;
      .purchaseActions__total {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 206px;
        height: 75px;
        color: #ffc328;
        font-size: 24px;
      }
      button.purchaseActions__purchase {
        width: 150px;
        &::after,
        &::before {
          background-image: url("../../Image/purchaseHover.svg");
        }
        height: 75px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #5b0d00;
        font-size: 24px;
        font-weight: 500;
      }
    }
  }
  .moreInfo {
    position: relative;
    display: flex;
    flex-direction: column;

    h3 {
      padding-top: 75px;
      background-color: #000;
      width: fit-content;
      padding-left: 200px;
      color: #ffc328;
      margin-bottom: 75px;
      font-size: 120px;
      line-height: 120px;
      position: relative;
      span {
        color: #fff;
      }
      &::after {
        content: "";
        position: absolute;
        bottom: -32px;
        left: 0;
        width: 816px;
        height: 65px;
        background-repeat: no-repeat;
        background-image: url("../../Image/moreInfoUnderLineDesktop.svg");
      }
    }
    & > .moreInfoText {
      align-self: flex-end;

      p {
        margin-right: 120px;
        font-size: 24px;
        max-width: 740px;
        width: 100%;
        text-align: right;
        line-height: 34.8px;
      }
    }
    .images {
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        font-size: 182px;
        font-weight: 700;
        @media (min-width: 1024px) {
          top: 261px;
          position: relative
        }
        margin-left: clamp(
                0,
                calc((110vw - 1353px - 740px) / 2),
                calc((110vw - 1353px - 740px) / 2)
        );
        margin-right: clamp(
                0,
                calc((110vw - 1353px - 740px) / 2),
                calc((110vw - 1353px - 740px) / 2)
        );
      }
      transform: translateY(-250px) translateX(-386px);
      position: relative;
      img[alt="habitat-2"] {
        //@media (min-width: 1024px) {
        //  left: 87px;
        //  position: relative;
        //
        //}
        max-width: 1353px;
      }
      img[alt="land-price"] {
        position: absolute;
        top: 26px;
        left: 44%;
        max-width: 470px;
        width: 100%;
      }
      img[alt="boxes"] {
        @media (min-width: 1024px) {
          left: 87px;
          position: relative;
          top: 38px;
        }
        max-width: 740px;
      }
    }
  }


@media (min-width: 2100px) {
  .images {
    margin: 0 auto;
    justify-content: space-around;

    margin-left: 20vw !important;
  }
}
@media (min-width: 3500px) {
  .images {
    margin: 0 auto;
    margin-top: 7vw;
    justify-content: space-around;
    margin-left: 14vw !important;
  }
}
@media (max-width: 1530px) {
    .habitat {
      top: 190px;
      right: -600px;
    }
    .pageTitle__info {
      padding-left: 75px;
    }
    .timer {
      img[alt="lock"] {
        top: -45px;
        right: 40px;
      }
    }
    .applyGuild {
      .guildBrands {
        .guildBrandsLogos {
          padding-left: 60px;
          padding-right: 50px;
        }
      }
    }
    .purchaseLands {
      .specificKingdom {
        padding-left: 20px;
        margin-right: -7%;
        h3 {
          margin-left: 15%;
        }
      }
      h4.randomLand {
        margin-left: 14%;
      }
      .purchaseLands__actions {
        &.purchaseLands__actionsRandom {
          margin-left: -5%;
        }
      }
    }
    .moreInfo {
      .images {
        //transform: translateY(-120px) translateX(-350px);
        transform: translateY(-120px) translateX(-350px);
        img[alt="habitat-2"] {
          max-width: 930px;
          width: 100%;
        }
        img[alt="land-price"] {
          max-width: 400px;
          width: 100%;
          top: 80px;
          left: 70%;
        }
      }
    }

}
@media (min-width: 1024px) and (max-width: 1440px) {

    .pageTitle__info {
      padding-left: 75px;
      .pageInfo {
        p:nth-of-type(2) {
          width: 75%;
        }
      }
    }
    .moreInfo {
      .images {
        span {
          margin-left: clamp(1rem, 2.5vw, 2rem);
          margin-right: clamp(1rem, 2.5vw, 2rem);
        }
        img {
          width: 550px;
        }
        img[alt="land-price"] {
          max-width: 407px;
          width: 100%;
          // top: -26px;
          left: calc(100vw - 45vw);
        }
      }
    }

}

@media (min-width: 1366px) {
  .images {
    img[alt="land-price"] {
      max-width: 407px;
      width: 100%;
      top: -26px;
      left: 38%;
    }
  }
}

@media (max-width: 1224px) {
  .input_block {
    justify-content: flex-start !important;
    p {
      margin-top: 0;
      padding-top: 10px;
    }
  }
  .background {
    background-image: url("../../Image/Landsale-bgTablet.svg");
  }
    .pageTitle__info {
      padding-left: 50px;
      .pageTitle {
        font-size: 100px;
        margin-bottom: 24px;
      }
      .imageHolder {
        width: 100%;
        display: flex;
        align-items: center;
        img {
          width: 1133px;
          margin-left: auto;
        }
      }

      .pageInfo {
        width: 75%;
        font-size: 18px;
        p {
          margin-bottom: 48px;
          &:last-of-type {
            width: 80%;
          }
        }
      }
    }
    button.formControl {
      width: 297px;
      height: 61px;
      font-size: 18px;
      margin-bottom: 2rem;
      &:not(:disabled):hover:not(:active) {
        &::after,
        &::before {
          display: none;
        }
      }
      &:disabled {
        &::after {
          background-image: url("../../Image/sold-out.svg");
          position: absolute;
          top: -132px;
          left: 171px;
          height: 255px;
          z-index: 10;
          transform: rotate(0deg);
          background-size: 232px 278px;
          display: inline-block;
          content: "";
        }
      }
    }
    .applyGuild {
      background-image: url("../../Image/apply-guild-bg-tablet.svg");
      padding: 110px 0;
      h3 {
        font-size: 65px;
      }
      p {
        font-size: 18px;
        width: 85%;
        margin: 0 auto;
        margin-bottom: 32px;
      }
      .guildBrands {
        height: 107px;
        .guildBrandsLogos {
          height: 107px;
          position: relative;
          overflow: visible;
          padding-left: 25px;
          padding-right: 0px;
          width: calc(72% - 90px);
          img {
            &:nth-of-type(1) {
              width: 196px;
              order: 0;
            }
            &:nth-of-type(2) {
              order: 1;
              width: 186px;
              z-index: 5;
              @media (max-width: 769px) {
                transform: translateX(-35px);
              }
            }
            &:nth-of-type(3) {
              @media (max-width: 769px) {
                transform: translateX(-20px);
              }
            }
          }
          &::after {
            height: 107px;
          }
        }
        .guildBrandsOhters {
          height: 107px;
          width: 26%;
          font-size: 42px;
          line-height: 42px;
        }
      }
    }

    .timer {
      background-image: url("../../Image/apply-guild-bg-tablet.png");
      background-size: 101%;
      padding-top: 90px;
      background-color: #000;
      &.landSaleEnded {
        padding: 167px 0;
      }
      &.connectWalet {
        padding: 120px 0;
        .walletNumber {
          background-image: url("../../Image/walletConnectedTablet.svg");
          background-repeat: no-repeat;
          width: 540px;
          height: 61px;
          font-size: 18px;
          margin-bottom: 150px;
        }
      }
      h3 {
        font-size: 65px;
        margin-bottom: 14px;
      }
      h2 {
        font-size: 100px;
        span {
          margin-right: 20px;
        }
      }
      p {
        font-size: 18px;
      }
      img[alt="mirror"] {
        position: absolute;
        top: 16px;
        width: calc(100% - 16px);
        left: 16px;
        min-height: 400px;
        max-height: 400px;
        pointer-events: none;
      }
      img[alt="lock"] {
        width: 70px;
        height: 103px;
        position: absolute;
        top: -10px;
        right: 5px;
      }
    }
    .play_timer{
      background-image: url("../../Image/apply-guild-bg-tablet-2.svg");
      background-size: 101% 100%;
      img[alt="mirror"] {
        position: absolute;
        top: 16px;
        width: calc(100% - 16px);
        left: 15px;
        min-height: auto;
        max-height: none;
        pointer-events: none;
        height: 369px;
        object-fit: fill;
      }
    }
    .moreInfo {
      h3 {
        padding-left: 100px;
        font-size: 70px;
        line-height: 70px;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          top: 135px;
          left: 0;
          width: 461px;
          height: 93px;
          background-repeat: no-repeat;
          background-image: url("../../Image/moreInfoUnderLine.svg");
        }
      }
      & > .moreInfoText {
        p {
          margin-right: 50px;
          margin-left: auto;
          font-size: 18px;
          width: 74%;
          text-align: start;
          margin-bottom: 15px;
          line-height: 26.1px;
        }
      }

      .images {
        flex-wrap: wrap;
        font-size: 182px;
        font-weight: 700;
        transform: translateY(0) translateX(0);
        div {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-size: 130px;
          & > img {
            width: 64% !important;
            margin-left: 20px;
            margin-right: 30px;
          }
        }
        img[alt="habitat-2"] {
          top: 35px;
          position: relative;
          transform: translateX(-15%);
          max-width: 932px;
          width: 932px;
          @media (min-width: 1024px) {
            height: 706px;
          }

          left: -45px;
          //width: 100%;
        }
        img[alt="land-price"] {
          max-width: 407px;
          width: 100%;
          top: 26px;
          left: 48%;
        }
      }
    }
    .landsSoldContainer {
      .landsSold {
        h3 {
          padding-left: 48px;
          font-size: 70px;
        }
        .progressBarContainer {
          width: 100%;
          padding-left: 48px;
          .progressBar {
            width: 84%;
            height: 75px;
            padding: 11px 13px;
            .progressBarArrows {
              .gradient {
                height: 75px;
              }
              &::after {
                height: 75px;
                background-size: auto 75px;
              }
              &::before {
                height: 75px;
              }
            }
          }
        }
      }
    }
    .purchaseLands {
      padding-top: 90px;
      padding-left: 0px;
      padding-right: 0;
      background-color: #000;
      &.topPurchase {
        background-color: unset;
        transform: translateX(-50px);
        width: calc(100% + 50px);
        .randomKingdom {
          margin-bottom: 65px;
        }
      }
      h3 {
        padding-left: 94px;
        font-size: 70px;
        position: relative;
        &.purchaseLandsTitle {
          &::after {
            content: "";
            position: absolute;
            top: -8px;
            left: 0;
            width: 461px;
            height: 93px;
            background-repeat: no-repeat;
            background-image: url("../../Image/moreInfoUnderLine.svg");
          }
        }
      }
      .content {
        padding-left: 94px;
        width: 55%;
        margin-bottom: 82px;
        margin-left: 0px;
      }
      h4 {
        font-size: 43px;
        width: fit-content;
        margin: 0;
        margin-bottom: 40px;
        &.randomLand {
          margin-bottom: 30px;
        }
      }
      .randomKingdom {
        padding-left: 47px;
        padding-top: 39px;
        padding-bottom: 46px;
        background: linear-gradient(
                        90deg,
                        #252525 0%,
                        rgba(60, 60, 60, 0.373106) 62.17%,
                        rgba(0, 0, 0, 0) 100%
        );
        mix-blend-mode: normal;
        h4 {
          margin-left: 0;
        }
      }
      .specificKingdom {
        padding-left: 47px;
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        padding-bottom: 46px;
        background: linear-gradient(
                        90deg,
                        #252525 0%,
                        rgba(60, 60, 60, 0.373106) 62.17%,
                        rgba(0, 0, 0, 0) 100%
        );
        mix-blend-mode: normal;
        div[data-type="search-input"] {
          margin: 0;
        }
        h3 {
          font-size: 65px;
          text-align: center;
          margin: 0 auto;
          padding: 30px 0;
          margin-bottom: 39px;
          background-color: #000000;
          width: 100%;
          transform: translateX(-47px);
          &::after,&::before {
            display: none;
          }
        }
        & > span {
          width: fit-content;
          margin: 0;
          margin-bottom: 15px;
          font-size: 18px;
        }
      }
      .purchaseLands__actions {
        margin-top: 40px;
        &.purchaseLands__actionsRandom {
          margin-left: 0%;
        }
        .purchaseActions button,
        .purchaseActions__buy button {
          &:not(:disabled):hover:not(:active) {
            &::after,
            &::before {
              display: none;
            }
          }
        }
        margin-bottom: 46px;
        width: 616px;
        height: 62px;
        background-image: url("../../Image/purchaseLandActionsTablet.svg");
        .purchaseActions {
          display: flex;
          align-items: center;
          .purchaseActions__decrement,
          .purchaseActions__increment {
            width: 58px;
            height: 60px;
            font-size: 20px;
          }

          .purchaseActions__landNumber {
            height: 60px;
            width: 120px;
            font-size: 18px;
          }
          .purchaseActions__max {
            width: 80px;
            height: 60px;
            font-size: 18px;
            padding-left: 20px;
            margin-right: 15px;
          }
        }
      }
      .purchaseActions__buy {
        width: 285px;
        height: 60px;
        .purchaseActions__total {
          width: 164px;
          height: 60px;
          font-size: 18px;
        }
        button.purchaseActions__purchase {
          width: 122px;
          height: 62px;
          font-size: 18px;
        }
      }
    }

}

@media (max-width: 670px) {
  .input_block {
    margin-top: 12px;
  }
  .background {
    background-image: url("../../Image/Landsale-bgMobile.svg");
  }
    .pageTitle__info {
      padding-left: 20px;
      .pageTitle {
        font-size: 50px;
        margin-bottom: 34px;
      }
      .imageHolder {
        display: flex;
        align-items: center;
        width: 100%;
        img {
          width: 653px;
          transform: translateX(-20px);
        }
      }

      .pageInfo {
        padding-left: 60px;
        width: 75%;
        font-size: 16px;
        p {
          margin-bottom: 20px;
          &:last-of-type {
            width: 100%;
          }
        }
      }
    }
    button.formControl {
      height: 56px;
      font-size: 18px;
      margin-bottom: 0;
      &:disabled {
        &::after {
          background-image: url("../../Image/sold-out-mobile.svg");
          position: absolute;
          top: -91px;
          left: 165px;
          height: 250px;
          z-index: 10;
          transform: rotate(0deg);
          background-size: 151px 186px;
          display: inline-block;
          content: "";
        }
      }
    }
    .applyGuild {
      background-image: url("../../Image/apply-guild-bg-tablet.svg");
      background-position-x: -174px;
      padding: 50px 0;
      padding-top: 50px;
      margin-bottom: 65px;
      &.applyGuild_walletConnected {
        padding-bottom: 25px;
        background-position-x: -165px;
      }
      h3 {
        font-size: 35px;
        span {
          font-size: 78px;
        }
      }
      p {
        font-size: 16px;
        width: 85%;
        margin: 0 auto;
        margin-bottom: 32px;
      }
      .guildBrands {
        height: unset;
        flex-wrap: wrap;
        .guildBrandsLogos {
          height: 152px;
          padding-left: 5px;
          width: calc(100% - 40px);
          justify-content: space-around;
          flex-wrap: wrap;
          img {
            &:nth-of-type(1) {
              width: 196px;
              order: 0;
            }
            &:nth-of-type(2) {
              width: 31.5px;
              transform: translateX(15px) scale(2.5);
              order: 2;
            }
            &:nth-of-type(3) {
              width: 194px;
              order: 4;
              transform: translateY(-15px) translateX(10px);
            }
            &:nth-of-type(4) {
              order: 1;
              transform: translateX(-10px);
              width: 79.73px;
            }
            &:nth-of-type(5) {
              width: 93px;
              order: 3;
              transform: translateY(-15px) translateX(30px) scale(1.3);
            }
          }
          &::after {
            height: 152px;
          }
        }
        .guildBrandsOhters {
          height: 80px;
          width: 45%;
          font-size: 31px;
          line-height: 31px;
          margin-top: 37px;
          margin-left: auto;
        }
      }
    }
    .timer {
      background-size: cover;
      background-position-x: 0;
      padding-top: 90px;
      &.landSaleEnded {
        padding: 147px 0;
      }
      &.connectWalet {
        h3 {
          margin-bottom: 0;
          span {
            display: block;
            line-height: 100px;
            font-size: 78px;
          }
        }
        .walletNumber {
          background-image: unset;
          background-repeat: no-repeat;
          width: 318px;
          height: unset;
          font-size: 18px;
          margin-bottom: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          span {
            font-size: 18px;
            margin-bottom: 8px;
            font-weight: 500;
            color: #000;
          }
          div {
            background-image: url("../../Image/walletNumberMobile.svg");
            background-size: 321px 56px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 56px;
          }
        }
      }
      h3 {
        font-size: 35px;
        margin-bottom: 14px;
        & > span {
          font-size: 78px;
          display: block;
          margin-top: 20px;
        }
      }
      h2 {
        font-size: 78px;
        line-break: normal;
        span {
          display: block;
          width: 100%;
          margin-right: 0px;
        }
      }
      p {
        font-size: 16px;
      }
      img[alt="mirror"] {
        width: 200%;
        transform: translateX(-170px);
        min-height: 420px;
        max-height: 420px;
      }
    }
    .moreInfo {
      margin-top: 80px;
      h3 {
        padding-top: 0;
        background-color: unset;
        padding-left: 45px !important;
        font-size: 40px;
        line-height: 40px;
        color: #fff;
        margin-bottom: 35px;
        &::after {
          top: 95px;
          width: 218px;
          height: 43px;
          background-size: contain;
        }
        span {
          color: #ffc328;
        }
      }
      & > .moreInfoText {
        & h3{
          padding-left: 45px !important;
        }
        p {
          margin-right: 20px;
          font-size: 16px;
          width: 71%;
          margin-bottom: 15px;
          line-height: 23.2px;
        }
      }

      .images {
        div {
          font-size: 70px;
          margin-left: auto;
          & > img {
            width: 63% !important;
          }
        }
        img[alt="habitat-2"] {
          max-width: 600px;
          width: 100%;
          transform: translateX(9%);
        }
        img[alt="land-price"] {
          max-width: calc(100vw - 40vw);
          width: 100%;
          top: -13px;
          left: 42%;
        }
        img[alt="boxes"] {
          max-width: 740px;
          width: 220.94px !important;
          height: 247.24px;
          margin-right: 19px;
          margin-left: 40px;
          margin-top: -50px !important;
        }
      }
    }
    .landsSoldContainer {
      padding: 0;
      border-top: none;
      background-color: transparent;
      margin-bottom: 47px;
      .landsSold {
        padding-top: 41px;
        padding-bottom: 23px;
        h3 {
          padding-left: 20px;
          font-size: 40px;
        }
        .progressBarContainer {
          padding-left: 20px;
          .progressBar {
            padding: 9px 10px;
            width: 82%;
            height: 58px;
            .progressBarArrows {
              .gradient {
                height: 58px;
              }
              span {
                transform: translateX(-50%) translateY(-46%);
              }
              &::after {
                height: 58px;
                background-size: auto 58px;
              }
              &::before {
                height: 58px;
              }
            }
          }
        }
      }
    }
    .purchaseLands {
      padding-left: 0px;
      &.topPurchase {
        padding-left: 0;
        padding-top: 35px;
        .randomKingdom {
          h4 {
            margin-left: 23px;
            margin-bottom: 23px;
          }
          .purchaseLands__actions {
            margin-top: 0;
            margin-left: 23px;
          }
        }
      }
      h3 {
        padding-left: 40px;
        font-size: 40px;
        margin-top: 45px;
        margin-bottom: 42px;
        &.purchaseLandsTitle {
          &::after {
            top: 55px;
            width: 218px;
            height: 43px;
            background-size: contain;
          }
        }
      }
      .content {
        padding-left: 40px;
        width: 75%;
        margin-bottom: 31px;
      }
      h4 {
        font-size: 26px;
        margin-bottom: 20px;
      }
      .randomKingdom {
        padding-left: 30px;
        padding-top: 30px;
        padding-bottom: 30px;
        h4 {
          margin-left: 0;
        }
      }
      .specificKingdom {
        padding-left: 30px;
        h3 {
          font-size: 35px;
          margin: 0;
          margin-bottom: 30px;
          padding: 30px 0;
          width: calc(100vw + 30px);
        }
        & > span {
          font-size: 16px;
          margin-bottom: 23px;
        }
        div[data-type="search-input"] {
          margin: 0;
        }
      }
      .purchaseLands__actions {
        margin-bottom: 0;
        width: 319px;
        flex-wrap: wrap;
        justify-content: flex-start;
        height: unset;
        background-image: unset;
        .purchaseActions {
          width: 319px;
          display: flex;
          align-items: center;
          .purchaseActions__decrement,
          .purchaseActions__increment {
            width: 53px;
            height: 57px;
            font-size: 20px;
            background-size: cover;
            margin-bottom: 23px;
          }
          .purchaseActions__increment {
            margin-right: -5px;
          }

          .purchaseActions__landNumber {
            height: 57px;
            align-self: flex-start;
            background-image: url("../../Image/landSale-total-lands-bg.svg");
            background-repeat: no-repeat;
            width: 135px;
            font-size: 18px;
            background-position-x: -53px;
          }
          .purchaseActions__max {
            width: 80px;
            height: 57px;
            background-size: cover;
            padding-left: 12px;
            margin-right: 0;
            margin-bottom: 23px;
          }
        }
      }
      .purchaseActions__buy {
        width: 319px;
        height: 60px;
        justify-content: flex-start;
        .purchaseActions__total {
          width: 178px !important;
          height: 57px;
          background-image: url("../../Image/landSale-total-bg.svg");
          font-size: 18px;
          flex-grow: 1;
          background-repeat: no-repeat;
        }
        button.purchaseActions__purchase {
          width: 116px;
          height: 57px;
          background-size: cover;
          margin-right: 3px;
          font-size: 18px;
        }
      }
    }
}

@media (min-width: 3800px) {
  .availableLands {
    font-size: 1rem !important;
  }
  .background {

    .pageTitle__info {
      .pageTitle {
        font-size: 5rem;
      }
      .pageInfo {
        width: 65%;
        font-size: 1rem;
      }
    }
    .habitat {
      width: 2184px;
    }
    .landsSoldContainer {
      padding-top: 100px;
      padding-bottom: 150px;
      .landsSold {
        padding: 60px 0;
        .progressBarContainer {
          height: 158px;
          .progressBar {
            height: 130px;
            width: 90%;
            .progressBarArrows {
              &::before {
                height: 130px;
              }
              span {
                font-size: 1.2rem;
              }
              &::after {
                height: 130px;
                background-size: auto 130px;
              }
              .gradient {
                height: 130px;
              }
            }
          }
        }
      }
    }
    .applyGuild {
      padding: 5rem 0;
      h3 {
        font-size: 3rem;
        margin-bottom: 2rem;
      }
      p {
        font-size: 1rem;
        margin-bottom: 2rem;
      }
      .formControl {
        transform: scale(1.5);
      }
      .guildBrands {
        height: 240px;
        .guildBrandsLogos,
        .guildBrandsOhters {
          height: 240px;
          &::after {
            height: 240px;
          }
          img {
            transform: scale(1.6);
          }
        }
      }
    }
    .timer {
      padding: 5rem 0;
      .walletNumber {
        transform: scale(1.6);
      }
      &.connectWalet {
        padding: 5rem 0;
      }
      h3 {
        font-size: 3rem;
        margin-bottom: 2rem;
      }
      p {
        font-size: 1rem;
        margin-bottom: 1rem;
      }
      .formControl {
        transform: scale(1.5);
      }
      img[alt="mirror"] {
        max-height: unset;
        height: 21rem;
      }
      img[alt="lock"] {
        transform: scale(1.5);
        top: 1rem;
      }
    }
    .purchaseLands {
      padding-top: 260px;
      padding-bottom: 6rem;
      &.topPurchase {
        margin-left: 3rem;
        h4 {
          transform: translateX(7rem);
        }
      }
      h4 {
        font-size: 1.5rem;
      }
      .purchaseLands__actions {
        transform: scale(2.3) translateX(4vw) translateY(1vh);
      }
      .specificKingdom {
        padding-left: 346px;
        h3 {
          margin-top: 4rem;
          font-size: 3rem;
          pointer-events: none;
        }
      }
    }
    .moreInfo {
      h3 {
        font-size: 4rem;
        line-height: 4rem;
      }
      .moreInfoText {
        p {
          font-size: 1rem;
          line-height: 1.2rem;
          max-width: 50vw;
        }
      }
      .images {
        img[alt="land-price"] {
          max-width: 22vw;
          left: 45%;
        }
        img[alt="habitat-2"] {
          max-width: unset;
          width: 47rem !important;
        }
        span {
          transform: scale(1.5) translateX(84px);
        }
        img[alt="boxes"] {
          transform: translateX(5rem);
          max-width: 1470px;
        }
      }
    }
  }
}
