.background {
  background-image: url("../../Image/PlayPattern.svg");
  background-repeat: no-repeat;
  padding: 7.813vw 0;
  overflow: hidden;
  & h1 {
    padding-left: 7.813vw;
    font-size: 7.813vw;
    margin-bottom: 1.708vw;
  }
  & h3 {
    font-size: 6.250vw;
  }
  & h6 {
    padding-left: 7.813vw;
    font-weight: 400;
    font-size: 1.250vw;
  }
}

@media (max-width: 1024px) {
  .background {
    background-image: url("../../Image/PlayPatternT.svg");
    h1 {
      font-size: 100px;
      line-height: 100%;
      width: 606.41px;
      margin-top: 50px;
      margin-bottom: 11px;
      padding-left: 50px;
      & span{
        display: block;
      }
    }
    & h3 {
      font-size: 70px;
    }
    & h6 {
      padding-left: 50px;
      font-size: 18px;
      width: 589.01px;
    }
  }
}

@media (max-width: 576px) {
  .background {
    h1 {
      padding-left: 20px;
      font-size: 50px;
      margin-top: 70px;
      margin-bottom: 27px;
      width: 327.62px;
      & span{
        display: inline;
      }
    }
    & h3 {
      font-size: 40px;
    }
    & h6 {
      padding-left: 20px;
      width: 293.32px;
      font-size: 16px;
    }
  }
}
