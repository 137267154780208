.nftPageBots{
  display: flex;
  margin-top: -150px;
  margin-bottom: -50px;
  justify-content: space-between;
  & h3{
    color: #FFC328;
    position: relative;
    &::after{
      content: "";
      position: absolute;
      height: 120px;
      width: 889px;
      right: -180px;
      top: 200px;
      background: url("../../../../Image/nftTitleLine3.svg") 100% 100%;
    }
  }
  &__botImageContainer{
    & img {
      width: 100%;
    }
  }
  &__content{
    overflow: hidden;
    //& button{
    //  filter: brightness(0.5);
    //  pointer-events: none;
    //}
    padding-right: 180px;
    width: 889px;
    margin-top: 350px;
    font-size: 24px;
    & p{
      margin-top: 92px;
      width: 600px;
      position: relative;
      margin-left: auto;
    }
  }
}
@media (min-width: 3200px) {
  .nftPageBots{
    margin-top: 100px;
    padding-bottom: 200px;
    & h3{
      &::after{
        width: 1677px;
        top: 309px;
      }
    }
    &__botImageContainer{
      width: 50vw;
      margin-bottom: -5vw;
    }
    &__content{
      overflow: visible;
      padding-right: 180px;
      width: 1400px;
      font-size: 54px;
      & p{
        width: 1400px;
      }
    }
  }
}
@media (max-width: 1224px){
  .nftPageBots {
    & h3 {
      font-size: 80px;
      &:after{
        top: 80px;
      }
    }
  }
}
@media (max-width: 1800px) and (min-width: 1200px) {
  .nftPageBots{
    margin-top: -250px;
    &__botImageContainer{
      position: relative;
      bottom: -140px;
      height: 70vw;
      & img {
        height: 100%;
        width: 100%;
      }
    }
    &__content{
      margin-top: 270px;
      padding-right: 50px;
      padding-bottom: 200px;
      width: 570px;
    }
    &__botImageContainer{
      bottom: -125px;
      & img {
        height: 70vw;
      }
    }
  }
}
@media (max-width: 1400px) {
  .nftPageBots {
    margin-top: -850px;
  }
}
@media (max-width: 1380px) and (min-width: 1224px){
  .nftPageBots{
    margin-top: -850px;
    &__botImageContainer{
      position: relative;
      bottom: -180px;
      height: 30vw;
      & img {
        height: 100%;
        width: 100%;
      }
    }
    &__content{
      margin-top: 270px;
      padding-right: 50px;
      padding-bottom: 200px;
      width: 570px;
    }
    &__botImageContainer{
      bottom: -500px;
      & img {
        height: 55vw;
      }
    }
  }
}
@media (max-width: 1224px) and (min-width: 1024px){
  .nftPageBots{
    margin-top: -850px;
    &__botImageContainer{
      position: relative;
      bottom: -220px;
      height: 30vw;
      & img {
        height: 100%;
        width: 100%;
      }
    }
    &__content{
      margin-top: 270px;
      padding-right: 80px;
      padding-bottom: 200px;

      width: 570px;
    }
    &__botImageContainer{
      bottom: -450px;
      & img {
        height: 55vw;
      }
    }
  }
}
@media (max-width: 1024px) {
  .nftPageBots{
    margin-top: -50px;
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: -50px;
    & h3{
      text-align: left;
      font-size: 70px;
      padding-left: 32vw;
      &::after{
        height: 130px;
        width: 90vw;
        right: -180px;
        top: 30px;
        background: url("../../../../Image/nftTitleLineTablet3.svg") 100% 100% no-repeat;
      }
    }
    &__botImageContainer{
      margin-bottom: -10px;
      margin-top: 40px;
      & img {
        margin-top: -330px;
        width: 72vw;
        height: 89vw;
      }
    }
    &__content{
      padding-right: 80px;
      width: auto;
      margin-top: 350px;
      font-size: 18px;
      & a{
        font-size: 18px;
      }
      & p{

        margin-top: 42px;
        width: 426px;
        margin-left: auto;
      }
    }
  }
}
@media (max-width: 576px) {
  .nftPageBots {
    & h3 {
      text-align: left;
      font-size: 40px !important;
      &::after {
        height: 64px;
        width: 228px;
        left: 0;
        top: 0;
        background: url("../../../../Image/nftTitleLineMobile.svg") 100% 100%;
      }
    }
  }
}
@media (max-width: 576px) {
  .nftPageBots{
    margin-top: -250px;
    margin-bottom: -50px;
    & h3{
      text-align: left;
      padding-left: 61px !important;
      font-size: 50px;
      width: 100vw;
      &::after{
        height: 64px;
        width: 228px;
        left: 0;
        top: 10px;
        background: url("../../../../Image/nftTitleLineMobile.svg") 100% 100%;
      }
    }
    &__botImageContainer{
      & img {
        width: 100vw;
        height: 699px;
        margin-top: -100px;
        margin-bottom: -200px;
      }
    }
    &__content{
      padding-right: 50px;
      width: auto;
      margin-top: 350px;
      & p{
        font-size: 16px !important;
        width: 265px;
        margin-left: 20px;
        & a{
          font-size: 16px !important;
        }
      }
    }
  }
}