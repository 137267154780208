.gamingGuildsMain{
    padding: 0 7.813vw;
    position: relative;
    & h2{
        font-size: 7.8vw;
        line-height: 100%;
        padding-top: 81px;
    }
    & h3{
        margin-top: 10px;
        font-size: 2.917vw;
        line-height: 98%;
    }

}
.buttonContainer{
    display: flex;
    justify-content: space-between;
    width: 33.125vw;
    height: 74px;
    margin-top: 47px;
    & button, & > a{
        width: 14.844vw;
        font-size: 1.250vw;
        @media (min-width: 1024px) {
            & span{
                font-size: 1.250vw !important;
            }
        }

        text-align: center;
        white-space: normal !important;
    }
}
.exploreButton{
    font-weight: 500;
}
.exploreButton:hover{
    filter:  brightness(110%) contrast(95%);
}
.exploreButton:active{
    filter:   brightness(0.8) contrast(160%);
}
.textContainer{

    margin-top: 48px;
    font-size: 1.250vw;
    line-height: 145%;
    & p{
        max-width: 51.458vw;
        margin-top: 28px;
    }
    & ol{
        list-style: auto;
        max-width: 51.458vw;
        margin-top: 28px;
        margin-left: 1.9vw;
    }
    & b{
        color: red;
        font-size: 1.042vw;
    }
}
.bannerImage{
    position: absolute;
    right: 150px;
    top: 150px;
    width: 26.510vw;
    height: 26.510vw;
    &__trading{
        margin-top: 20px;
        top: 180px;
        width: 28.510vw;
        height: 22.510vw;
        box-sizing: border-box;
    }
}
.rentButtonContainer{
    margin-top: 40px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    font-size: 1.250vw;
    & button{
        width: 17.969vw;
    }
}
@media (min-width: 2800px) {
    .buttonContainer {
        display: flex;
        justify-content: space-between;
        width: 33.125vw;
        height: 74px;
        margin-top: 47px;
        margin-bottom: 100px;
        & button, & > a {
            width: 490px;
            height: 130px;
            font-size: 45px;

        }
    }
}
@media(max-width: 1024px){
    .gamingGuildsMain{
        padding: 0 30px;
        & h2{
            font-size: 100px;
        }
        & h3{
            margin-top: 10px;
            font-size: 46px;
            text-align: left;
            width: 469.71px;
        }

    }
    .exploreButton span{
        font-size: 18px !important;
    }
    .buttonContainer{
        flex-direction: column;
        align-items: center;
        height: auto;
        gap: 30px;
        & button, & > a{
            width: 232.34px;
            font-size: 18px;
            height: 61px;
            & span{
                font-size: 18px;
            }
        }
    }
    .bannerImage{
        right: 50px;
        top: 305px;
        width: 50.781vw;
        height: 50.781vw;
        &__trading{
            width: 39.781vw;
            height: 39.781vw;
            top: 260px;
            box-sizing: border-box;
        }
    }
    .textContainer{
        margin-top: 48px;
        font-size: 18px;
        line-height: 145%;
        & p:nth-child(1){
            max-width: 260.09px;
        }
        & p:nth-child(3){
            margin-top: 18px;
        }
        & ol{
            margin-top: 23px;
            max-width: none;
            & li:first-child{
                max-width: 315.39px;
                display: list-item;
            }
        }
        & b{
            font-size: inherit;
        }
    }
    .rentButtonContainer{
        font-size: 61px;

        & button{
            width: 233px;
        }
    }
}
@media(max-width: 576px){
    .gamingGuildsMain{
        padding: 0 20px;
        & h2{
            font-size: 50px;
            padding-top: 40px;
        }
        & h3{
            margin-top: 10px;
            font-size: 35px;
            text-align: left;
            width: auto;
            padding: 0 !important;
        }
        &__trading{
            display: flex;
            flex-direction: column;
            order: 2;
        }

    }
    .buttonContainer{
        order: 3;
        margin-top: 40px;
        flex-direction: column;
        height: auto;
        gap: 15px;
        width: 100%;
        align-items: flex-start;
        & button, & > a{

            height: 56px;
            width: 213px;
            & span{
                font-size: 18px !important;
            }
        }
        & button{
            margin-left: 30px;
        }
        & > a{
            &:first-child{
                align-self: flex-start;
                margin-left: 30px;
            }
            &:last-child{
                margin-left: 100px;
            }
        }
    }
    .bannerImage{
        right: 20px;
        top: 654px;
        width: 318px;
        height: 318px;
        left: 50%;
        transform: translateX(-50%);
        &__trading{
            right: 0;
            top: 0;
            order: 2;
            width: 318px;
            height: 318px;
            position: relative;
        }
    }
    .textContainer{
        margin-top: 40px;
        font-size: 16px;
        max-width:286.33px;
        line-height: 145%;
        & p:nth-child(1){
            max-width: none;
        }
        & p:nth-child(3){
            margin-top: 382px;
        }
        & ol{
            margin-top: 5px;
            max-width:286.33px;
            margin-left: 18px;
            & li:first-child{
                max-width: 315.39px;
                display: list-item;
                margin-bottom: 0;
            }
        }
        & b{
            font-size: inherit;
        }


    }
    .rentButtonContainer{
        order: 3;
        font-size: 61px;
        justify-content: center;
        & button{
            height: 56.000762939453125px;
            width: 249.44140625px;

        }
    }
}