.header {
  .header-menu_wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  @media (max-width: 576px) {
    .header-menu_wrapper {
      overflow-y: auto;
      pointer-events: all;
      justify-content: flex-start !important;
      width: 100vw !important;
    }
  }
  .connect{
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    width: 170px;
    display: flex;
    align-items: center;
    pointer-events: all;
    cursor: pointer;
    & svg{
      margin-right: 15px;
    }
    &:hover{
      color: #FFC328;
      & svg path{
        fill: #FFC328;
      }
    }
  }
  .play-button{
    text-decoration: none;
    font-weight: 500;
    font-size: 16px;
    height: 47.27488327026367px;
    width: 183.490234375px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../Image/button-type1.svg");
    background-size: 100% 100%;
    color: #5B0D00;
    cursor: pointer;
    pointer-events: all;
    //margin-left: 42px;
    &:hover{
      filter: brightness(110%) contrast(95%);
    }
    &:active{
      filter: brightness(0.8) contrast(160%);
    }
  }
  .header-menu__item {
    font-size: 21px;
  }
  .header-menu__item-submenu{

    & img{
      filter: grayscale(1) brightness(120%);
      position: relative;

      height: 14px;
      margin-right: 17px;
    }

  }
  .header-menu__item-submenu__item{
    &.connect:after{
      left: 33px;
    }
    &:hover img{
      filter: none;
    }
  }

  @media (max-width: 1024px) {
    .header-popup {
      min-height: -moz-available;
      min-height: -webkit-fill-available;
      .header-menu_wrapper {
        padding-top: 17px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .play-button{
        margin-bottom: 21px;
      }
      .connect{
        border: 1.5px solid #FFFFFF;
        //padding: 19px 45px;
      }
      .header-menu__item {
        //margin-top: 0 !important;
        font-size: 20px;
        margin-bottom: 3px;
      }
      .header-menu__item span{
        font-size: 44px;
      }
      .header-menu__item-submenu__item {
        font-size: 20px;
        margin-bottom: 14px;
      }

      .header-menu__item-submenu__item:last-child {
        margin-bottom: 0px;
      }
      .header-menu__item-submenu__item {
        margin-bottom: 15px;
      }

      @media (min-width: 768px) {
        .header-right {
          width: 100%;
          display: flex;
          align-items: flex-end;
          position: absolute;
          bottom: 28px;
          right: 28px;
          height: unset;
        }
      }

      @media (max-width: 768px),
        (max-width: 926px) and (orientation: landscape) {
        .header-right {
          display: flex;
          position: absolute;
          bottom: 28px;
          right: 28px;
          height: unset;
          width: unset;
          align-items: flex-end;
        }

      }
      @media (max-width: 576px) {
        .header-menu__item span{
          font-size: 24px;
        }
        .header-menu__item-submenu__item {
          font-size: 16px;
          margin-bottom: 5px;
        }
        .header-menu_wrapper{
          margin-top: 20px;
        }
      }


      .connect {
        border: none;
      }



      .header-menu__item-submenu{
        margin-top: 10px;
        opacity: 1;
        visibility: visible;
        height: auto;
        transition: .3s ease;
        display: flex;
        padding-bottom: 10px;
        & img{
          position: relative;
          top: 2px;

          height: 15.417360305786133px;
          width: 18.417360305786133px;
          margin-right: 17px;
        }
      }
      .header-menu__item-submenu.hide{
        padding-bottom: 0;
        visibility: hidden;
        height: 0;
        opacity: 0;
        transition: .3s;
      }

      .header-menu__item span{
        position: relative;
        z-index: 20;
        pointer-events: all;
        &::after{
          content: "";
          width: 16.57px;
          height: 9.74px;
          position: absolute;
          top: 6px;
          right: -23px;
          background: url("../../Image/headerArrow.svg") no-repeat;
          transform: rotate(180deg);
        }
        &:last-child{
          &::after{
            display: none;
          }
        }
        &.hide{
          &:after{
            transform: rotate(0);
          }
        }
      }
    }
    @media (max-width: 1024px) and (min-width: 576px) {
      .header-menu__item {
        margin-top: 30px !important;
      }
      .header-menu__item span{
        &::after{
          width: 24.57px !important;
          height: 13.74px !important;
          top: 15px !important;
          right: -37px !important;
        }
      }
    }
    @media (max-height: 356px) {
      .header-popup {
        padding-top: 56px;

        .header-menu_wrapper {
          height: 100%;
          display: block;
        }

        .header-menu__item {
          font-size: 16px;
          margin-bottom: 12px;
        }

        .header-menu__item-submenu__item {
          font-size: 12px;
        }
      }

      .header-bg-image {
        height: 56px;
        background-size: contain;
        margin-top: 3.3333333333px;
      }

      //.header-logo {
      //  transform: scale(0.6666666666666666667);
      //}
    }
  }
}

@media (min-width: 3800px) {
  .header.js-header {
    .header-logo {
      margin-top: 1.5rem;
    }
    .header-menu__item.js-header-menu-item {
      font-size: 1rem;
      transform: translateX(-1.5rem) translateY(0.5rem);
    }
    .header-socials__item svg {
      width: 56.25px;
      height: 72px;
      transform: translateX(-1rem) translateY(0.5rem);
    }
    .header-menu__item-submenu__item {
      font-size: 0.65rem;
    }
  }
}
@media (max-width: 1500px ) and (min-width: 1240px) {
  .header-menu__item{
    padding: 20.75px 1vw;
  }
}