.background {
  background-image: url("../../Image/cardsPageBg.svg");
  padding: 100px 150px;
  & h3 {
    margin-top: 40px;
    text-align: left;
  }
  & h4{
    margin-top: 40px;
  }
}
.desc{
  margin-top: 20px;
  font-size: 24px;
}
.category{
  margin-top: 25px;
  & a{
    text-decoration: none;
    color: #00a3ff;
  }
}
.alphabetWrapper{
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  gap: 20px;
  color: #00a3ff;
  font-size: 30px;
  font-weight: 600;
  & span{
    cursor: pointer;
  }
}
.itemsWrapper{
}
.item{
  margin-top: 25px;
  text-decoration: none;
  color: #00a3ff;
  &__desk{
    margin-bottom: 20px;
    color: #FFFFFF;
  }
}
@media (max-width: 1024px) {
  .background {
    padding: 100px 50px;
  }
}
@media (max-width: 675px) {
  .background {
    padding: 100px 20px;
  }
}