.reward{
  text-align: center;
  margin-bottom: 100px;
  & span{
    color: red;
  }
}
.guild__pagination {
  display: flex;
  justify-content: right;
  margin-right: 67.09px;
  margin-bottom: 55px;

  &__block {
    display: flex;
    gap: 19.74px;
    background: linear-gradient(90deg, rgba(29, 29, 29, 0) 15%, #0B0B0B 27.99%, #1D1D1D 47.49%, #0B0B0B 72.98%, rgba(29, 29, 29, 0) 95.97%);
  }

  &__arrow {
    font-size: 28px;
    margin-top: 0;
  }

  &__number {
    cursor: pointer;
    color: #fff;
    font-size: 18px;
  }

  &__tip {
    display: flex;
    align-items: center;

    &__input {
      background-color: #060606;
      width: 39px;
      height: 22px;
      padding: 0;
      margin-right: 9.59px;

      font-family: 'Poppins';
      font-style: normal;
      font-size: 18px;
      line-height: 145%;
      /* identical to box height, or 26px */

      text-align: center;

      /* White */

      color: #fff;
    }

    &__btn {
      background-color: #FFC328;
      width: 36.7px;
      height: 17.27px;
      color: #5B0D00;
      font-weight: 600;
    }
  }
}
.disabledLink{
  filter: brightness(.5);
}
.guild__table {
  width: 100%;
  table-layout: fixed;
  border-spacing: 0 21.36px;
  border-collapse: separate;
  margin-bottom: 29.35px;
  padding: 32.14px 0;
  border-top: #FFC328 2px solid;
  border-bottom: #FFC328 2px solid;
  margin-top: 98.01px;

  & td[datatype='Size']{
    width: 160px;
  }
  &__header {
    height: 64px;
    background-color: #1D1D1D;
  }

  &__headData {
    font-size: 23px;
    width: 430px !important;
    padding: 15px 0 15px 145.54px;
    & span:nth-child(1){
      position: relative;
      cursor: pointer;
      &:after{
        content: "";
        position: absolute;
        top: 5px;
        right: -30px;
        background: url("../../Image/tableArrows.svg");
        width: 16px;
        height: 20px;
      }
    }
    &__trading {
      & span:nth-child(1) {
        &:after {
          display: none;
        }
      }
    }

    &__text {
      width: 300px;
      position: relative;
      &:nth-child(2) span{
        position: relative;
        cursor: pointer;
        &:after{
          content: "";
          position: absolute;
          top: 5px;
          right: -30px;
          background: url("../../Image/tableArrows.svg");
          width: 16px;
          height: 20px;
        }
      }
      /* Web 6 */
      font-family: 'Cold Warm';
      //padding: 15px 0;
      font-style: normal;
      font-weight: 400;
      font-size: 30px;
      line-height: 145%;
      /* identical to box height, or 44px */
      /* White */
      color: #FFFFFF;
    }
  }


}

.tooltip__info {
  width: 600px;
  padding: 20.77px 34px 33.51px 34px;
  position: relative;

  &__icon {
    cursor: pointer;
    position: absolute;
    left: 102px;
  }

  &__measureBlock {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  &__measurement {
    &__more {
      margin-left: 59.35px;
    }
    & div{
      width: 30%;
    }
    //justify-content: space-between;
    padding-left: 30.82px;
    background-color: #000;
    /* Web 16 */
    display: flex;
    align-items: center;
    & span{
      margin-left: 5%;
    }
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
    /* or 18px */
    letter-spacing: 0.03em;

    /* White */

    color: #FFFFFF;
  }

  &__title {
    /* Web 7 */
    height: unset !important;
    margin-bottom: 10px !important;
    text-align: left;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 145%;
    /* or 35px */
    /* Black */

    color: #000000;
  }
}
//@media (max-width: 1024px) {
  .guild__table {
    &__leaderboard{
      margin-top: -150px;
    }
    &__wrapper{
      margin-top: -7vw;
      overflow-x: auto;
      &::-webkit-scrollbar {
        display: none;
      }

    }
    min-width: 1310px;
  }
//}
.guilds_arrows{
  display: none;
}
@media (min-width: 3000px) {
  .tooltip__info {
    &__icon {
      width: 1.4vw;
      left: 6vw;
    }
  }
  .guild__table {
    &__headData {
      font-size: 23px;
      &__text {
        font-size: 2vw;
        &:nth-child(2) span{
          &:after{
            right: -1.3vw;
            background: url("../../Image/tableArrows.svg");
            width: .8vw;
            height: 1.7vw;
          }
        }
      }
    }
  }
  .guild__table {
    &__headData {
      & span:nth-child(1) {
        &:after {
          right: -1.3vw;
          background: url("../../Image/tableArrows.svg");
          width: .8vw;
          height: 1.7vw;
        }
      }
    }}
}
@media (max-width: 1024px) {
  .guild__table {
    &__wrapper {
      margin-top: -200px;
    }

    &__headData {
      font-size: 23px;
      width: 430px !important;
      padding: 15px 0 15px 145.54px;
      &__text {
        font-size: 23px;
        &:nth-child(2) span {
          &:after {
            top: 0;
          }
        }
      }
      & span:nth-child(1){
        position: relative;
        cursor: pointer;
        &:after{
          content: "";
          position: absolute;
          top: 0;
          right: -30px;
          background: url("../../Image/tableArrows.svg");
          width: 16px;
          height: 20px;
        }
      }
      }
  }
}
@media (max-width: 576px) {
  .guilds_arrows{
    display: block;
    position: relative;
    top: -60px;
  }
  .tooltip__info{
    max-width: 380px;
    padding: 30px 7px;
    &__icon {
      cursor: pointer;
      position: absolute;
      left: 102px;
    }

    &__measureBlock {
      display: flex;
      flex-direction: column;
      gap: 15px;
      font-size: 16px !important;
    }

    &__measurement {
      justify-content: space-between;
      &__more {
        margin-left: 29.35px;
      }
      & span{
        display: none;
      }
      padding: 0 10.82px;
    }

  }

  .guild__table {
    &__headData {
      padding: 15px 0 15px 20px;

    }
  }
}
@media (min-width: 3000px) {
  .tooltip__info{
    width: 950px;
    &__title {
      font-size: 54px;
    }
    &__measurement {
      font-size: 35px;
    }
  }
}