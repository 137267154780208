.nftPageLands{
  & h3{
    position: relative;
    &::before{
      content: "";
      position: absolute;
      height: 180px;
      width: 720px;
      left: -180px;
      top: 30px;
      background: url("../../../../Image/nftTitleLine2.svg") 100% 100%;
    }
  }
  & p{
    margin-top: 54px;
  }
  & .jsLandImage{
    width: 1529px;
    margin-top: 400px;
    height: 1142px;
    position: absolute;
    top: 30px;
  }
  & .landImage{
    margin-left: 620px;

  }
  & .landRow{
    height: 1550px;
    padding-top: 5vw;
    display: block;
  }
  & .landText{
    width: 36vw;
    font-size: 24px;
    & a[href='#']:before{
      filter: invert(1);
    }
  }
  .landBg{
    height: 1080px;
    background-size: 100% 100%;
  }
}


.NftLandsBgContainer{
  text-align: center;
  margin-top: -270px;
  clip-path: polygon(43% 9%, 76% 35%, 100% 60%, 100% 100%, 0 100%, 0% 60%, 0 0);
  & img{
    margin-bottom: -30px;
    width: 1522px;
    height: 846px;
  }
}

@media (max-width: 1680px) {
  .NftLandsBgContainer{
    margin-top: -100px;
    & img{
      margin-bottom: -50px;
    }
  }
}
@media (max-width: 1400px) {
  .NftLandsBgContainer{
    margin-top: -50px;
    & img{
      margin-bottom: -30px;
      width: 1222px;
      height: 646px;
    }
  }
  .nftPageLands{
    & p{
      margin-top: 35px;
    }
    & h3{
      &::before{
        height: 104px;
        width: 556px;
        //left: -80px;
        top: 30px;
        background: url("../../../../Image/nftTitleLineTablet2.svg") 100% 100% no-repeat;
      }
    }
    & .jsLandImage{
      width: 740px;
      margin-top: 400px;
      height: 520px;
      top: 30px;
    }
    .landBg{
      height: 740px;
      background-size: 100% 100%;
    }
  }
}
@media (max-width: 1680px) {
  .NftLandsBgContainer{
    & img{
      margin-bottom: -50px;
      width: 922px;
      height: 546px;
    }
  }
}
@media (max-width: 1224px){
  .nftPageLands {
    & h3 {
      font-size: 80px;
      &:before{
        top: 0;
      }
    }
  }
}

@media (max-width: 1024px) and (min-width: 676px) {
  .landBg{
    background: url("../../../../Image/nftAssetsLandBg.png") 100% 100%;
    height: 780px;
    margin-top: -50px;
  }
}
@media (max-width: 1024px) {
  .NftLandsBgContainer{
    margin-top: -160px;
    clip-path: polygon(43% 9%, 76% 35%, 100% 60%, 100% 100%, 0 100%, 0% 60%, 0 0);
    & img{
      margin-bottom: -25px;
      width: 764px;
      height: 540px;
    }
  }
  .nftPageLands{
    & h3{
      font-size: 70px;
      margin-top: 40px;
      padding-left: 60px;
      &::before{
        height: 104px;
        width: 556px;
        left: -80px;
        top: -10px;
        background: url("../../../../Image/nftTitleLineTablet2.svg") 100% 100% no-repeat;
      }
    }
    & .jsLandImage{
      width: 740px;
      margin-top: 400px;
      height: 520px;
      top: -78px;
      left: 220px;
    }
    & .landImage{
      margin-left: 190px;
    }
    & .landRow{
      height: 666px;
      padding-top: 34px;
    }
    & .landText{
      & span, & p{
        font-size: 18px !important;
      }
      width: 443px;
    }

    & p{
      width: 100%;
      margin-left: 0;
    }
    .landBg{
      height: 840px;
      background-size: 100% 100%;
    }
  }
}
@media (max-width: 976px)  and (min-width: 676px){
  .landBg{
    margin-top: -95px;
  }
}
@media (max-width: 676px) {
  .NftLandsBgContainer{
    margin-top: 20px;
    clip-path: none;
   & img{
      margin-bottom: -15px;
      width: 100%;

      height: auto;
    }
  }
  .nftPageLands{
    & h3{
      margin-top: 0;
      font-size: 40px;
      &::before{
        height: 30px;
        width: 312px;
        left: -72px;
        top: 30px;
        background: url("../../../../Image/nftTitleLineMobile2.svg") 100% 100% no-repeat;
      }
    }
    & .jsLandImage{
      width: 305px;
      margin-top: 355px;
      height: 355px;
      top: 98px;
      left: 89px;

    }
    & .landImage{
      margin-left:60px;
    }
    & .landRow{
      height: 666px;
      padding-top: 60px;
    }
    & .landText{

      width: 289px;
      a[href='/landsNft']{
        margin-top: 70px;
      }
      & p{
        font-size: 16px !important;
      }

    }
    & p{

      margin-left: 0;
    }
    .landBg{
      height: 790px;
      background-size: 100% 100%;
      clip-path: polygon(0% 0.2083333333vw, 23.1770833333% 0.2083333333vw, 24.7916666667% 0.625vw, 33.8541666667% 0.625vw, 35.5208333333% 0%,
              42.96875% 0%, 47.0833333333% 5.8333333333vw, 61.25% 5.8333333333vw, 61.71875% 6.1458333333vw, 67.2395833333% 6.1458333333vw,
              74.7916666667% 9.84375vw, 77.6041666667% 9.84375vw, 78.125% 10.4166666667vw, 85.1041666667% 10.4166666667vw, 86.71875% 9.0104166667vw,
              100% 9.0104166667vw, 100% calc(100% - 8.3333333333vw), 86.09375% calc(100% - 8.3333333333vw), 83.8020833333% calc(100% - 9.4791666667vw),
              54.0625% calc(100% - 9.4791666667vw), 48.59375% calc(100% - 2.8125vw), 25.5208333333% calc(100% - 2.8125vw), 23.6458333333%
              calc(100% - 1.0416666667vw), 19.3229166667% calc(100% - 1.0416666667vw), 18.3333333333% 100%, 0% 100%);
    }
  }
}
@media (min-width: 2200px) {
  .NftLandsBgContainer{
    text-align: center;
    margin-top: -270px;
    clip-path: polygon(63% 9%, 76% 35%, 100% 60%, 100% 100%, 0 100%, 0% 60%, 0 0);
    & img{
      margin-bottom: -1vw;
      width: 70vw;
      height: 1046px;
    }
  }
  .nftPageLands {
    & .jsLandImage {
      width: 1729px;
      margin-top: 400px;
      height: 1242px;
      position: absolute;
      top: 30px;
      right: 0;
    }
  }
}
@media (min-width: 3200px) {
  .nftPageLands{
    & h3{
      &::before{
        height: 180px;
        width: 920px;
        left: -320px;
        top: 74px;
      }
    }
    & .landRow{
      height: 1550px;
      padding-top: 5vw;
      display: block;
    }
    & .landText{
      width: 36vw;
      font-size: 54px;
    }
    .landBg{
      height: 1300pt;
      background-size: 100% 100%;
    }
  }
  .NftLandsBgContainer{
    margin-top: -540px;
    & img{
      margin-bottom: 0;
      width: 70vw;
      height: 1243px;
    }
  }
}