@import "page/mainPage";
@import "page/media";
@import "css/style.css";
@import "css/slick.css";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap");

@font-face {
  font-family: "Cold Warm";
  src: url("Fonts/Cold-Warm.otf");
}

.custom-button {
  background-repeat: no-repeat;
  background-size: auto;
  outline: none;
  box-shadow: none;

  &--disabled.yellow-button {
    span {
      color: #bfbfbf;
    }
  }
}

.web3modal-modal-lightbox {
  z-index: 100 !important;
}
