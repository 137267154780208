.homeAssemble{
  margin-top: -41.667vw;
  & h3{
    font-size: 6.250vw;
    width: 35vw;
    margin-top: 3vw;

    &::before{
      content: none;
    }
  }
  & p{
    font-size: 1.250vw;
    margin-left: 3vw;
  }

}
.building{
  background: url("../../../../Image/BuilderBg.svg") no-repeat;
  background-size: 100vw;
  background-position-y: 10vw;
  & p{
    margin-bottom: 2vw;
  }
}
.sparxLink{
  display: block;
  margin-top: 2vw;
  text-align: right;
  text-decoration: none;
}
.buildingRobotSign{
  background: url("../../../../Image/clickHere.svg") no-repeat;
}
@media (max-width: 1040px) {
  .homeAssemble{
    box-sizing: border-box;
    margin-top: -50px;
    & h3{
      font-size: 70px;
      width: 400px;
      text-align: left;
      padding-left: 39%;
      margin-bottom: 20px;
      max-width: none;
    }
  }
  .buildingContent{
    text-align: right;
    & p {
      text-align: left;
      font-size: 18px;
      margin-left: 43vw;
      @media (min-width: 576px) {
        width: 353.27px;
        &:last-child{
          max-width: 281.91px;
          padding-left: 8%;
        }
      }

    }
  }
  .buildingRow{
    display: block;
  }
  .buildingRobot{
    width: 75vw;
    margin-top: -10vw;
  }
  .sparxLink{
    margin-top: 5vw;
  }
  .building{
    background: none;
  }
  .buildingRobotSign{
    top: 15vw;
    right: -11%;
  }
}
@media (max-width: 576px) {
  .homeAssemble{
    margin-top: -50px;
    & h3{
      width: 350px;
      font-size: 50px;
      text-align: left;
    }
  }
  .buildingContent{
    text-align: left;
    & p {
      font-size: 16px;
      margin-left: 10vw;
      width: 70vw;
    }
  }
  .buildingRow{
    display: block;
  }
  .buildingRobot{
    width: 100%;
    margin-top: 5vw;
  }
  .sparxLink{
    text-align: left;
  }
  .building{
    background: none;
  }
  .buildingRobotSign{
    background: url("../../../../Image/clickHereMobile.svg") no-repeat;
    top: 2vw;
    right: -11%;
    background-size: 120px 70px;
  }
}