.background {
  background-image: url("../../Image/HomeBg.svg");
  background-repeat: no-repeat;
  padding: 0 0;
  background-attachment: fixed;
  overflow: hidden;
  & h2{
    margin-top: -50px;
  }
  & iframe{
    & article{
      border: 1px solid red;
    }
  }
}
@media (max-width: 576px) {
  .background {
    background-image: url("../../Image/HomeBgMobile.svg");
  }
}