.assetLookup{
  padding: 0 150px 0 0;
  position: relative;
  z-index: 7;
  box-sizing: border-box;
  margin-bottom: 150px;
  background-color: #000;
  //opacity: 0.85;

  border-top: #251A13 solid 8px;
  border-bottom: #251A13 solid 8px;
}
.big_text {
  font-family: Cold Warm, sans-serif;
  font-size: 97px;
  line-height: 1;

  >span {
    color: #ffc328;
  }
}

.search_block {
  display: flex;
  align-items: center;
  margin: 19.64px 0;
}

.medium_text {
  font-family: Cold Warm, sans-serif;
  font-size: 43px;
  margin-right: 86px;

  >span {
    color: #ffc328;
  }
}

.text_info {
  margin-bottom: 80px;
}

.text_info1,
.text_info2 {
  max-width: 1200px;
  font-family: "Poppins", "Open Sans", sans-serif;
  font-size: 24px;
}

.text_info2 {
  color: #bfbfbf;
}

.text_info1 {
  font-weight: 700;
}

.padding {
  padding: 50px 0;
}

.openSeaBtn {
  width: 298.46px !important;
  height: 75px !important;
}
@media (min-width: 2100px) {

  .asset{
    margin-top: 3vw !important;
  }
  .medium_text {
    font-size: 2vw;
  }
  .search_block{

    justify-content: center;
  }
}
.asset{
  //height: 325.6px;
  max-width: 1413px;
  margin: 50.56px auto 178px auto;
  background-image: url("../../Image/landBg.svg");
  background-size: 100%  325.6px;;
  background-repeat: no-repeat;
  display: flex;
  justify-content: space-between;
  padding: 50px 65px;
  & h5{
    cursor: pointer;
    font-size: 43px;
    line-height: 120%;
    letter-spacing: 0.06em;
    font-family: 'Cold Warm', sans-serif;
    text-transform: uppercase;
    color: #FFC328;
    margin-bottom: 35px;
  }
  &__content{
    display: flex;
    gap: 60px;
    .asset__image {
      width: 237px;
      height: 237px;
    }

    .xoil_icon {
      margin: 2px 6px 0 6px;
      position: relative;
      top: 3px;
    }
  }
  &__text{
    margin-top: 7px;
    font-size: 24px;
  }
  &__buttonContainer{
    margin-top: 85px;
    font-weight: 500;
    & span{
      font-weight: 500 !important;
    }
    & a:hover{
      filter:  brightness(110%) contrast(95%) !important;
    }
    & a:active{
      filter:  brightness(89%) contrast(80%) !important;
      color: #5B0D00 !important;
    }
  }
}
@media (min-width: 1440px) {
  .asset__text_bot{
    display: grid;
    grid-template-columns: 300px 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-column-gap: 30px;
    & p:nth-child(2){
      grid-row-start: 2;
      grid-row-end: 4;
    }
    & p:nth-child(4){
      grid-row-start: 2;
      grid-row-end: 3;
    }
  }
}
@media (max-width: 1440px) {
  .asset__buttonContainer span{
    font-size: 1.2rem !important;
  }
  .medium_text {
    margin-right: 50px;
  }
}
@media screen and (max-width: 1360px) {
  .content {
    margin-left: 100px;
    padding-right: 100px;
  }

  .medium_text {
    margin-right: 50px;
  }
  .input_block {
    margin-top: 35px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }


}
@media screen and (max-width: 1224px) {
  .search_block {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media screen and (max-width: 1024px) {
  .assetLookup {
    margin-top: 0 !important;
    padding: 0 50px;
    background: none;
    border: none;
    margin-bottom: 76.63px;
  }

  .content {
    padding-top: 70px;
    margin-left: 51px;
    padding-right: 51px;
  }

  .search_block {
    flex-direction: column;
    align-items: flex-start;
  }

  .medium_text {
    font-size: 51px;
  }

  .search_border {
    width: 532px;
    height: 61px;
  }

  .input_block {
    margin-top: 35px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text_info {
    text-align: center;
    padding: 10px;

    >p {
      color: #FFFFFF;
      font-size: 18px;
    }
  }

  .big_text {
    font-size: 70px;
  }
  .asset{
    margin-top: 150px;
    max-width: 670.25px;
    height: 250.6px;
    background-size: 100% 250px;;
    padding: 0 35px;
    margin-bottom: 44.65px;
    & h5{
      margin-bottom: 55px;
    }
    &__content{
      display: flex;
      gap: 60px;
      margin-top: -65px;
      .asset__image {
        width: 267px;
        height: 267px;
      }
    }
    &__text{
      margin-top: -7px;
      letter-spacing: 0.02em;
      font-size: 18px;
    }
    &__buttonContainer{
      & span{
        font-size: 18px !important;
      }
      margin-top: 35px;
      margin-bottom: 108px;
    }
  }

  .openSeaBtn {
    width: 297.3px !important;
    height: 61px !important;
  }
}

@media screen and (max-width: 630px) {
  .content {
    padding-top: 56px;
  }

  .big_text {
    font-size: 40px;
  }

  .search_block {
    margin-top: 38px;
    flex-direction: column;
    align-items: flex-start;
  }

  .medium_text {
    font-size: 27px;
  }

  .input_block {
    justify-content: flex-start;
    margin-top: 12px;
  }

  .text_info {
    padding: 0 40px;
    margin-bottom: 40px;

    >p {
      font-size: 16px;
    }
  }
  .asset{
    margin-left: 20px;
    width: calc(100% - 115px);
    margin-top: 50px;
    height: 472.6px;
    background-size: 100% 100%;
    padding: 50px 35px;
    justify-content: center;
    background-image: url("../../Image/landBgMobile.svg");
    & h5{
      margin-bottom: 20px;
      font-size: 40px;
    }
    &__content{
      flex-direction: column;
      display: flex;
      gap: 30px;
      margin-top: 0;
      .asset__image {
        width: 237px;
        height: 237px;
      }
    }
    &__text{
      margin-top: 7px;
      letter-spacing: 0.02em;
      font-size: 18px;
    }
    &__buttonContainer{
      & span{
        font-size: 18px !important;
      }
      margin-top: 25px;
    }
  }
  .assetLookup{
    border: none;
    background: none;
    margin-top: 100px;
    padding: 0 20px;
  }

  .openSeaBtn {
    width: 265.58px !important;
    height: 57px !important;
  }
}

@media (min-width: 3800px) {
  .content {
    padding-top: 150px;
    .big_text {
      font-size: 4rem;
    }
    .medium_text {
      font-size: 2rem;
    }
    .text_info {
      p {
        font-size: 1rem;
        max-width: 70vw;
      }
    }
  }
}