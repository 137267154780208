.background {
  background-image: url("../../Image/whitepaper-bg.svg");
  width: 100%;
  padding-top: 165px;
  padding-bottom: 150px;
  h1 {
    font-size: 120px;
    font-weight: 400;
    margin-left: 154px;
    margin-bottom: 35px;
    span {
      color: #ffc328;
    }
  }
  .list {
    width: 45%;
    margin-left: 154px;
    .listItem {
      cursor: pointer;
      text-decoration: none;
      color: #fff;
      height: 64px;
      width: 100%;
      margin-bottom: 23px;
      background: linear-gradient(
        90deg,
        #252525 -0.95%,
        rgba(14, 14, 14, 0.85) 94.9%,
        rgba(0, 0, 0, 0) 103.02%
      );
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 54px;
      padding-right: 59px;
      .listItemName {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        div {
          position: relative;
          margin-right: 50px;
          .yellowArrow {
            z-index: 5;
          }
          .redArrow {
            position: absolute;
            top: 3px;
            left: 5px;
            z-index: 1;
            path {
              fill: #e40b0b;
            }
          }
        }
        span {
          display: block;
          font-size: 24px;
          font-weight: 500;
        }
      }
      .downLoadIcon {
        path {
          pointer-events: none;
        }
      }
      &:hover {
        background: rgba(37, 37, 37, 0.88);
        .downLoadIcon {
          path {
            fill: #ffc328;
          }
        }
      }
      &:active {
        background-color: rgba(37, 37, 37, 0.6);
        .downLoadIcon {
          path {
            fill: #f8a300;
          }
        }
      }

      &[aria-disabled="true"] {
        pointer-events: none;
        .listItemName {
          div {
            .yellowArrow {
              path {
                fill: #6f6f6f;
              }
            }
            .redArrow {
              path {
                fill: #3d3a38;
              }
            }
          }
          span {
            color: #7e7e7d;
          }
        }
        .downLoadIcon {
          path {
            fill: #7e7e7d;
          }
        }
      }
    }
  }
}

@media (min-width: 2400px) {
  .background {
    padding-top: 7vw;
  }
}

@media (max-width: 1024px) {
  .background {
    padding-bottom: 110px;
    h1 {
      font-size: 100px;
      margin-left: 51px;
      margin-bottom: 60px;
    }
    .list {
      width: calc(100% - 170px);
      margin-left: 51px;
      .listItem {
        height: 56px;
        padding-left: 35px;
        padding-right: 35px;
        &:hover {
          background: linear-gradient(
            90deg,
            #252525 -0.95%,
            rgba(14, 14, 14, 0.85) 94.9%,
            rgba(0, 0, 0, 0) 103.02%
          );
          .downLoadIcon {
            path {
              fill: #fff;
            }
          }
        }
        .listItemName {
          div {
            margin-right: 34px;
            .yellowArrow,
            .redArrow {
              width: 22px;
              height: 26px;
            }
            .redArrow {
              top: 2px;
              left: 4px;
            }
          }
          span {
            font-size: 18px;
          }
        }
        .downLoadIcon {
          width: 23.21px;
          height: 23.9px;
        }
      }
    }
  }
}

@media (max-width: 756px) {
  .background {
    padding-top: 115px;
    padding-bottom: 73px;
    h1 {
      font-size: 50px;
      margin-left: 20px;
      margin-bottom: 40px;
    }
    .list {
      width: calc(100% - 87px);
      margin-left: 20px;
      .listItem {
        padding-left: 24px;
        padding-right: 24px;
        height: 53px;
        margin-bottom: 21px;
        .listItemName {
          div {
            margin-right: 23px;
            .yellowArrow,
            .redArrow {
              width: 17px;
              height: 23px;
            }
            .redArrow {
              top: 1.7px;
              left: 3.3px;
            }
          }
          span {
            font-size: 16px;
          }
        }
      }
    }
  }
}


@media (min-width: 3800px) {
  .background {
    h1 {
      font-size: 5rem;
    }
    .list {
      width: 30%;
      transform: scale(2) translateX(5.9rem);
      padding-top: 8rem;
      padding-bottom: 8rem;
    }
  }
}