.background {
  background-image: url("../../Image/team-bg.svg");
  padding-left: 155px;
  padding-right: 155px;
  padding-bottom: 110px;
  h1 {
    font-size: 150px;
    line-height: 150px;
    margin-top: 80px;
    margin-bottom: 92px;
  }
  .members {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
}

@media (max-width: 1810px) {
  .background {
    padding-left: 100px;
    padding-right: 100px;
  }
}

@media (max-width: 1024px) {
  .background {
    background-image: url("../../Image/team-bg-mobile.svg");
    padding-left: 41px;
    padding-right: 41px;
    h1 {
      font-size: 100px;
      line-height: 100%;
      margin-bottom: 71px;
    }
  }
}

@media (max-width: 760px) {
  .background {
    padding-left: 21px;
    padding-right: 21px;
    h1 {
      font-size: 50px;
      margin-top: 30px;
      margin-bottom: 27px;
    }
  }
}
