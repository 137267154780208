.container {
  padding-bottom: 50px;
}
.background {
  background-image: url("../../Image/nftBgPattern.svg");
}
@media (max-width: 968px) {
  .background {
    background-image: url("../../Image/nftBgPatternTablet.svg");
  }
}
@media (max-width: 576px) {
  .background {
    background-image: url("../../Image/nftBgMain.svg");
  }
}

