.background {
  background-image: url("../../Image/cardsPageBg.svg");
  padding: 100px 150px;
  & h3 {
    margin-top: 40px;
    text-align: left;
  }
  & h4{
    margin-top: 40px;
  }
  & table{
    margin: 0 auto;
  }
}
.desc{
  & img{
    max-width: 100%;
  }
  margin-top: 20px;
  font-size: 24px;
  & ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 40px;
    & li{
      list-style-type: disc;
    }
  }
  & ol{
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
  & p{
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  & h1{
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }
  & li{
    display: list-item;
    text-align: -webkit-match-parent;
  }
  //& a[href='#']{
  //  pointer-events: none;
  //}
}

.category{
  margin-top: 25px;
  & a{
    text-decoration: none;
    color: #00a3ff;
  }
}

.topWrapper{
  text-align: center;
  & h3{
    text-align: center;
    font-size: 60px;
  }
  & img{
    max-width: 600px;
    margin-top: 50px;
  }
}
@media (max-width: 1024px) {
  .background {
    padding: 100px 50px;
  }
}
@media (max-width: 675px) {
  .background {
    padding: 100px 20px;
  }
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
  &_open{
    display: block;
  }
}


/* Modal Content (Image) */
.modal_content {
  margin: auto;
  display: block;
  width: 80%;
  z-index: 100;
  max-width: 700px;
}

/* Caption of Modal Image (Image Text) - Same Width as the Image */

/* Add Animation - Zoom in the Modal */
.modal_content {
  animation-name: zoom;
  animation-duration: 0.6s;
}

@keyframes zoom {
  from {transform:scale(0)}
  to {transform:scale(1)}
}

/* The Close Button */
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px){
  .modal-content {
    width: 100%;
  }
}
