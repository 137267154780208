.PlayConnect{
  position: relative;
  padding: 0 7.813vw;
  margin-top: 8vw;
  &:after{
    content: "";
    position: absolute;
    right: 7.813vw;
    top: 3vw;
    width: 55.865vw;
    height: 39.115vw;
    background-image: url("../../../../Image/PlayConnectWallet.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  & h3{
    padding-top: 15.104vw;
    width: 31vw;
    position: relative;
    &:after{
      content: "3";
      position: absolute;
      background: #FFC328;
      border-radius: 50%;
      font-size: 7.500vw;
      color: #000000;
      width: 9.948vw;
      height: 9.948vw;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0.470vw solid #FFFFFF;
      box-shadow: inset 4.50879px 3.89038px 1.1272px 5.5264px #B37E00;
      top: 2vw;
      left: 10vw;
      font-weight: 400;
    }
    &:before{
      content: "";
      position: absolute;
      background-image: url("../../../../Image/playNowLines3.svg");
      width: 45.125vw;
      height: 5.938vw;
      bottom: -6vw;
      left: -7.813vw;
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }
  & p{
    font-size: 1.250vw;
    margin-top: 4.688vw;
    max-width: 25.771vw;
  }
  & a{
    z-index: 2;
    font-weight: 500;
    font-size: 1.250vw;
    margin-top: 5.990vw;
    height: 3.906vw;
    width: 13.490vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../../../Image/connectButtonBg.svg");
    background-size: 100% 100%;
    color: #103743;
    cursor: pointer;
    text-decoration: none;
    gap: 10px;
    margin-left: 4.427vw;
  }
}
.buttons{
  margin-top: -3vw;
  display: flex;
}
@media (max-width: 1024px) {
  .PlayConnect{
    position: relative;
    padding: 0 50px;
    margin-top: 50px;
    &:after{
      background-image: url("../../../../Image/PlayConnectWalletT.png");
      top: 292px;
      width: 780.24px;
      height: 551.09px;
    }
    & h3{
      padding-top: 15.104vw;

      width: 825.43px;
      text-align: left;
      &:after{
        font-size: 61.1125px;
        color: #000000;
        width: 81.06px;
        height: 81.06px;
        border: 3.82699px solid #FFFFFF;
        box-shadow: inset 1.9135px 3.34862px 0.478374px 5.74049px #B37E00;
        top: 2vw;
        left: 10vw;
      }
      &:before{
        content: "";
        position: absolute;
        background-image: url("../../../../Image/playNowLines1.svg");
        width: 519.04px;
        height: 25.33px;
        bottom: -50px;
        left: -50px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
    }
    & p{
      font-size: 18px;
      margin-top: 660px;
      max-width: none;
      width: 559.21px;
    }
    & a{
      font-size: 18px;
      width: 211.33px;
      height: 61px;
      margin-left: 0;
      margin-right: 100px;
      margin-top: 60px;
      position: relative;
      &:before{
        left: -120px;
        top: -50px;
        width: 92.18px;
        height: 123.74px;
      }
    }
  }
}

@media (max-width: 576px) {
  .PlayConnect{
    position: relative;
    padding: 0 20px;
    margin-top: 50px;
    &:after{
      right: auto;
      left: 50%;
      transform: translateX(-50%);
      top: 132px;
      width: 394.03px;
      height: 278.31px;
    }
    & h3{
      padding-top: 60px !important;
      padding-left: 0 !important;

      width: 195.82px;
      &:after{
        font-size: 40px;
        color: #000000;
        width: 53.06px;
        height: 53.06px;
        border: 2.53431px solid #FFFFFF;
        box-shadow: inset 1.26715px 2.21752px 0.316789px 3.80146px #B37E00;
        top: -20px;
        left: 10vw;
      }
      &:before{
        bottom: -20px;
        width: 284.96px;
        height: 15.18px;
        z-index: 2;
      }
    }
    & p{
      font-size: 16px;
      margin-top: 280px;

      max-width: 100%;
    }
    .buttons{
      flex-direction: column;
      margin-top: 40px;
      width: 100%;
      & a{
        margin: 0;
        margin-bottom: 27px;
        margin-right: 75px;
        align-self: flex-end;
        &:first-child{
          align-self: flex-start;
        }
      }
    }
    & a{
      font-size: 18px;
      width: 193.96px;
      height: 56px;
      margin: 56px auto 0;
      position: relative;
      &:before{
        left: auto;
        right: -76px;
        top: -123px;
        width: 92.18px;
        height: 123.74px;
        transform: scale(1, -1) rotate(261deg);
      }
    }
  }
}