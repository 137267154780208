.background {
  background-image: url("../../Image/team-bg.svg");
  padding-left: 155px;
  padding-right: 155px;
  padding-bottom: 110px;
  .backButton {
    margin-top: 62px;
  }
  h1 {
    font-size: 150px;
    line-height: 100%;
    margin-top: 40px;
    margin-bottom: 11px;
  }
  .short_desc {
    font-weight: 700;
    font-size: 39px;
    line-height: 100%;
    letter-spacing: 0.02em;
    margin-bottom: 93px;
  }
  .memberContent {
    display: flex;
    align-items: flex-start;
    .memberAvatar {
      width: 521px;
      height: 421px;
      object-fit: contain;
      margin-right: 162px;
    }
    .memberInfo {
      flex: 1;
      padding-bottom: 97.5px;
      .levels {
        max-width: 338px;
        margin-top: -10px;
        margin-bottom: 34.5px;
        padding-bottom: 12px;
        border-bottom: 3px solid #fff;
        div {
          width: 100%;
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          img {
            margin-right: 33px;
          }
          span {
            font-weight: 700;
            font-size: 24px;
            line-height: 145%;
            &:nth-of-type(2) {
              font-weight: 400;
              margin-left: auto;
            }
          }
        }
      }
      .credentials {
        div {
          display: flex;
          align-items: flex-start;
          margin-bottom: 24px;
          &:last-of-type {
            margin-bottom: 0;
          }
          span {
            font-weight: 700;
            font-size: 24px;
            line-height: 145%;
            &:nth-of-type(1) {
              width: 215px;
              margin-right: 73px;
            }
            &:nth-of-type(2) {
              width: calc(100% - 215px - 73px);
              font-weight: 400;
            }
          }
        }
      }
    }
  }
  .divider {
    width: 100vw;
    height: 15.5px;
    transform: translateX(-155px);
    background-color: #fff;
  }

  .members {
    width: 100%;
    padding-top: 94px;
    display: flex;
    flex-wrap: wrap;
  }
  .buttonHolder {
    display: flex;
    width: 100%;
    input {
      position: fixed;
      top: 0;
      left: 0;
      pointer-events: none;
      opacity: 0;
      width: 10px;
      height: 10px;
    }
  }
  button.formControl {
    position: relative;
    width: 305px;
    height: 79px;
    color: #fff;
    font-weight: 500;
    font-size: 24px;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 36px;
    letter-spacing: 0.02em;
    span {
      margin-right: 26px;
    }
    span,svg {
      pointer-events: none;
    }
    &::before,
    &::after {
      content: "";
      position: absolute;
      background-color: #fff;
      top: 1px;
      left: -202px;
      height: 5px;
      width: 187px;
    }
    &::after {
      width: 121px;
      height: 2px;
      top: 12px;
      left: -136px;
    }
    @media (min-width: 1024px) {
      &:not(.toggleClass) {
        &:not(:focus):hover {
          color: #ffc328;
          svg path {
            fill: #ffc328;
          }
          &::before,
          &::after {
            background-color: #ffc328;
          }
        }
      }
    }
    &:active {
      color: #fff;
      svg path {
        fill: #fff;
      }
    }
    &:focus {
      color: #f8a300;
      svg path {
        fill: #f8a300;
      }
      &::before,
      &::after {
        background-color: #f8a300;
      }
    }
    &:disabled {
      color: #7e7e7d;
      svg path {
        fill: #7e7e7d;
      }
      &::before,
      &::after {
        background-color: #7e7e7d;
      }
    }
  }
}

@media (max-width: 1810px) {
  .background {
    padding-left: 100px;
    padding-right: 100px;
    .divider {
      transform: translateX(-100px);
    }
  }
}

@media (max-width: 1440px) {
  .background {
    padding-left: 30px;
    padding-right: 30px;
    .divider {
      transform: translateX(-30px);
    }
    .memberContent {
      .memberAvatar {
        margin-right: 75px;
      }
      .memberInfo {
        .credentials {
          div {
            span {
              &:nth-of-type(1) {
                margin-right: 30px;
              }
              &:nth-of-type(2) {
                width: calc(100% - 215px - 30px);
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .background {
    background-image: url("../../Image/team-bg-mobile.svg");
    padding-left: 40px;
    padding-right: 40px;
    h1 {
      font-size: 100px;
    }
    .short_desc {
      font-size: 24px;
      line-height: 145%;
    }
    .divider {
      height: 12px;
      transform: translateX(-40px);
    }
    .memberContent {
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      .memberAvatar {
        width: 310px;
        height: 251px;
        margin-right: 55px;
        margin-bottom: 52px;
      }
      & > .levels {
        max-width: 230px;
        margin-top: 0;
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: unset;
        margin-right: 70px;
        width: calc(100% - 310px - 55px);
        div {
          width: 100%;
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          img {
            margin-right: 20px;
            width: 43px;
          }
          span {
            font-weight: 700;
            font-size: 18px;
            line-height: 120%;
            &:nth-of-type(2) {
              font-weight: 400;
              margin-left: auto;
            }
          }
        }
      }
      .memberInfo {
        flex: unset;
        width: 100%;
        padding-bottom: 75px;
        .credentials {
          width: 100%;
          div {
            margin-bottom: 21px;
            span {
              font-weight: 700;
              font-size: 18px;
              line-height: 120%;
              &:nth-of-type(1) {
                width: 167px;
                margin-right: 43px;
              }
              &:nth-of-type(2) {
                width: calc(100% - 167px - 43px);
                font-weight: 400;
              }
            }
          }
        }
      }
    }
    button.formControl {
      width: 243px;
      height: 62px;
      font-size: 18px;
      line-height: 27px;
      background-size: cover;
      span {
        margin-right: 23px;
      }
      svg {
        width: 16px;
      }
      &::before,
      &::after {
        content: "";
        left: -162px;
        height: 4px;
        width: 150px;
      }
      &::after {
        width: 97.5px;
        height: 2px;
        top: 9px;
        left: -109.5px;
      }
      &:focus,&.toggleClass,&[data-selected="true"] {
        color: #f8a300;
        svg path {
        fill: #f8a300;
        }
        &::before,
        &::after {
        background-color: #f8a300;
        }
        background-image: url('../../Image/show-more-btn-mobileSelected.svg') !important;
      }
      &:not([data-selected="true"]) {
        &:not(:focus):hover {
          color: #fff !important;
          svg path {
            fill: #fff !important;
          }
          &::before,
          &::after {
            background-color: #fff !important;
          }
        }
      }
    }
  }
}

@media (max-width: 760px) {
  .background {
    padding-left: 21px;
    padding-right: 21px;
    .backButton {
      font-size: 18px;
    }
    h1 {
      font-size: 50px;
      margin-top: 21px;
      margin-bottom: 27px;
    }
    .short_desc {
      display: none;
    }
    .divider {
      height: 6px;
      transform: translateX(-21px);
    }
    .memberContent {
      .memberAvatar {
        width: 100%;
        margin-right: 0;
        height: unset;
        margin-bottom: 46px;
      }
      & > .levels {
        max-width: unset;
        width: 100%;
        margin-bottom: 33px;
        margin-right: 0;
        div {
          width: 100%;
          display: flex;
          align-items: center;
          margin-bottom: 34px;
          img {
            margin-right: 18px;
            width: 34.5px;
          }
          span {
            width: 116px;
            margin-right: 43px;
            &:nth-of-type(2) {
              width: unset;
              margin-right: 0;
              margin-left: unset;
            }
          }
        }
      }
      .memberInfo {
        width: 100%;
        padding-bottom: 43px;
        .credentials {
          width: 100%;
          div {
            margin-bottom: 21px;
            flex-wrap: wrap;
            span {
              &:nth-of-type(1) {
                width: 100%;
                margin-right: 0;
                margin-bottom: 3px;
              }
              &:nth-of-type(2) {
                width: 100%;
                font-size: 16px;
              }
            }
          }
        }
      }
    }
    button.formControl {
      width: 222px;
      height: 57px;
      span {
        margin-right: 17px;
      }
      &::before,
      &::after {
        content: "";
        left: -96.25px;
        height: 3.67px;
        width: 85.4px;
      }
      &::after {
        width: 56.7px;
        height: 1.5px;
        top: 8.5px;
        left: -67.55px;
      }
    }
  }
}

@media (min-width: 3800px) {
 .background {
   .formControl {
     transform: scale(2) translateX(-2rem);
     margin-top: 2rem;
     margin-bottom: 2rem;
   }
   h1 {
    font-size: 5rem;
   }
   .backButton {
     font-size: 1.14rem;
    }
   .short_desc {
    font-size: 1.3rem;
    }
  .memberContent {
    .memberAvatar {
      width: 35vw;
      height: auto;
      margin-bottom: 2rem;
    }
    .memberInfo {
      .levels {
        margin-top: 0;
        max-width: 30vw;
        div {
          margin-bottom: 1.2rem;
          img {
            width: 3vw;
          }
          span {
            font-size: 1.14rem;
          }
        }
      }
      .credentials {
        div {
          margin-bottom: 1.2rem;
          span {
            font-size: 1.14rem;
            &:nth-of-type(1) {
              width: 490px;
            }
            &:nth-of-type(2) {
              width: calc(100% - 490px - 73px);
            }
          }
        }
      }
    }
  }
 }
}