.PlayDominate{
  position: relative;
  padding: 0 7.813vw 0 0;
  margin-top: 8vw;

}
.topWrapper{
  display: flex;
  margin-top: -5vw;
  justify-content: space-between;
  align-items: flex-start;
  & img{
    width: 53.865vw;
    height: 32.208vw;
    margin-top: 6vw;
    margin-right: 4vw;
  }
  & p{
    margin-left: 5vw;
    width: 25.938vw;
    margin-top: 100px;
  }
  & h3{
    margin-top: 2vw;
    padding-top: 15.104vw;
    width: 39.792vw;
    position: relative;
    &:after{
      content: "4";
      position: absolute;
      background: #FFC328;
      border-radius: 50%;
      font-size: 7.500vw;
      color: #000000;
      width: 9.948vw;
      height: 9.948vw;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0.470vw solid #FFFFFF;
      box-shadow: inset 4.50879px 3.89038px 1.1272px 5.5264px #B37E00;
      top: 2vw;
      left: 13vw;
      font-weight: 400;
    }
    &:before{
      content: "";
      position: absolute;
      background-image: url("../../../../Image/playNowLines2.svg");
      width: 43.438vw;
      height: 4.063vw;
      bottom: -2vw;
      right: -7.813vw;
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }
}
.buttons{
  display: flex;
  gap: 4.427vw;
  justify-content: flex-end;
  & a{
    color: #FFFFFF;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: .8vw;
    width: 13.533vw;
    height: 3.906vw;
    background: url("../../../../Image/learnMoreButtonBorder.svg") no-repeat;
    background-size: 100% 100%;
    & img{
      display: none ;
    }
    &:hover{
      background: url("../../../../Image/learnMoreButtonBorderHover.svg");
      background-size: 100% 100%;
      color: #FFC328;
    }
    &:active{
      background: url("../../../../Image/learnMoreButtonBorderActive.svg") no-repeat 100% 100%;
      background-size: 100% 100%;
      color: #F8A300;
    }
  }
}
@media (max-width: 1024px) {
  .PlayDominate{
    padding: 0 50px;
    margin-bottom: 100px;
    margin-top: 60px;
  }
  .topWrapper{
    margin-top: 0;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;
    & p{
      margin-left: 0;
      padding: 0 50px;
      width: auto;
      margin-top: 100px;
      font-size: 18px;
    }
    & img{
      width: 707.02px;
      height: 430.19px;
      margin-top: 90px;
    }
    & h3{
      align-self: flex-end;
      margin-top: 2vw;
      padding-top: 15.104vw;
      width: 452.32px;
      position: relative;
      text-align: right;
      margin-right: 2vw;
      @media (min-width: 576px) {
        float: right;
      }

      &:after{
        font-size: 61.1125px;
        color: #000000;
        width: 81.06px;
        height: 81.06px;
        border: 3.82699px solid #FFFFFF;
        box-shadow: inset 1.9135px 3.34862px 0.478374px 5.74049px #B37E00;
        top: 2vw;
        left: 40vw;
      }
      &:before{
        content: "";
        position: absolute;
        background-image: url("../../../../Image/playNowLines2T.svg");
        width: 630px;
        height: 60.88px;
        bottom: -57px;
        right: -80px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .buttons {
    display: flex;
    gap: 4.427vw;
    justify-content: flex-end;
    margin-top: 25px;
    & a {
      height: 61px;
      width: 194.93267822265625px;
    }
  }
}
@media (max-width: 576px) {
  .PlayDominate{
    padding: 0 20px;
    margin-top: 60px;
  }
  .topWrapper{

    margin-top: 0;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;
    & img{
      width: 399.2px;
      height: 242.9px;
      margin-left: -88.5px;
      margin-top: 90px;
    }
    & p{
      font-size: 16px;
      margin-top: 20px;
      padding: 0;
      width: 100%;
    }

    & h3{
      align-self: flex-start;
      text-align: left;
      margin-top: 2vw;
      padding-top: 60px !important;
      width: 317.82px;
      &:after{
        font-size: 40px;
        color: #000000;
        width: 53.06px;
        height: 53.06px;
        border: 2.53431px solid #FFFFFF;
        box-shadow: inset 1.26715px 2.21752px 0.316789px 3.80146px #B37E00;
        top: -20px;
        left: 16vw;
      }
      &:before{
        content: "";
        position: absolute;
        background-image: url("../../../../Image/playNowLines1.svg");
        width: 258.94px;
        height: 12.15px;
        right: auto;
        left: -20px;
        bottom: -20px;
      }
    }
  }
  .buttons {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    margin-top: 25px;
    padding-bottom: 50px;
    & a {
      //background: url("../../../../Image/learnMoreButtonYellow.svg") no-repeat;
      background: #FFC328;
      //-webkit-clip-path: url(#my-clip-path);
      clip-path: url(#my-clip-path);

      background-size: 100% 100%;
      height: 56px;
      width: 152.54034423828125px;
        color: #5B0D00;
      font-weight: 500;
      font-size: 18px;
      &:nth-child(2){
        align-self: center;
      }
      &:nth-child(3){
        align-self: flex-end;
      }
      &:hover{
        background: #F8A300;
        color: #5B0D00;
      }
      &:active{
       background: #F8A300;
        color: #5B0D00;
      }
    }
  }
}