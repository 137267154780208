@media only screen and (max-width: 850px) and (orientation: landscape) {
  .section__img-wrapper {
    margin-left: -450px !important;
    margin-top: 200px !important;
  }
}

@media (min-width: 2500px) {
  .section__wrapper-box {
    display: flex;
    padding-left: 1.4%;
  }
  .section__info-box a {
    font-size: 25px;
  }
  .section__box {
    max-width: 100%;
  }

  .section__img-wrapper {
    margin-left: unset;
  }

  .section__title {
    width: 1710px;
    font-size: 200px;
    line-height: 120px;
  }
  .section__title span {
    font-size: 200px;
    line-height: 120px;
  }
  .section__box p,
  ul {
    font-size: 54px;
  }
  .section__table {
    margin-top: -100px;
  }
  .section__table-body span:nth-child(1) {
    font-size: 55px;
  }

  .section__desc {
    width: 100%;
    line-height: 180%;
  }

  .section__subdesc {
    width: 100%;
  }

  .section__subdesc-list {
    width: 100%;
  }

  .section__table {
    margin-top: -170px;
  }

  .section__table-container {
    max-width: 1713px;
  }

  .section__table-header {
    padding: 40px 0;
  }

  .section__table-body {
    padding: 40px 0;
  }

  .section__table h4 {
    font-weight: 400;
    font-size: 54px;
  }

  .section__table-body span:nth-child(1) {
    font-size: 54px;
  }

  .section__table-info {
    font-size: 28px;
  }

  .section__time-wrapper {
    height: 547px;
    background-size: cover;
  }

  .section__time-wrapper h3 {
    font-size: 147px;
  }
  .section__info-box .tablet .section__info-btn {
    width: 507px !important;
    padding: 33px 0;
    font-size: 41px;
  }
  .section__info-btn img {
    margin-left: 30px;
    width: 41px;
  }
  .section__subdesc-list li:before {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: white;
    left: -30px;
    top: 30px;
  }
  .section__time-wrapper .communnity-active {
    font-size: 48px;
  }
}

@media (min-width: 2134px) {
  .section__img-wrapper {
    overflow: unset;
  }
}

@media (max-width: 1850px) {
  .line {
    width: 330px;
  }
  .active__wrapper-btns button {
    width: 70px !important;
  }
  .active__wrapper input {
    width: 198px;
    padding: 15px 7px 15px;
  }
  .active__wrapper input:nth-child(1) {
    width: 300px;
    padding: 15px 28px 15px 32px;
  }

  .active__wrapper-btns {
    margin-left: 15px;
  }
  .line {
    width: 340px;
  }
}

@media (max-width: 1700px) {
  .section__subdesc {
    width: 880px;
    margin-bottom: 10px;
  }
  .lineMeta {
    width: 460px;
    margin: unset !important;
  }

  .section__table {
    margin-top: -350px;
  }

  .section__img-wrapper {
    margin-left: -443px;
  }

  .active__wrapper input:nth-child(1) {
    width: 307px;
    padding: 20px 40px 15px 32px;
  }

  .active__wrapper input {
    padding: 20px 5px 15px 35px;
    width: 210px;
  }
  .line {
    width: 270px;
  }
  .active__wrapper-form div:nth-child(2) {
    margin: 0 14px;
  }
  .active__wrapper input {
    width: 200px;
  }
}

@media (max-width: 1600px) {
  .popup-generic-wrapper .popup-generic {
    max-width: 1000px;
    max-height: 390px;
    min-width: 1000px;
    min-height: 390px;
    background-size: contain;
    justify-content: unset;
  }
  .popup-generic-wrapper .popup-generic.verification {
    max-height: none;
    min-height: 502px
  }
  .popup-generic-wrapper .popup-claim {
    max-width: 1330px;
    max-height: 763px;
    min-width: 1330px;
    min-height: 763px;
    margin: 50px auto;
    position: relative;
  }
  .popup-generic-wrapper .popup-generic .box-desc {
    // margin: 50px 0;
    margin: 20px 0;
  }

  .popup-generic-wrapper .popup-claim .box-desc {
    margin: 50px auto 8px auto;
  }

  .popup-generic-wrapper .popup-generic .exit-button-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 85%;
    margin-top: 40px;
    font-size: 24px;
    font-family: "Poppins", sans-serif;
  }
  .popup-generic-wrapper .popup-generic.verification .exit-button-wrapper {
    margin-top: 80px;
  }
  .popup-generic-wrapper .popup-claim .exit-button-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 19px;
    font-size: 24px;
  }
  .popup-error-wrapper .popup-error .exit-button-wrapper {
    width: unset;
    margin: 50px 80px 0 auto;
  }
  .section__subdesc-list {
    width: 670px;
  }

  .line {
    width: 150px;
  }
  .active__wrapper input:nth-child(1) {
    width: 335px;
  }

  .active__wrapper-btns {
    margin-left: 14px;
  }
  .section__desc {
    width: 890px;
  }

  .section__img-wrapper {
    margin-top: 192px;
  }

  .section__img-wrapper img {
    width: 872px;
  }

  .section__time-lock {
    right: 0;
  }

  .section .public span {
    left: 0;
  }

  .section__time-wrapper {
    height: 451px;
  }
}

@media (max-width: 1500px) {
  .section__title {
    font-size: 95px;
    line-height: 100%;
    width: 1170px;
  }
  .popup-generic-wrapper .popup-claim {
    // min-width: 1410px;
    // min-height: 570px;
    max-width: 950px;
    max-height: 550px;
    min-width: 950px;
    min-height: 550px;
    margin: 50px auto;
    position: relative;
  }
  .popup-generic-wrapper .claim-timer {
    font-size: 24px;
    font-family: "Poppins", sans-serif;
    line-height: 145%;
    color: #000000;
    margin: 10px 9px 10px 0;
  }
  .popup-generic-wrapper .popup-claim .box-desc {
    text-align: center;
    width: 931px;
    margin: 8px auto;
    font-size: 20px;
  }
  .popup-generic-wrapper .claim-title {
    font-size: 25px;
  }

  .popup-generic-wrapper .claim-1-airdrop {
    margin-top: 10px;
    width: 100%;
    padding: 12px 0;

    &.success {
      font-size: 32px;
    }
  }
  .popup-generic-wrapper .claim-input {
    background-size: contain;
    width: 606px;
    padding-top: 19px;
  }

  .active__wrapper-btns button {
    width: 68px !important;

    span {
      font-size: 16px;
    }
  }
  .section__title span {
    font-size: 95px;
    line-height: 100%;
  }

  .section__desc {
    margin-bottom: 41px;
    margin-top: 30px;
  }

  .section__wrapper div p:nth-child(3) {
    margin: 20px 0;
  }

  .section__desc {
    width: 700px;
    font-size: 20px;
  }

  .section__table {
    margin-top: -290px;
  }

  .section__img-wrapper {
    margin-left: -556px;
    margin-top: 30px;
  }

  .section__time-wrapper h3 {
    font-size: 77px;
  }

  .section__time-wrapper .available h2 {
    font-size: 117px;
  }

  .section__metamask h4 {
    font-size: 60px;
  }

  .section__metamask h4 span {
    font-size: 60px;
  }

  .section__subdesc ul {
    margin-top: 20px;
    display: inline-block;
    width: 673px;
  }

  .section__info-title {
    font-size: 95px;
    width: 946px;
  }

  .section__info-title span {
    font-size: 95px;
  }

  .section__table h4 {
    font-size: 25px;
  }

  .section__info {
    padding: 100px 135px 100px;
  }

  .section__info-steps li img {
    margin-right: 14px;
  }

  .section__info-steps li:not(:last-child) {
    margin-bottom: 10px;
  }

  .section__info ul {
    margin: 62px 119px 80px;
  }

  .section__info-steps li a {
    font-size: 100%;
  }

  .section__info-box {
    padding: 50px 0 10px;
  }

  .section__info-subtitle {
    font-size: 95px;
    margin-bottom: 54px;
    text-align: left;
  }

  .section__info-btn {
    background-size: contain;
    width: 370px !important;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      font-size: 20px;
    }
  }
  .lineMeta {
    width: 410px;
  }
  .section__info-disclaimer {
    margin: 155px 160px 147px auto;
  }
  .section__time-wrapper .cumminity-claim {
    margin-top: 40px;
  }
  .section__time-wrapper .cummunity-ended {
    margin-top: 20px;
  }
}

@media (max-width: 1350px) {
  .notAvailable-wrapper {
    .notAvailable {
      .notAvailable-box {
        & p:nth-child(2) {
          font-size: 21px;
          width: 816px;
        }

        & p:nth-child(3) {
          font-size: 15px;
        }

        .notAvailable-title {
          font-size: 35px;
        }
      }
    }
  }
  .section__table {
    margin-top: -223px;
  }
  .active__title p {
    width: 1003px;
  }
  .active__wrapper input:nth-child(1) {
    width: 281px;
    padding: 20px 40px 20px 32px;
  }
  .active__wrapper input {
    padding: 20px 5px 20px 15px;
    width: 196px;
  }

  .active__wrapper-btns {
    margin-left: 0;
  }
  .section__info-btn {
    padding: 14px 0;
  }

  .section__img-wrapper {
    margin-left: -580px;
  }

  .section__table-container {
    padding: 0px 20px;
  }

  .section__time-wrapper {
    margin-top: 0;
  }
  .lineMeta {
    width: 380px;
  }
  .line {
    width: 200px;
    display: none;
  }
  .section__time-wrapper .cumminity-claim {
    margin-top: 26px;
  }
}

@media (max-width: 1250px) {
  .section__img-wrapper {
    margin-left: -620px;
  }

  .section__info-btn {
    background-size: contain;
    padding: 7px 0;
    width: 270px !important;
  }
  .line {
    display: none;
  }
  .lineMeta {
    width: 250px;
  }
  .active__wrapper {
    justify-content: center;
  }
}

@media (max-width: 1100px) {
  .section__time-wrapper .communnity-active {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 690px;
  }
  .section__time-wrapper .cumminity-claim {
    width: 297px;
    height: 61px;
    font-size: 18px;
  }
  .section__time-wrapper .cumminity-claim h2 {
    font-size: 18px;
  }
}

@media (max-width: 1024px) {
  .notAvailable-wrapper {
    .notAvailable {
      min-height: 100vh !important;

      .notAvailable-box {
        .notAvailable-title {
          font-size: 43px;
          width: 600px;
        }

        & p:nth-child(2) {
          font-size: 18px;
          width: 646px;
        }

        & p:nth-child(3) {
          font-size: 15px;
        }
      }
    }
  }
  .popup-generic-wrapper .popup-generic {
    max-width: 706.52px;
    min-width: 706.52px;
    background: url("../Image/regularPopupTablet.png") no-repeat
      center/cover;
  }
  .popup-generic-wrapper .popup-claim {
    background: url("../Image/heightPopupTablet.svg") no-repeat
      center/contain;
    max-width: 593px;
    max-height: 597px;
    min-width: 593px;
    min-height: 597px;
    margin: 50px auto;
    position: relative;
  }
  .popup-generic-wrapper .popup-generic .box-title {
    font-size: 36px;
  }
  .popup-generic-wrapper .popup-generic {
    .box-desc {
      font-size: 18px;
      margin: 80px 0;
    }

    .popup-subdesc {
      font-size: 18px;
    }
  }
  .popup-generic-wrapper .popup-claim {
    .box-desc {
      width: 561px;
      font-size: 18px;
      margin: 32px 0 0;
      text-align: left;
    }
    .popup-success {
      text-align: center;
    }
  }

  .popup-generic-wrapper .claim-1-airdrop {
    margin-top: 39px;
    width: 100%;
    padding: 12px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .popup-generic-wrapper .claim-input {
    width: 534px;
    margin-left: 0;
    font-size: 18px;
  }
  .popup-generic-wrapper .popup-generic .exit-button-wrapper {
    width: 100%;
    margin-top: 16px;
  }
  .popup-generic-wrapper .popup-generic .box-button {
    background-size: contain;
    width: 204px;
    font-size: 18px;
    font-family: "Poppins", sans-serif;
  }
  .popup-generic-wrapper .popup-error .exit-button-wrapper {
    width: unset;
    margin: 30px 10px 0 auto;
  }
  .section__box p,
  ul {
    font-size: 18px;
  }
  .section__title {
    font-size: 70px;
    line-height: 100%;
    width: 550px;
  }
  .lineMeta {
    display: none;
  }

  .active__wrapper input:nth-child(1) {
    width: 296px;
    padding: 18px 40px 18px 32px;
  }
  .public {
    margin-top: 107px;
  }

  .public .section__time-glass {
    background-image: url("../Image/glass.png");
  }

  .section__title span {
    font-size: 70px;
  }

  .section__info ul {
    margin: 62px 0 80px 0;
  }

  .section__info-title {
    font-size: 70px;
    width: 616px;
  }

  .section__info-title span {
    font-size: 70px;
  }

  .section__info {
    padding: 100px 55px 100px;
  }

  .section__time-wrapper {
    margin-top: 20px;
    height: 518px;
    background-size: cover;
    background-position-x: -310px;
    text-align: center;
    background-color: unset;
  }

  .section__subdesc-list {
    width: 379px;
    margin: 76px 0;
  }

  .section__time-glass {
    background-size: cover;
    background-position-x: -280px;
    height: 548px;
    top: 29px;
  }

  .section__desc {
    font-size: 18px;
    line-height: 26.1px;
    width: 665px;
    margin-bottom: unset;
  }

  .section__desca {
    font-size: 18px;
    line-height: 26.1px;
  }

  .section p:nth-child(3) {
    max-width: 391px;
    margin-top: 34px;
  }

  .section p:nth-child(3) ul {
    margin-top: 20px;
    display: inline-block;
    width: 260px;
  }

  .section p:nth-child(3) ul li {
    position: relative;
    margin-left: 15px;
  }

  .section p:nth-child(3) ul li:before {
    content: "";
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: white;
    left: -15px;
    top: 15px;
  }

  .section p:nth-child(4) {
    width: 200px;
    margin-top: 30px;
  }

  .section__img-wrapper {
    margin-top: 219px;
    margin-left: -343px;
  }

  .section__img-wrapper img {
    width: 659px;
  }
  .active__wrapper input {
    padding: 17px 5px 17px 15px;
    width: 196px;
  }
  .section__time-wrapper h3 {
    font-size: 65px;
  }
  .section__time-wrapper .public-box h3 {
    font-size: 100px;
  }

  .section__time-wrapper .available h2 {
    font-size: 100px;
    text-align: center;
  }
  .section__time-wrapper h4.section__time-wrapper-ended {
    font-size: 51px;
  }
  .section__time-wrapper .section__time-wrapper-ended span {
    display: block;
    font-size: 100px;
    margin-top: 30px;
  }
  .line {
    display: none;
  }

  .section__metamask {
    background: rgba(37, 37, 37, 0.55);
    padding: 120px 0 75px;
    margin: -70px 0 -140px;
  }

  .section__metamask .active__wrapper .tablet-btn {
    display: flex;
    justify-content: center;
    padding: 17px 20px 19px;
    margin: 0 108px 0 auto;
    width: 353px;
    background-repeat: no-repeat;
  }
  .active__title p {
    width: 823px;
  }
  .active__wrapper {
    display: block;
  }

  .active__wrapper-btns {
    width: 656px;
    margin: 10px auto;
  }
  .active__wrapper-btns button {
    width: 69px;
  }
  .section__metamask-box {
    align-items: center;
    justify-content: center;
  }
  .section__connecting-metamask {
    padding: 0 40px;
  }
  .section__metamask-box span {
    margin: 0 48px 0 auto;
    align-items: center;
    padding: unset;
    font-weight: 500;
    font-size: 18px;
  }

  .section__metamask h4 {
    font-size: 65px;
  }
  .section__metamask h4:nth-child(1) {
    display: none;
  }
  .section__metamask h4.metamask__connected {
    display: block;
  }
  .metamask__connected-box span {
    margin: unset;
  }
  .section__connecting-metamask h4:nth-child(2) {
    display: block;
    text-align: center;
  }
  .section .public {
    background-size: cover;
    background-position-x: -220px;
    transform: unset;
  }

  .section .public span {
    right: 0;
    left: unset;
    top: 0;
    buttom: unset;
    transform: unset;
  }

  .section .public .section__time-glass {
    background-size: cover;
    background-position-x: -170px;
    height: 548px;
    top: 0;
  }

  .section .public-box {
    transform: unset;
  }

  .section .public .public-box p {
    width: unset;
  }

  .section__table-container {
    max-width: 100%;
    padding: 0 50px;
  }

  .section__table h4 {
    font-size: 23px;
  }

  .section__info-btn {
    display: none;
  }

  .section__info-box .tablet {
    display: block;
    flex-wrap: wrap;
    justify-content: start;
  }

  .section__info-box .tablet .section__info-btn {
    width: 351px !important;
    display: block;
    padding: 16px 0;
  }

  .section__info-box .tablet .section__info-btn {
    font-size: 18px;
    line-height: 27px;
  }

  .section__info-box .tablet .section__info-btn:nth-child(2) {
    margin: 50px 0 50px 160px;
  }

  .section__info-box .tablet .section__info-btn:nth-child(3) {
    margin-left: 289px;
  }

  .section__info-box p.section__info-disclaimer {
    width: 470px;
    margin: 140px 0 0 auto;
  }
  .section__time-wrapper .cummunity-ended {
    width: 184px;
    font-size: 18px;
    height: 53px;
  }
}

@media (max-width: 950px) {
  .section__metamask .active__wrapper .tablet-btn {
    margin: 27px 30px 0 auto;
  }
  .active__title p {
    width: 683px;
  }
  .notAvailable-wrapper {
    .notAvailable {
      min-height: 100vh !important;

      .notAvailable-box {
        .notAvailable-title {
          font-size: 43px;
          width: 600px;
        }

        & p:nth-child(2) {
          font-size: 18px;
          width: 576px;
        }

        & p:nth-child(3) {
          font-size: 15px;
          width: 566px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .tablet-btn {
    display: inline-block;
  }

  .section__subdesc-list {
    width: 330px;
  }

  .section__wrapper {
    padding-top: 45px;
  }

  .section__box {
    max-width: 768px;
  }

  .line {
    display: none;
  }

  .section__desc {
    font-size: 18px;
    line-height: 145%;
  }

  .section__title {
    font-size: 70px;
    line-height: 100%;
    width: 600px;
  }

  .section__title span {
    font-size: 70px;
    line-height: 100%;
  }

  .section__time-wrapper {
    background-position-x: -280px;
    height: 388px;
  }

  .section__time-glass {
    height: 418px;
  }

  .section__time-wrapper h3 {
    font-size: 65px;
    line-height: 100%;
    width: 430px;
  }

  .public.section__time-wrapper h3 {
    width: 620px;
  }
  .section__time-wrapper .public-box h3 {
    font-size: 51px;
  }

  .section__time-wrapper {
    margin-top: 17px;
  }

  .section__time-wrapper .available h2 {
    font-size: 100px;
    letter-spacing: 0.02em;
    text-align: center;
  }

  .section__time-glass {
    background-position-x: -258px;
  }

  .section__wrapper-box {
    padding-left: 20px;
  }

  .section__wrapper-box div:nth-child(1) {
    padding-right: 20px;
  }

  .section__wrapper-box p:nth-child(5) {
    width: 170px;
  }

  .section__wrapper-box a {
    font-size: 18px;
  }

  .section p.section__subdesc {
    width: 100%;
    margin-top: 102px;
    line-height: 145%;
  }

  .section__wrapper p {
    margin-left: unset;
  }

  .section__img-wrapper {
    margin-left: -495px;
    margin-top: 160px;
  }

  .section__img-wrapper img {
    width: 716px;
    object-fit: contain;
  }

  .section__time-lock {
    width: 69px;
    right: 0;
  }

  .section .public {
    margin-top: 70px;
    transform: unset;
  }

  .section__info-box {
    text-align: left;
  }

  .public {
    margin-top: 107px;
  }

  .section__info-subtitle {
    margin-top: 138px;
  }

  .section .public .section__time-glass {
    right: -230px;
    transform: unset;
    bottom: 20px;
    background-position-x: -220px;
    height: 428px;
    top: 16px;
  }

  .section .public .section__time-lock {
    transform: unset;
    right: 0;
    bottom: unset;
    left: unset;
    top: 0;
    z-index: 22;
  }

  .section__time-wrapper {
    text-align: center;
  }

  .section__metamask {
    margin-bottom: unset;
  }

  .section__metamask h4 {
    font-size: 65px;
    line-height: 100%;
  }
  .section__connecting-metamask h4 {
    text-align: left;
  }
  .section__table {
    margin-top: -253px;
  }

  .section__table-container .col-4:nth-child(1) {
    width: 193px;
  }

  .section__table-container .col-4:not(:first-child) {
    width: 248px;
  }

  .section__table-info {
    font-size: 15px;
  }

  .section__table-container {
    padding: 0 31px;
  }

  .section__table h4 {
    font-size: 23px;
    line-height: 105%;
  }

  .section__table-body span:nth-child(1) {
    font-size: 18px;
    line-height: 120%;
  }

  .section__info {
    padding: 105px 47px 96px;
  }

  .section__info-title {
    width: 604px;
    margin-top: 10px;
  }

  .section__info-title span {
    font-size: 70px;
  }

  .section__info ul {
    margin: 42px 0 0;
  }

  .section__info-steps li {
    font-size: 18px;
  }

  .section__info-steps li:not(:last-child) {
    margin-bottom: 13.72px;
  }

  .section__info-steps li a {
    font-size: 18px;
  }

  .section__info-steps li img {
    width: 18px;
  }

  .section__info-box div {
    display: none;
  }

  .section__info-box .tablet {
    display: block;
    flex-wrap: wrap;
    justify-content: start;
  }

  .section__info-box .tablet a {
    padding: 10px;
  }

  .section__info-box .tablet a:nth-child(2) {
    margin: 50px 0 50px 160px;
  }

  .section__info-box .tablet a:nth-child(3) {
    margin-left: 289px;
  }

  .section__info-box .tablet .section__info-btn {
    width: 353px;
    background-size: contain;
    padding: 17px 0 19px;
    font-size: 18px;
    line-height: 27px;
  }
  .section__info-subtitle {
    font-size: 70px !important;
  }
  .section__info-subtitle span {
    font-size: 70px;
  }

  .section__info-disclaimer {
    margin: 140px 0 96px auto;
    width: 470px;
  }

  .section .public span {
    left: 0;
  }

  .active__state {
    margin: unset;
  }

  .active__box p {
    display: block;
    text-align: center;
    font-size: 18px;
    line-height: 145%;
    color: white;
    margin: 66px auto 37px;
  }

  .active__loading {
    width: 672px;
    margin: 0 auto;
  }

  .active h3 {
    font-size: 65px;
  }

  .active__wrapper {
    display: block;
  }

  .active__wrapper span {
    font-size: 18px;
    line-height: 27px;
  }

  .active__wrapper .tablet-btn {
    padding: 17px 151px 19px;
    width: 353px;
    font-size: 18px;
    line-height: 27px;
    display: block;
    margin: 30px 48px 0 auto;
  }
  .active__wrapper-btns {
    width: 650px;
  }

  .section__time-wrapper .cumminity-claim h2 {
    margin: unset;
  }
  .active__wrapper button {
    display: inline-block;
  }

  .active__wrapper-form {
    margin-bottom: 11px;
    background: rgba(120, 75, 43, 0.2);
    border-radius: unset;
    padding: 0 46px;
  }

  .active__title h3 {
    font-size: 65px;
  }
  .active__title h3 span {
    font-size: 65px;
  }
  .active__title p {
    width: 673px;
  }

  .active__wrapper input:nth-child(1)::placeholder {
    font-size: 18px;
    line-height: 27px;
  }

  .active__wrapper input:nth-child(3) {
    background-position: inherit;
    background-repeat: no-repeat;
    background-size: contain;
    width: 199px;
    padding: 16px 1px;
    text-align: center;
  }

  .active__wrapper input:nth-child(3)::placeholder {
    font-size: 18px;
    line-height: 27px;
  }
  .section__time-wrapper .cummunity-ended {
    margin-top: 50px;
  }
}

@media (max-width: 680px) {
  .popup-generic-wrapper .popup-generic {
    background: url("../Image/regularPopupMobile.png") no-repeat
      center/cover;
    max-width: 320.52px;
    max-height: 518px;
    min-width: 320.52px;
    min-height: 518px;
    padding: 10px;
    align-items: unset;
  }
  .popup-generic-wrapper .popup-claim {
    background: url("../Image/heightPopupMobile.png") no-repeat
      center/contain;
    max-width: 320px;
    max-height: 916.93px;
    min-width: 320px;
    min-height: 916.93px;
    margin: 50px auto;
    position: relative;
  }
  .popup-generic-wrapper .popup-generic .box-button {
    background: url("../Image/regularPopupButtonMobile.svg")
      no-repeat center/contain;
    width: 136px;
    margin: 0 5px 0 auto;
  }
  .popup-generic-wrapper .popup-generic .box-desc {
    font-size: 16px;
    margin: 150px 0 150px;
    text-align: center;
  }
  .popup-generic-wrapper .popup-claim .box-title {
    width: 200px;
    text-align: center;
    font-size: 40px;
    margin-left: 20px;
  }
  .popup-generic-wrapper .popup-claim .box-desc {
    font-size: 16px;
    margin: 30px auto 30px;
    text-align: left;
    width: 280px;
  }
  .popup-generic-wrapper .claim-input {
    background-size: contain;
    background-image: url("../Image/popupClaimInput.svg");
    width: 284px;
    padding: 20px 13px 15px 19px;
    text-overflow: ellipsis;
  }
  .popup-generic-wrapper .claim-title {
    font-size: 23px;
  }
  .popup-generic-wrapper .claim-1-airdrop {
    margin-top: 55px;
    width: 100%;
    height: 205px;
  }
  .popup-generic-wrapper .claim-button {
    background-image: url("../Image/popupClaimLeftMobileBtn.svg");
  }
  .popup-generic-wrapper .right-button {
    background-image: url("../Image/popupClaimRightMobileBtn.svg");
  }
  .popup-generic-wrapper .claim-button {
    background-size: contain;
    background-repeat: no-repeat;
    width: 152px;
    height: 57px;
    font-weight: 500;
    font-size: 18px;
    line-height: 36px;
    letter-spacing: 0.02em;
    color: #ffffff;
    margin-left: 8px;
  }

  .popup-claim .claim-timer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .popup-claim .popup-subdesc {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    padding: 0 4px;
  }

  .section__title {
    font-size: 50px;
    line-height: 100%;
    width: 390px;
  }
  .section__info .section__info-title {
    font-size: 40px;
    margin-top: unset;
    width: 420px;
  }
  .section__info .section__info-title span {
    font-size: 40px;
  }
  .section__wrapper-box {
    display: block;
  }
  .section .section__title {
    font-size: 50px;
  }
  .section__title span {
    font-size: 50px;
  }
  .active__wrapper-form span:nth-child(4) {
    margin: 0 10px;
  }
  .active__wrapper-btns {
    padding: 0 22px;
  }
  .active__wrapper-form div:nth-child(2) {
    margin: 0 10px;
  }
  .active__wrapper-form div:nth-child(2) span:nth-child(1) {
    margin-right: 5px;
  }
  .section__desc {
    font-size: 18px;
    width: 100%;
  }
  .active__title p {
    width: 593px;
  }
  .section__wrapper div p:nth-child(3) {
    margin: 20px 0;
    font-size: 16px;
  }

  .section p.section__subdesc {
    width: 521px;
  }

  .section__img-wrapper {
    margin-left: 0;
    margin-top: -132px;
  }
  .section__subdesc-list {
    width: 100%;
  }

  .section__subdesc-list li:before {
    width: 5px;
    height: 5px;
  }

  .section__table h4 {
    font-size: 23px;
    line-height: 105%;
    letter-spacing: 0.02em;
  }

  .col-3 {
    width: 25%;
    padding: 0 15px;
  }

  .col-4 {
    padding: 0 0 0 15px;
  }

  .col-6 {
    width: 50%;
    padding: 0 15px;
  }

  .section__table {
    overflow-x: scroll;
  }

  .section__table-body {
    width: 835px;
  }

  .section__table-header {
    width: 835px;
  }

  .section__table-container {
    padding: 0 40px;
  }

  .section__time-wrapper h3 {
    font-size: 35px;
  }

  .section__time-wrapper .available h2 {
    font-size: 80px;
    margin: 30px 0;
  }

  .section__info-title {
    font-size: 50px;
  }

  .section__info-title span {
    font-size: 50px;
  }

  .section__info-title {
    width: 473px;
    margin-left: 30px;
  }

  .section__info-box {
    padding: 0 0 10px;
  }

  .section__desc {
    font-size: 16px;
  }

  .section__info-box .tablet .section__info-btn:nth-child(2) {
    margin: 50px 0 50px 90px;
  }

  .section__info-box .tablet .section__info-btn:nth-child(3) {
    margin-left: 138px;
  }

  .section__time-wrapper {
    background-position-x: -420px;
    height: 430px;
    padding: 10px;
  }

  .section .public {
    background-position-x: -420px;
    height: 430px;
  }

  .section__time-lock {
    top: -10px;
    right: 10px;
  }

  .section__time-glass {
    background-position-x: -418px;
    top: 11px;
    left: 8px;
    height: 448px;
  }

  .section .public .section__time-lock {
    top: -10px;
    right: 10px;
  }

  .section .public .section__time-glass {
    background-position-x: -418px;
    top: 11px;
    left: 8px;
    height: 448px;
    right: 0;
  }
}

@media (max-width: 620px) {
  .notAvailable-wrapper {
    .notAvailable {
      .notAvailable-box {
        text-align: center;

        .notAvailable-title {
          font-size: 35px;
          width: 320px;

          span {
            display: inline-block;
            margin-right: 4px;
          }
        }

        & p:nth-child(2) {
          font-size: 16px;
          width: 320px;
          margin: 50px 0 48px;
        }

        & p:nth-child(3) {
          font-size: 11px;
          width: 325px;
        }
      }
    }
  }
  .active__wrapper-btns {
    display: none;
  }
  .section__time-wrapper .communnity-active {
    font-size: 16px;
    width: 400px;
  }

  .active__title {
    padding: 0 25px;
    align-items: flex-start;
  }
  .active__title p {
    width: 424px;
    text-align: unset;
  }
  .section__connecting-metamask {
    padding-top: 40px;
  }
}

@media (max-width: 560px) {
  .available span:nth-child(1) {
    display: block;
  }
  .available span:nth-child(2) {
    display: none;
  }
  .section__connecting-metamask button {
    background-size: contain;
    width: 319px;
    height: 61px;
  }
  .section__connecting-metamask a {
    background-size: contain;
    width: 319px;
    height: 61px;
  }
  .section__connecting-metamask span {
    height: 56px;
  }
  .section__connecting-metamask a {
    background-size: contain;
    width: 319px;
    height: 61px;
  }

  .active__title h3 {
    font-size: 40px;
  }
  .active__title h3 span {
    font-size: 40px;
  }
}

@media (max-width: 500px) {
  .section__desc {
    width: 480px;
    font-size: 16px;
  }
  .section p:nth-child(4) {
    width: 150px;
    margin-top: unset;
  }

  .section__metamask button {
    width: 380px;
    background-size: contain;
  }
  .section p.section__subdesc {
    width: 450px;
    font-size: 16px;
  }

  .section__subdesc-list {
    width: 460px;
    font-size: 16px;
  }

  .section__wrapper-box p:nth-child(5) {
    font-size: 16px;
  }

  .section__img-wrapper {
    margin-left: 40px;
    margin-top: -87px;
    pointer-events: none;
  }

  .section__img-wrapper img {
    width: 420px;
    object-fit: contain;
  }

  .section__table {
    margin-top: -70px;
  }

  .section__time-wrapper h2 {
    font-size: 78px;
    text-align: center;
    margin: 10px 0;
  }
  .section__time-wrapper h3 {
    width: 290px;
  }

  .section__wrapper p {
    width: 440px;
  }

  .section__metamask {
    padding: 79px 0 62px;
  }

  .section__metamask-box {
    background-color: unset;
  }

  .section__metamask button {
    margin: 0 auto;
  }
  .section__connecting-metamask button {
    width: 318px;
    margin: unset;
  }
  .section__connecting-metamask a {
    width: 318px;
    margin: unset;
  }
  .section__metamask-box {
    justify-content: center;
  }
  .section__metamask h5 {
    display: block;
    font-size: 40px;
    line-height: 100%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    font-family: "Cold Warm Regular", "Arial Black", sans-serif;
    margin-left: 21px;
  }

  .section__metamask h5 span {
    font-size: 40px;
    line-height: 100%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #ffc328;
  }

  .section__metamask h4 {
    display: none;
  }

  .section .public-box {
    text-align: center;
  }

  .section__info-title {
    margin-top: 77px;
    width: 217px;
    font-size: 40px;
  }

  .section__info-title span {
    font-size: 40px;
  }

  .section__info ul li {
    font-size: 16px;
  }

  .section__info ul li span {
    font-size: 16px;
  }

  .section__info {
    padding: 20px 17px;
  }

  .section__info-box h2 {
    font-size: 40px;
    width: 400px;
    margin-top: 90px;
  }

  .section__info-box h2 span {
    font-size: 40px;
  }
  .section__info-subtitle {
    font-size: 40px !important;
  }

  .section__info-box .tablet .section__info-btn {
    margin: 0 auto 25px !important;
    display: block;
    width: 320px !important;
    padding: 18px 0;

    @media (max-width: 500px) {
      height: 56px;
      padding: 0;
    }

    a {
      font-size: 18px;
    }
  }

  .section__info-disclaimer {
    width: unset;
    font-size: 11px;
  }
  .section__connecting-metamask h4:nth-child(2) {
    font-size: 40px;
  }
  .section__connecting-metamask h4:nth-child(2) span {
    font-size: 40px;
  }
}

@media (max-width: 480px) {
  .section__time-wrapper .available h2 {
    margin: 5px 0;
  }
  .section p {
    width: 100%;
  }
  .section__time-wrapper .public-box h3 {
    font-size: 35px;
  }
  .section__time-wrapper .section__time-wrapper-ended {
    font-size: 35px;
  }
  .section__time-wrapper .section__time-wrapper-ended span {
    font-size: 78px;
  }
  .section p.section__subdesc {
    width: 100%;
  }
  .section__time-wrapper .cummunity-ended {
    margin-top: 20px;
  }
  .section__subdesc-list {
    width: 100%;
    margin: 36px 0;
  }

  .section__time-wrapper {
    background-position-x: -420px;
    height: 430px;
    padding: 10px;
  }

  .section .public {
    background-position-x: -420px;
    height: 430px;
  }

  .section__time-lock {
    top: -10px;
    right: 10px;
  }

  .section__time-glass {
    background-position-x: -418px;
    top: 11px;
    left: 8px;
    height: 448px;
  }

  .section .public .section__time-lock {
    top: -10px;
    right: 10px;
  }

  .section .public .section__time-glass {
    background-position-x: -418px;
    top: 11px;
    left: 8px;
    height: 448px;
    right: 0;
  }

  .section__info-box p.section__info-disclaimer {
    width: 100%;
  }
  .metamask__connected-box div {
    background-size: contain;
    width: 333px;
    height: 59px;
  }
  .section__time-wrapper .public-box p {
    margin-bottom: 10px;
  }
  .section__time-wrapper .public-box h3 span {
    display: block;
    font-size: 78px;
    margin-top: 19px;
  }
  .coin:not(:last-child) {
    margin-right: 25px;
  }
  .section .section__title {
    font-size: 40px;
  }
  .section__title span {
    font-size: 40px;
  }
  .section__box .public .public-box p {
    width: 350px;
    margin: 35px auto;
  }
}

@media (max-width: 400px) {
  .section__title {
    font-size: 40px;
    width: 340px;
  }
  .section__metamask .active__wrapper .tablet-btn {
    margin: 30px auto;
  }
  .section .section__title {
    font-size: 40px;
  }
  .section__title span {
    font-size: 40px;
  }
  .section__box .public .public-box p {
    width: 350px;
    margin: 35px auto;
  }
  .section__wrapper-box {
    padding-left: unset;
  }

  .section__wrapper p {
    width: 340px;
  }

  .section p.section__subdesc {
    width: 320px;
  }

  .section__subdesc-list {
    width: 330px;
  }

  .section__wrapper-box a {
    font-size: 16px;
  }

  .section__img-wrapper {
    margin-left: 29px;
    margin-top: -64px;
  }

  .section__wrapper-box div:nth-child(1) {
    padding: 0 20px;
  }

  .section__table-container {
    padding: 0 0 0 20px;
  }

  .section__table-body {
    width: 866px;
  }

  .section__table-header {
    width: 866px;
  }
  .section__time-wrapper .communnity-active {
    width: 320px;
  }
  .section__metamask button {
    width: 380px;
  }

  .section__info-box .tablet .section__info-btn {
    width: 323px !important;
  }

  .section__info-box p.section__info-disclaimer {
    width: unset;
    margin: 140px 0 0 auto;
  }

  .section__info-steps li a {
    font-size: 16px;
  }

  .section__time-wrapper {
    background-position-x: -420px;
    height: 430px;
    padding: 10px;
  }

  .section .public {
    background-position-x: -420px;
    height: 430px;
  }

  .section__time-lock {
    top: -10px;
    right: 10px;
  }

  .section__time-glass {
    background-position-x: -418px;
    top: 11px;
    left: 8px;
    height: 448px;
  }

  .section .public .section__time-lock {
    top: -10px;
    right: 10px;
  }

  .section .public .section__time-glass {
    background-position-x: -418px;
    top: 11px;
    left: 8px;
    height: 448px;
    right: 0;
  }
  .section__connecting-metamask button {
    width: 319px;
    min-width: 319px;
    transform: scale(0.8);
  }
  .section__connecting-metamask a {
    width: 319px;
  }
}

@media (max-height: 450px) and (orientation: landscape) {
  .story-text p {
    font-size: 0.6rem !important;
  }
}

@media (max-width: 720px) {
  .story-text {
    left: 80px;
  }

  .story-text p {
    font-size: 16px;
    margin-left: 20px;
    width: calc(100vw - 100px);
  }

  .story-text-1,
  .story-text-2,
  .story-text-3,
  .story-text-4,
  .story-text-5 {
    top: 120px;
  }

  #story-title-1,
  #story-title-2,
  #story-title-3,
  #story-title-4,
  #story-title-5 {
    font-size: 40px;
  }

  .section h1,
  .section h2,
  .section h3,
  .section h4 {
    // font-size: 50px;
    // line-height: 100%;
  }
}

@media (max-width: 360px) {
  .story-text {
    left: 60px;
    p {
      margin-left: 10px;
      width: calc(100vw - 80px);
    }
  }

  #story-title-1,
  #story-title-2,
  #story-title-3,
  #story-title-4,
  #story-title-5 {
    font-size: 32px;
  }

  .section h1,
  .section h2,
  .section h3,
  .section h4 {
    font-size: 32px;
  }
}

@media (max-height: 420px) {
  .intro-container {
    display: flex;
    justify-content: space-around;

    .intro-logo {
      margin-top: 0;
      //transform: scale(0.666666667);
      width: 344.666666667px;
      height: 99.42px;
    }

    .intro-bottom {
      flex: none;
      justify-content: space-around;
      height: calc(100% - 98.56px);

      .lead {
        margin: 0;
        font-size: 24px;
      }

      .intro-fixed {
        height: 100px;

        .intro-preload {
          height: 100%;
        }
      }
    }
  }

  .story-text-1,
  .story-text-2,
  .story-text-3,
  .story-text-4,
  .story-text-5 {
    top: 5px;
  }

  #story-title-1,
  #story-title-2,
  #story-title-3,
  #story-title-4,
  #story-title-5 {
    font-size: 32px;
  }
}
