.playRegister{
  position: relative;
  padding: 0 7.813vw;
  margin-top: 2vw;
  &:after{
    content: "";
    position: absolute;
    right: 7.813vw;
    top: -6vw;
    width: 55.865vw;
    height: 60.515vw;
    background-image: url("../../../../Image/playnowLand.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  & h3{
    padding-top: 15.104vw;
    width: 36vw;
    position: relative;
    &:after{
      content: "1";
      position: absolute;
      background: #FFC328;
      border-radius: 50%;
      font-size: 7.500vw;
      color: #000000;
      width: 9.948vw;
      height: 9.948vw;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0.470vw solid #FFFFFF;
      box-shadow: inset 4.50879px 3.89038px 1.1272px 5.5264px #B37E00;
      top: 2vw;
      left: 10vw;
      font-weight: 400;
    }
    &:before{
      content: "";
      position: absolute;
      background-image: url("../../../../Image/playNowLines1.svg");
      width: 38.125vw;
      height: 1.875vw;
      bottom: -2vw;
      left: -7.813vw;
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }
  & p{
    font-size: 1.250vw;
    margin-top: 4.688vw;
    max-width: 46.771vw;
  }
  & a{
    z-index: 2;
    font-weight: 500;
    font-size: 1.250vw;
    margin-top: 5.990vw;
    height: 3.906vw;
    width: 13.490vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../../../Image/button-type1.svg");
    background-size: 100% 100%;
    color: #5B0D00;
    cursor: pointer;
    text-decoration: none;
    margin-left: 30vw;
    &:hover{
      filter: brightness(110%) contrast(95%);
    }
    &:active{
      filter: brightness(0.8) contrast(160%);
    }

    position: relative;
    &:before{
      content: "";
      position: absolute;
      left: -10vw;
      top: -4.125vw;
      background: url("../../../../Image/EnterBattleArrow.svg") no-repeat;
      background-size: 100% 100%;
      transform: rotate(58deg);
      height: 9.063vw;
      width: 6.719vw;
    }
  }
}
.PlayRegisterClosedLabel{
  font-size: 2.604vw;
  width: 41.406vw;
  margin-top: 4.219vw;
  text-align: center !important;
  position: relative;
  &:after, &:before{
    content: "";
    position: absolute;
    width: 9.948vw;
    height: 0.313vw;
    background: #FFC328;
    top: 50%;
    left: 0;
  }
  &:before{
    right: 0;
    left: auto;
  }
  & span{
    display: block;
  }
}
@media (max-width: 1024px) {
  .playRegister{
    position: relative;
    padding: 0 50px;
    margin-top: 50px;
    &:after{
      background-image: url("../../../../Image/playnowLandTablet.png");
      right: 0;
      top: 192px;
      width: 726px;
      height: 669px;
    }
    & h3{
      padding-top: 15.104vw;

      width: 525.43px;
      text-align: left;
      &:after{
        font-size: 61.1125px;
        color: #000000;
        width: 81.06px;
        height: 81.06px;
        border: 3.82699px solid #FFFFFF;
        box-shadow: inset 1.9135px 3.34862px 0.478374px 5.74049px #B37E00;
        top: 2vw;
        left: 10vw;
      }
      &:before{
        content: "";
        position: absolute;
        background-image: url("../../../../Image/playNowLines1.svg");
        width: 419.04px;
        height: 25.33px;
        bottom: -2vw;
        left: -50px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
    }
    & p{
      font-size: 18px;
      margin-top: 400px;
      max-width: none;
      width: 224.65px;
    }
    & a{
      font-size: 18px;
      margin-top: 100px;
      width: 211.33px;
      height: 61px;
      margin-left: 320px;
      position: relative;
      &:before{
        left: -120px;
        top: -50px;
        width: 92.18px;
        height: 123.74px;
      }
    }
  }
  .PlayRegisterClosedLabel{
    font-size: 40px;
    width: 100%;
    margin-top: 25px;
    &:after, &:before{
      width: 177px;
      height: 4.69px;
    }
    & span{
      display: block;
    }
  }
}

@media (max-width: 576px) {
  .playRegister{
    position: relative;
    padding: 0 20px;
    margin-top: 50px;
    &:after{
      right: auto;
      background-image: url("../../../../Image/playnowLand.png");
      left: 40%;
      transform: translateX(-50%);
      top: 132px;
      width: 556.3px;
      height: 628.15px;

    }
    & h3{
      padding-top: 60px !important;
      padding-left: 0 !important;

      width: 317.82px;
      &:after{
        font-size: 40px;
        color: #000000;
        width: 53.06px;
        height: 53.06px;
        border: 2.53431px solid #FFFFFF;
        box-shadow: inset 1.26715px 2.21752px 0.316789px 3.80146px #B37E00;
        top: -20px;
        left: 10vw;
      }
      &:before{
        width: 258.94px;
        height: 12.15px;
      }
    }
    & p{
      font-size: 16px;
      margin-top: 500px;
      width: 242.65px;
    }
    & a{
      font-size: 18px;
      width: 193.96px;
      height: 56px;
      margin: 56px auto 0;
      position: relative;
      &:before{
        left: auto;
        right: -76px;
        top: -123px;
        width: 92.18px;
        height: 123.74px;
        transform: scale(1, -1) rotate(261deg);
      }
    }
  }

  .PlayRegisterClosedLabel{
    font-size: 28px;
    width: 100vw;
    margin-left: -20px;
    margin-top: 45px;
    text-align: center ;
    &:after, &:before{
      width: 73px;
      height: 4px;
    }
    & span{
      display: block;
    }
  }
}