.card {
  margin-bottom: 110px;
  text-decoration: none;
  color: #fff;
  width: fit-content;
  max-width: 368px;
  width: 24%;
  margin-right: 40px;
  &:nth-of-type(4n) {
    margin-right: 0;
  }
  &:hover:not(:focus) {
    .avatarHolder {
      img {
        transform: scale(1.1);
      }
      &::after {
        top: 15px;
        right: -15px;
      }
    }
  }
  &:focus {
    .avatarHolder {
      background: #000000;
      opacity: 0.47;
    }
  }
  &.disabled {
    pointer-events: none;
    .avatarHolder {
      filter: grayscale(1);
    }
  }
  .avatarHolder {
    position: relative;
    width: 100%;
    height: min-content;
    margin-bottom: 37px;
    div {
      width: 100%;
      overflow: hidden;
      max-width: 368px;
      max-height: 297px;
    }
    img {
      max-width: 368px;
      max-height: 297px;
      transition: all 0.15s ease-out 0.35s;
    }
    &::after {
      content: "";
      position: absolute;
      transition: all 0.15s ease-out 0.35s;
      top: 1px;
      right: 1px;
      width: 99%;
      height: 98%;
      border: 1px solid #fff;
    }
    .avatar {
      position: relative;
      width: 100%;
      object-fit: contain;
      z-index: 5;
    }
  }

  .name {
    font-weight: 700;
    font-size: 35px;
    line-height: 145%;
  }
  .position {
    font-weight: 500;
    font-size: 29px;
    line-height: 44px;
    letter-spacing: 0.02em;
    margin-bottom: 28px;
  }
  .description {
    font-weight: 400;
    font-size: 24px;
    line-height: 145%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 10;
    line-clamp: 10;
    -webkit-box-orient: vertical;
  }
}

@media (max-width: 1902px) {
  .card {
    width: 23%;
  }
}
@media (max-width: 1700px) {
  .card {
    width: 22.2%;
    .avatarHolder {
      &::after {
        top: 2px;
        right: 2px;
        width: 97%;
        height: 94%;
      }
    }
  }
}

@media (max-width: 1024px) {
  .card {
    width: 47%;
    margin-right: auto;
    max-width: 318px;
    margin-bottom: 88px;
    &:nth-of-type(2n) {
      margin-right: 0;
    }
    &:hover:not(:focus) {
      .avatarHolder {
        img {
          transform: scale(1);
        }
      }
    }
    .avatarHolder {
      &::after {
        display: none;
      }
    }
    .name {
      font-size: 28px;
    }
    .position {
      font-size: 18px;
      line-height: 27px;
      margin-bottom: 25px;
    }
    .description {
      font-size: 18px;
    }
  }
}

@media (max-width: 760px) {
  .card {
    width: 100%;
    margin-right: 0;
    max-width: unset;
    margin-bottom: 86px;
    .name {
      font-size: 24px;
    }
    .avatarHolder {
      margin-bottom: 25px;
    }
    .position {
      font-size: 16px;
      line-height: 145%;
      margin-bottom: 22px;
    }
    .description {
      font-size: 16px;
    }
  }
}


@media (min-width: 3800px) {
  .card {
    width: 23%;
    max-width: unset;
    &:hover:not(:focus) {
      .avatarHolder {
        &::after {
          width: 100%;
          height: 100%;
          top: 15px;
          right: -15px;
        }
      }
    }
    .avatarHolder {
      &::after {
        top: 7px;
        right: 7px;
        width: 98%;
        height: 95%;
      }
      & > div{
        max-width: 811.891px;
        max-height: 655.672px; 
        img {
          max-width: 811.891px;
          max-height: 655.672px; 
        }
      }
    }
    .name {
      font-size: 2rem;
    }
    .position {
      font-size: 1.2rem;
      line-height: 145%;
      margin-bottom: 22px;
    }
    .description {
      font-size: 0.9rem;
    }
  }
}