.homePrepare{
  margin-top: -25vw;
  background: linear-gradient(180deg, #2D2D2D 0%, rgba(45, 45, 45, 0) 100%);
  min-height: 60.506vw;
  text-align: center;
  padding-top: 5.052vw;
  position: relative;
  &__wrapper{
    background: #000000;
  }
  &:after{
    content: "";
    position: absolute;
    right: 10vw;
    top: 27vw;
    width: 22.813vw;
    height: 12.396vw;
    background-image: url("../../../../Image/HomePrepareSold.svg");
    background-size: 100% 100%;
  }
  &:before{
    content: "";
    position: absolute;
    left: 24vw;
    top: 22.125vw;
    background: url("../../../../Image/EnterBattleArrow.svg") no-repeat;
    background-size: 100% 100%;
    transform: rotate(58deg);
    height: 9.063vw;
    width: 6.719vw;
  }
  & h3{
    position: relative;
    font-size: 6.250vw;
    &:before, &:after{
      content: "";
      width: 15.391vw;
      height: 4.247vw;
      background-image: url("../../../../Image/HomePrepareArrows.svg");
      background-size: 100% 100%;
      top: 1.3vw;
      position: absolute;
      left: 0;
    }
    &:after{
      right: 0;
      left: auto;
      transform: rotate(-180deg);
    }
  }
  & h4{
    margin-top: 3.125vw;
    font-size: 3.333vw;
  }
  & a{
    text-decoration: none;
    background-image: url("../../../../Image/HomePrepareBtn.svg");
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3.323vw auto 0;
    font-size: 1.719vw;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #5B0D00;
    width: 21.199vw;
    height: 6.306vw;
    position: relative;
    &:after{
      content: "";
      position: absolute;
      width: 9.852vw;
      height: 10.2vw;
      background-image: url("../../../../Image/HomePrepareLand.png");
      background-size: 100% 100%;
      left: -5vw;
      top: -1.5vw;
    }
    & span{
      margin-left: 1.5vw;
    }
    &:hover{
      background-image: url("../../../../Image/HomePrepareBtnHover.svg");
    }
    &:active{
      background-image: url("../../../../Image/HomePrepareBtnActive.svg");
    }
  }
}
@media (max-width: 1024px) {
  .homePrepare{
    margin-top: 0;
    margin-bottom: 100px;
    min-height: 453.42px;
    &:after{
      content: "";
      position: absolute;
      right: 10vw;
      top: 350px;
      width: 292.65px;
      height: 157.61px;
      background-image: url("../../../../Image/HomePrepareSold.svg");
      background-size: 100% 100%;
    }
    &:before{
      left: 77px;
      top: 224px;
      transform: rotate(58deg);
      width: 81.08px;
      height: 108.84px;
    }
    & h3{
      position: relative;
      font-size: 67px;
      padding-top: 20px;
      &:before, &:after{
        content: "";
        width: 237px;
        height: 49.83px;
        background-image: url("../../../../Image/HomePrepareArrows.svg");
        background-size: 100% 100%;
        top: -64px;
        position: absolute;
        left: 0;
      }
      &:after{
        display: none;
      }
    }
    & h4{
      margin: 41px auto 0;
      font-size: 28px;
      width: 588.38px;
    }
    & a{
      width: 272.53px;
      height: 72.77px;
      font-size: 18px;
      &:after{
        width: 131.19px;
        height: 133.71px;
        left: -59px;
        top: -24px;
      }
    }
  }
}
@media (max-width: 576px) {
  .homePrepare{
    &:after{
      right: 0;
      top: 400px;
      width: 244.2px;
      height: 118.33px;
    }
    &:before{
      display: none;
    }
    & h3{
      position: relative;
      font-size: 49px;
      width: 293px;
      margin: 0 auto;
      padding-top: 40px !important;
      &:before, &:after{
        width: 257px;
        height: 40.83px;
        top: -44px;
        position: absolute;
        left: -60px;
      }
    }
    & h4{
      margin: 41px auto 0;
      font-size: 25px;
      text-align: center;
      width: 286.64px;
    }
    & a{
      margin-left: 80px;
      margin-top: 40px;
    }
  }
}