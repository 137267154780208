.button{
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Poppins, sans-serif;
  font-weight: 500 ;
  font-size: 1.250vw;
  line-height: 36px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #5B0D00;
  background: #FFC328;
  clip-path: polygon(95% 0, 100% 30%, 100% 100%, 5% 100%, 0 75%, 0 0);
  height: 3.906vw;
  width: 23.021vw;
  margin: 0;
  &:hover{
    background: #FFD747;
    color: #5B0D00;
  }
  &:active{
    background: #F8A300;
    color: #5B0D00;
  }
}
.buttonContainer{
  width: 100vw;
  left: -7.813vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  &:after, &:before{
    content: "";
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    height: 16px;
    background: #FFC328;
    width: 34vw;
  }
  &:after{
    left: 0;
  }
  &:before{
    right: 0;
  }
}
@media (max-width: 1024px) {
  .button{
    height: 60px;
    width: 330px;
    font-size: 18px;
  }
  .buttonContainer{
    left: -35px;
    &:after, &:before{
      width: 22vw;
    }
  }
}
@media (max-width: 576px) {
  .button{
    font-weight: 500;

    height: 56px;
    width: 100%;
    font-size: 18px;
  }
  .buttonContainer{
    width: 100%;
    left: 0;
    &:after, &:before{
      display: none;
    }
  }
}