.homeRealm{
  padding: 0 7.813vw;
  margin-bottom: 50vw;
  margin-top: 14.375vw;
  & a{
    width: 280px;
    margin-left: 22vw;
    & span{
      font-weight: 500;
    }
  }
  display: flex;
  & img{
    width: 47.019vw;
    height: 38.625vw;
  }
}
.textBlock{
  & h3{
    width: 40.549vw;
    font-size: 6.250vw;
  }
  & p{
    margin-left: 4vw;
    width: 36.635vw;
    margin-bottom: 2vw;
    font-size: 1.250vw;
  }
}
@media (min-width: 3000px) {
  .homeRealm {
    & a {
      width: 13.533vw;
      height: 3.906vw;
      & span{
        font-size: 1.3vw !important;
      }
    }
  }
}
@media (max-width: 1024px) {
  .homeRealm {
    margin-bottom: 20vw;
    padding: 0 50px;
    & a{
      margin-left: 70px;
      & span{
        font-size: 18px !important;
      }
    }
    flex-direction: column;
    & img{
      width: 100%;
      height: auto;
      text-align: center;
      position: relative;
    }
  }
  .textBlock{
    & h3{
      width: 426px;
      font-size: 70px;
      text-align: left;
      margin-bottom: 31px;
    }
    & p{
      width: 352.59px;
      margin-bottom: 2vw;
      font-size: 18px;
      margin-left: 70px;
      &:nth-child(2){
        width: 452.05px;
        margin-left: 50px;
      }
    }
  }
}
@media (max-width: 576px) {
  .homeRealm {
    padding: 0 20px;
    & a{
      margin-left: 40px;
      margin-top: 20px;
    }
    & img{
      position: relative;
      right: -30px;
      width: 460px;
      height: 349.95px;
      text-align: center;
    }
  }
  .textBlock{
    & h3{
      width: 331px;
      font-size: 50px;
      text-align: left;
      margin-bottom: 31px;
    }
    & p{
      width: 264.5px;
      margin-bottom: 2vw;
      font-size: 16px;
      margin-left: 40px;
      &:nth-child(2){
        width: 264.5px;
        margin-left: 40px;
      }
    }
  }
}
