.background{
  background-image: url("../../Image/cardsPageBg.svg");
  padding: 100px 0;
  & h2{
    font-size: 4.271vw;
    text-align: center;
    margin-top: 4.688vw;
  }

  &:after{
    content: "";
    position: absolute;
    background-image: url("../../Image/contactBot1.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 43.531vw;
    width: 33.531vw;
    top: 6vw;
    left: 0;
  }
  @media (min-width: 1024px) {
    &:after {

      clip-path: polygon(0 0, 100% 0, 100% 85%, 0 85%);
    }
  }
  &:before{
    content: "";
    position: absolute;
    background-image: url("../../Image/contactBot2.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 39.531vw;
    width: 21.531vw;
    top: 10vw;
    right: 0;
  }
}
.contactItems{
  padding: 0 15.813vw;
  display: flex;
  justify-content: space-between;
  gap: 35px;
  margin-top: 3.698vw;
  & a{
    z-index: 2;
    width: 100%;
    height: 14.115vw;
    background: rgba(32, 32, 32, .9);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2.240vw;
    flex-direction: column;
    text-decoration: none;
    color: #FFFFFF;
    &:hover{
      background: #2A2A2A;
      & img{
        filter: brightness(1.2);
      }
    }
    &:active{
      background: #181818;
      & img{
        filter: brightness(.7);
      }
    }
    & span{
      font-family: 'Cold Warm', sans-serif;
      display: block;
      font-size: 2.188vw;
      text-transform: uppercase;
      letter-spacing: 0.02em;
      font-weight: 400;
    }
    & img{
      width: 4.792vw;
      height: 3.281vw;
      &:nth-child(1){
        width: 4.192vw;
      }
    }
    & img[data-type='support']{
      width: auto;
    }
    & img[data-type='mail']{
      width: 4vw;
      height: 2.9vw;
    }
  }
}
.social{
  z-index: 3;
  position: relative;
  padding: 1vw 7.813vw;
  border-top: .5vw solid #0B0A0A;
  border-bottom: .5vw solid #0B0A0A;
  background: linear-gradient(90deg, #0B0A0A 0%, #252525 53.12%, #0B0B0B 100%);
  margin-top: 5.469vw;
  & h4{
    font-size: 2.083vw;
  }
  & ul{
    display: flex;
    margin: 22px 0;
    justify-content: center;
    gap: 1.5vw;
    & img{
      //width: 1.510vw;
      height: 1.502vw;
      filter: grayscale(1) brightness(120%);
      &:hover{
        filter: none;
      }
      &:active{
        filter: brightness(0.7) contrast(180%);
      }
    }
  }
}
@media (max-width: 1024px) {
  .background{
    background-image: url("../../Image/cardsPageBg.svg"); //replace
    & h2{
      font-size: 59px;
      margin-top: 20px;
      margin-bottom: 60px;
    }
    &:after{
      height: 790.11px;
      width: 675.11px;
      top: 282px;
      left: -145px;
      transform: rotate(35deg);
    }
    &:before{
      height: 790.11px;
      width: 457.11px;
      top: 282px;
      right: -122px;
      transform: rotate(7deg);
    }
  }
  .contactItems{
    padding: 0 15.813vw;
    flex-direction: column;
    gap: 35px;
    margin-top: 3.698vw;
    align-items: center;
    & a{
      width: 322.73px;
      height: 205.1px;
      color: #FFFFFF;
      & span{
        font-size: 32px;
      }
      & img{
        width: 57px;
        height: 48px;
        &:nth-child(1){
          width: 57px;
        }
      }
      & img[data-type='support']{
        height: 48px;
        width: auto;
      }
      & img[data-type='mail']{
        height: 43px;
      }
    }
  }
  .social{
    border-top: 30px solid #0B0A0A;
    border-bottom: 30px solid #0B0A0A;
    position: relative;
    margin-top: 100px;
    &:after{
      content: "";
      position: absolute;
      height: 215px;
      width: 110%;
      background-image: url("../../Image/contactSocialTop.svg");
      left: -20px;
      top: -220px;
    }
    & h4{
      font-size: 43px;
      text-align: center;
    }
    & ul{
      display: flex;
      margin: 22px 0;
      justify-content: center;
      gap: 30px;
      & img{
        width: 25px;
        height: 25px;
      }
    }
  }
}
@media (max-width: 576px) {
  .background{
    background-image: url("../../Image/cardsPageBg.svg"); //replace
    & h2{
      padding: 0 40px;
      font-size: 50px;
      text-align: left;
      margin-bottom: 120px;
    }
    &:after{
      display: none;
    }
    &:before{
      background-image: url("../../Image/contactBot2Mobile.png");
      width: 169px;
      height: 425px;
      top: 213px;
      right: 0;
      transform: rotate(0deg);
    }
  }
  .contactItems{
    padding: 0 15.813vw;
    flex-direction: column;
    gap: 35px;
    margin-top: 3.698vw;
    align-items: center;
    & a{
      width: 245px;
      height: 156px;
      & span{
        font-size: 24px;
      }
      & img{
        width: 46px;
        height: 39px;
        &:nth-child(1){
          width: 46px;
        }
      }
      & img[data-type='support']{
        width: auto;
        height: 39px;
      }
      & img[data-type='mail']{
        height: 35px;
      }
    }
  }
  .social{
    border-top: 30px solid #0B0A0A;
    border-bottom: 30px solid #0B0A0A;
    position: relative;
    margin-top: 100px;
    &:after{
      display: none;
    }
    & h4{
      font-size: 27px;
    }
    & ul{
      display: flex;
      margin: 22px 0;
      justify-content: center;
      gap: 30px;
      & img{
        width: 22px;
        height: 21px;
      }

    }
  }
}