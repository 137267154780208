.backButton {
  display: block;
  text-decoration: none;
  color: #fff;
  font-weight: 700;
  font-size: 24px;
  cursor: pointer;
  width: fit-content;
  &:hover {
    color: #ffc328;
  }
  &:active {
    color: #f8a300;
  }
}
