.nftPageMain{
  padding: 0 180px;
  clear: both;
  & h2{
    text-align: center;
    color: #FFC328;
    margin-top: 94px;
  }
  & > p{
    padding-top: 30px;
    text-align: center;
    max-width: 1052px;
    margin: 0 auto;
    font-size: 24px;
    line-height: 145%;
  }
}
.rebelDetails{
  & button{
    //filter: brightness(0.5);
    //pointer-events: none;
    z-index: 3;
  }
  & h3{
    color: #FFC328;
    margin-top: 77px;
    text-align: right;
    padding-right: 150px;
    position: relative;
    &::after{
      content: "";
      position: absolute;
      height: 180px;
      width: 720px;
      right: -180px;
      top: 30px;
      background: url("../../../../Image/nftTitleLine.svg") 100% 100%;
    }
  }
  & p {
    font-size: 24px;
    margin-top: 72px;
    width: 600px;
    position: relative;
    margin-left: auto;
  }
}
@media (min-width: 3200px) {
  .nftPageMain {
    & h2 {
      font-size: 250px;
      margin-top: 134px;
    }
    & > p{
      max-width: 1452px;
      font-size: 55px;
    }
  }
  .rebelDetails{
    & h3{
      margin-top: 77px;
      &::after{
        top: 60px;
        width: 854px;
      }
    }
    & p {
      font-size: 55px;
      width: 1000px;
    }
  }
}
@media (max-width: 1224px) {
  .nftPageMain {
    & h3 {
      font-size: 80px;
      margin-bottom: 100px;
      left: 30px;
      &::after{
        right: -150px;
        top: -20px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .nftPageMain{
    padding: 0 50px;
    & h3{
      left: 0;
      font-size: 70px;
      text-align: left;
    }
    & > p{
      text-align: left;
      width: 579px;
      margin-left: 0;
      font-size: 18px;
    }
    
  }
  @media (min-width: 576px) {
    .nftPageMain h2{
      text-align: left;
    }
  }
  .rebelDetails{
    & h3{
      color: #FFC328;
      text-align: right;
      padding-right: 200px;
      position: relative;
      margin-bottom: 0;
      &::after{
        height: 129px;
        width: 518px;
        right: -50px;
        top: -30px;
        background: url("../../../../Image/nftTitleLineTablet.svg") 100% 100%;
      }
    }
 
    & p {
      margin-right: 95px;
      margin-top: 42px;
      width: 323px;
      font-size: 18px;
      position: relative;
      margin-left: auto;
    }
    & span{
      margin-top: 20px;
      display: block;
      margin-left: 80px;
      width: 305px;
    }
    & a{
      font-size: 18px;
    }
  }
}
@media (max-width: 706px) {
  .nftPageMain {
    & h3 {
      font-size: 55px;
      &:after{
        top: -50px;
      }
    }
  }
}
@media (max-width: 576px) {
  .nftPageMain{
    padding: 0 20px;
    & h2{
      margin-top: 40px;
      font-size: 55px;
      text-align: left;
    }
    & h3{
      font-size: 40px;
      text-align: left;
    }
    & > p{
      width: 293px;
      font-size: 16px;
    }
  }
  .rebelDetails{
    & h3{
      margin-top: 65px;
      text-align: center;
      padding-right: 10vw !important;
      font-size: 40px;
      margin-bottom: 0;
      &::after{
        width: 84vw;
        height: 91.42px;
        left: -21px;
        top: -21px;
        background: url("../../../../Image/nftTitleLineMobile.svg") 100% 100%;
      }
    }
    & p {
      margin-top: 30px;
      width: 198px;
      margin-left: 14.5vw;
      font-size: 16px;
    }
    & span{
      width: 232.92px;
      margin-left: 50px;
    }
  }
}
