.HomeVideo{
  background: #000000;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 10;
}
.videoWrapper{
  position: relative;
  z-index: 10;
  height: 48.323vw;
  margin-top: 8.979vw;
  width: 100vw;
  background-image: url("../../../../Image/homeVideoBg.png");
  background-size: 100% 100%;
  & iframe{
    position: relative;
    left: 9.5vw;
    width: 81.4vw;
    height: 45vw;
    top: 1.7vw;
  }
}
@media (min-width: 3000px) and (max-height: 1800px){
  .videoWrapper{
    height: 90vh;
    margin-top: 0;
    width: 100vw;
    & iframe{
      position: relative;
      left: 9.5vw;
      width: 81.4vw;
      height: 82vh;
      top: 1.7vw;
    }
  }
}