@media(min-width: 578px){
  .infoButtons__details{
    &:hover{
      background: url("../../../../Image/learnMoreButtonBorderHover.svg");
      background-size: 100% 100%;
      color: #FFC328 !important;
    }
  }
}
.infoButtons{
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
  z-index: 2;

  &__list{
    display: flex;
    gap: 25px;
    & a{
      position: relative;
      color: #FFC328;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 145%;
      text-decoration: none;
      &:hover{
        color: #FFD747;
      }
      &:active{
        color: #F8A300;
      }
      &:before{
        top: -0.5vw !important;
      }
    }
  }
  &__details{
    width: 251px;
    height: 75px;
    background: url("../../../../Image/learnMoreButtonBorder.svg") 100% 100%;
    background-size: 100% 100%;

    &:active{
      background: url("../../../../Image/learnMoreButtonBorderActive.svg") 100% 100%;
      background-size: 100% 100%;
      color: #F8A300 !important;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500 !important;
    font-size: 24px;
    line-height: 36px;
    color: #FFFFFF !important;
    letter-spacing: 0.02em;
    text-decoration: none;

  }
}

.infoButtons_land{
  align-items: flex-start;
  & a{
    margin-left: 0;
    margin-top: 0;
    z-index: 2;
  }
  & .infoButtons__list{
    justify-content: flex-start;
    display: flex;
    gap: 37px;
    & a{
      color: #000000;
      margin-left: 0;
      margin-top: 0;
      &:hover{
        color: #5B0D00 !important;
      }
      &:active{
        color: #3D3A38 !important;
      }
    }
  }
  & .infoButtons__details{
    background: url("../../../../Image/learnMoreButtonBlack.svg") 100% 100%;
    & span:active{
      filter: grayscale(60%) !important;
    }
    margin-top: 0;
    margin-left: 0;

  }

}

.infoButtons_landPage{
  align-items: center;
  flex-direction: row;
  .infoButtons__list{
    justify-content: flex-start;
    display: flex;
    gap: 37px;
    & a{
      margin-left: 0;
      margin-top: 0;
      &:hover{
        color: #FFD747;
      }
      &:active{
        color: #F8A300;
      }
    }
  }
  & .infoButtons__details{
    margin-left: 30px;
    background: url("../../../../Image/learnMoreButtonYellow.svg") 100% 100%;
    background-size: 100% 100%;
    &:hover{
        color: #5B0D00;
    }
    &:active{
      filter: brightness(0.8);
    }
    color: #5B0D00;

  }
}
.landButton.disabled{
  color: #BFBFBF;
  &:before, &:after{
    background-image: url("../../../../Image/button-type-disabled.svg");
  }
  &:active, &:hover{
    filter: none;
  }
}
.landButton:active{
  filter:  brightness(89%) contrast(80%);
}
.landButton:hover{
  filter:  brightness(110%) contrast(95%);
}

.buyBattleSet{
  margin-left: 30px;
  &:active{
    filter: brightness(0.8) contrast(160%);
  }
  & span{
    font-weight: 500 !important;
  }
}
.landNftButton:active{
  filter: invert(17%) sepia(1%) saturate(2011%) hue-rotate(341deg) brightness(120%) contrast(93%);
}
.infoButtons__claim{
  width: 475.76px;
  background: url("../../../../Image/outline_btn_lg.svg") 100% 100%;
  background-size: 100% 100%;
  &:hover{
    background: url("../../../../Image/outline_btn_lg_hover.svg") ;
  }
  &.disabled,  &:active{
    background: url("../../../../Image/outline_btn_lg_disabled.svg") !important;

    background-size: 100% 100% !important;
    background-repeat: no-repeat;
    & a{
      color:  #7E7E7D;
    }
  }
}

.disabledLink{
  color: #A67F1A !important;
  pointer-events: none;
}
@media (max-width: 968px) {
  .infoButtons_landPage{
    justify-content: center;
    margin-top: 0 !important;
    & .infoButtons__details {
      margin-left: 30px;
      background: url("../../../../Image/learnMoreButtonYellow.svg") 100% 100%;
      background-size: 100% 100%;
    }
  }

}

@media (min-width: 2240px) {
  .infoButtons{
    & a{
      font-size: 35px;
    }
  }
  .buyBattleSet{
    width: 350px;
    height: 100px;
    & span{
      font-size: 35px !important;
    }
  }
}
@media (min-width: 3200px) {
  .infoButtons{
    & a{

      font-size: 55px;
    }
    &__list {
      display: flex;
      gap: 55px;
      margin-bottom: 20px;
    }
  }
  .infoButtons {
    &__details {
      width: 540px;
      height: 150px;
    }
  }
  .landButton{
    margin-top: 20px !important;
  }
  .buyBattleSet{
    width: 450px;
    height: 130px;
    & span{
      text-align: center;
      font-size: 55px !important;
      margin-left: 10px;
    }
  }
  .infoButtons_land {
    & .infoButtons__list {
      margin-bottom: 20px;
    }
  }
}
@media (max-width: 1024px) {
  .infoButtons_bot, .infoButtons_land{
    .infoButtons__list{
      flex-direction: column;
      gap: 6px;
    }
  }
}
@media (max-width: 878px) {
  .infoButtons{
    margin-top: 30px;
    gap: 20px;
    &__list{
      gap: 30px;
      & a{
        font-size: 18px;
        line-height: 145%;
      }
    }
    &__details, .buyBattleSet{
      width: 184px;
      height: 56px;

      background-size: 100% 100%;
      & a{
        font-size: 18px;
        line-height: 100%;
      }
    }
    &__botNft{
      width: 211px;
    }

  }
  .buyBattleSet{
    & span{
      font-size: 19px !important;
    }
  }

  .infoButtons_land{
    .infoButtons__details, buyBattleSet{
      margin-top: 100px;
      z-index: 2;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
  .infoButtons__claim{
    font-size: 18px;
    width: 418px;
  }
}
@media (max-width: 578px) {
  .infoButtons{
    align-items: flex-start;

    &__details{
      font-size: 18px ;
      width: 176px;
      background-size: 100% 100%;
      &:active{
        background: url("../../../../Image/learnMoreButtonBorderMobileActive.svg");
        background-size: 100% 100%;
        & a{
          color: #F8A300;
        }
      }
    }
    &__list{
      width: 176px;
      align-items: flex-start;
      flex-direction: column;
      gap: 6px;
      & a{
        font-size: 16px;
      }
    }
    &__botNft, .buyBattleSet{
      width: 197px;
    }
  }
  .infoButtons_land {
    align-items: flex-start;
    & > a {
      z-index: 2;
      margin-top: 100px;
    }
  }
  .infoButtons__claim{
    font-size: 18px;
    width: 318px;
  }
  .infoButtons_landPage{
    align-items: flex-start;
    flex-direction: column;
    .infoButtons__list{
      justify-content: flex-start;
      display: flex;
      gap: 17px;
      & a{
        margin-left: 0;
        margin-top: 0;
      }
      & a:nth-child(1){
        margin-left: 0;
      }
      & a:nth-child(2){
        margin-left: 5vw;
      }
      & a:nth-child(3){
        margin-left: 10vw;
      }
    }
    & .infoButtons__details, .buyBattleSet{
      margin-left: 15vw;
      background: url("../../../../Image/learnMoreButtonYellow.svg") 100% 100%;
      background-size: 100% 100%;
    }
  }
}