.background {
  background-image: url("../../Image/RoadmapPattern.svg");
  padding: 0 0;
  & h2{
    margin-top: -50px;
  }
  & iframe{
    & article{
      border: 1px solid red;
    }
  }
}
