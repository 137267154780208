.background {
  background-image: url("../../Image/xoilRblsDesc.svg");
  padding: 100px 7.813vw;
  & p{
    font-size: 1.250vw;
  }
}
.title{
  padding-top: 4.271vw;
  font-size: 7.813vw;
}
.subtitle{
  text-align: left;
  font-size: 2.917vw;
}
.descriptionWrapper{
  display: flex;
  justify-content: space-around;
  & img{
    margin-top: -5vw;
    width: 46.302vw;
    height: 31.406vw;
  }
  & h3{
    display: none;
    z-index: 2;
  }
  & span{
    font-family: 'Cold Warm', sans-serif;
    line-height: 120%;
    font-weight: 400;
    font-size: 1.979vw;
  }
  & p{
    margin-top: 3vw;
    width: 40.510vw;
    margin-bottom: 2.344vw;
  }
}

.buy{
  margin-top: -6vw;
  &__buttonsWrapper{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
    &:after{
      content: "";
      position: absolute;
      width: 35vw;
      height: 1.614vw;
      left: 32vw;
      background: url("../../Image/xoilRblsLines.svg") no-repeat;
    }
  }
  &__buttons{
    & a{
      display: inline-block;
      background-size: 100% 100%;
      width: 10.248vw;
      height: 3.906vw;
      text-decoration: none;
      text-align: center;
      line-height: 3.906vw;
      color: #5B0D00;
      font-weight: 500;
      &:hover{
        filter: brightness(110%) contrast(95%);
      }
      &:active{
        filter: brightness(0.8) contrast(160%);
      }
      &:nth-child(1){
        background-image: url("../../Image/xoilRblsButton1.svg");
        clip-path: polygon(0 0, 90% 0, 90% 23%, 100% 100%, 110% 108%, 0 101%);
        & span{
          position: relative;
          left: -0.321vw;
        }
      }
      &:nth-child(2){
        margin-left: -1.3vw;
        background-image: url("../../Image/xoilRblsButton2.svg");
        clip-path: polygon(0 0, 82% 0, 90% 23%, 100% 100%, 110% 108%, 0 101%);
      }
      &:nth-child(3){
        margin-left: -1.3vw;
        background-image: url("../../Image/xoilRblsButton3.svg");
        & span{
          position: relative;
          left: 0.321vw;
        }
      }
    }
  }
  &__urls{
      z-index: 2;
      font-size: 1.250vw;
      & a{
        margin-left: 1.302vw;
        text-decoration: none;
        color: #FFC328;
        font-weight: 700;
        &:hover{
          color: #FFD747;
        }
        &:active{
          color: #F8A300;
        }
      }
      &_mobile{
        display: none;
      }
  }
}
.content{
  margin-top: 7.344vw;

  & h4{
    letter-spacing: 0.02em;
    font-size: 3.906vw;
    text-align: left;
  }
  &__item{
    margin-bottom: 4.427vw;
    & p{
      max-width: 62.656vw;
      @media (max-width: 1300px) {
        max-width: none;
      }
    }
    & div{
      margin-top: 2.344vw;
    }
    & ol{
      padding-left: 2vw;
      list-style: disc;
    }
  }
}
.infoButton{
  width: 15.521vw;
  height: 3.906vw;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  background: url("../../Image/learnMoreButtonBorder.svg") no-repeat;
  font-size: 1.250vw;
  margin-right: 4.167vw;
  &__disabled{
    background: url("../../Image/learnMoreButtonBorderDisable.svg") no-repeat;
    pointer-events: none;
    position: relative;
    color: #7E7E7D !important;

    &:before{
      background: url("../../Image/comingSOON.svg") no-repeat;
      right: -40px;
      top: -5%;
    }
  }
  &:hover{
    background: url("../../Image/learnMoreButtonBorderHover.svg") no-repeat;
    background-size: 100% 100%;
    color: #FFC328;
  }
  &:active{
    background: url("../../Image/learnMoreButtonBorderActive.svg") no-repeat;
    background-size: 100% 100%;
    color: #F8A300;
  }
}
.descriptionWrapper_rbls{

}
@media (max-width: 1024px) {
  .background {
    background-image: url("../../Image/xoilRbls.svg");
    padding: 100px 35px;
    & p{
      font-size: 18px;
    }
  }
  .title{
    padding-top: 4.271vw;
    font-size: 100px;
  }
  .subtitle{
    text-align: left;
    font-size: 46px;
    max-width:469.71px;
  }
  .descriptionWrapper{
    flex-direction: column-reverse;
    & img{
      width: 130%;
      height: auto;
      position: relative;
      left: -15%;
    }
    & span{
      font-size: 29px;
    }
    & p{
      margin-top: 3vw;
      width: 100%;
      margin-bottom: 2.344vw;
    }
    &_rbls{
      & img{
        margin-top: -12vw;
        left: -17%;
        margin-bottom: -10vw;
      }

    }
  }
  .buy{
    margin-top: -10vw;
    & h3{
      text-align: left;
      font-size: 63px;
    }
    &__buttonsWrapper{
      &:after{
        display: none;
      }
    }
    &__buttons{
      justify-content: flex-start;
      margin-top: 30px;
      & a{
        height: 7.813vw;
        width: 26.563vw;
        line-height: 7.813vw;
        &:nth-child(1){
          margin-left: 0;
        }
        &:nth-child(2){
          margin-left: -25px;

        }
        &:nth-child(3){
          margin-left: -25px;
        }
      }
    }
    &__urls{
      display: none;
      &_mobile{
        display: block;
        font-size: 18px;
        & a{
          margin-left: 0;
          margin-right: 56px;
        }
      }
    }
  }
  .content{
    & h4{
      font-size: 43px;
      margin-bottom: 30px;
    }
    &__item{
      margin-bottom: 50px;
      & ol{
        padding-left: 25px;
      }
    }
    & p span{
      margin-top: 25px;
      display: block;
    }
  }
  .infoButton{
    height: 61px;
    width: 211px;
    font-size: 18px;

  }
}
@media (max-width: 576px) {
  .background {
    background-image: url("../../Image/xoilRblsMob.svg");
    padding: 100px 20px;
    & p{
      font-size: 16px;
    }
  }
  .title{
    padding-top: 2.271vw;
    font-size: 50px;
  }
  .subtitle{
    margin-top: 15px;
    text-align: left;
    font-size: 35px;
    max-width: 306px;
  }
  .descriptionWrapper{

    margin-top: 50px;
    & h3{
      margin-top: 63px;
      display: block;
      margin-left: 0;
      text-align: left;
      font-size: 63px;
    }
    & img{
      margin-top: -40px;
      width: 100vw;
      position: relative;
      left: -20px;
      height: auto;
    }
    & span{
      font-size: 22px;
    }
    & p{
      max-width:293.32px;
      margin-top: 3vw;
      //width: 100%;
      margin-bottom: 2.344vw;
    }
  }
  .buy{
    margin-top: -100px;
    & h3{
      display: none;
      text-align: left;
      font-size: 63px;
    }
    &__buttonsWrapper{
      &:after{
        display: none;
      }
    }
    &__buttons{
      justify-content: space-between;
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      width: 100%;
      & a{
        height: 56px;
        width: 152px;
        display: block;
        font-size: 18px;
        margin-top: 25px;
        line-height: 56px;
        clip-path: none;
        &:nth-child(1){
          background-image: url("../../Image/yellowButton.svg");

          clip-path: none;
          & span{
            left: 0;
          }
        }
        &:nth-child(2){
          align-self: center;
          background-image: url("../../Image/yellowButton.svg");

          margin-left: 0;
          clip-path: none;
        }
        &:nth-child(3){
          background-image: url("../../Image/yellowButton.svg");
          align-self: flex-end;
          margin-left: 0;
          & span{
            left: 0;
          }
        }
      }
    }
    &__urls{
      font-size: 16px;
      & a{
        display: block;
        margin-top: 5px;
      }
    }
  }
  .content{
    margin-top: 80px;

    & h4{
      font-size: 35px;
    }
  }
  .infoButton{
    margin-bottom: 26px;
    &__second{
      float: right;
      margin-bottom: 140px;
    }
  }
}