/* Additional Breakpoints */
/* Fonts */
/* Colors */
/* Animations */
/* Grid */
/* Global styles */
@font-face {
  font-family: "Poppins";
  src: local("Poppins Regular"), local("Poppins-Regular"),
    url("../Fonts/Poppins-Regular.woff2") format("woff2"),
    url("../Fonts/Poppins-Regular.woff") format("woff"),
    url("../Fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Cold Warm Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Cold Warm Regular"), url("../Fonts/ColdWarm.woff") format("woff");
}

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

button {
  background-color: transparent;
  border: none;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote::after,
blockquote::before {
  content: "";
  content: none;
}

q::after,
q::before {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

svg {
  display: block;
  overflow: visible;
  position: relative;
}

body,
html {
  background-color: #000;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  font-size: 1.25vw;
  font-family: "Poppins", "Open Sans", sans-serif;
  font-weight: normal;
  line-height: 1.45;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  -ms-overflow-style: none;
  scrollbar-width: none; 
  -webkit-text-size-adjust: 100%;
}

@media (max-width: 1024px) {
  body,
  html {
    font-size: 18px;
  }
}

@media (max-width: 767.98px) {
  body,
  html {
    font-size: 16px;
  }
}

html::-webkit-scrollbar,
body::-webkit-scrollbar {
  display: none;
}

.wrapper.no-links .header .header-popup * ,.wrapper.no-links .header .header-hamburger{
  opacity: 0;
  pointer-events: none;
  cursor: default !important;
}

@media (max-width: 1024px) {
  .wrapper {
    overflow: hidden;
    position: relative;
  }
}

@media (max-width: 756px) and (orientation: landscape) {
  .wrapper {
   width: 100vw;
  }
}

#fullpage {
  z-index: 1;
}

.section {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

main {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  min-height: 100vh;
  padding-top: 5.8333333333vw;
  position: relative;
}

.gateIo {
  background-color: #de5959;
  width: 350px;
  height: 82px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gateIo svg {
  width: 165px;
  height: 50px;
}

.gateIo:hover {
  background-color: #f37f7f;
}

.gateIo:active {
  background-color: #ac4747;
}

.gateIo:disabled {
  background-color: #7e7e7d;
}

@media (max-width: 1024px) {
  main {
    padding-top: 84px;
    min-height: 0;
  }
}

@media (max-width: 767.98px) {
  main {
    padding-top: 70px;
  }
}

@media (pointer: coarse) and (hover: none) {
  main {
    min-height: 0;
  }
}

.container {
  padding: 0 8.3333333333vw;
}

@media (max-width: 1024px) {
  .container {
    padding: 0 48px;
  }
}

@media (max-width: 767.98px) {
  .container {
    padding: 0 20px;
  }
}

.container-center {
  padding: 0 11.5625vw;
}

@media (max-width: 1024px) {
  .container-center {
    padding: 0 48px;
  }
}

@media (max-width: 767.98px) {
  .container-center {
    padding: 0 20px;
  }
}

.device-landscape {
  display: none;
}

@media (max-width: 959px) and (orientation: landscape) {
  .device-landscape {
    background-color: #ffc328;
    background-image: url("../Image/landscape-bg.svg");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-family: "Cold Warm Regular", "Arial Black", sans-serif;
    font-size: 40px;
    line-height: 120%;
    letter-spacing: 0.02em;
    text-align: center;
    text-transform: uppercase;
    color: #000;
    z-index: 200;
  }

  .device-landscape p {
    max-width: 420px;
  }
}

.tablet-landscape,
.tablet-portrait,
.phone {
  display: none !important;
}

@media (max-width: 1024px) {
  .desktop-image,
  .tablet-portrait,
  .phone {
    display: none !important;
  }

  .tablet-landscape {
    display: block !important;
  }
}

@media (max-width: 767.98px) {
  .desktop-image,
  .tablet-landscape,
  .tablet-portrait {
    display: none !important;
  }

  .phone {
    display: block !important;
  }
}

@media (pointer: coarse) and (hover: none) {
  .desktop-image,
  .tablet-portrait,
  .phone {
    display: none !important;
  }

  .tablet-landscape {
    display: block !important;
  }
}

@media (pointer: coarse) and (hover: none) and (max-width: 1024px) and (orientation: portrait) {
  .desktop-image,
  .tablet-landscape,
  .phone {
    display: none !important;
  }

  .tablet-portrait {
    display: block !important;
  }
}

@media (pointer: coarse) and (hover: none) and (max-width: 767.98px) {
  .desktop-image,
  .tablet-landscape,
  .tablet-portrait {
    display: none !important;
  }

  .phone {
    display: block !important;
  }
}

h1 {
  font-family: Cold Warm, sans-serif;
  font-size: 6.25vw;
  font-weight: normal;
  line-height: 120%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

@media (max-width: 1024px) {
  h1 {
    font-size: 90px;
  }
}

@media (max-width: 767.98px) {
  h1 {
    font-size: 50px;
  }
}

h2 {
  font-family: Cold Warm, sans-serif;
  font-size: 7.8125vw;
  font-weight: normal;
  line-height: 115%;
  text-transform: uppercase;
}

h2 b {
  color: #70ddff;
  font-weight: normal;
}

@media (max-width: 1024px) {
  h2 {
    font-size: 90px;
  }
}

@media (max-width: 767.98px) {
  h2 {
    font-size: 50px;
  }
}

h3 {
  font-family: Cold Warm, sans-serif;
  font-size: 6.25vw;
  font-weight: normal;
  line-height: 120%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

h3 b {
  color: #ffc328;
  font-weight: normal;
}

@media (max-width: 1024px) {
  h3 {
    font-size: 70px;
    line-height: 1;
  }
}

@media (max-width: 767.98px) {
  h3 {
    font-size: 40px;
  }
}

h4 {
  font-family: Cold Warm, sans-serif;
  font-weight: normal;
  font-size: 2.6041666667vw;
  line-height: 120%;
  letter-spacing: 0.02em;
  margin-bottom: 2.0833333333vw;
  text-align: center;
}

@media (max-width: 1024px) {
  h4 {
    font-size: 35px;
    margin-bottom: 32px;
  }
}

@media (max-width: 767.98px) {
  h4 {
    font-size: 40px;
    margin-bottom: 46px;
    text-align: left;
  }
}

/* Blocks Includes */
.header {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  overflow-x: hidden;
  height: 100%;
}
@media (max-width: 1024px) {
  .header-popup {
    pointer-events: auto;
    overflow:scroll;
    -webkit-overflow-scrolling: touch
  }
}
.header::after {
  background-image: url("../Image/header-glowing.webp");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  content: "";
  display: block;
  height: 3.4895833333vw;
  position: absolute;
  left: 0;
  top: 3.47vw;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: -webkit-transform 0.25s ease-in;
  transition: -webkit-transform 0.25s ease-in;
  -o-transition: transform 0.25s ease-in;
  transition: transform 0.25s ease-in;
  transition: transform 0.25s ease-in, -webkit-transform 0.25s ease-in;
  width: 100%;
}

.header-bg {
  background: rgba(19, 19, 21, 0.85);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  -webkit-clip-path: polygon(
    65.9375% 92.3973214%,
    67.0484375% 84.7953571%,
    80.3473958% 84.7953571%,
    81.4234375% 97.0758929%,
    86.3192708% 97.0758929%,
    86.875% 91.8125%,
    100% 91.8125%,
    100% 0%,
    0.0000064% -5.0394107%,
    0.0000061% 100%,
    14.9305729% 100%,
    16.1110938% 87.7192857%,
    22.9860938% 87.7192857%,
    23.5069271% 91.2276786%,
    28.75% 91.2276786%,
    29.7916667% 85.9649107%,
    36.6319271% 85.9649107%,
    37.3610938% 81.8713393%,
    55.5901042% 81.8713393%,
    56.0765625% 92.3973214%,
    65.9375% 92.3973214%
  );
  clip-path: polygon(
    65.9375% 92.3973214%,
    67.0484375% 84.7953571%,
    80.3473958% 84.7953571%,
    81.4234375% 97.0758929%,
    86.3192708% 97.0758929%,
    86.875% 91.8125%,
    100% 91.8125%,
    100% 0%,
    0.0000064% -5.0394107%,
    0.0000061% 100%,
    14.9305729% 100%,
    16.1110938% 87.7192857%,
    22.9860938% 87.7192857%,
    23.5069271% 91.2276786%,
    28.75% 91.2276786%,
    29.7916667% 85.9649107%,
    36.6319271% 85.9649107%,
    37.3610938% 81.8713393%,
    55.5901042% 81.8713393%,
    56.0765625% 92.3973214%,
    65.9375% 92.3973214%
  );
  height: 5.8333333333vw;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: -webkit-transform 0.25s ease-in;
  transition: -webkit-transform 0.25s ease-in;
  -o-transition: transform 0.25s ease-in;
  transition: transform 0.25s ease-in;
  transition: transform 0.25s ease-in, -webkit-transform 0.25s ease-in;
}

.header-bg-image {
  background-image: url("../Image/header-bg.svg");
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: 100% auto;
  height: 5.8333333333vw;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.header-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: -0.2777777778vw;
  padding: 0 1.4583333333vw 0 2.0833333333vw;
  position: relative;
}

.header-logo {
  height: 4.34375vw;
  pointer-events: auto;
  width: auto;
  z-index: 1;
}

.header-logo img {
  display: block;
  height: 100%;
  width: auto;
}

.header-popup {
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 100%;
  -ms-flex: 0 1 100%;
  flex: 0 1 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.header-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 100%;
  -ms-flex: 0 1 100%;
  flex: 0 1 100%;
  height: 140px;
}

.header-menu__item {
  color: #fff;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  font-family: Cold Warm, sans-serif;
  font-size: 28px;
  line-height: 2;
  letter-spacing: 0.02em;
  margin-top: 1px;
  padding: 20.75px 28px;
  pointer-events: auto;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  -webkit-transition: color 0.25s ease-in-out;
  -o-transition: color 0.25s ease-in-out;
  transition: color 0.25s ease-in-out;
}

.header-menu__item svg {
  margin-left: 7px;
  width: 24px;
  -webkit-transform-origin: 50% 40%;
  -ms-transform-origin: 50% 40%;
  transform-origin: 50% 40%;
  -webkit-transform: scaleY(1);
  -ms-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transition: -webkit-transform 0.25s ease-in-out;
  transition: -webkit-transform 0.25s ease-in-out;
  -o-transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
}

.header-menu__item span {
  font-size: 24px;
}

/*@media (min-width: 1025px) {*/
/*  .header-menu__item span {*/
/*    font-size: 100%;*/
/*  }*/
/*}*/
@media (min-width: 1025px) and (max-width: 1400px) {
  .header-menu__item span {
    font-size: 20px;
  }
  .header-menu_wrapper{
    justify-content: space-around !important;
    padding: 0 20px;
  }
  .header-menu__item{
    padding: 20.75px 0;
  }
}


.header-menu__item svg path {
  fill: #fff;
  -webkit-transition: fill 0.25s ease-in-out;
  -o-transition: fill 0.25s ease-in-out;
  transition: fill 0.25s ease-in-out;
}

.header-menu__item.selected {
  color: #ffc328;
  pointer-events: none;
}

.header-menu__item.selected span {
  position: relative;
}

.header-menu__item.selected span::after {
  content: "";
  position: absolute;
  bottom: 0.33vw;
  left: 12%;
  right: 12%;
  height: 1px;
  background: #ffc328;
}

.header-menu__item-submenu {
  background: #fff;
  -webkit-clip-path: polygon(
    0% 0.78125vw,
    -webkit-calc(50% - 0.78125vw) 0.78125vw,
    50% 0%,
    -webkit-calc(50% + 0.78125vw) 0.78125vw,
    100% 0.78125vw,
    100% 100%,
    0% 100%,
    0% 0%
  );
  clip-path: polygon(
    0% 0.78125vw,
    calc(50% - 0.78125vw) 0.78125vw,
    50% 0%,
    calc(50% + 0.78125vw) 0.78125vw,
    100% 0.78125vw,
    100% 100%,
    0% 100%,
    0% 0%
  );
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 3.90625vw 2.3958333333vw 3.0208333333vw;
  position: absolute;
  left: 50%;
  margin-left: -7.5vw;
  top: 70%;
  width: 15vw;
  z-index: 110;
  opacity: 0;
  pointer-events: none;
  -webkit-transform: translate3d(0, 4vw, 0);
  transform: translate3d(0, 4vw, 0);
  -webkit-transition: opacity 0.25s ease-in, -webkit-transform 0.25s ease-in;
  transition: opacity 0.25s ease-in, -webkit-transform 0.25s ease-in;
  -o-transition: opacity 0.25s ease-in, transform 0.25s ease-in;
  transition: opacity 0.25s ease-in, transform 0.25s ease-in;
  transition: opacity 0.25s ease-in, transform 0.25s ease-in,
    -webkit-transform 0.25s ease-in;
}

.header-menu__item-submenu:before {
  background: -o-radial-gradient(
      10.93% 10.22%,
      53.72% 52.12%,
      rgba(112, 221, 255, 0.15) 0%,
      rgba(112, 221, 255, 0) 100%
    ),
    #131315;
  background: radial-gradient(
      53.72% 52.12% at 10.93% 10.22%,
      rgba(112, 221, 255, 0.15) 0%,
      rgba(112, 221, 255, 0) 100%
    ),
    #131315;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-clip-path: polygon(
    0% 0.78125vw,
    -webkit-calc(50% - 0.78125vw) 0.78125vw,
    50% 0%,
    -webkit-calc(50% + 0.78125vw) 0.78125vw,
    100% 0.78125vw,
    100% 100%,
    0% 100%,
    0% 0%
  );
  clip-path: polygon(
    0% 0.78125vw,
    calc(50% - 0.78125vw) 0.78125vw,
    50% 0%,
    calc(50% + 0.78125vw) 0.78125vw,
    100% 0.78125vw,
    100% 100%,
    0% 100%,
    0% 0%
  );
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
}

.header-menu__item-submenu__item {
  color: #fff;
  display: block;
  font-size: 16px;
  font-family: "Poppins", "Open Sans", sans-serif;
  line-height: 120%;
  margin-bottom: 1.25vw;
  text-decoration: none;
  text-transform: none;
  position: relative;
  -webkit-transition: color 0.25s ease-in-out;
  -o-transition: color 0.25s ease-in-out;
  transition: color 0.25s ease-in-out;
}

.header-menu__item-submenu__item::after {
  background: #ffc328;
  content: "";
  height: 1px;
  position: absolute;
  left: 0;
  bottom: -0.3125vw;
  -webkit-transition: width 0.25s ease-in-out;
  -o-transition: width 0.25s ease-in-out;
  transition: width 0.25s ease-in-out;
  width: 0;
}

.header-menu__item-submenu__item:last-child {
  margin-bottom: 0;
}

.coming-soon-item {
  color: rgba(255, 255, 255, 0.4);
  pointer-events: none;
}

.header-menu__item-submenu__item.sold-out-item {
  color: rgba(255, 255, 255, 0.4);
  pointer-events: none;
}

.coming-soon-item::before {
  background: url("../Image/comingSoonLabel.svg");
  background-position: 100% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  position: absolute;
  right: -40px;
  top: 50%;
  -webkit-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
  /*height: 1.875vw;*/
  /*width: 2.96875vw;*/
  width: 50px;
  height: 30px;
}

.header-menu__item-submenu__item.sold-out-item::before {
  background: url("../Image/soldOutLabel.svg");
  background-position: 100% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  height: 1.875vw;
  position: absolute;
  right: 25px;
  top: 80%;
  -webkit-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
  width: 2.96875vw;
}

@media (any-pointer: fine) {
  .header-menu__item-submenu__item:hover, .header-menu__item-submenu__item.selected{
    color: #ffc328;
  }

  .header-menu__item-submenu__item:hover::after, .header-menu__item-submenu__item.selected:after {
    width: 2.4255208333vw;
  }
}

@media (any-pointer: fine) {
  .header-menu__item:hover {
    color: #ffc328;
  }

  .header-menu__item:hover svg {
    -webkit-transform: scaleY(-1);
    -ms-transform: scaleY(-1);
    transform: scaleY(-1);
  }

  .header-menu__item:hover svg path {
    fill: #ffc328;
  }

  .header-menu__item:hover .header-menu__item-submenu {
    opacity: 1;
    pointer-events: auto;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition: opacity 0.25s ease-out, -webkit-transform 0.25s ease-out;
    transition: opacity 0.25s ease-out, -webkit-transform 0.25s ease-out;
    -o-transition: opacity 0.25s ease-out, transform 0.25s ease-out;
    transition: opacity 0.25s ease-out, transform 0.25s ease-out;
    transition: opacity 0.25s ease-out, transform 0.25s ease-out,
      -webkit-transform 0.25s ease-out;
  }
}

@media (pointer: coarse) and (hover: none) {
  .header-menu__item.open {
    color: #ffc328;
  }

  .header-menu__item.open svg {
    -webkit-transform: scaleY(-1);
    -ms-transform: scaleY(-1);
    transform: scaleY(-1);
  }

  .header-menu__item.open svg path {
    fill: #ffc328;
  }

  .header-menu__item.open .header-menu__item-submenu {
    opacity: 1;
    pointer-events: auto;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition: opacity 0.25s ease-out, -webkit-transform 0.25s ease-out;
    transition: opacity 0.25s ease-out, -webkit-transform 0.25s ease-out;
    -o-transition: opacity 0.25s ease-out, transform 0.25s ease-out;
    transition: opacity 0.25s ease-out, transform 0.25s ease-out;
    transition: opacity 0.25s ease-out, transform 0.25s ease-out,
      -webkit-transform 0.25s ease-out;
  }
}

.header-right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  height: 40px;
}

.header-metamask {
  color: #fff;
  font-family: Cold Warm, sans-serif;
  font-size: 28px;
  line-height: 2;
  letter-spacing: 0.02em;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-right: 4.4270833333vw;
  pointer-events: auto;
  text-decoration: none;
  text-transform: uppercase;
  -webkit-transition: color 0.25s ease-in-out;
  -o-transition: color 0.25s ease-in-out;
  transition: color 0.25s ease-in-out;
  white-space: nowrap;
}

.header-metamask span {
  margin-top: 1px;
}

.header-metamask img {
  display: block;
  margin-left: 0.6770833333vw;
  width: 1.6666666667vw;
}

@media (any-pointer: fine) {
  .header-metamask:hover {
    color: #ffc328;
  }
}

.header-socials {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.header-socials__item {
  margin-left: 1.3541666667vw;
  pointer-events: auto;
  width: unset;
}

.header-socials__item svg path {
  fill: #fff;
  -webkit-transition: fill 0.25s ease-in-out;
  -o-transition: fill 0.25s ease-in-out;
  transition: fill 0.25s ease-in-out;
}

.header-socials__item:first-child {
  margin-left: 0;
}

@media (any-pointer: fine) {
  .header-socials__item:hover svg path {
    fill: #ffc328;
  }
}

.header-hamburger {
  -webkit-clip-path: polygon(0 7px, 35px 7px, 35px 29px, 19px 29px);
  clip-path: polygon(0 7px, 35px 7px, 35px 29px, 19px 29px);
  display: none;
  height: 36px;
  position: relative;
  padding: 0;
  -webkit-transition: -webkit-clip-path 0.25s ease-in-out;
  transition: -webkit-clip-path 0.25s ease-in-out;
  -o-transition: clip-path 0.25s ease-in-out;
  transition: clip-path 0.25s ease-in-out;
  transition: clip-path 0.25s ease-in-out, -webkit-clip-path 0.25s ease-in-out;
  width: 35px;
}

.header-hamburger span {
  background: #fff;
  height: 4px;
  position: absolute;
  right: 0;
  top: 16px;
  width: 100%;
  -webkit-transition: background-color 0.25s ease-in-out;
  -o-transition: background-color 0.25s ease-in-out;
  transition: background-color 0.25s ease-in-out;
}

.header-hamburger span::before {
  background: #fff;
  content: "";
  height: 4px;
  position: absolute;
  right: 0;
  top: -9px;
  width: 100%;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transform-origin: 7px 6px;
  -ms-transform-origin: 7px 6px;
  transform-origin: 7px 6px;
  -webkit-transition: background-color 0.25s ease-in-out,
    -webkit-transform 0.25s ease-in;
  transition: background-color 0.25s ease-in-out,
    -webkit-transform 0.25s ease-in;
  -o-transition: background-color 0.25s ease-in-out, transform 0.25s ease-in;
  transition: background-color 0.25s ease-in-out, transform 0.25s ease-in;
  transition: background-color 0.25s ease-in-out, transform 0.25s ease-in,
    -webkit-transform 0.25s ease-in;
}

.header-hamburger span::after {
  background: #fff;
  content: "";
  height: 4px;
  position: absolute;
  right: 0;
  top: 9px;
  width: 100%;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transform-origin: 7px -2px;
  -ms-transform-origin: 7px -2px;
  transform-origin: 7px -2px;
  -webkit-transition: background-color 0.25s ease-in-out,
    -webkit-transform 0.25s ease-in;
  transition: background-color 0.25s ease-in-out,
    -webkit-transform 0.25s ease-in;
  -o-transition: background-color 0.25s ease-in-out, transform 0.25s ease-in;
  transition: background-color 0.25s ease-in-out, transform 0.25s ease-in;
  transition: background-color 0.25s ease-in-out, transform 0.25s ease-in,
    -webkit-transform 0.25s ease-in;
}

@media (any-pointer: fine) {
  .header-hamburger:hover {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }

  .header-hamburger:hover span {
    background: #ffc328;
  }

  .header-hamburger:hover span::before {
    background: #ffc328;
  }

  .header-hamburger:hover span::after {
    background: #ffc328;
  }
}

@media (max-width: 2048px) {
  .header-menu {
    height: 105px;
  }

  .header-menu__item {
    font-size: 28px;
  }

  .header-menu__item svg {
    width: 18px;
  }

  .header-metamask {
    font-size: 21px;
  }
}

@media (max-width: 1824px) {
  .header-menu {
    height: 100px;
  }

  .header-menu__item {
    font-size: 20px;
  }

  .header-menu__item svg {
    width: 17px;
  }

  .header-metamask {
    font-size: 20px;
  }
}

@media (max-width: 1728px) {
  .header-menu {
    height: 95px;
  }

  .header-menu__item {
    font-size: 19px;
  }

  .header-menu__item svg {
    margin-left: 6px;
    width: 16px;
  }

  .header-metamask {
    font-size: 19px;
  }
}

@media (max-width: 1632px) {
  .header-menu {
    height: 90px;
  }

  .header-menu__item {
    font-size: 18px;
  }

  .header-menu__item svg {
    margin-top: -1px;
    width: 15px;
  }

  .header-metamask {
    font-size: 18px;
  }
}

@media (max-width: 1536px) {
  .header-menu {
    height: 84px;
  }

  .header-menu__item {
    font-size: 17px;
  }

  .header-menu__item svg {
    margin-top: 0;
    width: 14px;
  }

  .header-metamask {
    font-size: 17px;
  }
}

@media (max-width: 1440px) {
  .header-menu {
    height: 79px;
  }

  .header-menu__item {
    font-size: 16px;
  }

  .header-menu__item svg {
    margin-left: 5px;
  }

  .header-metamask {
    font-size: 16px;
  }
}

@media (max-width: 1344px) {
  .header-menu {
    height: 74px;
  }

  .header-menu__item {
    font-size: 15px;
  }

  .header-menu__item svg {
    margin-top: 1px;
    width: 13px;
  }

  .header-metamask {
    font-size: 15px;
  }
}

@media (max-width: 1248px) {
  .header-menu {
    height: 68px;
  }

  .header-menu__item {
    font-size: 14px;
  }

  .header-menu__item svg {
    margin-top: 0;
    width: 12px;
  }

  .header-metamask {
    font-size: 14px;
  }
}

@media (max-width: 1056px) {
  .header-menu {
    height: 58px;
  }
}

@media (max-width: 1024px) {
  .header::after {
    height: 50.25px;
    left: 50%;
    margin-left: -720px;
    top: 51px;
    width: 1440px;
  }

  .header-bg {
    height: 84px;
    left: 50%;
    margin-left: -720px;
    width: 1440px;
  }

  .header-bg-image {
    height: 84px;
    left: 50%;
    margin-left: -720px;
    width: 1440px;
  }

  .header-container {
    margin-top: 0;
    padding: 21px 24px 0 21px;
  }

  .header-logo {
    height: 50px;
  }

  .header-popup {
    background: rgba(19, 19, 21, 0.85);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: baseline;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding: 84px 127px 27px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    -webkit-transition: -webkit-transform 0.25s ease-in;
    transition: -webkit-transform 0.25s ease-in;
    -o-transition: transform 0.25s ease-in;
    transition: transform 0.25s ease-in;
    transition: transform 0.25s ease-in, -webkit-transform 0.25s ease-in;
  }
}

@media (max-width: 1024px) and (pointer: coarse) and (hover: none) {
  .header-popup {
    height: 100vh;
  }
}

@media (max-width: 1024px) {
  .header-menu {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: auto;
    opacity: 0;
    width: 400px;
    -webkit-transition: opacity 0.1s ease-in;
    -o-transition: opacity 0.1s ease-in;
    transition: opacity 0.1s ease-in;
  }

  .header-menu__item {
    display: block;
    font-size: 44px;
    line-height: 130%;
    margin-bottom: 30px;
    margin-top: 0;
    padding: 0;
  }

  .header-menu__item.selected span::after {
    bottom: 0;
  }

  .header-menu__item:last-child {
    margin-bottom: 0;
  }
}

@media (max-width: 1024px) and (pointer: coarse) and (hover: none) {
  .header-menu__item.js-header-menu-item {
    pointer-events: none;
  }
}

@media (max-width: 1024px) {
  .header-menu__item svg {
    display: none;
  }
}

@media (max-width: 1024px) {
  .header-menu__item-submenu {
    background: transparent;
    -webkit-clip-path: none;
    clip-path: none;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding: 0px 0 0 30px;
    position: relative;
    left: auto;
    margin-left: 0;
    top: auto;
    width: auto;
    opacity: 1;
    pointer-events: auto;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .header-menu__item-submenu:before {
    display: none;
  }

  .header-menu__item-submenu__item {
    font-size: 20px;
    margin-bottom: 14px;
  }

  .header-menu__item-submenu__item::after {
    bottom: -5px;
  }

  .header-menu__item-submenu__item.coming-soon-item::before {
    background: url("../Image/soonLabel.svg");
    height: 12px;
    top: -8px;
    right: -16px;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    width: 22px;
  }

  .header-menu__item-submenu__item.sold-out-item::before {
    background: url("../Image/soldOutLabel.svg");
    top: 35%;
    right: -16px;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    background-position: 100% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    width: 38px;
    height: 22px;
  }
}

@media (max-width: 1024px) and (any-pointer: fine) {
  .header-menu__item-submenu__item:hover::after, .header-menu__item-submenu__item.selected::after {
    width: 35px;
  }
}

@media (max-width: 1024px) {
  .header-right {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    opacity: 0;
    -webkit-transition: opacity 0.1s ease-in;
    -o-transition: opacity 0.1s ease-in;
    transition: opacity 0.1s ease-in;
  }
}

@media (max-width: 1024px) {
  .header-metamask {
    font-size: 16px;
    line-height: 2;
    letter-spacing: 0.02em;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    margin-right: 0;
    margin-top: 24px;
  }
  .header-logo {
    margin-top: -5px;
  }

  .header-metamask span {
    margin-top: 3px;
  }

  .header-metamask img {
    margin-right: 13px;
    width: 28px;
  }
}

@media (max-width: 1024px) {
  .header-socials {
    display: none;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .header-socials__item {
    margin: 0 10px;
    width: 28px;
  }

  .header-socials__item:first-child {
    margin-left: 10px;
  }
}

@media (max-width: 1024px) {
  .header-hamburger {
    display: block;
    pointer-events: auto;
  }
}

@media (max-width: 1024px) {
  .header.open::after {
    -webkit-transform: translate3d(0, 100vh, 0);
    transform: translate3d(0, 100vh, 0);
    -webkit-transition: -webkit-transform 0.35s ease-out;
    transition: -webkit-transform 0.35s ease-out;
    -o-transition: transform 0.35s ease-out;
    transition: transform 0.35s ease-out;
    transition: transform 0.35s ease-out, -webkit-transform 0.35s ease-out;
  }

  .header.open .header-bg {
    -webkit-transform: translate3d(0, 100vh, 0);
    transform: translate3d(0, 100vh, 0);
    -webkit-transition: -webkit-transform 0.35s ease-out;
    transition: -webkit-transform 0.35s ease-out;
    -o-transition: transform 0.35s ease-out;
    transition: transform 0.35s ease-out;
    transition: transform 0.35s ease-out, -webkit-transform 0.35s ease-out;
  }

  .header.open .header-popup {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition: -webkit-transform 0.35s ease-out;
    transition: -webkit-transform 0.35s ease-out;
    -o-transition: transform 0.35s ease-out;
    transition: transform 0.35s ease-out;
    transition: transform 0.35s ease-out, -webkit-transform 0.35s ease-out;
  }

  .header.open .header-menu,
  .header.open .header-right {
    display: flex;
    justify-content: flex-start;
    opacity: 1;
    -webkit-transition: opacity 0.1s ease-out 0.35s;
    -o-transition: opacity 0.1s ease-out 0.35s;
    transition: opacity 0.1s ease-out 0.35s;
  }

  .header.open .header-hamburger {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }

  .header.open .header-hamburger span {
    background-color: transparent;
  }

  .header.open .header-hamburger span::before {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: -webkit-transform 0.35s ease-out;
    transition: -webkit-transform 0.35s ease-out;
    -o-transition: transform 0.35s ease-out;
    transition: transform 0.35s ease-out;
    transition: transform 0.35s ease-out, -webkit-transform 0.35s ease-out;
  }

  .header.open .header-hamburger span::after {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transition: -webkit-transform 0.35s ease-out;
    transition: -webkit-transform 0.35s ease-out;
    -o-transition: transform 0.35s ease-out;
    transition: transform 0.35s ease-out;
    transition: transform 0.35s ease-out, -webkit-transform 0.35s ease-out;
  }
}

@media (max-width: 1024px) and (orientation: landscape) {
  .header-menu {
    width: auto;
  }

  .header-menu__item {
    margin-bottom: 30px;
  }

  .header-menu__item-submenu {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding-left: 0;
  }

  .header-menu__item-submenu__item {
    margin-left: 30px;
    margin-bottom: 0;
  }

  .header-menu__item-submenu__item::after {
    bottom: -5px;
  }
  .header.open .header-menu,
  .header.open .header-right {
    display: flex;
    justify-content: center;
    opacity: 1;
    -webkit-transition: opacity 0.1s ease-out 0.35s;
    -o-transition: opacity 0.1s ease-out 0.35s;
    transition: opacity 0.1s ease-out 0.35s;
  }
}

@media (max-width: 1024px) and (orientation: landscape) and (any-pointer: fine) {
  .header-menu__item-submenu__item:hover::after, .header-menu__item-submenu__item.selected:after {
    width: 35px;
  }
}

@media (max-width: 1024px) and (orientation: landscape) {
  .header-right {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    height: unset;
  }
}

@media (max-width: 960px) and (orientation: landscape) {
  .header-menu__item {
    margin-bottom: 16px;
  }
}

@media (max-width: 896px) and (orientation: landscape) {
  .header-popup {
    padding-bottom: 24px;
    padding-left: 40px;
    padding-right: 40px;
  }

  .header-menu {
    width: auto;
    overflow-y: auto;
  }

  .header-menu__item {
    font-size: 20px;
    margin-bottom: 16px;
  }

  .header-menu__item-submenu__item {
    font-size: 14px;
    margin-bottom: 0;
  }

  .header-metamask {
    margin-top: 12px;
  }
}

@media (max-width: 767.98px) {
  .header::after {
    height: 41.875px;
    left: 0;
    margin-left: 0;
    top: 42px;
    width: 1200px;
  }

  .header-bg {
    height: 70px;
    left: 0;
    margin-left: 0;
    width: 1200px;
  }

  .header-bg-image {
    height: 70px;
    left: 0;
    margin-left: 0;
    width: 1200px;
  }

  .header-container {
    padding-top: 16px;
  }

  .header-logo {
    height: 50px;
  }

  .header-popup {
    padding: 70px 40px 24px;
  }

  .header-menu {
    width: auto;
    overflow-y: auto;
  }

  .header-menu__item {
    font-size: 24px;
    margin-bottom: 17px;
  }

  .header-menu__item-submenu__item {
    font-size: 16px;
    margin-bottom: 13px;
  }

  .header-right {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    position: absolute;
    bottom: 28px;
    right: 28px;
  }

  .header-metamask {
    margin-top: 52px;
  }

  .header-metamask span {
    display: none;
  }

  .header-metamask img {
    margin-right: 0;
  }

  .header-socials__item {
    margin: 26px 0 0;
  }
}

@media (max-width: 767px),  (max-width: 927px) and (max-height: 490px) and  (orientation: landscape) {
  .header-socials {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    
    @media (orientation: landscape) {
      justify-content: space-between;
      height: calc(100vh - 200px);
    }
    @media (min-width: 767.98px) {
    }
  }
}
@media  (min-width: 767.98px) and (max-width: 927px) and (max-height: 490px) and  (orientation: landscape) {
  .header-socials {
    height: calc(100vh - 170px);
    transform: translateX(14px);
  }
}
@media (max-width: 767px) and(orientation: landscape) {
  .header-socials {
    height: calc(100vh - 125px);
  }
}

.footer {
  margin-top: -1.275vw;
  position: relative;
}

.footer-up {
  background-image: url("../Image/beamUp.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  color: #000;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-family: Cold Warm, sans-serif;
  font-size: 0.9375vw;
  height: 4.1145833333vw;
  left: 8.3333333333vw;
  letter-spacing: 0.01em;
  position: absolute;
  top: -1.75vw;
  text-decoration: none;
  text-transform: uppercase;
  width: 10.15625vw;
  z-index: 2;
}

.footer-up svg {
  margin-right: 0.5729166667vw;
  margin-top: -2px;
  width: 1.6666666667vw;
}

.footer-up svg path {
  fill: #000;
}

.footer-up span {
  display: block;
}

.footer-up:active {
     color: #5b0d00;
  }

  .footer-up:active svg path {
    fill: #5b0d00;
  }

@media (any-pointer: fine) {
  .footer-up:hover {
    color: #5b0d00;
  }

  .footer-up:hover svg path {
    fill: #5b0d00;
  }
}

.footer-top {
  overflow: hidden;
  height: 22.5vw;
  position: relative;
  z-index: 1;
}

.footer-top-bg {
  background-color: #ffc328;
  -webkit-clip-path: polygon(
    100% 44.27106%,
    100% 99.92315%,
    56.21106% 99.92315%,
    55.69059% 97.14537%,
    55.13524% 99.92315%,
    22.27618% 99.92315%,
    21.6169% 98.94074%,
    5.65572% 98.94074%,
    5.13526% 99.92315%,
    -0.00008% 99.92315%,
    -0.00008% 2.44491%,
    9.75012% 2.44491%,
    10.20121% 4.24403%,
    10.61759% 2.44491%,
    17.45312% 2.34333%,
    18.71958% 0.07731%,
    37.64746% 0.07733%,
    38.8966% 3.69245%,
    46.32203% 3.69245%,
    47.05069% 5.32426%,
    60.37484% 5.32426%,
    61.34657% 2.14924%,
    70.92327% 2.14924%,
    72.03344% 5.63292%,
    92.57494% 5.63292%,
    93.33795% 2.54648%,
    100% 2.54648%,
    100% 44.27106%
  );
  clip-path: polygon(
    100% 44.27106%,
    100% 99.92315%,
    56.21106% 99.92315%,
    55.69059% 97.14537%,
    55.13524% 99.92315%,
    22.27618% 99.92315%,
    21.6169% 98.94074%,
    5.65572% 98.94074%,
    5.13526% 99.92315%,
    -0.00008% 99.92315%,
    -0.00008% 2.44491%,
    9.75012% 2.44491%,
    10.20121% 4.24403%,
    10.61759% 2.44491%,
    17.45312% 2.34333%,
    18.71958% 0.07731%,
    37.64746% 0.07733%,
    38.8966% 3.69245%,
    46.32203% 3.69245%,
    47.05069% 5.32426%,
    60.37484% 5.32426%,
    61.34657% 2.14924%,
    70.92327% 2.14924%,
    72.03344% 5.63292%,
    92.57494% 5.63292%,
    93.33795% 2.54648%,
    100% 2.54648%,
    100% 44.27106%
  );
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.footer-top-bg::before {
  background-image: url(../Image/footer-bg.svg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.footer-top .container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  position: relative;
}

.footer-socials {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  /*margin-top: 2vw;*/
}

.footer-socials__item {
  margin: 0 0.6770833333vw;
  pointer-events: auto;
  width: 1.6666666667vw;
}

.footer-socials__item svg path {
  fill: #000;
  -webkit-transition: fill 0.25s ease-in-out;
  -o-transition: fill 0.25s ease-in-out;
  transition: fill 0.25s ease-in-out;
}

@media (any-pointer: fine) {
  .footer-socials__item:hover svg path {
    fill: #fff;
  }
}

.footer-hashtag {
  color: #000;
  font-size: 1.0416666667vw;
  line-height: 120%;
  letter-spacing: 0.02em;
  margin-top: 0.78125vw;
}

.footer-logo {
  display: block;
  max-width: 18.5416666667vw;
  margin-top: 0.5208333333vw;
}

.footer-bottom {
  background-color: #000;
  color: rgba(255, 255, 255, 0.5);
  font-size: 27px;
  line-height: 43px;
  margin-top: -0.5729166667vw;
  padding-bottom: 2.4479166667vw;
  padding-top: 3.0208333333vw;
  position: relative;
}

.footer-bottom .container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.footer-bottom span,
.footer-bottom a {
  line-height: 43px;
}

.footer-left {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.footer-copyright span,
.footer-copyright a {
  margin-right: 0.9375vw;
}

.footer-copyright span:last-child,
.footer-copyright a:last-child {
  margin-right: 0;
}

.footer-copyright a {
  color: rgba(255, 255, 255, 0.5);
  position: relative;
  text-decoration: none;
  -webkit-transition: color 0.25s ease-in-out;
  -o-transition: color 0.25s ease-in-out;
  transition: color 0.25s ease-in-out;
}

.footer-copyright a::after {
  background-color: rgba(255, 255, 255, 0.5);
  content: "";
  height: 1px;
  position: absolute;
  left: 0;
  top: 100%;
  width: 50%;
  -webkit-transition: background-color 0.25s ease-in-out,
    width 0.25s ease-in-out;
  -o-transition: background-color 0.25s ease-in-out, width 0.25s ease-in-out;
  transition: background-color 0.25s ease-in-out, width 0.25s ease-in-out;
}

@media (any-pointer: fine) {
  .footer-copyright a:hover {
    color: #fff;
  }

  .footer-copyright a:hover::after {
    background-color: #fff;
    width: 100%;
  }
}

.footer-menu__item {
  color: rgba(255, 255, 255, 0.5);
  margin-left: 2.9166666667vw;
  font-size: 14px;
  text-decoration: none;
  -webkit-transition: color 0.25s ease-in-out;
  -o-transition: color 0.25s ease-in-out;
  transition: color 0.25s ease-in-out;
}

@media (any-pointer: fine) {
  .footer-menu__item:hover {
    color: #fff;
  }
}

.footer-right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-left: 8.8541666667vw;
}

.footer-69 {
  color: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-weight: bold;
  font-size: 38px;
  line-height: 34px;
  letter-spacing: -0.025em;
  margin-left: 0.625vw;
  text-decoration: none;
}

.footer-69 span {
  position: relative;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: color 0.25s ease-in-out,
    -webkit-transform 0.25s ease-in-out;
  transition: color 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
  -o-transition: color 0.25s ease-in-out, transform 0.25s ease-in-out;
  transition: color 0.25s ease-in-out, transform 0.25s ease-in-out;
  transition: color 0.25s ease-in-out, transform 0.25s ease-in-out,
    -webkit-transform 0.25s ease-in-out;
}

@media (any-pointer: fine) {
  .footer-69:hover span {
    color: #e30613;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

@media (max-width: 2048px) {
  .footer-bottom {
    font-size: 20px;
  }

  .footer-bottom span,
  .footer-bottom a {
    line-height: 34px;
  }

  .footer-69 {
    font-size: 28px;
    line-height: 34px;
  }
}

@media (max-width: 1824px) {
  .footer-bottom {
    font-size: 19px;
  }

  .footer-bottom span,
  .footer-bottom a {
    line-height: 32px;
  }

  .footer-69 {
    font-size: 27px;
    line-height: 32px;
  }
}

@media (max-width: 1728px) {
  .footer-bottom {
    font-size: 18px;
  }

  .footer-bottom span,
  .footer-bottom a {
    line-height: 30px;
  }

  .footer-69 {
    font-size: 25px;
    line-height: 30px;
  }
}

@media (max-width: 1632px) {
  .footer-bottom {
    font-size: 17px;
  }

  .footer-bottom span,
  .footer-bottom a {
    line-height: 28px;
  }

  .footer-69 {
    font-size: 24px;
    line-height: 28px;
  }
}

@media (max-width: 1536px) {
  .footer-bottom {
    font-size: 16px;
  }

  .footer-bottom span,
  .footer-bottom a {
    line-height: 26px;
  }

  .footer-69 {
    font-size: 22px;
    line-height: 26px;
  }
}

@media (max-width: 1440px) {
  .footer-bottom {
    font-size: 15px;
  }

  .footer-bottom span,
  .footer-bottom a {
    line-height: 24px;
  }

  .footer-69 {
    font-size: 21px;
    line-height: 24px;
  }
}

@media (max-width: 1344px) {
  .footer-bottom {
    font-size: 14px;
  }

  .footer-69 {
    font-size: 20px;
  }
}

@media (max-width: 1248px) {
  .footer-bottom {
    font-size: 13px;
  }

  .footer-bottom span,
  .footer-bottom a {
    line-height: 22px;
  }

  .footer-69 {
    font-size: 18px;
    line-height: 22px;
  }
}

@media (max-width: 1056px) {
  .footer-bottom {
    font-size: 12px;
  }

  .footer-bottom span,
  .footer-bottom a {
    line-height: 18px;
  }

  .footer-69 {
    font-size: 15px;
    line-height: 18px;
  }
}

@media (max-width: 1024px) {
  .footer {
    margin-top: -25px;
  }

  .footer-up {
    font-size: 14px;
    height: 55px;
    left: 48px;
    top: -27.5px;
    width: 143px;
  }

  .footer-up svg {
    margin-right: 8px;
    margin-top: -2px;
    width: 22px;
  }

  .footer-top {
    height: 432px;
  }

  .footer-top-bg {
    left: 50%;
    margin-left: -960px;
    width: 1920px;
    height: 432px;
  }

  .footer-socials {
    margin-top: 40px;
  }

  .footer-socials__item {
    margin: 0 13px;
    width: 32px;
  }

  .footer-hashtag {
    font-size: 20px;
    margin-top: 15px;
  }

  .footer-logo {
    max-width: 499px;
    margin-top: 51px;
  }

  .footer-bottom {
    font-size: 16px;
    margin-top: -13px;
    padding-bottom: 40px;
    padding-top: 53px;
  }

  .footer-bottom .container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .footer-bottom span {
    line-height: 28px;
  }

  .footer-copyright span,
  .footer-copyright a {
    margin-right: 16px;
  }

  .footer-menu {
    margin-left: 24px;
  }

  .footer-menu__item {
    margin-left: 24px;
  }

  .footer-right {
    margin-left: 0;
    margin-top: 40px;
  }

  .footer-69 {
    font-size: 28px;
    margin-left: 12px;
    line-height: 28px;
  }
}

@media (max-width: 767.98px) {
  .footer-up {
    left: 20px;
    top: -22.5px;
  }

  .footer-top {
    height: 297px;
  }

  .footer-top-bg {
    margin-left: -660px;
    width: 1320px;
    height: 297px;
  }

  .footer-socials {
    margin-top: 12px;
  }

  .footer-socials__item {
    width: 28px;
  }

  .footer-hashtag {
    font-size: 14px;
    margin-top: 12px;
  }

  .footer-logo {
    max-width: 229px;
    margin-top: 6px;
  }

  .footer-bottom {
    font-size: 14px;
  }

  .footer-bottom span,
  .footer-bottom a {
    line-height: 22px;
  }

  .footer-left {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .footer-menu {
    margin-left: 0;
    margin-top: 16px;
  }

  .footer-menu__item {
    margin-left: 16px;
  }

  .footer-menu__item:first-child {
    margin-left: 0;
  }

  .footer-69 {
    font-size: 22px;
    margin-left: 9px;
    line-height: 22px;
  }
}

.skip {
  color: #ffc328;
  font-size: 1.25vw;
  line-height: 120%;
  position: fixed;
  top: 2.6041666667vw;
  right: 30px;
  opacity: 0;
  pointer-events: none;
  text-decoration: none;
  -webkit-transition: opacity 0.25s ease-in-out;
  -o-transition: opacity 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out;
  z-index: 10;
}

.fp-viewing-1 .skip,
.fp-viewing-2 .skip,
.fp-viewing-3 .skip,
.fp-viewing-4 .skip,
.fp-viewing-5 .skip,
.fp-viewing-6 .skip {
  opacity: 1;
  pointer-events: auto;
  -webkit-transition: opacity 0.25s ease-in-out 0.7s;
  -o-transition: opacity 0.25s ease-in-out 0.7s;
  transition: opacity 0.25s ease-in-out 0.7s;
}

.skip::after {
  background: #ffc328;
  content: "";
  height: 1px;
  position: absolute;
  left: 10px;
  top: 100%;
  margin-top: 0.2083333333vw;
  width: 1.8229166667vw;
  -webkit-transition: width 0.25s ease-in-out;
  -o-transition: width 0.25s ease-in-out;
  transition: width 0.25s ease-in-out;
}

@media (any-pointer: fine) {
  .skip:hover::after {
    width: 6.5vw;
  }
}

@media (max-width: 1024px) {
  .skip {
    font-size: 18px;
    top: 48px;
    width: auto;
    right: 48px;
  }

  .skip::after {
    margin-top: 4px;
    width: 35px;
    left: 6.5px;
  }
}

@media (max-width: 1024px) and (any-pointer: fine) {
  .skip:hover::after {
    width: 100%;
  }
}

@media (max-width: 767.98px) {
  .skip {
    font-size: 14px;
    top: 30px;
    right: 20px;
  }

  .skip::after {
    margin-top: 3px;
    width: 30px;
  }
}

.timeline-wrapper {
  overflow: visible;
  pointer-events: none;
  position: fixed;
  height: 100%;
  top: 0;
  left: -10vw;
  z-index: 20;
  width: 9.5vw;
  -webkit-transition: left 0.25s ease-in;
  -o-transition: left 0.25s ease-in;
  transition: left 0.25s ease-in;
}

.fp-viewing-2 .timeline-wrapper,
.fp-viewing-3 .timeline-wrapper,
.fp-viewing-4 .timeline-wrapper,
.fp-viewing-5 .timeline-wrapper,
.fp-viewing-6 .timeline-wrapper {
  left: 0;
  -webkit-transition: left 0.35s ease-out 0.7s;
  -o-transition: left 0.35s ease-out 0.7s;
  transition: left 0.35s ease-out 0.7s;
}

@media (max-width: 1024px) {
  .timeline-wrapper {
    left: -150px;
    width: 130px;
  }
}

@media (max-width: 767.98px) {
  .timeline-wrapper {
    left: -70px;
    width: 60px;
  }
}

.timeline {
  position: relative;
  top: 0;
  right: 0;
  height: 200%;
  -webkit-transform: translate3d(0, -24%, 0);
  transform: translate3d(0, -24%, 0);
  -webkit-transition: -webkit-transform 0.7s ease;
  transition: -webkit-transform 0.7s ease;
  -o-transition: transform 0.7s ease;
  transition: transform 0.7s ease;
  transition: transform 0.7s ease, -webkit-transform 0.7s ease;
  width: 100%;
}

.timeline-path {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  -webkit-clip-path: polygon(
    0% 0%,
    2.75vw 0%,
    2.75vw -webkit-calc(50% - 5.95vw),
    7.85vw -webkit-calc(50% - 4.2vw),
    7.85vw -webkit-calc(50% + 1.7vw),
    2.75vw -webkit-calc(50% + 3.05vw),
    2.75vw 100%,
    0% 100%
  );
  clip-path: polygon(
    0% 0%,
    2.75vw 0%,
    2.75vw calc(50% - 5.95vw),
    7.85vw calc(50% - 4.2vw),
    7.85vw calc(50% + 1.7vw),
    2.75vw calc(50% + 3.05vw),
    2.75vw 100%,
    0% 100%
  );
  position: absolute;
  left: 0;
  top: 0;
  height: 200%;
  width: 100%;
  -webkit-transform: translate3d(0, -24%, 0);
  transform: translate3d(0, -24%, 0);
  -webkit-transition: -webkit-transform 0.7s ease;
  transition: -webkit-transform 0.7s ease;
  -o-transition: transform 0.7s ease;
  transition: transform 0.7s ease;
  transition: transform 0.7s ease, -webkit-transform 0.7s ease;
}

.fp-viewing-2 .timeline-path {
  -webkit-transform: translate3d(0, -42%, 0);
  transform: translate3d(0, -42%, 0);
}

.fp-viewing-3 .timeline-path {
  -webkit-transform: translate3d(0, -33%, 0);
  transform: translate3d(0, -33%, 0);
}

.fp-viewing-4 .timeline-path {
  -webkit-transform: translate3d(0, -24%, 0);
  transform: translate3d(0, -24%, 0);
}

.fp-viewing-5 .timeline-path {
  -webkit-transform: translate3d(0, -15%, 0);
  transform: translate3d(0, -15%, 0);
}

.fp-viewing-6 .timeline-path {
  -webkit-transform: translate3d(0, -6%, 0);
  transform: translate3d(0, -6%, 0);
}

.timeline-bg {
  background: url("../Image/timeline-glowing.webp");
  background-position: 100% 50%;
  background-repeat: repeat-y;
  background-size: 100% auto;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 8.4375vw;
}

.timeline-number {
  font-family: Cold Warm, sans-serif;
  font-size: 3.125vw;
  line-height: 120%;
  letter-spacing: 0.02em;
  left: 3vw;
  position: absolute;
  top: 50%;
  -webkit-transform: translate3d(0, -81%, 0);
  transform: translate3d(0, -81%, 0);
  text-transform: uppercase;
  color: #ffc328;
}

.fp-viewing-2 .timeline {
  -webkit-transform: translate3d(0, -42%, 0);
  transform: translate3d(0, -42%, 0);
}

.fp-viewing-3 .timeline {
  -webkit-transform: translate3d(0, -33%, 0);
  transform: translate3d(0, -33%, 0);
}

.fp-viewing-4 .timeline {
  -webkit-transform: translate3d(0, -24%, 0);
  transform: translate3d(0, -24%, 0);
}

.fp-viewing-5 .timeline {
  -webkit-transform: translate3d(0, -15%, 0);
  transform: translate3d(0, -15%, 0);
}

.fp-viewing-6 .timeline {
  -webkit-transform: translate3d(0, -6%, 0);
  transform: translate3d(0, -6%, 0);
}

@media (max-width: 1024px) {
  .timeline-path {
    -webkit-clip-path: polygon(
      0% 0%,
      34px 0%,
      34px -webkit-calc(50% - 84px),
      107px -webkit-calc(50% - 60px),
      107px -webkit-calc(50% + 24px),
      34px -webkit-calc(50% + 42px),
      34px 100%,
      0% 100%
    );
    clip-path: polygon(
      0% 0%,
      34px 0%,
      34px calc(50% - 84px),
      107px calc(50% - 60px),
      107px calc(50% + 24px),
      34px calc(50% + 42px),
      34px 100%,
      0% 100%
    );
  }

  .timeline-bg {
    width: 120px;
  }

  .timeline-number {
    font-size: 45px;
    left: 36px;
  }
}

@media (max-width: 767.98px) {
  .timeline-path {
    -webkit-clip-path: polygon(
      0% 0%,
      20px 0%,
      20px -webkit-calc(50% - 38px),
      50px -webkit-calc(50% - 27px),
      50px -webkit-calc(50% + 10px),
      20px -webkit-calc(50% + 20px),
      20px 100%,
      0% 100%
    );
    clip-path: polygon(
      0% 0%,
      20px 0%,
      20px calc(50% - 38px),
      50px calc(50% - 27px),
      50px calc(50% + 10px),
      20px calc(50% + 20px),
      20px 100%,
      0% 100%
    );
  }

  .timeline-bg {
    width: 50px;
  }

  .timeline-number {
    font-size: 24px;
    left: 14px;
    -webkit-transform: translate3d(0, -75%, 0);
    transform: translate3d(0, -75%, 0);
  }
}

.form {
  height: 100px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  width: 100%;
}

.form-input {
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 100%;
  -ms-flex: 0 1 100%;
  flex: 0 1 100%;
  overflow: hidden;
  margin-top: -0.5833333333vw;
  padding-top: 0.5833333333vw;
  position: relative;
}

.form-input-bg {
  height: 100px;
  position: absolute;
  width: 100%;
}

.form-input-bg svg {
  fill: none;
  height: 100%;
}

.form-input-bg svg path {
  stroke: #000;
  strokewidth: 0.75;
}

.form-input.error .form-input-bg svg path {
  stroke: #e40b0b;
}

.form-success {
  display: none;
  text-align: center;
}

.form-success svg {
  margin: 1.9270833333vw auto 0;
  width: 6.5104166667vw;
}

.sent .form-success {
  display: block;
}

.sent .form {
  display: none;
}

@media (max-width: 2048px) {
  .form {
    height: 75px;
  }

  .form-input-bg {
    height: 75px;
  }

  .form-input-bg svg path {
    strokewidth: 1;
  }
}

@media (max-width: 1824px) {
  .form {
    height: 72px;
  }

  .form-input-bg {
    height: 72px;
  }

  .form-input-bg svg path {
    strokewidth: 1.0416666667;
  }
}

@media (max-width: 1728px) {
  .form {
    height: 68px;
  }

  .form-input-bg {
    height: 68px;
  }

  .form-input-bg svg path {
    strokewidth: 1.1029411765;
  }
}

@media (max-width: 1632px) {
  .form {
    height: 64px;
  }

  .form-input-bg {
    height: 64px;
  }

  .form-input-bg svg path {
    strokewidth: 1.171875;
  }
}

@media (max-width: 1536px) {
  .form {
    height: 60px;
  }

  .form-input-bg {
    height: 60px;
  }

  .form-input-bg svg path {
    strokewidth: 1.25;
  }
}

@media (max-width: 1440px) {
  .form {
    height: 56px;
  }

  .form-input-bg {
    height: 56px;
  }

  .form-input-bg svg path {
    strokewidth: 1.3392857143;
  }
}

@media (max-width: 1344px) {
  .form {
    height: 52px;
  }

  .form-input-bg {
    height: 52px;
  }

  .form-input-bg svg path {
    strokewidth: 1.4423076923;
  }
}

@media (max-width: 1248px) {
  .form {
    height: 48px;
  }

  .form-input-bg {
    height: 48px;
  }

  .form-input-bg svg path {
    strokewidth: 1.5625;
  }
}

@media (max-width: 1056px) {
  .form {
    height: 45px;
  }

  .form-input-bg {
    height: 45px;
  }

  .form-input-bg svg path {
    strokewidth: 1.6666666667;
  }
}

@media (max-width: 1024px) {
  .form {
    height: 58px;
  }

  .form-input {
    margin-top: -8.4px;
    padding-top: 8.4px;
  }

  .form-input-bg {
    height: 58px;
  }

  .form-input-bg svg path {
    strokewidth: 1.2931034483;
  }

  .form-success svg {
    margin-top: 28px;
    width: 94px;
  }
}

@media (max-width: 767.98px) {
  .form {
    height: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .form-input {
    height: 58px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58px;
    -ms-flex: 0 0 58px;
    flex: 0 0 58px;
    margin-bottom: 12px;
    width: 100%;
  }

  .form-input-bg {
    display: none;
  }

  .form-input-bg-phone {
    height: 58px;
    position: absolute;
    width: 100%;
  }

  .form-input-bg-phone svg {
    fill: none;
    height: 100%;
  }

  .form-input-bg-phone svg path {
    stroke: #000;
    strokewidth: 1;
  }

  .error .form-input-bg-phone svg path {
    stroke: red;
  }

  .form-input-bg-phone svg.border-right {
    position: absolute;
    right: 0;
    top: 0;
    width: 1px;
  }

  .form-success p {
    margin-left: auto;
    margin-right: auto;
    max-width: 223px;
  }

  .form-success svg {
    margin-top: 37px;
    width: 63px;
  }
}

input {
  background: transparent;
  border: 0;
  border-radius: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: none;
  box-shadow: none;
  display: block;
  height: 100%;
  font-family: "Poppins", "Open Sans", sans-serif;
  font-size: 1.25vw;
  line-height: 150%;
  color: #000;
  padding: 0 2.5vw;
  position: relative;
  margin: 0;
  outline: none;
  width: 100%;
}

input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.4);
}

input::-moz-placeholder {
  color: rgba(0, 0, 0, 0.4);
}

input:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.4);
}

input::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.4);
}

input::placeholder {
  color: rgba(0, 0, 0, 0.4);
}

input + label {
  color: rgba(0, 0, 0, 0.4);
  font-size: 0.8333333333vw;
  line-height: 140%;
  left: 80px;
  position: absolute;
  top: 1px;
  white-space: nowrap;
}

input + label::after {
  background-color: #000;
  content: "";
  height: 1px;
  left: 100%;
  margin-left: 5px;
  position: absolute;
  top: -webkit-calc(50% - 1px);
  top: calc(50% - 1px);
  width: 100vw;
}

input + label span {
  display: block;
  overflow: hidden;
  position: relative;
  width: auto;
}

@media (max-width: 1824px) {
  input + label {
    left: 77px;
  }
}

@media (max-width: 1728px) {
  input + label {
    left: 73px;
  }
}

@media (max-width: 1632px) {
  input + label {
    left: 69px;
  }
}

@media (max-width: 1536px) {
  input + label {
    left: 65px;
  }
}

@media (max-width: 1440px) {
  input + label {
    left: 61px;
  }
}

@media (max-width: 1344px) {
  input + label {
    left: 57px;
  }
}

@media (max-width: 1248px) {
  input + label {
    left: 53px;
  }
}

@media (max-width: 1056px) {
  input + label {
    left: 50px;
  }
}

input:-moz-placeholder-shown + label::after {
  margin-left: 1.3888888889vw;
}

input:-ms-input-placeholder + label::after {
  margin-left: 1.3888888889vw;
}

input:placeholder-shown + label::after {
  margin-left: 1.3888888889vw;
}

input:-moz-placeholder-shown + label span {
  width: 0;
}

input:-ms-input-placeholder + label span {
  width: 0;
}

input:placeholder-shown + label span {
  width: 0;
}

.error input {
  color: #e40b0b;
}

.error input + label {
  color: #e40b0b;
}

.error input + label::after {
  background-color: #e40b0b;
}

.error input:-moz-placeholder-shown + label::after {
  margin-left: 4px;
}

.error input:-ms-input-placeholder + label::after {
  margin-left: 4px;
}

.error input:placeholder-shown + label::after {
  margin-left: 4px;
}

.error input:-moz-placeholder-shown + label span {
  width: auto;
}

.error input:-ms-input-placeholder + label span {
  width: auto;
}

.error input:placeholder-shown + label span {
  width: auto;
}

@media (max-width: 1024px) {
  input {
    font-size: 18px;
    padding: 0 34px;
  }

  input + label {
    font-size: 12px;
    left: 62px;
    top: 1px;
  }

  input + label::after {
    margin-left: 4px;
    top: -webkit-calc(50% - 1px);
    top: calc(50% - 1px);
  }

  input:-moz-placeholder-shown + label::after {
    margin-left: 19px;
  }

  input:-ms-input-placeholder + label::after {
    margin-left: 19px;
  }

  input:placeholder-shown + label::after {
    margin-left: 19px;
  }
}

@media (max-width: 767.98px) {
  input {
    padding: 0 26px;
  }

  input + label {
    left: 62px;
  }

  input:-moz-placeholder-shown + label::after {
    margin-left: 19px;
    top: 50%;
  }

  input:-ms-input-placeholder + label::after {
    margin-left: 19px;
    top: 50%;
  }

  input:placeholder-shown + label::after {
    margin-left: 19px;
    top: 50%;
  }
}

.backdrop {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  pointer-events: none;
  z-index: 190;
  -webkit-transition: opacity 0.25s ease-in;
  -o-transition: opacity 0.25s ease-in;
  transition: opacity 0.25s ease-in;
}

.popup-open .backdrop {
  opacity: 1;
  pointer-events: auto;
  -webkit-transition: opacity 0.35s ease-out;
  -o-transition: opacity 0.35s ease-out;
  transition: opacity 0.35s ease-out;
}

.popup {
  background-color: #ffc328;
  background-image: url("../Image/footer-bg.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #000;
  -webkit-clip-path: polygon(
    0% 5.78125vw,
    8.3963691377% 0%,
    46.3691376702% 0%,
    47.6550680787% 0.625vw,
    100% 0.625vw,
    100% -webkit-calc(100% - 2.9166666667vw),
    95.7639939486% 100%,
    71.7851739788% 100%,
    70.726172466% -webkit-calc(100% - 0.6770833333vw),
    0% -webkit-calc(100% - 0.6770833333vw)
  );
  clip-path: polygon(
    0% 5.78125vw,
    8.3963691377% 0%,
    46.3691376702% 0%,
    47.6550680787% 0.625vw,
    100% 0.625vw,
    100% calc(100% - 2.9166666667vw),
    95.7639939486% 100%,
    71.7851739788% 100%,
    70.726172466% calc(100% - 0.6770833333vw),
    0% calc(100% - 0.6770833333vw)
  );
  padding: 5.2777777778vw 9.7916666667vw 6.9444444444vw;
  position: fixed;
  left: 50%;
  top: 50%;
  height: auto;
  width: 69.0277777778vw;
  opacity: 0;
  pointer-events: none;
  -webkit-transform: translate3d(-50%, 0, 0);
  transform: translate3d(-50%, 0, 0);
  -webkit-transition: opacity 0.25s ease-in, -webkit-transform 0.25s ease-in;
  transition: opacity 0.25s ease-in, -webkit-transform 0.25s ease-in;
  -o-transition: opacity 0.25s ease-in, transform 0.25s ease-in;
  transition: opacity 0.25s ease-in, transform 0.25s ease-in;
  transition: opacity 0.25s ease-in, transform 0.25s ease-in,
    -webkit-transform 0.25s ease-in;
  z-index: 195;
}

.popup-close {
  display: block;
  height: 1.6666666667vw;
  width: 1.6666666667vw;
  position: absolute;
  right: 1.3194444444vw;
  top: 2.0138888889vw;
}

.popup-close svg {
  fill: none;
}

.popup-close svg path {
  stroke: #000;
  strokewidth: 2;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transition: -webkit-transform 0.75s
    cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: -webkit-transform 0.75s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: transform 0.75s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: transform 0.75s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: transform 0.75s cubic-bezier(0.175, 0.885, 0.32, 1.275),
    -webkit-transform 0.75s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

@media (any-pointer: fine) {
  .popup-close:hover svg path {
    -webkit-transform: rotate(-720deg);
    -ms-transform: rotate(-720deg);
    transform: rotate(-720deg);
  }
}

.popup-open .popup {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  -webkit-transition: opacity 0.35s ease-out, -webkit-transform 0.35s ease-out;
  transition: opacity 0.35s ease-out, -webkit-transform 0.35s ease-out;
  -o-transition: opacity 0.35s ease-out, transform 0.35s ease-out;
  transition: opacity 0.35s ease-out, transform 0.35s ease-out;
  transition: opacity 0.35s ease-out, transform 0.35s ease-out,
    -webkit-transform 0.35s ease-out;
}

.popup .sent h4 {
  margin-bottom: 0.5208333333vw;
}

@media (max-width: 1024px) {
  .popup {
    -webkit-clip-path: polygon(
      0% 33px,
      33px 0%,
      305px 0%,
      314px 9px,
      100% 9px,
      100% -webkit-calc(100% - 14px),
      655px 100%,
      332px 100%,
      324px -webkit-calc(100% - 7px),
      0% -webkit-calc(100% - 7px)
    );
    clip-path: polygon(
      0% 33px,
      33px 0%,
      305px 0%,
      314px 9px,
      100% 9px,
      100% calc(100% - 14px),
      655px 100%,
      332px 100%,
      324px calc(100% - 7px),
      0% calc(100% - 7px)
    );
    padding: 76px 56px 100px;
    width: 672px;
  }

  .popup-close {
    height: 24px;
    width: 24px;
    right: 23px;
    top: 32px;
  }

  .popup .sent h4 {
    margin-bottom: 8px;
  }
}

@media (max-width: 767.98px) {
  .popup {
    -webkit-clip-path: polygon(
      0% 28px,
      112px 28px,
      140px 0%,
      100% 0%,
      100% 100%,
      40px 100%,
      0% -webkit-calc(100% - 32px)
    );
    clip-path: polygon(
      0% 28px,
      112px 28px,
      140px 0%,
      100% 0%,
      100% 100%,
      40px 100%,
      0% calc(100% - 32px)
    );
    padding: 92px 20px 64px;
    width: 320px;
  }

  .popup h4 {
    text-align: center;
  }

  .popup-close {
    height: 26px;
    /* padding: 11px; */
    width: 26px;
    right: 8px;
    top: 8px;
  }

  .popup-close svg {
    width: 100%;
  }

  .popup .sent h4 {
    margin-bottom: 25px;
  }
}

/* Blocks */
.button-transparent {
  color: #ffc328;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 100px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 1.25vw;
  line-height: 1.5;
  letter-spacing: 0.02em;
  padding: 0 1.6666666667vw;
  position: relative;
  white-space: nowrap;
  text-decoration: none;
  min-width: 14.0625vw;
}

.button-transparent::before {
  background-image: url("../Image/button-transparent.svg");
  background-position: 0% 50%;
  background-repeat: no-repeat;
  background-size: auto 100%;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 6.25vw;
}

.button-transparent::after {
  background-image: url("../Image/button-transparent.svg");
  background-position: 100% 50%;
  background-repeat: no-repeat;
  background-size: auto 100%;
  content: "";
  position: absolute;
  left: 6.25vw;
  top: 0;
  height: 100%;
  width: -webkit-calc(100% - 6.25vw);
  width: calc(100% - 6.25vw);
}

.button-transparent span {
  position: relative;
  z-index: 1;
}

@media (any-pointer: fine) {
  .button-transparent:hover {
    color: #5b0d00;
  }

  .button-transparent:hover::before,
  .button-transparent:hover::after {
    background-image: url("../Image/button-transparent-left.svg");
  }
}

@media (max-width: 2048px) {
  .button-transparent {
    height: 75px;
  }
}

@media (max-width: 1824px) {
  .button-transparent {
    height: 72px;
  }
}

@media (max-width: 1728px) {
  .button-transparent {
    height: 68px;
  }
}

@media (max-width: 1632px) {
  .button-transparent {
    height: 64px;
  }
}

@media (max-width: 1536px) {
  .button-transparent {
    height: 60px;
  }
}

@media (max-width: 1440px) {
  .button-transparent {
    height: 56px;
  }
}

@media (max-width: 1344px) {
  .button-transparent {
    height: 52px;
  }
}

@media (max-width: 1248px) {
  .button-transparent {
    height: 48px;
  }
}

@media (max-width: 1056px) {
  .button-transparent {
    height: 45px;
  }
}

@media (max-width: 1024px) {
  .button-transparent {
    height: 56px;
    font-size: 18px;
    padding: 0 24px;
    min-width: 196px;
  }

  .button-transparent::before {
    width: 100px;
  }

  .button-transparent::after {
    left: 100px;
    width: -webkit-calc(100% - 100px);
    width: calc(100% - 100px);
  }
}

.button-type1,
.button-type2 {
  width: 298.46px;
  height: 75px;
  color: #5b0d00;
  margin: 0 auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 1.5;
  letter-spacing: 0.02em;
  position: relative;
  white-space: nowrap;
  text-decoration: none;
  font-family: "Poppins", "Open Sans", sans-serif;
}

@media (max-width: 1440px) {
  .button-type1:not(.notAdaptive),
  .button-type2 {
    width: 204px;
    height: 56px;
    display: flex;
    justify-content: space-evenly;
  }

  .button-type1 .label,
  .button-type2 .label {
    font-size: 18px;
  }
}

.button-type1::before {
  background-image: url("../Image/button-type1.svg");
  background-position: 0% 50%;
  background-repeat: no-repeat;
  background-size: auto 100%;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 6.25vw;
}

.button-type1::after {
  background-image: url("../Image/button-type1.svg");
  background-position: 100% 50%;
  background-repeat: no-repeat;
  background-size: auto 100%;
  content: "";
  position: absolute;
  left: 6.25vw;
  top: 0;
  height: 100%;
  width: -webkit-calc(100% - 6.25vw);
  width: calc(100% - 6.25vw);
}

.button-type1 .label {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 1.6666666667vw;
  position: relative;
  z-index: 1;
  font-size: 24px;
  font-family: "Poppins", "Open Sans", sans-serif;
}

.button-type1 .label + .icon {
  margin-left: -1.1458333333vw;
}

.button-type1 .icon {
  margin-right: 1.6666666667vw;
  position: relative;
  z-index: 1;
}

.button-type1 .icon svg {
  height: 1.3020833333vw;
}

.button-type1 .icon svg path {
  fill: #5b0d00;
}

.button-type1-before,
.button-type1-after {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: opacity 0.25s ease-in-out,
    -webkit-transform 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
  -o-transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out,
    -webkit-transform 0.25s ease-in-out;
}

.button-type1-before::before,
.button-type1-after::before {
  background-image: url("../Image/button-type1-hover.svg");
  background-position: 0% 50%;
  background-repeat: no-repeat;
  background-size: auto 100%;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 6.25vw;
}

.button-type1-before::after,
.button-type1-after::after {
  background-image: url("../Image/button-type1-hover.svg");
  background-position: 100% 50%;
  background-repeat: no-repeat;
  background-size: auto 100%;
  content: "";
  position: absolute;
  left: 6.25vw;
  top: 0;
  height: 100%;
  width: -webkit-calc(100% - 6.25vw);
  width: calc(100% - 6.25vw);
}

.button-type1.transparent {
  color: #ffc328;
}

.button-type1.transparent::before,
.button-type1.transparent::after {
  background-image: url("../Image/button-type1-hover.svg");
}

.button-type1.transparent .button-type1-before,
.button-type1.transparent .button-type1-after {
  display: none;
}

.button-type1.black {
  color: #fff;
}

.button-type1.black::before,
.button-type1.black::after {
  background-image: url("../Image/button-type1-black.svg");
}

.button-type1.black .button-type1-before::before,
.button-type1.black .button-type1-before::after,
.button-type1.black .button-type1-after::before,
.button-type1.black .button-type1-after::after {
  background-image: url("../Image/button-type1-hover-black.svg");
}

.button-type1.disabled {
  color: #4d4d4d;
  pointer-events: none;
}

.button-type1.disabled::before,
.button-type1.disabled::after {
  background-image: url("../Image/button-disabled.svg");
}

@media (any-pointer: fine) {
  .button-type1:hover .button-type1-before {
    -webkit-transform: translate3d(-0.5vw, -0.5vw, 0);
    transform: translate3d(-0.5vw, -0.5vw, 0);
    opacity: 1;
  }

  .button-type1:hover .button-type1-after {
    -webkit-transform: translate3d(0.5vw, 0.5vw, 0);
    transform: translate3d(0.5vw, 0.5vw, 0);
    opacity: 1;
  }

  .button-type1.transparent:hover {
    color: #5b0d00;
  }

  .button-type1.transparent:hover::before,
  .button-type1.transparent:hover::after {
    background-image: url("../Image/button-type1.svg");
  }
}

@media (max-width: 1024px) {
  .button-type1 {
    height: 56px;
    font-size: 18px;
    min-width: 196px;
  }

  .button-type1 .label {
    padding: 0 24px;
  }

  .button-type1 .label + .icon {
    margin-left: -16px;
  }

  .button-type1 .icon {
    margin-right: 24px;
  }

  .button-type1 .icon svg {
    height: 18px;
  }

  .button-type1::before {
    width: 102px;
  }

  .button-type1::after {
    left: 100px;
    width: -webkit-calc(100% - 100px);
    width: calc(100% - 100px);
  }
}

.button-type2::before {
  background-image: url("../Image/button-transparent-left.svg");
  background-position: 0% 50%;
  background-repeat: no-repeat;
  background-size: auto 100%;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 50%;
}

.button-type2::after {
  background-image: url("../Image/button-transparent-left.svg");
  background-position: 100% 50%;
  background-repeat: no-repeat;
  background-size: auto 100%;
  content: "";
  position: absolute;
  left: 6.25vw;
  top: 0;
  height: 100%;
  width: -webkit-calc(100% - 6.25vw);
  width: calc(100% - 6.25vw);
}

.button-type2 .label {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 1.6666666667vw;
  position: relative;
  z-index: 1;
  font-size: 24px;
  font-family: "Poppins", "Open Sans", sans-serif;
}

.button-type2-before,
.button-type2-after {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: opacity 0.25s ease-in-out,
    -webkit-transform 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
  -o-transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out,
    -webkit-transform 0.25s ease-in-out;
}

.button-type2-before::before,
.button-type2-after::before {
  background-image: url("../Image/button-transparent-shape.svg");
  background-position: 0% 50%;
  background-repeat: no-repeat;
  background-size: auto 100%;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 6.25vw;
}

.button-type2-before::after,
.button-type2-after::after {
  background-image: url("../Image/button-transparent-shape.svg");
  background-position: 100% 50%;
  background-repeat: no-repeat;
  background-size: auto 100%;
  content: "";
  position: absolute;
  left: 6.25vw;
  top: 0;
  height: 100%;
  width: -webkit-calc(100% - 6.25vw);
  width: calc(100% - 6.25vw);
}

@media (any-pointer: fine) {
  .button-type2:hover .button-type2-before {
    -webkit-transform: translate3d(-0.5vw, -0.5vw, 0);
    transform: translate3d(-0.5vw, -0.5vw, 0);
    opacity: 1;
  }

  .button-type2:hover .button-type2-after {
    -webkit-transform: translate3d(0.5vw, 0.5vw, 0);
    transform: translate3d(0.5vw, 0.5vw, 0);
    opacity: 1;
  }
}

@media (max-width: 1024px) {
  .button-type2 {
    height: 56px;
    font-size: 18px;
    min-width: 196px;
    width: 168px;
  }

  .button-type2 .label {
    padding: 0 24px;
  }

  .button-type2::before {
    width: 110px;
  }

  .button-type2::after {
    left: 100px;
    width: -webkit-calc(100% - 100px);
    width: calc(100% - 100px);
  }
}

.button-notify {
  color: #5b0d00;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 75px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-family: Cold Warm, sans-serif;
  font-size: 1.0416666667vw;
  line-height: 1.2;
  letter-spacing: 0.02em;
  padding: 0 1.0416666667vw;
  position: absolute;
  right: -3.3333333333vw;
  top: -1.4583333333vw;
  white-space: nowrap;
  text-decoration: none;
  text-transform: uppercase;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transform: rotate(15deg);
  -ms-transform: rotate(15deg);
  transform: rotate(15deg);
  min-width: 8.8541666667vw;
  z-index: 1;
}

.button-notify::before,
.button-notify::after {
  content: "";
  background-image: url("../Image/notify.svg");
  background-position: 50% 50%;
  background-repeat: repeat-x;
  background-size: auto 100%;
  -webkit-clip-path: polygon(
    0% 0%,
    100% 0%,
    100% 48px,
    -webkit-calc(100% - 27px) 100%,
    0% 100%
  );
  clip-path: polygon(
    0% 0%,
    100% 0%,
    100% 48px,
    calc(100% - 27px) 100%,
    0% 100%
  );
  display: block;
  opacity: 0.2;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.button-notify .label,
.button-notify .icon {
  position: relative;
  z-index: 2;
}

.button-notify .label {
  margin-top: 1px;
}

.button-notify .icon {
  margin-left: 0.4166666667vw;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 1.0416666667vw;
  -ms-flex: 0 0 1.0416666667vw;
  flex: 0 0 1.0416666667vw;
}

.button-notify-bg {
  background-image: url("../Image/notify.svg");
  background-position: 50% 50%;
  background-repeat: repeat-x;
  background-size: auto 100%;
  -webkit-clip-path: polygon(
    0% 0%,
    100% 0%,
    100% 48px,
    -webkit-calc(100% - 27px) 100%,
    0% 100%
  );
  clip-path: polygon(
    0% 0%,
    100% 0%,
    100% 48px,
    calc(100% - 27px) 100%,
    0% 100%
  );
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}

@media (any-pointer: fine) {
  .button-notify:hover {
    -webkit-animation: ring 1.25s linear infinite;
    animation: ring 1.25s linear infinite;
  }

  .button-notify:hover::before {
    -webkit-animation: ring-2 1.25s linear infinite;
    animation: ring-2 1.25s linear infinite;
  }

  .button-notify:hover::after {
    -webkit-animation: ring-3 1.25s linear infinite;
    animation: ring-3 1.25s linear infinite;
  }

  .popup-open .button-notify:hover {
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
  }

  .popup-open .button-notify:hover::before,
  .popup-open .button-notify:hover::after {
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
  }
}

@media (max-width: 2048px) {
  .button-notify {
    height: 56px;
  }

  .button-notify::before,
  .button-notify::after,
  .button-notify-bg {
    -webkit-clip-path: polygon(
      0% 0%,
      100% 0%,
      100% 36px,
      -webkit-calc(100% - 20px) 100%,
      0% 100%
    );
    clip-path: polygon(
      0% 0%,
      100% 0%,
      100% 36px,
      calc(100% - 20px) 100%,
      0% 100%
    );
  }
}

@media (max-width: 1824px) {
  .button-notify {
    height: 53px;
  }

  .button-notify::before,
  .button-notify::after,
  .button-notify-bg {
    -webkit-clip-path: polygon(
      0% 0%,
      100% 0%,
      100% 34px,
      -webkit-calc(100% - 19px) 100%,
      0% 100%
    );
    clip-path: polygon(
      0% 0%,
      100% 0%,
      100% 34px,
      calc(100% - 19px) 100%,
      0% 100%
    );
  }
}

@media (max-width: 1728px) {
  .button-notify {
    height: 50px;
  }

  .button-notify::before,
  .button-notify::after,
  .button-notify-bg {
    -webkit-clip-path: polygon(
      0% 0%,
      100% 0%,
      100% 32px,
      -webkit-calc(100% - 18px) 100%,
      0% 100%
    );
    clip-path: polygon(
      0% 0%,
      100% 0%,
      100% 32px,
      calc(100% - 18px) 100%,
      0% 100%
    );
  }
}

@media (max-width: 1632px) {
  .button-notify {
    height: 48px;
  }

  .button-notify::before,
  .button-notify::after,
  .button-notify-bg {
    -webkit-clip-path: polygon(
      0% 0%,
      100% 0%,
      100% 31px,
      -webkit-calc(100% - 17px) 100%,
      0% 100%
    );
    clip-path: polygon(
      0% 0%,
      100% 0%,
      100% 31px,
      calc(100% - 17px) 100%,
      0% 100%
    );
  }
}

@media (max-width: 1536px) {
  .button-notify {
    height: 45px;
  }

  .button-notify::before,
  .button-notify::after,
  .button-notify-bg {
    -webkit-clip-path: polygon(
      0% 0%,
      100% 0%,
      100% 29px,
      -webkit-calc(100% - 16px) 100%,
      0% 100%
    );
    clip-path: polygon(
      0% 0%,
      100% 0%,
      100% 29px,
      calc(100% - 16px) 100%,
      0% 100%
    );
  }
}

@media (max-width: 1440px) {
  .button-notify {
    height: 42px;
  }

  .button-notify::before,
  .button-notify::after,
  .button-notify-bg {
    -webkit-clip-path: polygon(
      0% 0%,
      100% 0%,
      100% 27px,
      -webkit-calc(100% - 15px) 100%,
      0% 100%
    );
    clip-path: polygon(
      0% 0%,
      100% 0%,
      100% 27px,
      calc(100% - 15px) 100%,
      0% 100%
    );
  }
}

@media (max-width: 1344px) {
  .button-notify {
    height: 39px;
  }

  .button-notify::before,
  .button-notify::after,
  .button-notify-bg {
    -webkit-clip-path: polygon(
      0% 0%,
      100% 0%,
      100% 25px,
      -webkit-calc(100% - 14px) 100%,
      0% 100%
    );
    clip-path: polygon(
      0% 0%,
      100% 0%,
      100% 25px,
      calc(100% - 14px) 100%,
      0% 100%
    );
  }
}

@media (max-width: 1248px) {
  .button-notify {
    height: 36px;
  }

  .button-notify::before,
  .button-notify::after,
  .button-notify-bg {
    -webkit-clip-path: polygon(
      0% 0%,
      100% 0%,
      100% 23px,
      -webkit-calc(100% - 13px) 100%,
      0% 100%
    );
    clip-path: polygon(
      0% 0%,
      100% 0%,
      100% 23px,
      calc(100% - 13px) 100%,
      0% 100%
    );
  }
}

@media (max-width: 1056px) {
  .button-notify {
    height: 34px;
  }

  .button-notify::before,
  .button-notify::after,
  .button-notify-bg {
    -webkit-clip-path: polygon(
      0% 0%,
      100% 0%,
      100% 22px,
      -webkit-calc(100% - 12px) 100%,
      0% 100%
    );
    clip-path: polygon(
      0% 0%,
      100% 0%,
      100% 22px,
      calc(100% - 12px) 100%,
      0% 100%
    );
  }
}

@media (max-width: 1024px) {
  .button-notify {
    height: 42px;
    font-size: 15px;
    padding: 0 16px;
    right: -40px;
    top: -21px;
    min-width: 128px;
  }

  .button-notify::before,
  .button-notify::after,
  .button-notify-bg {
    -webkit-clip-path: polygon(
      0% 0%,
      100% 0%,
      100% 27px,
      -webkit-calc(100% - 15px) 100%,
      0% 100%
    );
    clip-path: polygon(
      0% 0%,
      100% 0%,
      100% 27px,
      calc(100% - 15px) 100%,
      0% 100%
    );
  }

  .button-notify .icon {
    margin-left: 6px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 15px;
    -ms-flex: 0 0 15px;
    flex: 0 0 15px;
  }
}

@-webkit-keyframes ring {
  0%,
  100% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
  }
  27.5%,
  32.5% {
    -webkit-transform: rotate(19deg);
    transform: rotate(19deg);
  }
  67.5%,
  72.5% {
    -webkit-transform: rotate(11deg);
    transform: rotate(11deg);
  }
}

@keyframes ring {
  0%,
  100% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
  }
  27.5%,
  32.5% {
    -webkit-transform: rotate(19deg);
    transform: rotate(19deg);
  }
  67.5%,
  72.5% {
    -webkit-transform: rotate(11deg);
    transform: rotate(11deg);
  }
}

@-webkit-keyframes ring-2 {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  27.5%,
  32.5% {
    -webkit-transform: rotate(-7.5deg);
    transform: rotate(-7.5deg);
  }
  67.5%,
  72.5% {
    -webkit-transform: rotate(7.5deg);
    transform: rotate(7.5deg);
  }
}

@keyframes ring-2 {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  27.5%,
  32.5% {
    -webkit-transform: rotate(-7.5deg);
    transform: rotate(-7.5deg);
  }
  67.5%,
  72.5% {
    -webkit-transform: rotate(7.5deg);
    transform: rotate(7.5deg);
  }
}

@-webkit-keyframes ring-3 {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  27.5%,
  32.5% {
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }
  67.5%,
  72.5% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
  }
}

@keyframes ring-3 {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  27.5%,
  32.5% {
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }
  67.5%,
  72.5% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
  }
}

.button-submit {
  color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 1.25vw;
  line-height: 1.2;
  letter-spacing: 0.03em;
  padding: 0 2.5vw;
  position: relative;
  white-space: nowrap;
  text-decoration: none;
  -webkit-transition: opacity 0.25s ease-in-out, color 0.25s ease-in-out,
    background-color 0.25s ease-in-out;
  -o-transition: opacity 0.25s ease-in-out, color 0.25s ease-in-out,
    background-color 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out, color 0.25s ease-in-out,
    background-color 0.25s ease-in-out;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 241px;
  -ms-flex: 0 0 241px;
  flex: 0 0 241px;
  width: 241px;
}

.button-submit-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.button-submit-bg svg {
  width: 100%;
  height: 100%;
}

.button-submit-bg svg path {
  fill: #000;
  strokewidth: 0;
  -webkit-transition: fill 0.25s ease-in-out;
  -o-transition: fill 0.25s ease-in-out;
  transition: fill 0.25s ease-in-out;
}

.loading .button-submit-bg svg path {
  fill: #ff8e08;
}

.button-submit-loading {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: opacity 0.25s ease-in-out;
  -o-transition: opacity 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out;
}

.button-submit-loading svg {
  width: 100%;
  height: 100%;
}

.button-submit-loading svg path {
  fill: none;
  stroke: #000;
  -webkit-animation: loading 2.5s linear infinite;
  animation: loading 2.5s linear infinite;
  stroke-dasharray: 362.9305 147.6435;
  stroke-dashoffset: 0;
}

.loading .button-submit-loading {
  opacity: 1;
}

.button-submit .label {
  position: relative;
  z-index: 1;
}

.button-submit.disabled {
  color: #ffc328;
  cursor: default;
}

.button-submit.disabled .button-submit-bg svg path {
  fill: #a67f1a;
}

@media (any-pointer: fine) {
  .button-submit:not(.disabled):hover {
    color: #000;
  }

  .button-submit:not(.disabled):hover .button-submit-bg svg path {
    fill: #ff8e08;
  }
}

.loading .button-submit {
  color: #000;
  cursor: wait;
}

@media (max-width: 2048px) {
  .button-submit {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 181px;
    -ms-flex: 0 0 181px;
    flex: 0 0 181px;
    width: 181px;
  }
}

@media (max-width: 1824px) {
  .button-submit {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 174px;
    -ms-flex: 0 0 174px;
    flex: 0 0 174px;
    width: 174px;
  }
}

@media (max-width: 1728px) {
  .button-submit {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 164px;
    -ms-flex: 0 0 164px;
    flex: 0 0 164px;
    width: 164px;
  }
}

@media (max-width: 1632px) {
  .button-submit {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 154px;
    -ms-flex: 0 0 154px;
    flex: 0 0 154px;
    width: 154px;
  }
}

@media (max-width: 1536px) {
  .button-submit {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 145px;
    -ms-flex: 0 0 145px;
    flex: 0 0 145px;
    width: 145px;
  }
}

@media (max-width: 1440px) {
  .button-submit {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 135px;
    -ms-flex: 0 0 135px;
    flex: 0 0 135px;
    width: 135px;
  }
}

@media (max-width: 1344px) {
  .button-submit {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 125px;
    -ms-flex: 0 0 125px;
    flex: 0 0 125px;
    width: 125px;
  }
}

@media (max-width: 1248px) {
  .button-submit {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 116px;
    -ms-flex: 0 0 116px;
    flex: 0 0 116px;
    width: 116px;
  }
}

@media (max-width: 1056px) {
  .button-submit {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 108px;
    -ms-flex: 0 0 108px;
    flex: 0 0 108px;
    width: 108px;
  }
}

@media (max-width: 1024px) {
  .button-submit {
    font-size: 18px;
    height: 58px;
    padding: 0 34px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 140px;
    -ms-flex: 0 0 140px;
    flex: 0 0 140px;
    width: 140px;
  }
}

@media (max-width: 767.98px) {
  .button-submit {
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
  }
}

@-webkit-keyframes loading {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 1021.148;
  }
}

@keyframes loading {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 1021.148;
  }
}

.button-comingsoon {
  background-image: url("../Image/button-comingsoon-bg.svg");
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: auto 100%;
  color: rgba(255, 255, 255, 0.3);
  cursor: default;
  -webkit-clip-path: polygon(
    0% 0%,
    100% 0%,
    100% 110%,
    7.6% 110%,
    7.6% -webkit-calc(100% - 1px),
    0% -webkit-calc(100% - 1px)
  );
  clip-path: polygon(
    0% 0%,
    100% 0%,
    100% 110%,
    7.6% 110%,
    7.6% calc(100% - 1px),
    0% calc(100% - 1px)
  );
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-left: 0;
  border-bottom: 0;
  height: 4.3229166667vw;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 1.3541666667vw;
  line-height: 1.5;
  position: relative;
  white-space: nowrap;
  text-decoration: none;
  min-width: 18.28125vw;
}

.button-comingsoon .bottom {
  bottom: -0.46875vw;
  font-family: Cold Warm, sans-serif;
  font-size: 0.6770833333vw;
  line-height: 140%;
  letter-spacing: 0.05em;
  color: #ffc328;
  position: absolute;
  text-transform: uppercase;
}

.button-comingsoon .bottom::before {
  background: rgba(255, 255, 255, 0.3);
  content: "";
  height: 1px;
  position: absolute;
  left: 100%;
  margin-left: 0.46875vw;
  margin-top: -webkit-calc(0.46875vw - 0.5px);
  margin-top: calc(0.46875vw - 0.5px);
  width: 300%;
}

.button-comingsoon .bottom::after {
  background: rgba(255, 255, 255, 0.3);
  content: "";
  height: 1px;
  position: absolute;
  right: 100%;
  margin-right: 0.46875vw;
  margin-top: -webkit-calc(0.46875vw - 0.5px);
  margin-top: calc(0.46875vw - 0.5px);
  width: 300%;
}

@media (max-width: 1024px) {
  .button-comingsoon {
    -webkit-clip-path: polygon(
      0% 0%,
      100% 0%,
      100% 110%,
      18px 110%,
      18px 55px,
      0% 55px
    );
    clip-path: polygon(
      0% 0%,
      100% 0%,
      100% 110%,
      18px 110%,
      18px 55px,
      0% 55px
    );
    height: 56px;
    font-size: 18px;
    min-width: 257px;
  }

  .button-comingsoon .bottom {
    bottom: -7px;
    font-size: 10px;
  }

  .button-comingsoon .bottom::before {
    margin-left: 4px;
    margin-top: 6px;
  }

  .button-comingsoon .bottom::after {
    margin-right: 4px;
    margin-top: 6px;
  }
}

/* Page Sections styles */
.intro {
  position: relative;
}

.intro-parallax {
  height: 104%;
  margin: -2% 0 0 -3.3333333333vw;
  position: relative;
  width: 106.6666666667vw;
}

@media (pointer: coarse) and (hover: none) {
  .intro-parallax {
    height: 100%;
    margin: 0;
    width: 100%;
  }
}

.intro-bg {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  height: -webkit-calc(100% + 2.8125vw);
  height: calc(100% + 2.8125vw);
  width: 100%;
}

.intro-bg-image {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  position: absolute;
  width: 100%;
}

.intro-container {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 100%;
  padding: 5.8333333333vw 6.25vw 0;
}

.intro-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: stretch;
  -webkit-justify-content: stretch;
  -ms-flex-pack: stretch;
  justify-content: stretch;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 100%;
}

.intro-logo {
  margin-top: 2.3958333333vw;
  max-width: 26.9270833333vw;
  width: 100%;
}

.intro-bottom {
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 100%;
  -ms-flex: 0 1 100%;
  flex: 0 1 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.intro-bottom .lead {
  font-family: Cold Warm, sans-serif;
  font-size: 2.2916666667vw;
  line-height: 90%;
  letter-spacing: 0.03em;
  margin-bottom: 1.3020833333vw;
  text-align: center;
}

.intro-fixed {
  height: 16.3020833333vw;
  position: relative;
}

.intro-preload {
  font-family: Cold Warm, sans-serif;
  font-size: 1.0416666667vw;
  line-height: 120%;
  letter-spacing: 0.03em;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 13.9583333333vw;
  position: absolute;
  top: 0;
  left: 50%;
  text-align: center;
  text-transform: uppercase;
  -webkit-transform: translate3d(-50%, 0, 0);
  transform: translate3d(-50%, 0, 0);
}

.intro-preload svg {
  fill: none;
  height: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translate3d(-50%, 0, 0);
  transform: translate3d(-50%, 0, 0);
}

.intro-preload svg .js-preload-rotate {
  -webkit-transform: scale(1) rotate(0deg);
  -ms-transform: scale(1) rotate(0deg);
  transform: scale(1) rotate(0deg);
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transition: -webkit-transform 0.25s ease-in-out;
  transition: -webkit-transform 0.25s ease-in-out;
  -o-transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
}

.intro-cta {
  opacity: 0;
  pointer-events: none;
}

.intro-button {
  margin-bottom: 8.125vw;
  -webkit-transform: translate3d(0, 200%, 0);
  transform: translate3d(0, 200%, 0);
  width: 280px;
}

.intro-scroll {
  display: block;
  margin: 0 auto 0.6770833333vw;
  opacity: 0;
  -webkit-transform: translate3d(0, 50%, 0);
  transform: translate3d(0, 50%, 0);
  position: relative;
  width: 2.1875vw;
}

.intro-scroll svg {
  width: 100%;
}

.intro-scroll svg .top {
  fill: #ffc328;
}

.intro-scroll svg .down-1,
.intro-scroll svg .down-2,
.intro-scroll svg .down-3 {
  fill: none;
  stroke: #ffc328;
  strokewidth: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: -webkit-transform 0.25s ease-in-out;
  transition: -webkit-transform 0.25s ease-in-out;
  -o-transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
}

@media (any-pointer: fine) {
  .intro-scroll:hover svg .down-2 {
    -webkit-transform: translate3d(0, 18%, 0);
    transform: translate3d(0, 18%, 0);
  }

  .intro-scroll:hover svg .down-3 {
    -webkit-transform: translate3d(0, 36%, 0);
    transform: translate3d(0, 36%, 0);
  }
}

.fp-enabled .intro {
  overflow: hidden;
}

.intro.active {
  overflow: visible;
}

.intro.loaded .intro-preload {
  opacity: 0;
  -webkit-transition: opacity 0.35s ease-out, -webkit-transform 0.35s ease-out;
  transition: opacity 0.35s ease-out, -webkit-transform 0.35s ease-out;
  -o-transition: transform 0.35s ease-out, opacity 0.35s ease-out;
  transition: transform 0.35s ease-out, opacity 0.35s ease-out;
  transition: transform 0.35s ease-out, opacity 0.35s ease-out,
    -webkit-transform 0.35s ease-out;
  -webkit-transform: translate3d(-50%, 0, 0) scale(1.33);
  transform: translate3d(-50%, 0, 0) scale(1.33);
}

.intro.loaded .intro-cta {
  -webkit-transition: opacity 0.35s ease-out 0.35s;
  -o-transition: opacity 0.35s ease-out 0.35s;
  transition: opacity 0.35s ease-out 0.35s;
  opacity: 1;
  pointer-events: auto;
}

.intro.loaded .intro-button {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: -webkit-transform 0.35s ease-out 0.35s;
  transition: -webkit-transform 0.35s ease-out 0.35s;
  -o-transition: transform 0.35s ease-out 0.35s;
  transition: transform 0.35s ease-out 0.35s;
  transition: transform 0.35s ease-out 0.35s,
    -webkit-transform 0.35s ease-out 0.35s;
}

.intro.loaded .intro-scroll {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: opacity 0.35s ease-out 0.7s,
    -webkit-transform 0.35s ease-out 0.7s;
  transition: opacity 0.35s ease-out 0.7s, -webkit-transform 0.35s ease-out 0.7s;
  -o-transition: transform 0.35s ease-out 0.7s, opacity 0.35s ease-out 0.7s;
  transition: transform 0.35s ease-out 0.7s, opacity 0.35s ease-out 0.7s;
  transition: transform 0.35s ease-out 0.7s, opacity 0.35s ease-out 0.7s,
    -webkit-transform 0.35s ease-out 0.7s;
}

@media (max-width: 1024px) {
  .intro-container {
    padding: 84px 40px 0;
  }

  .intro-logo {
    margin-top: 54px;
    max-width: 517px;
  }

  .intro-bottom .lead {
    font-size: 33px;
    margin-bottom: 25px;
  }

  .intro-fixed {
    height: 289px;
  }

  .intro-preload {
    font-size: 20px;
    height: 220px;
  }

  .intro-button {
    margin-bottom: 152px;
  }

  .intro-scroll {
    margin-bottom: 28px;
    width: 32px;
  }
}
@media (max-width: 767.98px) {
  .intro-container {
    padding: 70px 20px 0;
  }

  .intro-logo {
    margin-top: 30px;
    max-width: 517px;
  }

  .intro-bottom{
    padding-top: 200px;
    justify-content: center !important;
  }
  .intro-bottom .lead {
    font-size: 24px;
    line-height: 120%;
    text-align: center;
    max-width: 240px;
  }

  .intro-fixed {
    height: 163px;
  }

  .intro-preload {
    font-size: 12px;
    height: 106px;
  }

  .intro-button {
    margin-bottom: 54px;
  }

  .intro-scroll {
    margin-bottom: 0;
  }
}

.js-parallax-front {
  mix-blend-mode: screen;
}

#intro,
#story-slide-intro {
  height: 100vh;
}

#story-slide-1,
#story-slide-2,
#story-slide-3,
#story-slide-4,
#story-slide-5 {
  display: none;
}

.fp-enabled #story-slide-intro,
.fp-enabled #story-slide-1,
.fp-enabled #story-slide-2,
.fp-enabled #story-slide-3,
.fp-enabled #story-slide-4,
.fp-enabled #story-slide-5 {
  display: block;
}

.slide-intro-bg {
  -webkit-clip-path: polygon(
    0% 1.9791666667vw,
    5.78125% 1.9791666667vw,
    6.6145833333% 2.8125vw,
    18.4895833333% 2.8125vw,
    20.5729166667% 1.3020833333vw,
    36.9270833333% 1.3020833333vw,
    37.65625% 0.5729166667vw,
    54.6354166667% 0.5729166667vw,
    55.625% 1.5625vw,
    69.6875% 1.5625vw,
    71.25% 0%,
    97.1875% 0%,
    100% 2.8125vw,
    100% 100%,
    0% 100%,
    0% 0%
  );
  clip-path: polygon(
    0% 1.9791666667vw,
    5.78125% 1.9791666667vw,
    6.6145833333% 2.8125vw,
    18.4895833333% 2.8125vw,
    20.5729166667% 1.3020833333vw,
    36.9270833333% 1.3020833333vw,
    37.65625% 0.5729166667vw,
    54.6354166667% 0.5729166667vw,
    55.625% 1.5625vw,
    69.6875% 1.5625vw,
    71.25% 0%,
    97.1875% 0%,
    100% 2.8125vw,
    100% 100%,
    0% 100%,
    0% 0%
  );
  overflow: hidden;
  pointer-events: none;
  position: fixed;
  top: -2.8125vw;
  left: 0;
  height: -webkit-calc(100% + 5.625vw);
  height: calc(100% + 5.625vw);
  -webkit-transform: translate3d(0, 2.8125vw, 0);
  transform: translate3d(0, 2.8125vw, 0);
  -webkit-transition: -webkit-transform 0.7s ease;
  transition: -webkit-transform 0.7s ease;
  -o-transition: transform 0.7s ease;
  transition: transform 0.7s ease;
  transition: transform 0.7s ease, -webkit-transform 0.7s ease;
  width: 100%;
}

.slide-intro-bg-image {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  -webkit-transform: scale(1.75);
  -ms-transform: scale(1.75);
  transform: scale(1.75);
}

.fp-viewing-1 .slide-intro-bg-image {
  opacity: 1;
  -webkit-transition: opacity 0s linear 0.7s;
  -o-transition: opacity 0s linear 0.7s;
  transition: opacity 0s linear 0.7s;
}

.fp-viewing-2 .slide-intro-bg-image {
  -webkit-animation: slide-1-to-2 0.7s ease forwards;
  animation: slide-1-to-2 0.7s ease forwards;
  opacity: 0;
  -webkit-transition: opacity 0s linear 0.7s;
  -o-transition: opacity 0s linear 0.7s;
  transition: opacity 0s linear 0.7s;
}

.fp-moving-from-2-to-1 .slide-intro-bg-image {
  -webkit-animation: slide-2-to-1 0.7s ease forwards;
  animation: slide-2-to-1 0.7s ease forwards;
  opacity: 0;
  -webkit-transition: opacity 0s linear 0.7s;
  -o-transition: opacity 0s linear 0.7s;
  transition: opacity 0s linear 0.7s;
}

@media (max-width: 1024px) {
  .slide-intro-bg-image {
    -webkit-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
  }

  .fp-viewing-2 .slide-intro-bg-image {
    -webkit-animation: slide-1-to-2-tablet 0.7s ease forwards;
    animation: slide-1-to-2-tablet 0.7s ease forwards;
  }

  .fp-moving-from-2-to-1 .slide-intro-bg-image {
    -webkit-animation: slide-2-to-1-tablet 0.7s ease forwards;
    animation: slide-2-to-1-tablet 0.7s ease forwards;
  }
}

@media (max-width: 767.98px) {
  .slide-intro-bg-image {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
  }

  .fp-viewing-2 .slide-intro-bg-image {
    -webkit-animation: slide-1-to-2-phone 0.7s ease forwards;
    animation: slide-1-to-2-phone 0.7s ease forwards;
  }

  .fp-moving-from-2-to-1 .slide-intro-bg-image {
    -webkit-animation: slide-2-to-1-phone 0.7s ease forwards;
    animation: slide-2-to-1-phone 0.7s ease forwards;
  }
}

.fp-viewing-1 .slide-intro-bg,
.fp-viewing-2 .slide-intro-bg {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.story-intro {
  z-index: 1;
}

.story-intro-bg-image {
  opacity: 1;
  -webkit-transform: scale(1.75);
  -ms-transform: scale(1.75);
  transform: scale(1.75);
}

.fp-viewing-1 .story-intro-bg-image {
  opacity: 0;
  -webkit-transition: opacity 0s linear 0.7s;
  -o-transition: opacity 0s linear 0.7s;
  transition: opacity 0s linear 0.7s;
}

.fp-viewing-2 .story-intro-bg-image,
.fp-viewing-3 .story-intro-bg-image {
  opacity: 0;
}

.fp-moving-from-2-to-1 .story-intro-bg-image {
  opacity: 1;
  -webkit-transition: opacity 0s linear 0.7s;
  -o-transition: opacity 0s linear 0.7s;
  transition: opacity 0s linear 0.7s;
}

.story-intro-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  position: relative;
  z-index: 1;
}

.story-intro-center {
  position: relative;
}

.story-intro-center h1 {
  position: relative;
  text-align: center;
  max-width: 68.9583333333vw;
}

.story-intro-center h1::before {
  background: url("../Image/story-intro-left.svg");
  background-position: 0% 50%;
  background-repeat: no-repeat;
  background-size: auto 100%;
  content: "";
  height: 0.46875vw;
  bottom: 7.7083333333vw;
  left: 2.8125vw;
  position: absolute;
  right: 0;
}

.story-intro-center h1::after {
  background: url("../Image/story-intro-right.svg");
  background-position: 100% 50%;
  background-repeat: no-repeat;
  background-size: auto 100%;
  content: "";
  height: 0.625vw;
  bottom: 7.8911458333vw;
  right: 2.8125vw;
  position: absolute;
  left: 0;
}

.story-intro-center h1 span::before {
  background: url("../Image/story-intro-circle.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: auto 100%;
  content: "";
  height: 5.5729166667vw;
  bottom: 5.1vw;
  left: -2.66vw;
  position: absolute;
  width: 5.5729166667vw;
}

.story-intro-center h1 span::after {
  background: #fff;
  content: "";
  opacity: 0;
  position: absolute;
  margin-left: 0.5vw;
  margin-top: 0.7vw;
  height: 5.7291666667vw;
  width: 0.8333333333vw;
}

.fp-enabled .story-intro-center h1::before,
.fp-enabled .story-intro-center h1::after {
  opacity: 0;
  -webkit-transition: opacity 0.35s ease-in-out;
  -o-transition: opacity 0.35s ease-in-out;
  transition: opacity 0.35s ease-in-out;
}

.fp-enabled .story-intro-center h1 span::before {
  opacity: 0;
  -webkit-transition: opacity 0.35s ease-in-out;
  -o-transition: opacity 0.35s ease-in-out;
  transition: opacity 0.35s ease-in-out;
}

.fp-enabled .story-intro-center h1 span::after {
  -webkit-animation: blink 1s ease infinite;
  animation: blink 1s ease infinite;
  opacity: 1;
}

.fp-viewing-2 .story-intro-center h1 span::after {
  -webkit-animation: none;
  animation: none;
  opacity: 0;
}

.story-intro-center h1.ready::before,
.story-intro-center h1.ready::after {
  opacity: 1;
}

.story-intro-center h1.ready span::before {
  opacity: 1;
}

.story-intro-center h1.ready span::after {
  -webkit-animation: none;
  animation: none;
  opacity: 0;
}

.story-intro-center a {
  position: absolute;
  left: 50%;
  margin-top: 4.8958333333vw;
  -webkit-transform: translate3d(-50%, 0, 0);
  transform: translate3d(-50%, 0, 0);
}

.fp-enabled .story-intro-center button {
  display: none;
}

.fp-enabled .story-intro-center {
  opacity: 0;
  -webkit-transition: opacity 0s linear 0.75s;
  -o-transition: opacity 0s linear 0.75s;
  transition: opacity 0s linear 0.75s;
}

.fp-viewing-1 .story-intro-center {
  opacity: 1;
  -webkit-transition: opacity 0s linear 0.75s;
  -o-transition: opacity 0s linear 0.75s;
  transition: opacity 0s linear 0.75s;
}

@media (max-width: 1024px) {
  .story-intro-bg-image {
    -webkit-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
  }

  .story-intro-center h1 {
    max-width: 674px;
  }

  .story-intro-center h1::before {
    height: 6px;
    bottom: 109px;
    left: 109px;
  }

  .story-intro-center h1::after {
    display: none;
  }

  .story-intro-center h1 span::before {
    height: 79px;
    bottom: 73px;
    left: 31px;
    width: 79px;
  }

  .story-intro-center h1 span::after {
    margin-left: 8px;
    margin-top: 10px;
    height: 84px;
    width: 10px;
  }

  .story-intro-center a {
    margin-top: 100px;
  }
}

@media (max-width: 767.98px) {
  .story-intro-bg-image {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
  }

  .story-intro-center h1 {
    max-width: 292px;
  }

  .story-intro-center h1::before {
    height: 3px;
    bottom: 59px;
    left: 24px;
  }

  .story-intro-center h1::after {
    display: block;
    height: 4px;
    bottom: 60px;
    left: auto;
    right: 4px;
    width: 50px;
  }

  .story-intro-center h1 span::before {
    height: 24px;
    bottom: 49px;
    left: 0;
    width: 24px;
  }

  .story-intro-center h1 span::after {
    margin-left: 6px;
    margin-top: 6px;
    height: 49px;
    width: 5px;
  }

  .story-intro-center a {
    margin-top: 75px;
  }
}

.story-slide {
  position: relative;
}

.story-slide p {
  position: relative;
  z-index: 1;
}

.story-slide-bg {
  -webkit-clip-path: polygon(
    0% 1.9791666667vw,
    5.78125% 1.9791666667vw,
    6.6145833333% 2.8125vw,
    18.4895833333% 2.8125vw,
    20.5729166667% 1.3020833333vw,
    36.9270833333% 1.3020833333vw,
    37.65625% 0.5729166667vw,
    54.6354166667% 0.5729166667vw,
    55.625% 1.5625vw,
    69.6875% 1.5625vw,
    71.25% 0%,
    97.1875% 0%,
    100% 2.8125vw,
    100% 100%,
    0% 100%,
    0% 0%
  );
  clip-path: polygon(
    0% 1.9791666667vw,
    5.78125% 1.9791666667vw,
    6.6145833333% 2.8125vw,
    18.4895833333% 2.8125vw,
    20.5729166667% 1.3020833333vw,
    36.9270833333% 1.3020833333vw,
    37.65625% 0.5729166667vw,
    54.6354166667% 0.5729166667vw,
    55.625% 1.5625vw,
    69.6875% 1.5625vw,
    71.25% 0%,
    97.1875% 0%,
    100% 2.8125vw,
    100% 100%,
    0% 100%,
    0% 0%
  );
  overflow: hidden;
  position: absolute;
  top: -2.8125vw;
  left: 0;
  height: -webkit-calc(100% + 5.625vw);
  height: calc(100% + 5.625vw);
  width: 100%;
  -webkit-transform: translate3d(0, 2.8125vw, 0);
  transform: translate3d(0, 2.8125vw, 0);
  -webkit-transition: -webkit-transform 0.7s ease;
  transition: -webkit-transform 0.7s ease;
  -o-transition: transform 0.7s ease;
  transition: transform 0.7s ease;
  transition: transform 0.7s ease, -webkit-transform 0.7s ease;
}

.story-slide-bg-image {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}

.story-slide.active .story-slide-bg {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.story-slide-1-bg-image {
  opacity: 0;
}

.fp-viewing-2 .story-slide-1-bg-image {
  opacity: 1;
  -webkit-transition: opacity 0s linear 0.7s;
  -o-transition: opacity 0s linear 0.7s;
  transition: opacity 0s linear 0.7s;
}

.fp-viewing-3 .story-slide-1-bg-image {
  opacity: 1;
}

.fp-moving-from-2-to-1 .story-slide-1-bg-image {
  opacity: 0;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
}

@-webkit-keyframes slide-1-to-2 {
  0% {
    opacity: 1;
    -webkit-transform: scale(1.75);
    transform: scale(1.75);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes slide-1-to-2 {
  0% {
    opacity: 1;
    -webkit-transform: scale(1.75);
    transform: scale(1.75);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes slide-2-to-1 {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1.75);
    transform: scale(1.75);
  }
}

@keyframes slide-2-to-1 {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1.75);
    transform: scale(1.75);
  }
}

@-webkit-keyframes slide-1-to-2-tablet {
  0% {
    opacity: 1;
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes slide-1-to-2-tablet {
  0% {
    opacity: 1;
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes slide-2-to-1-tablet {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
}

@keyframes slide-2-to-1-tablet {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
}

@-webkit-keyframes slide-1-to-2-phone {
  0% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes slide-1-to-2-phone {
  0% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes slide-2-to-1-phone {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}

@keyframes slide-2-to-1-phone {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}

@-webkit-keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
}

.story-next-button {
  position: fixed;
  bottom: 2.03125vw;
  right: 2.03125vw;
  opacity: 0;
  pointer-events: none;
  text-decoration: none;
  -webkit-transition: opacity 0.25s ease-in-out;
  -o-transition: opacity 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out;
  z-index: 10;
}

.fp-viewing-1 .story-next-button,
.fp-viewing-2 .story-next-button,
.fp-viewing-3 .story-next-button,
.fp-viewing-4 .story-next-button,
.fp-viewing-5 .story-next-button,
.fp-viewing-6 .story-next-button {
  opacity: 1;
  pointer-events: auto;
  -webkit-transition: opacity 0.25s ease-in-out 0.7s;
  -o-transition: opacity 0.25s ease-in-out 0.7s;
  transition: opacity 0.25s ease-in-out 0.7s;
}

@media (max-width: 1024px) {
  .story-next-button {
    bottom: 24px;
    right: 24px;
  }
}

@media (max-width: 428px) {
  .story-next-button {
    display: none;
  }
}

.story-text {
  color: #fff;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  left: 12.7604166667vw;
  top: 3.4375vw;
  max-width: 64.5833333333vw;
  -webkit-transition: opacity 0.25s ease-in-out;
  -o-transition: opacity 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out;
  z-index: 9;
}

.story-text-1,
.story-text-2,
.story-text-3,
.story-text-4,
.story-text-5 {
  position: absolute;
  pointer-events: none;
  opacity: 0;
  -webkit-transition: opacity 0.35s ease;
  -o-transition: opacity 0.35s ease;
  transition: opacity 0.35s ease;
}

.fp-viewing-2 .story-text .story-text-1 {
  opacity: 1;
  -webkit-transition: opacity 0s linear 0.7s;
  -o-transition: opacity 0s linear 0.7s;
  transition: opacity 0s linear 0.7s;
}

.fp-viewing-2 .story-text .story-text-1 h3 {
  opacity: 1;
  -webkit-transition: opacity 0s linear 0.7s;
  -o-transition: opacity 0s linear 0.7s;
  transition: opacity 0s linear 0.7s;
}

.fp-viewing-3 .story-text .story-text-2 {
  opacity: 1;
  -webkit-transition: opacity 0s linear 0.7s;
  -o-transition: opacity 0s linear 0.7s;
  transition: opacity 0s linear 0.7s;
}

.fp-viewing-3 .story-text .story-text-2 h3 {
  opacity: 1;
  -webkit-transition: opacity 0s linear 0.7s;
  -o-transition: opacity 0s linear 0.7s;
  transition: opacity 0s linear 0.7s;
}

.fp-viewing-4 .story-text .story-text-3 {
  opacity: 1;
  -webkit-transition: opacity 0s linear 0.7s;
  -o-transition: opacity 0s linear 0.7s;
  transition: opacity 0s linear 0.7s;
}

.fp-viewing-4 .story-text .story-text-3 h3 {
  opacity: 1;
  -webkit-transition: opacity 0s linear 0.7s;
  -o-transition: opacity 0s linear 0.7s;
  transition: opacity 0s linear 0.7s;
}

.fp-viewing-5 .story-text .story-text-4 {
  opacity: 1;
  -webkit-transition: opacity 0s linear 0.7s;
  -o-transition: opacity 0s linear 0.7s;
  transition: opacity 0s linear 0.7s;
}

.fp-viewing-5 .story-text .story-text-4 h3 {
  opacity: 1;
  -webkit-transition: opacity 0s linear 0.7s;
  -o-transition: opacity 0s linear 0.7s;
  transition: opacity 0s linear 0.7s;
}

.fp-viewing-6 .story-text .story-text-5 {
  opacity: 1;
  -webkit-transition: opacity 0s linear 0.7s;
  -o-transition: opacity 0s linear 0.7s;
  transition: opacity 0s linear 0.7s;
}

.fp-viewing-6 .story-text .story-text-5 h3 {
  opacity: 1;
  -webkit-transition: opacity 0s linear 0.7s;
  -o-transition: opacity 0s linear 0.7s;
  transition: opacity 0s linear 0.7s;
}

.fp-viewing-2 .story-text,
.fp-viewing-3 .story-text,
.fp-viewing-4 .story-text,
.fp-viewing-5 .story-text,
.fp-viewing-6 .story-text {
  opacity: 1;
  -webkit-transition: opacity 0s linear 0.705s;
  -o-transition: opacity 0s linear 0.705s;
  transition: opacity 0s linear 0.705s;
}

.story-text h3 {
  display: inline-block;
  opacity: 0;
  position: relative;
  -webkit-transition: opacity 0.7s ease;
  -o-transition: opacity 0.7s ease;
  transition: opacity 0.7s ease;
}

.story-text h3::after {
  background: url("../Image/story-heading.svg");
  background-position: 0% 50%;
  background-repeat: no-repeat;
  background-size: auto 100%;
  content: "";
  height: 1.25vw;
  position: absolute;
  left: 0;
  right: 0;
  top: 90%;
}

.story-text p {
  margin-bottom: 1.875vw;
  margin-left: 2.7083333333vw;
  margin-top: 2.0833333333vw;
  width: 33.1770833333vw;
  opacity: 0;
  pointer-events: none;
  -webkit-transform: translate3d(-2.7083333333vw, 0, 0);
  transform: translate3d(-2.7083333333vw, 0, 0);
  -webkit-transition: opacity 0.7s ease, -webkit-transform 0s ease 0.7s;
  transition: opacity 0.7s ease, -webkit-transform 0s ease 0.7s;
  -o-transition: opacity 0.7s ease, transform 0s ease 0.7s;
  transition: opacity 0.7s ease, transform 0s ease 0.7s;
  transition: opacity 0.7s ease, transform 0s ease 0.7s,
    -webkit-transform 0s ease 0.7s;
}

.story-text p:last-child {
  margin-bottom: 0;
}

.story-text .ready p {
  padding-right: 0;
  box-sizing: border-box;
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: opacity 0.35s ease-out, -webkit-transform 0.35s ease-out;
  transition: opacity 0.35s ease-out, -webkit-transform 0.35s ease-out;
  -o-transition: opacity 0.35s ease-out, transform 0.35s ease-out;
  transition: opacity 0.35s ease-out, transform 0.35s ease-out;
  transition: opacity 0.35s ease-out, transform 0.35s ease-out,
    -webkit-transform 0.35s ease-out;
}

@media (max-width: 767px) {
  .story-text .ready p {
    padding-right: 20px;
  }
}

@media (max-width: 1024px) {
  .story-text {
    left: 166px;
    top: 111px;
    max-width: 433px;
  }

  .story-text h3::after {
    height: 21px;
  }

  .story-text p {
    margin-bottom: 28px;
    margin-left: 30px;
    margin-top: 30px;
    width: 393px;
    -webkit-transform: translate3d(-30px, 0, 0);
    transform: translate3d(-30px, 0, 0);
  }
}

@media screen and (max-width: 1024px), screen and (min-height: 500px) {
  .story-text {
    left: 166px;
    top: 3.4375vw;
    max-width: 433px;
  }
}

@media screen and (max-width: 767.98px), screen and (min-height: 500px) {
  .story-text {
    left: 166px;
    top: 3.4375vw;
    max-width: 433px;
  }
}

@media (max-height: 420px) {
  .story-text p {
    font-size: 14px;
  }
}

@media (max-width: 767.98px) {
  .story-text {
    left: 78px;
    /* top: 88px; */
    max-width: none;
  }

  .story-text h3::after {
    height: 12px;
  }

  .story-text p {
    margin-left: 26px;
    margin-top: 22px;
    width: -webkit-calc(100vw - 140px);
    width: calc(100vw - 140px);
    -webkit-transform: translate3d(-26px, 0, 0);
    transform: translate3d(-26px, 0, 0);
  }
}

.content {
  background-color: #000;
  overflow: hidden;
  position: relative;
  z-index: 2;
}

.content::before {
  background-attachment: fixed;
  background-image: url("../Image/content-bg-blue.svg");
  background-position: 50% 0%;
  background-repeat: repeat-y;
  background-size: 100% auto;
  content: "";
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: opacity 1.5s ease-out;
  -o-transition: opacity 1.5s ease-out;
  transition: opacity 1.5s ease-out;
}

.content .container {
  position: relative;
}

.content.yellow::before {
  background-image: url("../Image/content-bg-yellow.svg");
}

.content.visible::before {
  opacity: 1;
}

.description {
  padding-bottom: 12.7604166667vw;
  padding-top: 14.4270833333vw;
  position: relative;
}

.description::before {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(black),
    color-stop(10%, black),
    to(rgba(0, 0, 0, 0))
  );
  background: -o-linear-gradient(
    top,
    black 0%,
    black 10%,
    rgba(0, 0, 0, 0) 100%
  );
  background: linear-gradient(
    180deg,
    black 0%,
    black 10%,
    rgba(0, 0, 0, 0) 100%
  );
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 36.1458333333vw;
}

.description-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-left: -25px;
  margin-right: -25px;
}

.description-row h2 {
  margin-bottom: 2.5vw;
  margin-left: 25px;
  margin-right: 25px;
  position: relative;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 -webkit-calc(66.6666667% - 50px);
  -ms-flex: 0 0 calc(66.6666667% - 50px);
  flex: 0 0 calc(66.6666667% - 50px);
}

.description-row h2::after {
  background-image: url("../Image/h2-after.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  position: absolute;
  bottom: -5.0520833333vw;
  left: 24.21875vw;
  height: 12.1354166667vw;
  width: 50.78125vw;
}

.description-row p {
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  margin-left: 25px;
  margin-right: 25px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 -webkit-calc(33.33333333% - 50px);
  -ms-flex: 0 0 calc(33.33333333% - 50px);
  flex: 0 0 calc(33.33333333% - 50px);
  position: relative;
}

@media (max-width: 1024px) {
  .description {
    padding-bottom: 210px;
    padding-top: 114px;
  }

  .description-row {
    display: block;
    margin-left: 0;
    margin-right: 0;
  }

  .description-row h2 {
    margin-bottom: 38px;
    margin-left: 0;
    margin-right: 0;
    width: 570px;
  }

  .description-row h2::after {
    display: none;
  }

  .description-row p {
    margin-left: auto;
    margin-right: 0;
    width: 373px;
  }

  .description-row p::after {
    background-image: url("../Image/h2-after.svg");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    position: absolute;
    bottom: -64px;
    right: 103.5px;
    height: 175px;
    width: 733px;
  }
}

@media (max-width: 767.98px) {
  .description {
    padding-bottom: 160px;
    padding-top: 72px;
  }

  .description-row h2 {
    margin-bottom: 40px;
    width: auto;
  }

  .description-row h2::after {
    display: block;
    bottom: -79px;
    left: auto;
    right: -137px;
    height: 73px;
    width: 304px;
  }

  .description-row p {
    width: auto;
    margin-left: 20px;
    padding-right: 70px;
    box-sizing: border-box;
  }

  .description-row p::after {
    display: none;
  }
}

.modes__item {
  padding-bottom: 11.7708333333vw;
  padding-top: 7.5520833333vw;
  margin-left: -25px;
  margin-right: -25px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.modes__item-content {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 -webkit-calc(50% - 50px);
  -ms-flex: 0 0 calc(50% - 50px);
  flex: 0 0 calc(50% - 50px);
  margin-left: 25px;
  margin-right: 25px;
  position: relative;
}

.modes__item-content h3 {
  line-height: 1;
  position: absolute;
  bottom: 12.2916666667vw;
}

.modes__item-content p {
  margin-left: -webkit-calc(20% + 10px);
  margin-left: calc(20% + 10px);
  margin-right: -webkit-calc(20% + 10px);
  margin-right: calc(20% + 10px);
  margin-top: -9.1666666667vw;
  position: absolute;
}

.modes__item-image {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 -webkit-calc(50% - 50px);
  -ms-flex: 0 0 calc(50% - 50px);
  flex: 0 0 calc(50% - 50px);
  margin-left: 25px;
  margin-right: 25px;
  position: relative;
}

.modes__item-image::before {
  background-image: url("../Image/modes-item-right.svg");
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  height: 6.25vw;
  bottom: 7.7083333333vw;
  position: absolute;
  pointer-events: none;
  right: 100%;
  width: -webkit-calc(100% + 51px);
  width: calc(100% + 51px);
}

.modes__item-image-clip {
  -webkit-clip-path: polygon(
    0% 0%,
    54.25% 0%,
    54.25% 0.6vw,
    89.9094437257% 0.6vw,
    100% 5.2083333333vw,
    100% 100%,
    7.7083333333vw 100%,
    0% -webkit-calc(100% - 7.7083333333vw)
  );
  clip-path: polygon(
    0% 0%,
    54.25% 0%,
    54.25% 0.6vw,
    89.9094437257% 0.6vw,
    100% 5.2083333333vw,
    100% 100%,
    7.7083333333vw 100%,
    0% calc(100% - 7.7083333333vw)
  );
  position: relative;
}

.modes__item-image-clip::before {
  background-image: url("../Image/multiplayer-mode-border.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modes__item-image img {
  display: block;
  height: auto;
  width: 100%;
}

.modes__item.modes__item-left {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.modes__item.modes__item-left .modes__item-content {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 -webkit-calc(41.6666666667% - 50px);
  -ms-flex: 0 0 calc(41.6666666667% - 50px);
  flex: 0 0 calc(41.6666666667% - 50px);
}

.modes__item.modes__item-left .modes__item-content h3 {
  bottom: 15.4166666667vw;
}

.modes__item.modes__item-left .modes__item-content p {
  margin-right: 0;
  margin-top: -12.2916666667vw;
}

.modes__item.modes__item-left .modes__item-image {
  margin-top: 2.7083333333vw;
}

.modes__item.modes__item-left .modes__item-image::before {
  background-image: url("../Image/modes-item-left.svg");
  background-position: 0% 100%;
  height: 11.1171875vw;
  bottom: 6.1vw;
  left: 100%;
  right: auto;
}

.modes__item.modes__item-left .modes__item-image-clip {
  -webkit-clip-path: polygon(
    0% 6vw,
    14.5% 0%,
    33.1% 0%,
    38.1% 0.9vw,
    72.2222222222% 0.9vw,
    72.2222222222% 0.31vw,
    100% 0.31vw,
    100% -webkit-calc(100% - 6vw),
    -webkit-calc(100% - 6.875vw) 100%,
    0% 100%
  );
  clip-path: polygon(
    0% 6vw,
    14.5% 0%,
    33.1% 0%,
    38.1% 0.9vw,
    72.2222222222% 0.9vw,
    72.2222222222% 0.31vw,
    100% 0.31vw,
    100% calc(100% - 6vw),
    calc(100% - 6.875vw) 100%,
    0% 100%
  );
}

.modes__item.modes__item-left .modes__item-image-clip::before {
  background-image: url("../Image/adventure-mode-border.svg");
}

@media (max-width: 1024px) {
  .modes__item {
    padding-bottom: 0;
    padding-top: 0;
    margin-bottom: 183px;
    margin-left: 0;
    margin-right: 0;
    display: block;
    position: relative;
  }

  .modes__item-content {
    margin-left: 0;
    margin-right: 0;
    position: static;
  }

  .modes__item-content h3 {
    position: relative;
    bottom: auto;
    max-width: none;
  }

  .modes__item-content p {
    bottom: auto;
    margin-left: 0;
    margin-right: 0;
    margin-top: 79px;
    max-width: 30.078125vw;
  }

  .modes__item-image {
    margin-left: auto;
    margin-right: 0;
    margin-top: 28px;
    width: 52.6041666667vw;
  }

  .modes__item-image::before {
    bottom: 10vw;
    height: 7.6822916667vw;
    width: 57.03125vw;
  }

  .modes__item-image-clip {
    -webkit-clip-path: polygon(
      0% 0%,
      54.6% 0%,
      54.6% 0.775vw,
      89.9094437257% 0.775vw,
      100% 5.2083333333vw,
      100% 100%,
      10vw 100%,
      0% -webkit-calc(100% - 10vw)
    );
    clip-path: polygon(
      0% 0%,
      54.6% 0%,
      54.6% 0.775vw,
      89.9094437257% 0.775vw,
      100% 5.2083333333vw,
      100% 100%,
      10vw 100%,
      0% calc(100% - 10vw)
    );
  }

  .modes__item.modes__item-left .modes__item-content h3 {
    bottom: auto;
    margin-left: auto;
    text-align: right;
    max-width: none;
  }

  .modes__item.modes__item-left .modes__item-content p {
    bottom: auto;
    right: 0;
    margin-top: 59px;
    max-width: 30.078125vw;
  }

  .modes__item.modes__item-left .modes__item-image {
    margin-left: 0;
    margin-top: 28px;
  }

  .modes__item.modes__item-left .modes__item-image::before {
    height: 161px;
    width: 550px;
  }

  .modes__item.modes__item-left .modes__item-image-clip {
    -webkit-clip-path: polygon(
      0% 7.75vw,
      14.5% 0%,
      33.25% 0%,
      38.05% 1.125vw,
      72.3% 1.125vw,
      72.3% 0.35vw,
      100% 0.35vw,
      100% -webkit-calc(100% - 6vw),
      -webkit-calc(100% - 6.875vw) 100%,
      0% 100%
    );
    clip-path: polygon(
      0% 7.75vw,
      14.5% 0%,
      33.25% 0%,
      38.05% 1.125vw,
      72.3% 1.125vw,
      72.3% 0.35vw,
      100% 0.35vw,
      100% calc(100% - 6vw),
      calc(100% - 6.875vw) 100%,
      0% 100%
    );
  }
}

@media (max-width: 896px) {
  .modes__item {
    margin-bottom: 197px;

  }

  .modes h3{
    font-size: 55px;
  }
  .modes__item-content h3 {
    position: relative;
    bottom: 66px;
    max-width: 500px;
  }

  .modes__item-content p {
    bottom: -70px;
    margin-top: 0;
    max-width: 33.8541666667vw;
  }

  .modes__item-image {
    margin-top: -42px;
  }

  .modes__item.modes__item-left .modes__item-content h3 {
    margin-bottom: 36px;
    margin-left: -webkit-calc(30vw - 48px);
    margin-left: calc(30vw - 48px);
    text-align: left;
    max-width: 435px;
  }

  .modes__item.modes__item-left .modes__item-content p {
    bottom: auto;
    margin-left: -webkit-calc(45vw - 48px);
    margin-left: calc(45vw - 48px);
    margin-top: 0;
    position: relative;
    right: auto;
    max-width: none;
  }

  .modes__item.modes__item-left .modes__item-image {
    margin-top: 37px;
  }

  .modes__item.modes__item-left .modes__item-image::before {
    bottom: -webkit-calc(100% - 1px);
    bottom: calc(100% - 1px);
    left: 13.9534883721%;
  }
}

@media (max-width: 767.98px) {
  .modes__item {
    margin-bottom: 160px;
  }

  .modes__item-content h3 {
    margin-bottom: 40px;
    max-width: 260px;
  }

  .modes__item-content h3::after {
    background-image: url("../Image/modes-item-right.svg");
    background-position: 0% 100%;
    background-repeat: no-repeat;
    background-size: auto 100%;
    content: "";
    pointer-events: none;
    position: absolute;
    left: -26px;
    top: 95%;
    height: 56px;
    width: 390px;
  }

  .modes__item-content p {
    margin-left: 20px;
    margin-right: 20px;
    position: relative;
    max-width: none;
  }

  .modes__item-image {
    margin-left: -20px;
    margin-right: -20px;
    margin-top: 40px;
    width: auto;
  }

  .modes__item-image::before {
    display: none;
  }

  .modes__item-image-clip {
    -webkit-clip-path: polygon(
      0.5px 0%,
      54.6% 0%,
      54.6% 1.5vw,
      90.25% 1.5vw,
      100% 10vw,
      100% 100%,
      19.5vw 100%,
      0.5px -webkit-calc(100% - 19.5vw)
    );
    clip-path: polygon(
      0.5px 0%,
      54.6% 0%,
      54.6% 1.5vw,
      90.25% 1.5vw,
      100% 10vw,
      100% 100%,
      19.5vw 100%,
      0.5px calc(100% - 19.5vw)
    );
  }

  .modes__item.modes__item-left .modes__item-content h3 {
    margin-bottom: 40px;
    margin-left: 0;
    max-width: 260px;
  }

  .modes__item.modes__item-left .modes__item-content h3::after {
    background-image: url("../Image/modes-item-left.svg");
    left: -82px;
    top: 85%;
    height: 109px;
    width: 373px;
  }

  .modes__item.modes__item-left .modes__item-content p {
    margin-left: 20px;
  }

  .modes__item.modes__item-left .modes__item-image {
    margin-left: -20px;
    margin-top: 40px;
  }

  .modes__item.modes__item-left .modes__item-image-clip {
    -webkit-clip-path: polygon(
      0.5px 15vw,
      14.5% 0%,
      33.25% 0%,
      38.05% 2.25vw,
      72.3% 2.25vw,
      72.3% 0.7875vw,
      100% 0.7875vw,
      100% -webkit-calc(100% - 6vw),
      -webkit-calc(100% - 6.875vw) 100%,
      0.5px 100%
    );
    clip-path: polygon(
      0.5px 15vw,
      14.5% 0%,
      33.25% 0%,
      38.05% 2.25vw,
      72.3% 2.25vw,
      72.3% 0.7875vw,
      100% 0.7875vw,
      100% calc(100% - 6vw),
      calc(100% - 6.875vw) 100%,
      0.5px 100%
    );
  }
}

.nfts {
  background-image: url("../Image/nfts-bg.webp");
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  padding-bottom: 42.8645833333vw;
  position: relative;
}

.nfts-content {
  position: relative;
}

.nfts-content h2 {
  margin-bottom: 4.9479166667vw;
  margin-left: -webkit-calc(4.5572916667vw + 50px);
  margin-left: calc(4.5572916667vw + 50px);
  position: relative;
}

.nfts-content h2::before {
  background-image: url("../Image/nfts-h3.svg");
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  height: 3.6458333333vw;
  pointer-events: none;
  position: absolute;
  bottom: -0.75vw;
  left: -15.8333333333vw;
  width: 42.5520833333vw;
}

.nfts-top {
  margin-bottom: 1.25vw;
  margin-left: -webkit-calc((4.5572916667vw + 50px) * 3);
  margin-left: calc((4.5572916667vw + 50px) * 3);
}

.nfts-top h3 {
  margin-bottom: 2.7604166667vw;
}

.nfts-top p {
  max-width: -webkit-calc(22.7864583333vw + 200px);
  max-width: calc(22.7864583333vw + 200px);
}

.nfts-bottom {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-left: -webkit-calc((4.5572916667vw + 50px) * 5);
  margin-left: calc((4.5572916667vw + 50px) * 5);
}

.nfts-bottom p {
  margin-bottom: 2.7604166667vw;
  max-width: -webkit-calc(22.7864583333vw + 200px);
  max-width: calc(22.7864583333vw + 200px);
}

.nfts-bottom a {
  margin-bottom: 0.9375vw;
}

.nfts-bottom a:last-child {
  margin-bottom: 0;
}

@media (max-width: 1024px) {
  .nfts {
    background-image: url("../Image/nfts-1024.webp");
    padding-top: 48px;
  }

  .nfts-content h2 {
    margin-bottom: 76px;
    margin-left: 0;
  }

  .nfts-content h2::before {
    height: 7vw;
    bottom: -18px;
    left: 14.25vw;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
    transform: scaleX(-1);
    width: 80vw;
  }

  .nfts-top {
    margin-bottom: 18px;
    margin-left: auto;
    margin-right: auto;
    width: 56.5104166667vw;
  }

  .nfts-top h3 {
    margin-bottom: 35px;
  }

  .nfts-top p {
    max-width: 435px;
  }

  .nfts-bottom {
    margin-left: auto;
    width: 56.5104166667vw;
  }

  .nfts-bottom p {
    margin-bottom: 35px;
    max-width: 435px;
  }

  .nfts-bottom a {
    margin-bottom: 10px;
  }
}

@media (max-width: 767.98px) {
  .nfts {
    background-image: url("../Image/nfts-768.webp");
    padding-bottom: 63.3333333333vw;
  }

  .nfts-content h2 {
    margin-bottom: 64px;
  }

  .nfts-content h2::before {
    left: 16vw;
  }

  .nfts-top {
    margin-bottom: 24px;
    margin-left: 0;
    margin-right: 0;
    width: auto;
  }

  .nfts-top p {
    max-width: none;
  }

  .nfts-bottom {
    margin-left: 0;
    width: auto;
  }

  .nfts-bottom p {
    margin-bottom: 50px;
    max-width: none;
  }

  .nfts-bottom a {
    margin-bottom: 15px;
  }
}

.land {
  margin-top: -11.2vw;
  overflow: hidden;
  position: relative;
}

.land-bg {
  background-color: #ffc328;
  background-image: url("../Image/land-bg.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-clip-path: polygon(
    0% 0.2083333333vw,
    23.1770833333% 0.2083333333vw,
    24.7916666667% 0.625vw,
    33.8541666667% 0.625vw,
    35.5208333333% 0%,
    42.96875% 0%,
    47.0833333333% 5.8333333333vw,
    61.25% 5.8333333333vw,
    61.71875% 6.1458333333vw,
    67.2395833333% 6.1458333333vw,
    74.7916666667% 9.84375vw,
    77.6041666667% 9.84375vw,
    78.125% 10.4166666667vw,
    85.1041666667% 10.4166666667vw,
    86.71875% 9.0104166667vw,
    100% 9.0104166667vw,
    100% -webkit-calc(100% - 8.3333333333vw),
    86.09375% -webkit-calc(100% - 8.3333333333vw),
    83.8020833333% -webkit-calc(100% - 9.4791666667vw),
    54.0625% -webkit-calc(100% - 9.4791666667vw),
    48.59375% -webkit-calc(100% - 2.8125vw),
    25.5208333333% -webkit-calc(100% - 2.8125vw),
    23.6458333333% -webkit-calc(100% - 1.0416666667vw),
    19.3229166667% -webkit-calc(100% - 1.0416666667vw),
    18.3333333333% 100%,
    0% 100%
  );
  clip-path: polygon(
    0% 0.2083333333vw,
    23.1770833333% 0.2083333333vw,
    24.7916666667% 0.625vw,
    33.8541666667% 0.625vw,
    35.5208333333% 0%,
    42.96875% 0%,
    47.0833333333% 5.8333333333vw,
    61.25% 5.8333333333vw,
    61.71875% 6.1458333333vw,
    67.2395833333% 6.1458333333vw,
    74.7916666667% 9.84375vw,
    77.6041666667% 9.84375vw,
    78.125% 10.4166666667vw,
    85.1041666667% 10.4166666667vw,
    86.71875% 9.0104166667vw,
    100% 9.0104166667vw,
    100% calc(100% - 8.3333333333vw),
    86.09375% calc(100% - 8.3333333333vw),
    83.8020833333% calc(100% - 9.4791666667vw),
    54.0625% calc(100% - 9.4791666667vw),
    48.59375% calc(100% - 2.8125vw),
    25.5208333333% calc(100% - 2.8125vw),
    23.6458333333% calc(100% - 1.0416666667vw),
    19.3229166667% calc(100% - 1.0416666667vw),
    18.3333333333% 100%,
    0% 100%
  );
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.land-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 13.75vw;
  position: relative;
  margin: 0 -4.6875vw;
}

.land-text {
  color: #000;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-left: 4.6875vw;
  padding-bottom: 8.75vw;
}

.land-text h3 {
  line-height: 1;
  margin-bottom: 2.8125vw;
  position: relative;
}

.land-text h3::before {
  background-image: url("../Image/land-h3.svg");
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  position: absolute;
  height: 5vw;
  width: 40.78125vw;
  left: -21.25vw;
  top: -3.2291666667vw;
}

.land-text p {
  margin-left: -webkit-calc(4.5572916667vw + 50px);
  margin-left: calc(4.5572916667vw + 50px);
  margin-bottom: 1.25vw;
}

.land-text a {
  margin-left: -webkit-calc(4.5572916667vw + 50px);
  margin-left: calc(4.5572916667vw + 50px);
  margin-top: 1.5625vw;
}

.land-image {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 50%;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  margin-left: 50px;
}

.land-image img {
  display: block;
  height: auto;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  width: 100%;
}

@media (max-width: 1024px) {
  .land {
    margin-top: -12vw;
    overflow: visible;
  }

  .land-bg {
    bottom: 25.6510416667vw;
    -webkit-clip-path: polygon(
      0% 0.2083333333vw,
      23.1770833333% 0.2083333333vw,
      24.7916666667% 0.625vw,
      33.8541666667% 0.625vw,
      35.5208333333% 0%,
      42.96875% 0%,
      47.0833333333% 5.8333333333vw,
      61.25% 5.8333333333vw,
      61.71875% 6.1458333333vw,
      67.2395833333% 6.1458333333vw,
      74.7916666667% 9.84375vw,
      77.6041666667% 9.84375vw,
      78.125% 10.4166666667vw,
      85.1041666667% 10.4166666667vw,
      86.71875% 9.0104166667vw,
      100% 9.0104166667vw,
      100% -webkit-calc(100% - 17.578125vw),
      77.6041666667% -webkit-calc(100% - 17.578125vw),
      64.5833333333% 100%,
      0% 100%
    );
    clip-path: polygon(
      0% 0.2083333333vw,
      23.1770833333% 0.2083333333vw,
      24.7916666667% 0.625vw,
      33.8541666667% 0.625vw,
      35.5208333333% 0%,
      42.96875% 0%,
      47.0833333333% 5.8333333333vw,
      61.25% 5.8333333333vw,
      61.71875% 6.1458333333vw,
      67.2395833333% 6.1458333333vw,
      74.7916666667% 9.84375vw,
      77.6041666667% 9.84375vw,
      78.125% 10.4166666667vw,
      85.1041666667% 10.4166666667vw,
      86.71875% 9.0104166667vw,
      100% 9.0104166667vw,
      100% calc(100% - 17.578125vw),
      77.6041666667% calc(100% - 17.578125vw),
      64.5833333333% 100%,
      0% 100%
    );
  }

  .land-row {
    display: block;
    padding-bottom: 0;
    padding-top: 170px;
    margin: 0;
  }

  .land-text {
    margin-left: 0;
    padding-bottom: 52px;
  }

  .land-text h3 {
    margin-bottom: 35px;
    max-width: 400px;
  }

  .land-text h3::before {
    height: 72px;
    width: 591px;
    left: -313px;
    top: -35px;
  }

  .land-text p {
    margin-left: 120px;
    margin-right: 120px;
    margin-bottom: 18px;
  }

  .land-text a {
    margin-left: 120px;
    margin-top: 22px;
  }

  .land-image {
    margin-left: 0;
    width: 66.1458333333vw;
  }
  .gateIo {
    width: 282px;
    height: 61px;
    user-select: none;
  }
  .gateIo svg {
    width: 122px;
    height: 37px;
    user-select: none;
  }
}
@media only screen and (max-width: 450px) {
  .gateIo {
    width: 260px;
    height: 56px;
  }
  .gateIo svg {
    width: 112px;
    height: 34px;
  }
}

@media (max-width: 767.98px) {
  .land-bg {
    bottom: 48.0555555556vw;
  }

  .land-row {
    padding-top: 82px;
  }

  .land-text {
    padding-bottom: 26px;
  }

  .land-text h3 {
    max-width: 240px;
  }

  .land-text h3::before {
    height: 26px;
    width: 208px;
    left: -118px;
    top: -13px;
  }

  .land-text p {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 24px;
  }

  .land-text a {
    margin-left: 0;
    margin-top: 24px;
  }

  .land-image {
    width: 100%;
  }

  .land-image .js-land-image {
    display: none;
  }
}

.building {
  background: -o-radial-gradient(
    -10.44% 39.77%,
    35.47% 40.98%,
    rgba(92, 216, 255, 0.2) 0%,
    rgba(112, 221, 255, 0) 100%
  );
  background: radial-gradient(
    35.47% 40.98% at -10.44% 39.77%,
    rgba(92, 216, 255, 0.2) 0%,
    rgba(112, 221, 255, 0) 100%
  );
  padding-bottom: 9vw;
}

.building-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  margin-left: -25px;
  margin-right: -25px;
}

.building-content {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 -webkit-calc(41.6666666667% - 50px);
  -ms-flex: 0 0 calc(41.6666666667% - 50px);
  flex: 0 0 calc(41.6666666667% - 50px);
  margin-left: 25px;
  margin-right: 25px;
  padding-top: 11.7708333333vw;
}

.building-content h3 {
  line-height: 1;
  margin-bottom: 1.5625vw;
  position: relative;
}

.building-content h3::before {
  background-image: url("../Image/building.svg");
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  pointer-events: none;
  position: absolute;
  bottom: -0.8333333333vw;
  left: 0;
  height: 4.84375vw;
  width: 24.0104166667vw;
}

.building-content p {
  margin-left: -webkit-calc(20% + 10px);
  margin-left: calc(20% + 10px);
  position: relative;
}

.building-robot {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 -webkit-calc(58.3333333333% - 50px);
  -ms-flex: 0 0 calc(58.3333333333% - 50px);
  flex: 0 0 calc(58.3333333333% - 50px);
  margin-left: 25px;
  margin-right: 25px;
}

.building-robot-constructor {
  height: 0;
  padding-top: 128.8377192982%;
  position: relative;
}

.building-robot img {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  height: auto;
  width: 100%;
  -webkit-transition: opacity 0.25s ease-in-out;
  -o-transition: opacity 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out;
}

.building-robot img.selected {
  opacity: 1;
}

.building-robot-bullet {
  cursor: pointer;
  display: block;
  position: absolute;
  width: 10vw;
  margin-left: -5vw;
  margin-top: -5vw;
}

.building-robot-bullet svg {
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transform: scale(1) rotate(0deg);
  -ms-transform: scale(1) rotate(0deg);
  transform: scale(1) rotate(0deg);
  -webkit-transition: -webkit-transform 0.25s ease-in-out;
  transition: -webkit-transform 0.25s ease-in-out;
  -o-transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
  width: 100%;
}

.building-robot-bullet svg circle {
  -webkit-animation: pulse 2s ease-in-out infinite;
  animation: pulse 2s ease-in-out infinite;
  fill: #fff;
  -webkit-transition: fill 0.25s ease-in-out;
  -o-transition: fill 0.25s ease-in-out;
  transition: fill 0.25s ease-in-out;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

.building-robot-bullet svg path {
  stroke: #fff;
  -webkit-transition: stroke 0.25s ease-in-out;
  -o-transition: stroke 0.25s ease-in-out;
  transition: stroke 0.25s ease-in-out;
}

.building-robot-bullet span {
  color: #ffc328;
  font-family: Cold Warm, sans-serif;
  font-size: 1.0416666667vw;
  line-height: 120%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  opacity: 0;
  position: absolute;
  left: 100%;
  top: 50%;
  -webkit-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
  -webkit-transition: opacity 0.25s ease-in-out;
  -o-transition: opacity 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out;
  white-space: nowrap;
}

.building-robot-bullet-head {
  top: 12%;
  left: 42%;
}

.building-robot-bullet-head span {
  left: auto;
  right: 100%;
}

.building-robot-bullet-rarm {
  top: 27%;
  left: 63%;
}

.building-robot-bullet-larm {
  top: 25%;
  left: 18%;
}

.building-robot-bullet-larm span {
  left: auto;
  right: 100%;
}

.building-robot-bullet-body {
  top: 43%;
  left: 50%;
}

.building-robot-bullet-legs {
  top: 80%;
  left: 58%;
}

@media (any-pointer: fine) {
  .building-robot-bullet:hover svg {
    -webkit-transform: scale(1.1) rotate(-45deg);
    -ms-transform: scale(1.1) rotate(-45deg);
    transform: scale(1.1) rotate(-45deg);
  }

  .building-robot-bullet:hover svg circle {
    fill: #ffc328;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
  }

  .building-robot-bullet:hover svg path {
    stroke: #ffc328;
  }

  .building-robot-bullet:hover span {
    opacity: 1;
  }
}

.building-robot-sign {
  -webkit-animation: saturate 0.75s linear infinite;
  animation: saturate 0.75s linear infinite;
  background-image: url("../Image/click-here.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  pointer-events: none;
  height: 7.9166666667vw;
  top: 3vw;
  right: -1vw;
  width: 17.96875vw;
}

@media (max-width: 1024px) {
  .building {
    padding-bottom: 48px;
  }

  .building-content {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 -webkit-calc(50% - 50px);
    -ms-flex: 0 0 calc(50% - 50px);
    flex: 0 0 calc(50% - 50px);
    margin-left: 0;
    padding-top: 0;
  }

  .building-content h3 {
    margin-bottom: 67px;
  }

  .building-content h3::before {
    bottom: -33px;
    height: 93px;
    width: 461px;
  }

  .building-content p {
    margin-left: 48px;
  }

  .building-robot {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    margin-right: 0;
  }

  .building-robot-bullet {
    width: 110px;
    margin-left: -55px;
    margin-top: -55px;
  }

  .building-robot-bullet span {
    font-size: 12px;
  }

  .building-robot-sign {
    background-image: url("../Image/click-here-768.svg");
    height: 27.0833333333vw;
    top: -16vw;
    right: 25%;
    width: 23.4375vw;
  }
}
.fb-text{
  font-size: 24px;
}
@media (max-width: 1024px) {
  .fb-text{
    font-size: 18px !important;
  }
}
@media (max-width: 576px) {
  .fb-text{
    font-size: 16px !important;
  }
}


@media (max-width: 767.98px) {
  .building {
    padding-bottom: 40px;
  }

  .building-row {
    display: block;
    margin-left: 0;
    margin-right: 0;
  }

  .building-content {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 -webkit-calc(50% - 50px);
    -ms-flex: 0 0 calc(50% - 50px);
    flex: 0 0 calc(50% - 50px);
    margin-left: 0;
    padding-top: 0;
  }

  .building-content h3 {
    margin-bottom: 40px;
    max-width: 160px;
  }

  .building-content h3::before {
    bottom: -21px;
    left: -26px;
    height: 76px;
    width: 379px;
  }

  .building-content p {
    margin-left: 20px;
  }

  .building-robot {
    margin-left: 0;
    margin-top: 25vw;
  }

  .building-robot-bullet {
    width: 96px;
    margin-left: -48px;
    margin-top: -48px;
  }

  .building-robot-sign {
    background-image: url("../Image/click-here-360.svg");
    height: 56.3888888889vw;
    top: -20vw;
    right: 0;
    width: 44.7222222222vw;
  }
}

@-webkit-keyframes pulse {
  0%,
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }
}

@keyframes pulse {
  0%,
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }
}

@-webkit-keyframes saturate {
  0%,
  100% {
    -webkit-filter: saturate(100%);
    filter: saturate(100%);
  }
  50% {
    -webkit-filter: saturate(200%);
    filter: saturate(200%);
  }
}

@keyframes saturate {
  0%,
  100% {
    -webkit-filter: saturate(100%);
    filter: saturate(100%);
  }
  50% {
    -webkit-filter: saturate(200%);
    filter: saturate(200%);
  }
}

.fighting-bots {
  background-image: url("../Image/fighting-bots-bg.svg");
  background-position: 0% 0%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  height: 200vh;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.fighting-bots-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 100vh;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  width: 140vw;
}

.fighting-bots-row {
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.fighting-bots-left {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 50vw;
  -ms-flex: 0 0 50vw;
  flex: 0 0 50vw;
}

.fighting-bots-left h3 {
  line-height: 1;
  margin-bottom: 2.8125vw;
}

.fighting-bots-left p {
  margin-bottom: 1.25vw;
  max-width: 28.6458333333vw;
}

.fighting-bots-first {
  margin-left: -webkit-calc(9.1145833333vw + 100px);
  margin-left: calc(9.1145833333vw + 100px);
}

.fighting-bots-second {
  margin-left: -webkit-calc(4.5572916667vw + 50px);
  margin-left: calc(4.5572916667vw + 50px);
  position: relative;
}

.fighting-bots-second::before {
  background-image: url("../Image/fighting-bots-p.svg");
  background-position: 0% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  position: absolute;
  left: 0;
  top: -1.0416666667vw;
  height: 0.4166666667vw;
  width: 6.4583333333vw;
}

.fighting-bots-button {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-left: -webkit-calc(4.5572916667vw + 50px);
  margin-left: calc(4.5572916667vw + 50px);
  margin-top: 3.75vw;
  position: relative;
}

.fighting-bots-right {
  margin-right: -4vw;
  position: relative;
}

.fighting-bots-right img {
  display: block;
  height: auto;
  width: 90vw;
}

@media (max-width: 1024px) {
  .fighting-bots {
    background-image: none;
    height: auto;
    position: relative;
    padding-top: 120px;
  }

  .fighting-bots-content {
    display: block;
    height: auto;
    width: auto;
  }

  .fighting-bots-row {
    height: auto;
    display: block;
  }

  .fighting-bots-left h3 {
    margin-bottom: 36px;
    max-width: 580px;
  }

  .fighting-bots-left p {
    margin-bottom: 18px;
    max-width: 435px;
  }

  .fighting-bots-first {
    margin-left: 120px;
  }

  .fighting-bots-second {
    margin-left: 0;
  }

  .fighting-bots-second::before {
    display: none;
  }

  .fighting-bots-button {
    margin-left: 0;
  }

  .fighting-bots-right {
    margin: 56px -144px 0;
  }

  .fighting-bots-right img {
    width: 100%;
  }
}

@media (max-width: 767.98px) {
  .fighting-bots {
    padding-top: 83px;
  }

  .fighting-bots-left h3 {
    max-width: 280px;
  }

  .fighting-bots-left p {
    margin-bottom: 24px;
    max-width: none;
  }

  .fighting-bots-first {
    margin-left: 0;
  }

  .fighting-bots-button {
    margin-top: 50px;
  }

  .fighting-bots-right {
    margin: 32px -60px 0;
  }

  .fighting-bots .not-phone {
    display: none;
  }
}

@media (pointer: coarse) and (hover: none) {
  .fighting-bots {
    background-image: none;
    height: auto;
    position: relative;
    padding-top: 120px;
  }

  .fighting-bots-content {
    display: block;
    height: auto;
    width: auto;
  }

  .fighting-bots-row {
    height: auto;
    display: block;
  }

  .fighting-bots-left h3 {
    margin-bottom: 36px;
    max-width: 580px;
  }

  .fighting-bots-left p {
    margin-bottom: 18px;
    max-width: 435px;
  }

  .fighting-bots-right {
    margin: 56px -16.6666666667vw 0;
  }

  .fighting-bots-right::before {
    display: none;
  }

  .fighting-bots-right img {
    width: 100%;
  }
}

@media (pointer: coarse) and (hover: none) and (max-width: 1024px) {
  .fighting-bots-right {
    margin: 56px -144px 0;
  }
}

@media (pointer: coarse) and (hover: none) and (max-width: 767.98px) {
  .fighting-bots {
    padding-top: 83px;
  }

  .fighting-bots-left h3 {
    max-width: 280px;
  }

  .fighting-bots-left p {
    margin-bottom: 24px;
    max-width: none;
  }

  .fighting-bots-right {
    margin: 32px -60px 0;
  }
}

#under-fighting-bots {
  margin-top: 200vh;
}

@media (max-width: 1024px) {
  #under-fighting-bots {
    margin-top: 0;
  }
}

@media (pointer: coarse) and (hover: none) {
  #under-fighting-bots {
    margin-top: 0;
  }
}

.roadmap {
  overflow: hidden;
  position: relative;
  padding-bottom: 11.2916666667vw;
  padding-top: 11.8229166667vw;
}

.roadmap-content {
  margin-top: 5.15625vw;
  position: relative;
}

.roadmap-list {
  position: relative;
}

.roadmap-list__item {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #000;
  padding: 2.0833333333vw 2.96875vw;
  position: relative;
  width: 20.2604166667vw;
  z-index: 2;
}
.roadmap-list__greyscale{
  filter: brightness(0.7);
}
.roadmap-list__item-bg {
  background-image: url("../Image/roadmap-middle.webp");
  background-position: 50% 0%;
  background-repeat: repeat-y;
  background-size: 100% auto;
  position: absolute;
  left: 50%;
  bottom: 2.65625vw;
  top: 2.65625vw;
  -webkit-transform: translate3d(-50%, 0, 0);
  transform: translate3d(-50%, 0, 0);
  pointer-events: none;
  width: 45.1041666667vw;
}

.roadmap-list__item-bg::before {
  background-image: url("../Image/roadmap-top.webp");
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  content: "";
  height: 14.9479166667vw;
  left: 0;
  position: absolute;
  top: -14.9479166667vw;
  width: 100%;
}

.roadmap-list__item-bg::after {
  background-image: url("../Image/roadmap-bottom.webp");
  background-position: 50% 0%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  content: "";
  height: 14.9479166667vw;
  left: 0;
  position: absolute;
  bottom: -14.9479166667vw;
  width: 100%;
}

.roadmap-list__item-date {
  font-family: Cold Warm, sans-serif;
  font-weight: bold;
  font-size: 1.0416666667vw;
  line-height: 110%;
  margin-bottom: 0.3645833333vw;
  position: relative;
  text-transform: uppercase;
  color: #979797;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 3;
}

.roadmap-list__item-title {
  font-family: Cold Warm, sans-serif;
  font-size: 1.71875vw;
  line-height: 110%;
  letter-spacing: 0.02em;
  margin-bottom: 0.8680572917vw;
  position: relative;
  text-transform: uppercase;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 3;
}

.roadmap-list__item-text {
  font-size: 0.9375vw;
  line-height: 135%;
  letter-spacing: -0.02em;
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 3;
}

.roadmap-list__item:nth-child(1) {
  margin-bottom: 1.71875vw;
}

.roadmap-list__item:nth-child(2) {
  margin-left: 7.34375vw;
  position: absolute;
}

.roadmap-list__item:nth-child(3) {
  margin-left: 31.71875vw;
  margin-top: -6.875vw;
  position: absolute;
}

.roadmap-list__item:nth-child(4) {
  margin-bottom: 2.03125vw;
  margin-left: auto;
  margin-right: 7.34375vw;
  margin-top: -14.0625vw;
}

.roadmap-list__item:nth-child(5) {
  margin-bottom: 2.03125vw;
  margin-left: auto;
}

.roadmap-list__item:nth-child(6) {
  margin-left: auto;
  margin-right: 7.34375vw;
}

.roadmap-list__item:nth-child(7) {
  margin-left: auto;
  margin-right: 29.53125vw;
  margin-top: -5.8333333333vw;
}
.roadmap-list__item:nth-child(8) {
  margin-top: -5.8333333333vw;
  margin-left: 7.34375vw;
  position: absolute;
}
.roadmap-list__item:nth-child(9) {
  margin-top: 10.5vw;
  margin-bottom: 1.71875vw;
}

.roadmap-list__item:nth-child(10) {
  margin-left: 7.34375vw;
  position: absolute;
}

.roadmap-list__item:nth-child(11) {
  margin-left: 31.71875vw;
  margin-top: -6.875vw;
  position: absolute;
}

.roadmap-list__item:nth-child(12) {
  margin-bottom: 2.03125vw;
  margin-left: auto;
  margin-right: 7.34375vw;
  margin-top: -14.0625vw;
}
.roadmap-list__item:nth-child(13) {
  margin-bottom: 2.03125vw;
  margin-left: auto;
}
.roadmap-list__item:nth-child(14) {
  margin-left: auto;
  margin-right: 7.34375vw;
}
.roadmap-list__item.passed {
  -webkit-filter: brightness(45%);
  filter: brightness(45%);
}

.roadmap-list__item.slick-active:not(.passed) {
  z-index: 1;
}

.roadmap-list__item.slick-active:not(.passed) .roadmap-list__item-bg {
  background-image: url("../Image/roadmap-active-middle.webp");
}

.roadmap-list__item.slick-active:not(.passed) .roadmap-list__item-bg::before {
  background-image: url("../Image/roadmap-active-top.webp");
}

.roadmap-list__item.slick-active:not(.passed) .roadmap-list__item-bg::after {
  background-image: url("../Image/roadmap-active-bottom.webp");
}
.roadmap-list__item.slick-active:not(.passed).red .roadmap-list__item-bg {
  background-image: url("../Image/redmid.webp");
}

.roadmap-list__item.slick-active:not(.passed).red .roadmap-list__item-bg::before {
  background-image: url("../Image/redtop.webp");
}

.roadmap-list__item.slick-active:not(.passed).red .roadmap-list__item-bg::after {
  background-image: url("../Image/redbot.webp");
}
.roadmap-list__item.slick-active:not(.passed).blue .roadmap-list__item-bg {
  background-image: url("../Image/bluemid.webp");
}

.roadmap-list__item.slick-active:not(.passed).blue .roadmap-list__item-bg::before {
  background-image: url("../Image/bluetop.webp");
}

.roadmap-list__item.slick-active:not(.passed).blue .roadmap-list__item-bg::after {
  background-image: url("../Image/bluebot.webp");
}
.roadmap-list__item.slick-active:not(.passed) .roadmap-list__item-date {
  color: #985b00;
}

.roadmap-list__item.slick-active:not(.passed).red .roadmap-list__item-date {
  color: #5B0D00;
}
.roadmap-list__item.slick-active:not(.passed).blue .roadmap-list__item-date {
  color: #156D89;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
.roadmap-tip {
  color: rgba(255, 255, 255, 0.35);
  font-size: 0.78125vw;
  line-height: 1.4667;
  letter-spacing: 0.02em;
  bottom: -150px;
  position: absolute;
  right: 0;
  max-width: 24.1666666667vw;
}
.upcomingRoadmap{
  font-weight: 400;
  font-size: 24px;
  line-height: 145%;
  color: #FFFFFF;
  margin-top: 30px;
}
.roadmap-tip::before {
  content: "*";
  position: absolute;
  left: -0.5208333333vw;
}

@media (max-width: 1024px) {
  .roadmap {
    padding-bottom: 118px;
    padding-top: 165px;
  }

  .roadmap-content {
    margin-top: 83px;
  }

  .roadmap-list .slick-list {
    overflow: visible;
  }

  .roadmap-list__item {
    padding: 39px 57px;
    width: 389px !important;
  }

  .roadmap-list__item-bg {
    bottom: 51px;
    top: 51px;
    width: 866px;
  }

  .roadmap-list__item-bg::before {
    height: 287px;
    top: -287px;
  }

  .roadmap-list__item-bg::after {
    height: 287px;
    bottom: -287px;
  }

  .roadmap-list__item-date {
    font-size: 20px;
    margin-bottom: 7px;
  }

  .roadmap-list__item-title {
    font-size: 33px;
    margin-bottom: 17px;
  }

  .roadmap-list__item-text {
    font-size: 18px;
  }

  .roadmap-list__item:nth-child(1),
  .roadmap-list__item:nth-child(2),
  .roadmap-list__item:nth-child(3),
  .roadmap-list__item:nth-child(4),
  .roadmap-list__item:nth-child(5),
  .roadmap-list__item:nth-child(6),
  .roadmap-list__item:nth-child(7),
  .roadmap-list__item:nth-child(8),
  .roadmap-list__item:nth-child(9),
  .roadmap-list__item:nth-child(10),
  .roadmap-list__item:nth-child(11),
  .roadmap-list__item:nth-child(12),
  .roadmap-list__item:nth-child(13)
  {
    margin: 0 25px;
    position: relative;
  }

  .roadmap .slick-dots {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 33px;
  }

  .roadmap .slick-dots li {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 50%;
    cursor: pointer;
    display: block;
    height: 20px;
    margin: 0 4px;
    position: relative;
    width: 20px;
  }

  .roadmap .slick-dots li::before {
    background: #4b4b4b;
    border-radius: 50%;
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -4px;
    margin-top: -4px;
    height: 8px;
    width: 8px;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transition: background-color 0.25s ease-in-out,
      -webkit-transform 0.25s ease-in-out;
    transition: background-color 0.25s ease-in-out,
      -webkit-transform 0.25s ease-in-out;
    -o-transition: background-color 0.25s ease-in-out,
      transform 0.25s ease-in-out;
    transition: background-color 0.25s ease-in-out, transform 0.25s ease-in-out;
    transition: background-color 0.25s ease-in-out, transform 0.25s ease-in-out,
      -webkit-transform 0.25s ease-in-out;
  }

  .roadmap .slick-dots li::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 50%;
    border: 1px solid #4b4b4b;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transition: border-color 0.25s ease-in-out,
      -webkit-transform 0.25s ease-in-out;
    transition: border-color 0.25s ease-in-out,
      -webkit-transform 0.25s ease-in-out;
    -o-transition: border-color 0.25s ease-in-out, transform 0.25s ease-in-out;
    transition: border-color 0.25s ease-in-out, transform 0.25s ease-in-out;
    transition: border-color 0.25s ease-in-out, transform 0.25s ease-in-out,
      -webkit-transform 0.25s ease-in-out;
  }

  .roadmap .slick-dots li button {
    display: none;
  }
}

@media (max-width: 1024px) and (any-pointer: fine) {
  .roadmap .slick-dots li:hover::after {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

@media (max-width: 1024px) {
  .roadmap .slick-dots li.slick-active::before {
    background-color: #ffc328;
    -webkit-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
  }

  .roadmap .slick-dots li.slick-active::after {
    border-color: #ffc328;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

@media (max-width: 1024px) {
  .roadmap-tip {
    bottom: auto;
    font-size: 15px;
    position: relative;
    right: auto;
    margin-top: 30px;
    max-width: 464px;
  }

  .roadmap-tip::before {
    left: -10px;
  }
}

@media (max-width: 767.98px) {
  .roadmap {
    padding-bottom: 82px;
    padding-top: 68px;
  }
  .upcomingRoadmap{
    font-size: 16px;
  }
  .roadmap-content {
    margin-top: 54px;
  }

  .roadmap-list__item {
    padding: 29px 43px;
    width: 292px !important;
  }

  .roadmap-list__item-bg {
    bottom: 38px;
    top: 38px;
    width: 650px;
  }

  .roadmap-list__item-bg::before {
    height: 215px;
    top: -215px;
  }

  .roadmap-list__item-bg::after {
    height: 215px;
    bottom: -215px;
  }

  .roadmap-list__item-date {
    font-size: 12px;
    margin-bottom: 5px;
  }

  .roadmap-list__item-title {
    font-size: 24px;
    margin-bottom: 12px;
  }

  .roadmap-list__item-text {
    font-size: 12px;
  }

  .roadmap-list__item:nth-child(1),
  .roadmap-list__item:nth-child(2),
  .roadmap-list__item:nth-child(3),
  .roadmap-list__item:nth-child(4),
  .roadmap-list__item:nth-child(5),
  .roadmap-list__item:nth-child(6),
  .roadmap-list__item:nth-child(7),
  .roadmap-list__item:nth-child(8),
  .roadmap-list__item:nth-child(9),
  .roadmap-list__item:nth-child(10),
  .roadmap-list__item:nth-child(11),
  .roadmap-list__item:nth-child(12),
  .roadmap-list__item:nth-child(13)
  {
    margin: 0 10px;
  }

  .roadmap .slick-dots {
    margin-top: 25px;
  }

  .roadmap-tip {
    font-size: 12px;
    margin-top: 44px;
  }

  .roadmap-tip::before {
    left: -8px;
  }
}

.subscribe {
  margin-top: 15vw;
  background-color: #ffc328;
  background-image: url("../Image/footer-bg.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  color: #000;
  -webkit-clip-path: polygon(
    0% 0%,
    34.7916666667% 0%,
    36.5625% 1.3541666667vw,
    91.9270833333% 1.3541666667vw,
    93.28125% 2.65625vw,
    100% 2.65625vw,
    100% 100%,
    66.5625% 100%,
    64.1666666667% -webkit-calc(100% - 1.3541666667vw),
    22.8645833333% -webkit-calc(100% - 1.3541666667vw),
    20.6770833333% -webkit-calc(100% - 2.7083333333vw),
    7.2395833333% -webkit-calc(100% - 2.7083333333vw),
    5.46875% -webkit-calc(100% - 2.1875vw),
    0% -webkit-calc(100% - 2.1875vw)
  );
  clip-path: polygon(
    0% 0%,
    34.7916666667% 0%,
    36.5625% 1.3541666667vw,
    91.9270833333% 1.3541666667vw,
    93.28125% 2.65625vw,
    100% 2.65625vw,
    100% 100%,
    66.5625% 100%,
    64.1666666667% calc(100% - 1.3541666667vw),
    22.8645833333% calc(100% - 1.3541666667vw),
    20.6770833333% calc(100% - 2.7083333333vw),
    7.2395833333% calc(100% - 2.7083333333vw),
    5.46875% calc(100% - 2.1875vw),
    0% calc(100% - 2.1875vw)
  );
  margin-bottom: -2.7083333333vw;
  padding-bottom: 9.2708333333vw;
  padding-top: 7.7083333333vw;
  position: relative;
  z-index: 2;
}

.subscribe-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  max-width: 50vw;
}

@media (max-width: 1024px) {
  .subscribe {
    -webkit-clip-path: polygon(
      0% 0%,
      47.5260416667% 0%,
      51.171875% 2.6041666667vw,
      100% 2.6041666667vw,
      100% 100%,
      23.1770833333% 100%,
      18.4895833333% -webkit-calc(100% - 3.125vw),
      0% -webkit-calc(100% - 3.125vw)
    );
    clip-path: polygon(
      0% 0%,
      47.5260416667% 0%,
      51.171875% 2.6041666667vw,
      100% 2.6041666667vw,
      100% 100%,
      23.1770833333% 100%,
      18.4895833333% calc(100% - 3.125vw),
      0% calc(100% - 3.125vw)
    );
    margin-bottom: -3.125vw;
    padding-bottom: 118px;
    padding-top: 138px;
  }

  .subscribe-content {
    max-width: none;
  }
}

@media (max-width: 767.98px) {
  .subscribe {
    -webkit-clip-path: polygon(
      0% 0%,
      80.8333333333% 0%,
      90.8333333333% 7.7777777778vw,
      100% 7.7777777778vw,
      100% 100%,
      11.1111111111% 100%,
      0% -webkit-calc(100% - 7.2222222222vw)
    );
    clip-path: polygon(
      0% 0%,
      80.8333333333% 0%,
      90.8333333333% 7.7777777778vw,
      100% 7.7777777778vw,
      100% 100%,
      11.1111111111% 100%,
      0% calc(100% - 7.2222222222vw)
    );
    margin-bottom: -7.2222222222vw;
    padding-bottom: 107px;
    padding-top: 97px;
  }
}

.partners {
  background: #000;
  padding: 8.4375vw 0;
  position: relative;
}

.partners-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 5.7291666667vw -2.6041666667vw 0;
}

.partners-list__item {
  display: block;
  height: 4.1666666667vw;
  margin: 0 1.0416666667vw;
  opacity: 0.35;
  -webkit-transition: opacity 0.25s ease-in-out;
  -o-transition: opacity 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out;
}

/*@media (pointer: coarse) and (hover: none) {*/
/*  .partners-list__item {*/
/*    opacity: 1;*/
/*  }*/
/*}*/

.partners-list__item img {
  display: block;
  height: 100%;
  width: auto;
}

@media (any-pointer: fine) {
  .partners-list a.partners-list__item:hover {
    opacity: 1;
  }
}

.partners-list-primary {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  /* -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; */
  margin-bottom: 2.9166666667vw;
}

@media (max-width: 685px) {
  .partners-list-primary {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

}

@media (max-width: 420px) {
  .partners-list-primary .partners-list__item {
    height: 82px;
  }
}

.partners-list-primary .partners-list__item {
  height: 9.25vw;
  margin-left: 2.6041666667vw;
  margin-right: 2.6041666667vw;
}

.partners-list-secondary {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 4.6875vw;
}

.partners-list-secondary .partners-list__item {
  height: 5.33vw;
  margin-left: 0.78125vw;
  margin-right: 0.78125vw;
}

.partners-list-other {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media (max-width: 1024px) {
  .partners {
    padding: 176px 0;
  }

  .partners-list {
    margin: 70px -32px 0;
  }

  .partners-list__item {
    height: 40px;
    margin: 0 10px;
  }

  .partners-list-primary {
    margin-bottom: 50px;
  }

  .partners-list-primary .partners-list__item {
    height: 80px;
    margin-left: 32px;
    margin-right: 32px;
  }

  .partners-list-secondary {
    margin-bottom: 40px;
  }

  .partners-list-secondary .partners-list__item {
    height: 54px;
    margin: 5px 12px;
  }
}

@media (max-width: 767.98px) {
  .partners {
    padding: 164px 0;
  }

  .partners-list {
    margin: 60px -20px 0;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .partners-list__item {
    height: 36px;
    margin: 6px 5px;
  }

  .partners-list-primary {
    margin-bottom: 50px;
    width: 100%;
  }

  .partners-list-primary .partners-list__item:nth-child(1) {
    height: 77px;
    width: 137px;
    margin: 6px 20px;
  }

  .partners-list-primary .partners-list__item:nth-child(2) {
    height: 83px;
    width: 263px;
    margin: 6px 20px;
  }

  .partners-list-primary .partners-list__item:nth-child(3) {
    height: 80px;
    width: 249px;
    margin: 6px 20px;
  }

  .partners-list-secondary {
    margin-bottom: 60px;
  }

  .partners-list-secondary .partners-list__item {
    height: 48px;
    margin: 5px 7.5px;
  }
}

/* Page styles */
.faq {
  background-color: #000;
  background-attachment: fixed;
  background-image: url("../Image/content-bg-yellow.svg");
  background-position: 50% 0%;
  background-repeat: repeat-y;
  background-size: 100% auto;
  padding-bottom: 8.75vw;
  overflow: hidden;
  position: relative;
}

.faq.land-sale {
  background-image: none;
  background-color: unset;
}

@media (max-width: 1024px) {
  .faq.land-sale {
    margin-top: 0;
  }
}

.faq-left {
  background-image: url("../Image/faq-left.webp");
  background-position: 0% 0%;
  background-repeat: no-repeat;
  background-size: auto 100%;
  position: absolute;
  height: 95vh;
  top: 18vw;
  left: 0;
  width: 50%;
}

.faq-right {
  background-image: url("../Image/faq-right.webp");
  background-position: 100% 0%;
  background-repeat: no-repeat;
  background-size: auto 100%;
  position: absolute;
  height: 95vh;
  top: 18vw;
  right: 0;
  width: 50%;
}

.faq-container {
  padding: 0 30%;
  position: relative;
}

.faq-title {
  margin-bottom: 2.1875vw;
  margin-top: 3.2291666667vw;
  text-align: center;
}

.faq-list__item {
  background: #ffc328;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-clip-path: polygon(
    0% 0%,
    59.3679458239% 0%,
    60% 0.2083333333vw,
    100% 0.2083333333vw,
    100% -webkit-calc(100% - 1.25vw),
    97.2911963883% 100%,
    0% 100%
  );
  clip-path: polygon(
    0% 0%,
    59.3679458239% 0%,
    60% 0.2083333333vw,
    100% 0.2083333333vw,
    100% calc(100% - 1.25vw),
    97.2911963883% 100%,
    0% 100%
  );
  color: #000;
  margin-bottom: 1.09375vw;
  padding-top: 0.5208333333vw;
}

.faq-list__item:last-child {
  margin-bottom: 0;
}

.faq-list__item-top {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1.25vw 2.8125vw;
  -webkit-transition: color 0.25s ease-in-out;
  -o-transition: color 0.25s ease-in-out;
  transition: color 0.25s ease-in-out;
}

.faq-list__item-top svg {
  margin-left: 4.0625vw;
  -webkit-transform-origin: 50% 75%;
  -ms-transform-origin: 50% 75%;
  transform-origin: 50% 75%;
  -webkit-transform: scaleY(-1) translate3d(0, 33.333333333%, 0);
  transform: scaleY(-1) translate3d(0, 33.333333333%, 0);
  -webkit-transition: -webkit-transform 0.25s ease-in;
  transition: -webkit-transform 0.25s ease-in;
  -o-transition: transform 0.25s ease-in;
  transition: transform 0.25s ease-in;
  transition: transform 0.25s ease-in, -webkit-transform 0.25s ease-in;
  width: 1.8229166667vw;
}

.faq-list__item-top svg .fill {
  fill: #000;
  stroke: #000;
  -webkit-transition: fill 0.25s ease-in-out, stroke 0.25s ease-in-out;
  -o-transition: fill 0.25s ease-in-out, stroke 0.25s ease-in-out;
  transition: fill 0.25s ease-in-out, stroke 0.25s ease-in-out;
}

.faq-list__item-top svg .stroke {
  opacity: 0;
  fill: none;
  stroke: #000;
  -webkit-transition: opacity 0.25s ease-in, stroke 0.25s ease-in-out;
  -o-transition: opacity 0.25s ease-in, stroke 0.25s ease-in-out;
  transition: opacity 0.25s ease-in, stroke 0.25s ease-in-out;
}

@media (any-pointer: fine) {
  .faq-list__item-top:hover {
    color: #5b0d00;
  }

  .faq-list__item-top:hover svg .fill {
    fill: #5b0d00;
    stroke: #5b0d00;
  }

  .faq-list__item-top:hover svg .stroke {
    stroke: #5b0d00;
  }
}

.faq-list__item-question {
  font-family: Cold Warm, sans-serif;
  font-size: 1.71875vw;
  line-height: 110%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

.faq-list__item-answer {
  font-size: 1.25vw;
  line-height: 145%;
  display: none;
  padding: 0 2.8125vw 2.8125vw;
  position: relative;
}

.faq-list__item-answer::before {
  background: #000;
  -webkit-clip-path: polygon(0% 0.78125vw, 100% 0%, 100% 100%, 0% 100%);
  clip-path: polygon(0% 0.78125vw, 100% 0%, 100% 100%, 0% 100%);
  content: "";
  top: 0;
  bottom: 2.8125vw;
  position: absolute;
  width: 0.6770833333vw;
}

.faq-list__item-answer p {
  margin-bottom: 0.8333333333vw;
  padding-left: 1.9270833333vw;
}

.faq-list__item-answer p:last-child {
  margin-bottom: 0;
}

.faq-list__item-answer a {
  font-weight: bold;
  color: #003caf;
  text-decoration: none;
  position: relative;
  -webkit-transition: color 0.25s ease-in-out;
  -o-transition: color 0.25s ease-in-out;
  transition: color 0.25s ease-in-out;
}

.faq-list__item-answer a::after {
  background-color: #003caf;
  content: "";
  height: 1px;
  position: absolute;
  left: 0;
  top: 100%;
  width: 50%;
  -webkit-transition: background-color 0.25s ease-in-out,
    width 0.25s ease-in-out;
  -o-transition: background-color 0.25s ease-in-out, width 0.25s ease-in-out;
  transition: background-color 0.25s ease-in-out, width 0.25s ease-in-out;
}

@media (any-pointer: fine) {
  .faq-list__item-answer a:hover {
    color: #5b0d00;
  }

  .faq-list__item-answer a:hover::after {
    background-color: #5b0d00;
    width: 100%;
  }
}

.faq-list__item-answer ul li {
  margin-bottom: 0.5208333333vw;
  margin-left: 1.9270833333vw;
  padding-left: 1.4583333333vw;
  position: relative;
}

.faq-list__item-answer ul li::before {
  background-image: url("../Image/li.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  display: block;
  height: 1.0416666667vw;
  position: absolute;
  left: 0;
  top: 0.3645833333vw;
  width: 0.625vw;
}

.faq-list__item.open .faq-list__item-top svg {
  -webkit-transform: scaleY(1) translate3d(0, 0, 0);
  transform: scaleY(1) translate3d(0, 0, 0);
  -webkit-transition: -webkit-transform 0.35s ease-out;
  transition: -webkit-transform 0.35s ease-out;
  -o-transition: transform 0.35s ease-out;
  transition: transform 0.35s ease-out;
  transition: transform 0.35s ease-out, -webkit-transform 0.35s ease-out;
}

.faq-list__item.open .faq-list__item-top svg .stroke {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease-out 0.1s, stroke 0.25s ease-in-out;
  -o-transition: opacity 0.25s ease-out 0.1s, stroke 0.25s ease-in-out;
  transition: opacity 0.25s ease-out 0.1s, stroke 0.25s ease-in-out;
}

@media (max-width: 1224px) {
  .faq {
    padding-bottom: 146px;
  }

  .faq-left {
    left: -200px;
    height: 750px;
    top: 280px;
  }

  .faq-right {
    right: -200px;
    height: 750px;
    top: 280px;
  }

  .faq-container {
    margin: 0 auto;
    padding: 0 48px;
    max-width: 768px;
  }

  .faq-title {
    margin-bottom: 42px;
    margin-top: 50px;
  }

  .faq-list__item {
    -webkit-clip-path: polygon(
      0% 0%,
      59.3679458239% 0%,
      60% 4px,
      100% 4px,
      100% -webkit-calc(100% - 24px),
      -webkit-calc(100% - 24px) 100%,
      0% 100%
    );
    clip-path: polygon(
      0% 0%,
      59.3679458239% 0%,
      60% 4px,
      100% 4px,
      100% calc(100% - 24px),
      calc(100% - 24px) 100%,
      0% 100%
    );
    margin-bottom: 16px;
    padding-top: 7px;
  }

  .faq-list__item-top {
    padding: 18px 40px;
  }

  .faq-list__item-top svg {
    margin-left: 59px;
    width: 25px;
  }

  .faq-list__item-question {
    font-size: 24px;
  }

  .faq-list__item-answer {
    font-size: 18px;
    padding: 0 40px 40px;
  }

  .faq-list__item-answer::before {
    -webkit-clip-path: polygon(0% 12px, 100% 0%, 100% 100%, 0% 100%);
    clip-path: polygon(0% 12px, 100% 0%, 100% 100%, 0% 100%);
    bottom: 40px;
    width: 10px;
  }

  .faq-list__item-answer p {
    margin-bottom: 12px;
    padding-left: 28px;
  }

  .faq-list__item-answer ul li {
    margin-bottom: 7px;
    margin-left: 28px;
    padding-left: 20px;
  }

  .faq-list__item-answer ul li::before {
    height: 15px;
    top: 5.5px;
    width: 9px;
  }
}

@media (min-width: 1024px) {
  .faq.land-sale .faq-title {
    width: calc(100% + 90px);
    transform: translateX(-35px);
  }
}
@media (max-width: 670px) {
  .faq.land-sale .faq-title h1 {
    font-size: 40px;
    text-align: center;
    width: 100%;
  }
  .modes__item-content p {
    bottom: 0;
  }
}

@media (max-width: 767.98px) {
  .faq {
    padding-bottom: 85px;
  }

  .faq-left,
  .faq-right {
    display: none;
  }

  .faq-container {
    padding: 0 20px;
  }

  .faq-title {
    margin-bottom: 22px;
    text-align: left;
  }

  .faq-list__item {
    -webkit-clip-path: polygon(
      0% 0%,
      59.3679458239% 0%,
      60% 3px,
      100% 3px,
      100% -webkit-calc(100% - 18px),
      -webkit-calc(100% - 18px) 100%,
      0% 100%
    );
    clip-path: polygon(
      0% 0%,
      59.3679458239% 0%,
      60% 3px,
      100% 3px,
      100% calc(100% - 18px),
      calc(100% - 18px) 100%,
      0% 100%
    );
    margin-bottom: 10px;
    padding-top: 6px;
  }

  .faq-list__item-top {
    padding: 14px 20px;
  }

  .faq-list__item-top svg {
    margin-left: 48px;
    width: 20px;
  }

  .faq-list__item-question {
    font-size: 18px;
  }

  .faq-list__item-answer {
    font-size: 16px;
    padding: 0 20px 24px;
  }

  .faq-list__item-answer::before {
    bottom: 24px;
  }

  .faq-list__item-answer p {
    margin-bottom: 10px;
    font-size: 14px;
  }

  .faq-list__item-answer ul li {
    margin-bottom: 6px;
    padding-left: 16px;
    font-size: 14px;
  }

  .faq-list__item-answer ul li::before {
    height: 10px;
    top: 6.5px;
    width: 6px;
  }
}

.dev {
  position: relative;
}

.dev::after {
  background-image: url("../Image/internal.svg");
  background-position: 50% 6.75vw;
  background-repeat: no-repeat;
  background-size: 100% auto;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.dev-planet {
  background: url("../Image/planet.webp");
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.dev .container-center {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.dev-title {
  margin-top: 20vh;
  position: relative;
  z-index: 2;
}

.dev-title::before {
  background: url("../Image/faq-left.svg");
  background-position: 0% 50%;
  background-repeat: no-repeat;
  background-size: auto 100%;
  height: 0.6770833333vw;
  content: "";
  position: absolute;
  left: -2.0833333333vw;
  top: 95%;
  width: 100%;
}

.dev-title::after {
  background: url("../Image/faq-right.svg");
  background-position: 100% 50%;
  background-repeat: no-repeat;
  background-size: auto 100%;
  height: 0.6770833333vw;
  content: "";
  position: absolute;
  right: -2.0833333333vw;
  top: 95%;
  width: 100%;
}

@media (max-width: 1620px) {
  .dev-planet {
    left: 50%;
    right: auto;
    margin-left: -810px;
    width: 1620px;
  }

  .dev-title {
    margin-top: 15vh;
  }
}

@media (max-width: 1024px) {
  .dev {
    padding-bottom: 440px;
    padding-top: 200px;
  }

  .dev::after {
    background-position: 50% 120px;
    right: auto;
    left: 50%;
    margin-left: -640px;
    width: 1280px;
  }

  .dev-title {
    margin-top: 0;
  }

  .dev-title::before {
    height: 6.5px;
    left: -20px;
  }

  .dev-title::after {
    height: 6.5px;
    right: -20px;
  }
}

@media (max-width: 767.98px) {
  .dev {
    padding-top: 160px;
  }

  .dev-title {
    margin-top: 0;
  }

  .dev-title::before {
    height: 4.3333px;
    left: -10px;
  }

  .dev-title::after {
    height: 4.3333px;
    right: -10px;
  }
}

@media (pointer: coarse) and (hover: none) {
  .dev {
    padding-bottom: 500px;
    padding-top: 260px;
  }

  .dev-title {
    margin-top: 0;
  }
}

@media (pointer: coarse) and (hover: none) and (max-width: 1024px) {
  .dev {
    padding-bottom: 440px;
    padding-top: 200px;
  }
}

@media (pointer: coarse) and (hover: none) and (max-width: 767.98px) {
  .dev {
    padding-top: 160px;
  }
}

.planet {
  position: relative;
}

.planet::before {
  background-image: url("../Image/planet-stars.webp");
  background-position: 50% 6.75vw;
  background-repeat: no-repeat;
  background-size: 100% auto;
  content: "";
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.planet::after {
  background-image: url("../Image/planet-aim.svg");
  background-position: 50% 6.75vw;
  background-repeat: no-repeat;
  background-size: 100% auto;
  content: "";
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.planet-bg {
  height: 212.5vw;
  pointer-events: none;
  position: absolute;
  top: 5.8333333333vw;
  left: -56.25vw;
  width: 212.5vw;
}

.planet-bg img {
  display: block;
  width: 100%;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: -webkit-transform 0.5s ease-in-out;
  transition: -webkit-transform 0.5s ease-in-out;
  -o-transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
}

.planet-bg.rotate img {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.planet .container-center {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}

.planet-title {
  margin-bottom: 2.25vw;
  margin-top: 4.25vw;
  position: relative;
  text-align: center;
  z-index: 2;
}

.planet-button {
  margin-bottom: 5.8854166667vw;
  position: relative;
}

.planet-interaction {
  color: #000;
  font-size: 1.09375vw;
  line-height: 111%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 9.2708333333vw;
  width: 9.2708333333vw;
  position: relative;
  text-decoration: none;
  -webkit-transition: color 0.25s ease-in-out;
  -o-transition: color 0.25s ease-in-out;
  transition: color 0.25s ease-in-out;
}

.planet-interaction::before {
  background-image: url("../Image/planet-click-before.svg");
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  pointer-events: none;
  position: absolute;
  bottom: 88%;
  right: 77%;
  width: 21.3020833333vw;
  height: 13.8020833333vw;
}

.planet-interaction svg {
  fill: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transform: rotate(0deg) scale(1);
  -ms-transform: rotate(0deg) scale(1);
  transform: rotate(0deg) scale(1);
  -webkit-transition: -webkit-transform 0.5s ease-in-out;
  transition: -webkit-transform 0.5s ease-in-out;
  -o-transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
}

.planet-interaction svg circle {
  fill: #fff;
  -webkit-transition: fill 0.25s ease-in-out;
  -o-transition: fill 0.25s ease-in-out;
  transition: fill 0.25s ease-in-out;
}

.planet-interaction span {
  position: relative;
}

.planet-interaction.rotate svg {
  -webkit-transform: rotate(-90deg) scale(0.9);
  -ms-transform: rotate(-90deg) scale(0.9);
  transform: rotate(-90deg) scale(0.9);
}

@media (any-pointer: fine) {
  .planet-interaction:hover {
    color: #5b0d00;
  }

  .planet-interaction:hover svg circle {
    fill: #ffc328;
  }
}

@media (max-width: 1024px) {
  .planet {
    padding-bottom: 300px;
    padding-top: 220px;
  }

  .planet::after {
    background-position: 50% 270px;
    left: 50%;
    margin-left: -640px;
    right: auto;
    width: 1280px;
  }

  .planet-bg {
    height: 3060px;
    top: 220px;
    left: -webkit-calc((100vw - 3060px) / 2);
    left: calc((100vw - 3060px) / 2);
    width: 3060px;
  }

  .planet-title {
    margin-bottom: 93px;
    margin-top: 0;
  }

  .planet-title h1 {
    font-size: 80px;
  }

  .planet-button {
    margin-bottom: 105px;
  }

  .planet-interaction {
    font-size: 16px;
    height: 125px;
    width: 125px;
  }

  .planet-interaction::before {
    bottom: 88px;
    right: 69px;
    width: 434px;
    height: 280px;
  }
}

@media (max-width: 767.98px) {
  .planet {
    padding-bottom: 133px;
    padding-top: 123px;
  }

  .planet::before {
    background-position: 50% 90px;
    left: 50%;
    margin-left: -384px;
    right: auto;
    width: 768px;
  }

  .planet::after {
    background-position: 50% 150px;
    margin-left: -384px;
    width: 768px;
  }

  .planet-bg {
    height: 2040px;
    top: 150px;
    left: -webkit-calc((100vw - 2040px) / 2);
    left: calc((100vw - 2040px) / 2);
    width: 2040px;
  }

  .planet-title {
    margin-bottom: 63px;
  }

  .planet-title h1 {
    font-size: 50px;
  }

  .planet-button {
    margin-bottom: 69px;
  }

  .planet-interaction {
    font-size: 12px;
    height: 105px;
    width: 105px;
  }

  .planet-interaction::before {
    display: none;
  }
}

@media (pointer: coarse) and (hover: none) {
  .planet {
    padding-bottom: 280px;
    padding-top: 160px;
  }

  .planet-title {
    margin-top: 0;
  }
}

@media (pointer: coarse) and (hover: none) and (max-width: 1024px) {
  .planet {
    padding-bottom: 300px;
    padding-top: 220px;
  }
}

@media (pointer: coarse) and (hover: none) and (max-width: 767.98px) {
  .planet {
    padding-bottom: 133px;
    padding-top: 123px;
  }
}

/*privacy and police*/
.privacy-title {
  margin-bottom: 2.1875vw;
  margin-top: 3.2291666667vw;
  text-align: center;
}
.privacy-container {
  padding: 0 20%;
  position: relative;
}

.privacy-container p {
  margin-bottom: 1.5%;
  margin-left: 1.5%;
}

.privacy-item__title {
  font-family: Cold Warm, sans-serif;
  font-size: 1.71875vw;
  line-height: 110%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.privacy__item {
  font-size: 1.25vw;
  line-height: 145%;
  padding: 0 2.8125vw 2.8125vw;
  position: relative;
}

@media (max-width: 1024px) {
  .privacy-title {
    margin-bottom: 42px;
    margin-top: 50px;
  }
  .privacy-item__title {
    font-size: 24px;
  }
  .privacy-container {
    margin: 0 auto;
    padding: 0 48px;
    max-width: 768px;
  }
}

@media (max-width: 767.98px) {
  .privacy-title {
    margin-bottom: 22px;
    text-align: left;
  }
  .privacy-item__title {
    font-size: 18px;
  }
  .privacy-container {
    padding: 0 20px;
  }
}

/*Terms of use*/

.terms-title {
  margin-bottom: 2.1875vw;
  margin-top: 3.2291666667vw;
  text-align: center;
}
.terms-container {
  padding: 0 20%;
  position: relative;
}

.terms-container p {
  margin-bottom: 1.5%;
  margin-left: 1.5%;
}

.terms-container h5 {
  margin-bottom: 10px;
}

.terms-item__title {
  font-family: Cold Warm, sans-serif;
  font-size: 1.71875vw;
  line-height: 110%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.terms__item {
  font-size: 1.25vw;
  line-height: 145%;
  padding: 0 2.8125vw 2.8125vw;
  position: relative;
}

@media (max-width: 1024px) {
  .terms-title {
    margin-bottom: 42px;
    margin-top: 50px;
  }
  .terms-item__title {
    font-size: 24px;
  }
  .terms-container {
    margin: 0 auto;
    padding: 0 48px;
    max-width: 768px;
  }
}

@media (max-width: 767.98px) {
  .terms-title {
    margin-bottom: 22px;
    text-align: left;
  }
  .terms-item__title {
    font-size: 18px;
  }
  .terms-container {
    padding: 0 20px;
  }
}

video {
  height: 100vh;
  width: 100%;
  object-fit: cover;
}

@media (min-width: 3800px) {
  .js-restart-story,.js-start-story.intro-button {
    width: 480px;
    height: 129px;
  }
  .js-restart-story .label,.js-start-story .label {
    font-size: 0.9rem;
  }
  .fighting-bots-left h3 {
    max-width: 58vw;
  }
  .fighting-bots-left p {
    max-width: 29vw;
    margin-bottom: 1rem;
  }
  .fighting-bots-left p:nth-of-type(2) {
    max-width: 28vw;
  }
  .dev {
    padding-top: 12rem;
    padding-bottom: 22rem;
  }
  .story-text.js-story-text {
    margin-left: 5rem;
  }
  .nfts-bottom .button-type1 {
    transform: scale(2);
  }
  .nfts-bottom .button-type1:nth-of-type(2){
    transform: scale(2) translateY(1rem);
  }
  .land-row .button-type1{
    transform: scale(2) translateX(1.4rem);
  }
  .fighting-bots-button {
    transform: scale(1.3) translateX(1.4rem) translateY(1rem);
  }
  .footer-left .footer-copyright span,
  .footer-left .footer-copyright a,
  .footer-left .footer-menu__item {
    font-size: 1.0416666667vw !important;
  }
}
.web1{
  font-family: 'Cold Warm', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 120px;
  line-height: 100%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}
.web55{
  font-family: 'Cold Warm', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 150px;
  line-height: 100%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}
.web3{
  font-family: "Poppins", "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 145%;
}
.web9{
  font-family: 'Cold Warm';
  font-style: normal;
  font-weight: 400;
  font-size: 43px;
  line-height: 120%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}
.web27{
  font-family: 'Cold Warm', sans-serif;
  font-size: 75px;
  font-weight: 400;
  line-height: 75px;
  letter-spacing: 0.02em;
  text-align: left;
  text-transform: uppercase;
}

.tablet18 {
  /* Tablet 18 */

  font-family: 'Cold Warm';
  font-style: normal;
  font-weight: 400;
  font-size: 100px;
  line-height: 100%;
  /* or 100px */
  text-align: start;

  letter-spacing: 0.02em;
  text-transform: uppercase;
}

.web48{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 26px;
  line-height: 145%;
}

@media (min-width: 3200px) {
  .web1{
    font-size: 180px;
  }
}
@media (max-width: 868px) {
  .web1{
    font-size: 70px;
    line-height: 100%;
  }
  .web55{
    font-size: 100px;
  }
  .web48{
    font-size: 18px;
  }
}
@media (max-width: 576px) {
  .web1{
    font-size: 40px;
    line-height: 100%;
  }
  .web55{
    font-size: 55px;
  }
}
.white{
  color: #FFFFFF !important;
}
.yellow{
  color: rgba(255, 195, 40, 1);
}

.MuiTooltip-popper:has(h4){
  pointer-events: none;
}