.landing {
  padding-top: 3vw;
  width: 100vw;
  overflow: hidden;
  .imageHolder {
    overflow: hidden;
    width: 100vw;
    height: 110vh;
    min-height: -webkit-fill-available;
    min-height: -moz-available;
    background-image: url("../../Image/landing-bg.png");
    background-size: cover;
    background-position: center top;
    background-repeat: repeat-y;
  }

  .sponsors {
    position: relative;
    background-color: #000;
    margin-top: 70px;
    width: 100vw;
    border-top: 7px solid #6f6f6f;
    padding-top: 38px;
    padding-bottom: 72px;
    border-bottom: 2px solid #3d3a38;
    h4 {
      display: table;
      min-width: fit-content;
      position: absolute;
      top: -34px;
      left: 50%;
      padding: 0 25px;
      transform: translateX(-50%);
      background-color: #000;
      font-weight: 400;
      font-size: 50px;
      line-height: 120%;
      letter-spacing: 0.02em;
    }
    .sponsor__logos {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      max-width: 100%;
      box-sizing: border-box;
      background: linear-gradient(
        90deg,
        #000000 0%,
        #212121 28.65%,
        #1f1f1f 52.79%,
        #1f1f1f 75%,
        #000000 98.44%
      );
      padding: 35px 12.24%;
      a {
        opacity: 0.35;
        transition: opacity 0.25s ease-in-out;
        &:hover {
          opacity: 1;
        }
      }
      @media (max-width: 1440px) {
        a {
          &:first-of-type {
            width: 20%;
            img {
              width: 100%;
            }
          }
          &:not(:first-of-type) {
            width: 30%;
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
  .content {
    width: 100vw;
    padding: 100px 7.92% 0 7.92%;
    box-sizing: border-box;
    background-image: url("../../Image/landing-pattern.svg");
    @media (max-width: 1440px) {
      padding: 100px 4.92% 168px 4.92%;
    }
    h3 {
      font-weight: 400;
      font-size: 120px;
      line-height: 100%;
      letter-spacing: 0.02em;
      margin-bottom: 61px;
      position: relative;
      span {
        color: #ffc328;
      }
      &::after {
        content: "";
        position: absolute;
        top: 85%;
        left: -7.92vw;
        width: 100%;
        height: 100%;
        background-image: url("../../Image/moreInfoUnderLineDesktop.svg");
        background-repeat: no-repeat;
      }
      @media (max-width: 1440px) {
        width: 120%;
        &::after {
          left: -9vw;
        }
      }
    }

    .content__desc {
      position: relative;
      button[alt="content-sticker"] {
        position: absolute;
        &::after,
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 50px;
          height: 50px;
          background-image: url("../../Image/landing-stickerHover.png");
          opacity: 0;
        }
        &::before {
          background-image: url("../../Image/landing-stickerSelected.png");
        }
        top: 54%;
        height: 35%;
        width: 37%;
        right: 1rem;
        background-image: url("../../Image/landing-sticker.png");
        background-repeat: no-repeat;
        cursor: pointer;
        background-position: bottom;
        transform: translateY(-5%);
        &:not(:focus):hover {
          background-image: url("../../Image/landing-stickerHover.png");
        }
        &:focus {
          background-image: url("../../Image/landing-stickerSelected.png");
        }
        @media (max-width: 1440px) {
          background-size: contain;
          right: -2rem;
          width: 45%;
          top: 60%;
          height: 28%;
        }
      }
      p {
        font-size: 24px;
        width: 58%;
        &:nth-of-type(1) {
          margin-bottom: 35px;
        }
        &:nth-of-type(2) {
          width: 57.5%;
        }
      }
    }
    .gameScreens {
      margin-top: 102px;
      transform: translateX(-11px);
      position: relative;
      margin-bottom: 22.7rem;
      @media (max-width: 1440px) {
        margin-bottom: 26.7rem;
      }
      img {
        @media (max-width: 1440px) {
          width: 65%;
        }
        &:nth-of-type(2) {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translateX(-50%);
        }
        &:nth-of-type(3) {
          position: absolute;
          right: 0%;
          top: 100%;
        }
      }
    }
    .playRules {
      padding-top: 4rem;
      h3 {
        text-align: right;
        width: fit-content;
        margin-left: auto;
        margin-bottom: 125px;
        &::after {
          background-image: url("../../Image/rules_underline.svg");
          right: -7.92vw;
          left: unset;
          width: 1114px;
        }
      }
      p {
        margin-left: auto;
        width: fit-content;
        font-size: 24px;
        line-height: 145%;
      }
      .images {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 2rem;
        span {
          font-weight: 700;
          font-size: 182px;
          line-height: 100%;
        }
        @media (max-width: 1440px) {
          img {
            &:nth-of-type(1) {
              width: 45%;
            }
            &:nth-of-type(2) {
              width: 48%;
            }
          }
        }
      }
    }
    .landSale {
      box-sizing: border-box;
      background-image: url("../../Image/apply-guild-bg.svg");
      background-size: cover;
      width: 100vw;
      background-position-x: -180px;
      background-repeat: no-repeat;
      margin-top: 104px;
      margin-bottom: 250px;
      padding-top: 110px;
      padding-bottom: 124px;
      transform: translateX(-7.92vw) translateY(65px);
      @media (max-width: 1440px) {
        transform: translateX(-4.92vw) translateY(65px);
        margin-bottom: 150px;
      }
      h2 {
        font-weight: 400;
        font-size: 109px;
        line-height: 100%;
        text-align: center;
        letter-spacing: 0.02em;
        color: #000000;
        margin-bottom: 43px;
      }
      div {
        display: flex;
      }
      .formControl {
        font-family: "Poppins";
        width: 770px;
        height: 116.47px;
        color: #fff;
        font-weight: 500;
        font-size: 37px;
        line-height: 56px;
        text-align: center;
        letter-spacing: 0.02em;
        margin: 0 auto;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          top: -40%;
          right: -102%;
          width: 100%;
          height: 150%;
          background-image: url("../../Image/landing-landsale-sticker.png");
          background-repeat: no-repeat;
          @media (max-width: 1640px) {
            width: 33%;
            height: 100%;
            background-size: contain;
            top: -9%;
            right: -34%;
          }
        }
        @media (min-width: 1024px) {
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            background-image: url("../../Image/landing-btn-border.svg");
            background-repeat: no-repeat;
            transition: all 0.3s ease-in-out;
          }
          &:focus {
            &::before {
              display: none;
            }
          }

          &:not(:focus):hover {
            &::before {
              content: "";
              position: absolute;
              opacity: 1;
              top: 20px;
              left: 20px;
            }
          }
        }
        &:disabled {
          color: #ffc328;
        }
      }
    }
  }
  .partners {
    box-sizing: border-box;
    width: 100vw;
    h2 {
      font-weight: 400;
      font-size: 150px;
      line-height: 100%;
      text-align: center;
      letter-spacing: 0.02em;
      margin-bottom: 70px;
    }
    .partner__logos {
      background: linear-gradient(
        90deg,
        #000000 0%,
        #212121 28.65%,
        #1f1f1f 52.79%,
        #1f1f1f 75%,
        #000000 98.44%
      );
      margin-bottom: 178px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 120px 11.14%;
      @media (min-width: 1024px) {
        &:nth-of-type(1) {
          padding-bottom: 0;
          margin-bottom: 0;

          img {
            &:nth-of-type(1) {
              width: 424.87px;
              height: 181.73px;
            }
            &:nth-of-type(2) {
              width: 201.84px;
              height: 201.84px;
            }
            &:nth-of-type(3) {
              width: 218.18px;
              height: 182.17px;
            }
            &:nth-of-type(4) {
              width: 225.05px;
              height: 183.56px;
            }
          }
        }
        &:nth-of-type(2) {
          img {
            &:nth-of-type(1) {
              width: 281.19px;
              height: 158.17px;
            }
            &:nth-of-type(2) {
              width: 321.38px;
              height: 214.26px;
            }
            &:nth-of-type(3) {
              width: 275.57px;
              height: 275.5px;
            }
            &:nth-of-type(4) {
              width: 461.39px;
              height: 87.11px;
            }
          }
        }
      }
    }
    @media (max-width: 1440px) and (min-width: 1024px) {
      h2 {
        font-size: 140px;
      }
      .partner__logos {
        padding: 120px 5.14%;
        &:nth-of-type(2) {
          justify-content: flex-start;
          img {
            &:nth-of-type(1) {
              width: 265.19px;
              height: 141.17px;
            }
            &:nth-of-type(2) {
              width: 300.38px;
              height: 205.26px;
            }
            &:nth-of-type(3) {
              width: 250px;
              height: 250px;
            }
            &:nth-of-type(4) {
              width: 331.39px;
              height: 66.11px;
            }
          }
        }
      }
    }
  }
  .moreInfo {
    h4 {
      font-weight: 400;
      font-size: 69px;
      line-height: 100%;
      text-align: center;
      letter-spacing: 0.02em;
      margin-bottom: 67px;
    }
    div {
      display: flex;
    }
    .formControl {
      font-family: "Poppins";
      width: 430px;
      height: 75px;
      background-size: cover;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      text-align: center;
      letter-spacing: 0.02em;
      color: #5b0d00;
      margin: 0 auto;
      margin-bottom: 10rem;
    }
  }
}

@media (max-width: 1024px) {
  .landing {
    .sponsors {
      h4 {
        font-size: 51px;
      }
      .sponsor__logos {
        padding: 52px 4.95%;
        img {
          pointer-events: none;
        }
        a {
          &:not(:focus):hover {
            opacity: 0.35;
          }
          &:focus,
          &:focus-visible,
          &:active,
          &:focus-within {
            outline: none;
            opacity: 1;
          }
          &:nth-of-type(1) {
            order: 1;
            img {
              width: 110%;
            }
          }
          &:nth-of-type(2) {
            order: 0;
            img {
              width: 100%;
            }
          }
          &:nth-of-type(3) {
            order: 2;
            img {
              width: 90%;
            }
          }
        }
      }
    }
    .content {
      padding: 100px 5.86% 0 5.86%;
      h3 {
        font-size: 70px;
        margin-bottom: 33px;
        &::after {
          background-size: contain;
          width: 40%;
          left: -10vw;
        }
      }
      .content__desc {
        button[alt="content-sticker"] {
          background-image: url("../../Image/landing-stickerTablet.png");
          top: unset;
          right: 0rem;
          bottom: -36%;
          width: 60%;
          height: 36%;
          &:not(:focus):hover {
            background-image: url("../../Image/landing-stickerTablet.png");
          }
          &:focus {
            background-image: url("../../Image/landing-stickerTabletSelected.png");
          }
        }
        p {
          font-size: 18px;
          width: 80% !important;
          line-height: 145%;
          margin: 0 auto;
        }
      }
      .gameScreens {
        margin-top: 200px;
        margin-bottom: 15rem;
        img {
          width: 471px;
        }
      }
      .playRules {
        padding-top: 132px;
        h3 {
          font-size: 70px;
          text-align: left;
          margin: 0 auto;
          margin-bottom: 66px;
          &::after {
            right: -20.92vw;
            width: 613px;
          }
        }
        p {
          font-size: 18px;
        }
        .images {
          flex-direction: column;
          img {
            width: 100% !important;
          }
        }
      }
      .landSale {
        transform: translateX(-5.92vw) translateY(65px);
        margin-top: 175px;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          top: -44%;
          right: 2%;
          width: 55%;
          height: 40%;
          background-image: url("../../Image/landing-landsale-stickerTablet.png");
          background-repeat: no-repeat;
        }
        h2 {
          font-size: 65px;
        }
        .formControl {
          width: 502px;
          height: 82px;
          background-size: cover;
          font-size: 24px;
          &::after {
            display: none;
          }
        }
      }
    }
    .partners {
      h2 {
        font-size: 77px;
      }
      @media (min-width: 756px) {
        .partner__logos {
          padding: 35px 3%;
          justify-content: space-evenly;
          img {
            &:nth-of-type(1) {
              order: 0;
            }
            &:nth-of-type(2) {
              order: 2;
            }
            &:nth-of-type(3) {
              order: 1;
            }
            &:nth-of-type(4) {
              order: 6;
            }
            &:nth-of-type(5) {
              order: 3;
            }
            &:nth-of-type(6) {
              order: 7;
            }
            &:nth-of-type(7) {
              order: 4;
            }
            &:nth-of-type(8) {
              order: 5;
            }
          }
        }
      }
    }
    .moreInfo {
      h4 {
        font-size: 46px;
      }
      .formControl {
        width: 472px;
        height: 61px;
        font-size: 18px;
        line-height: 27px;
      }
    }
  }
}

@media (max-width: 756px) {
  .landing {
    padding-top: 11vw;
    @media (orientation: landscape) {
      padding-top: 0;
    }
    .imageHolder {
      background-image: url("../../Image/landing-bgMobile.png");
      background-repeat: no-repeat;
      width: 100vw;
      height: 97vh;
      min-height: unset;
      @media (orientation: landscape) {
        height: 100vh;
        background-position-y: -11rem;
        min-height: 42rem;
        width: 100%;
      }
    }
    .sponsors {
      padding: 42px 0 21px 0;
      h4 {
        font-size: 35px;
        padding: 10px 6px;
      }
      .sponsor__logos {
        flex-direction: column;
        padding: 30px 0;
        a {
          &:nth-of-type(1) {
            width: 136.45px;
            height: 77.1px;
            margin-bottom: 30px;
            img {
              width: 100%;
            }
          }
          &:nth-of-type(2) {
            width: 205.31px;
            height: 65.27px;
            margin-bottom: 31px;
            img {
              width: 100%;
            }
          }
          &:nth-of-type(3) {
            width: 219.32px;
            height: 70.96px;
            img {
              width: 100%;
            }
          }
        }
      }
    }
    .content {
      padding: 40px 11.39%;
      padding-bottom: 0;
      h3 {
        font-size: 40px;
        margin-bottom: 40px;
        &::after {
          width: 73%;
          left: -18vw;
          height: 60%;
          top: 95%;
          @media (orientation: landscape) {
            top: 75%;
          }
        }
      }
      .content__desc {
        button[alt="content-sticker"] {
          bottom: -25%;
          width: 100%;
          height: 21%;
          @media (orientation: landscape) {
            width: 57%;
            height: 40%;
            right: 30%;
            bottom: -42%;
          }
          background-size: contain;
          background-image: url("../../Image/landing-stickerMobile.svg");
          &:not(:focus):hover {
            background-image: url("../../Image/landing-stickerMobile.svg");
          }
          &:focus {
            background-image: url("../../Image/landing-stickerMobileSelected.svg");
          }
        }
        p {
          font-size: 16px;
          width: 90% !important;
          margin: 0;
          margin-left: auto;
        }
      }
      .gameScreens {
        position: static;
        margin-top: 200px;
        margin-bottom: 0;
        img {
          transform: translateZ(0);
          -webkit-transform: translateZ(0);
          width: 120%;
          position: static !important;
          top: unset;
          left: unset;
          transform: translateX(-6vw) translateY(0) !important;
          margin-bottom: 25px;
        }
      }
      .playRules {
        padding-top: 59px;
        h3 {
          font-size: 40px;
          text-align: right;
          margin: 0;
          margin-left: auto;
          margin-bottom: 35px;
          &::after {
            right: -18.5vw;
            width: 335px;
          }
        }
        p {
          font-size: 16px;
          width: 71%;
          margin: 0;
          margin-left: auto;
          br {
            display: none;
          }
        }
        .images {
          img {
            width: 97vw !important;
          }
        }
      }
      .landSale {
        transform: translateX(-11.39vw) translateY(65px);
        background-position-x: -408px;
        padding-top: 30px;
        padding-bottom: 96px;
        &::after {
          top: -30%;
          right: -6px;
          background-size: contain;
          width: 75%;
          @media (orientation: landscape) {
            top: -41%;
            width: 60%;
          }
        }
        h2 {
          font-size: 35px;
          span {
            font-size: 78px;
            line-height: 78px;
          }
        }
        .formControl {
          width: 319.5px;
          height: 56px;
          font-size: 18px;
        }
      }
    }
    .partners {
      h2 {
        font-size: 50px;
        margin-bottom: 20px;
      }
      .partner__logos {
        padding: 42px 6.67% 53px 9.72%;
        justify-content: space-around;
        margin-bottom: 85px;
        img {
          margin-bottom: 45px;
        }
        img {
          &:nth-of-type(1) {
            order: 0;
            width: 170.11px;
            height: 72.76px;
          }
          &:nth-of-type(2) {
            order: 5;
            width: 102.81px;
            height: 102.81px;
          }
          &:nth-of-type(3) {
            order: 1;
            width: 87.35px;
            height: 72.94px;
          }
          &:nth-of-type(4) {
            order: 3;
            width: 90.1px;
            height: 73.49px;
          }
          &:nth-of-type(5) {
            order: 2;
            width: 158.6px;
            height: 80.33px;
          }
          &:nth-of-type(6) {
            order: 4;
            width: 162.67px;
            height: 115.78px;
          }
          &:nth-of-type(7) {
            order: 7;
            width: 110.33px;
            height: 110.3px;
            margin-bottom: 0;
          }
          &:nth-of-type(8) {
            order: 6;
            width: 184.73px;
            height: 34.88px;
            margin-bottom: 0;
          }
        }
      }
    }
    .moreInfo {
      h4 {
        font-size: 28px;
      }
      .formControl {
        width: 319.01px;
        height: 56px;
      }
    }
  }
}

@media (min-width: 3800px) {
  .landing {
    .sponsors {
      padding-top: 1.5rem;
      padding-bottom: 2.5rem;
      margin-top: 2rem;
      h4 {
        font-size: 2rem;
        top: -1.3rem;
      }
      .sponsor__logos {
        padding: 2rem 12.24%;
        img {
          width: 15vw;
        }
      }
    }
    .content {
      .content__desc {
        h3 {
          font-size: 4rem;
        }
        p {
          font-size: 1rem;
        }
        button[alt="content-sticker"] {
          background-size: contain;
        }
      }
      .gameScreens {
        img {
          width: 40vw !important;
        }
      }
      .playRules {
        h3 {
          font-size: 4rem;
        }
        p {
          font-size: 1rem;
        }
        img {
          width: 50vw;
        }
      }
      .landSale {
        background-position-x: -8rem;
        padding: 7rem 0;
        h2 {
          font-size: 4rem;
          margin-bottom: 2rem;
        }
        button {
          transform: scale(1.5);
        }
      }
    }
    .partners {
      h2 {
        font-size: 5rem;
      }
      .partner__logos {
        margin-bottom: 178px;
        padding-top: 4rem;
        padding-bottom: 3rem;
        img {
          height: auto !important;
        }
        &:nth-of-type(1) {
          transform: translateY(20px);

          img {
            &:nth-of-type(1) {
              width: 20vw;
            }
            &:nth-of-type(2) {
              width: 7vw;
            }
            &:nth-of-type(3) {
              width: 8vw;
            }
            &:nth-of-type(4) {
              width: 11vw;
            }
          }
        }
        &:nth-of-type(2) {
          img {
            &:nth-of-type(1) {
              width: 12vw;
            }
            &:nth-of-type(2) {
              width: 12vw;
            }
            &:nth-of-type(3) {
              width: 12vw;
            }
            &:nth-of-type(4) {
              width: 20vw;
            }
          }
        }
      }
    }
    .moreInfo {
      h4 {
        font-size: 2.5rem;
      }
      button {
        transform: scale(2) translateY(1rem);
      }
    }
  }
}
