.search {
  position: relative;
  z-index: 3;
  color: #FFC328;
  -webkit-appearance: none;
}

.search_border {
  background-color: rgba(120, 75, 43, 0.2);
  background-image: url('../../../Image/search-border.svg');
  background-repeat: no-repeat;
  background-size: cover;
  width: 657px;
  height: 75px;
  display: flex;
}

.search_border::before {
  content: url('../../../Image/search.svg');
  position: relative;
  top: 25px;
  left: 27px;
  z-index: 2;
  margin-right: 30px;
  background-repeat: no-repeat;
}

.clear {
  position: relative;
  top: 28px;
  right: 22px;
  z-index: 5;
  padding: 2px;

  &:hover {
    cursor: pointer;
  }
}

.search::-moz-placeholder {
  color: #5E4217;
  font-size: 24px;
}

.search::-webkit-input-placeholder {
  color: #5E4217;
  font-size: 24px;
}

.search::placeholder {
  color: #5E4217;
  font-size: 24px;
}

//@media (min-width: 1920px) {
//  .search_border {
//    &::before {
//      top: 19px;
//    }
//  }
//}

@media screen and (max-width: 1024px) {
  .search_border {
    width: 532px;
    height: 61px;
  }

  .search_border::before {
    content: url('../../../Image/searchTablet.svg');
    position: relative;
    top: 20px;
    left: 21px;
    z-index: 2;
    margin-right: 24px;
    background-repeat: no-repeat;
  }

  .search::-moz-placeholder {
    font-size: 18px;
  }
  
  .search::-webkit-input-placeholder {
    font-size: 18px;
  }
  
  .search::placeholder {
    font-size: 18px;
  }

  .search {
    padding: 0 24px;
  }

  .clear {
    top: 20px;
  }
}


@media screen and (max-width: 670px) {

   .search::-moz-placeholder {
    font-size: 16px;
  }
  
  .search::-webkit-input-placeholder {
    font-size: 16px;
  }
  
  .search::placeholder {
    font-size: 16px;
  }

  .search_border {
    background-image: url('../../../Image/search-border-media.svg');
    width: 100%;
    height: 56px;
  }

  .search_border::before {
    top: 20px;
    left: 15px;
    margin-right: 3px;
  }

  .clear {
    right: 12px;
  }
}


@media (min-width: 3800px) {
  .search_border {
    width: 25vw;
    height: 3.2vw;
    background-size: 100% 100%;
    input {
      font-size: 1.3rem;
      &::placeholder {
        font-size: 1.3rem;
      }
    }
    svg {
      transform: scale(2.5);
      top: 54px;
      right: 46px;
    }
    &::before {
      transform: scale(2.5);
      top: 60px;
      left: 66px;
    }
  }
}