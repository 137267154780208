.homeGlory{
  height: 46.875vw;
  position: relative;
  margin-top: 7vw;
  &:after{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 67.494vw;
    height: 59.935vw;
    background-image: url("../../../../Image/homeGloryBg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  &:before{
    content: "";
    position: absolute;
    height: 59.10vw;
    width: 88.698vw;
    background-image: url("../../../../Image/GloryArrow.svg");
    z-index: 1;
    right: -19vw;
    top: -6vw;
    transform: rotate(-9deg);
  }
}
.textBlock{
  padding: 0 7.813vw;
  position: relative;
  z-index: 3;
  & h3{
    padding-top: 4.021vw;
    text-align: right;
    font-size: 6.250vw;
  }
  &__content{
    width: 33.170vw;
    float: right;
    margin-top: 1.563vw;
    & p{
      margin-bottom: 2vw;
      font-size: 1.250vw;
    }
  }
}

@media (max-width: 1024px) {
  .homeGlory{
    height: 96.875vw;
    &:before{
      height: 50.10vw;
      z-index: 0;
      right: -25vw;
      top: 17vw;
    }
    &:after{
      width: 88.494vw;
      height: 82.935vw;
    }
  }
  .textBlock{
    padding: 0 50px;
    & h3{
      font-size: 70px;
    }
    &__content{
      margin-right: 130px;
      & p{
        width: 384px;
        font-size: 18px;
        &:nth-child(2){
          width: 309px;
          margin-left: 80px;
        }
      }
    }
  }
}
@media (max-width: 576px) {
  .homeGlory{
    height: 630px;
    &:before{
      height: 50.10vw;
      z-index: 0;
      right: -19vw;
      top: 17vw;
    }
    &:after{
      left: auto;
      bottom: 0;
      right: 0;
      width: 100vw;
      height: 588.84px;
      background-image: url("../../../../Image/homeGloryBgMobile.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    &:before{
      display: none;
    }
  }

  .textBlock{
    padding: 0 20px;
    & h3{
      font-size: 50px;
      width: 190px;
      text-align: left;
    }
    &__content{
      float: left;
      & p{
        width: 296.03px;
        font-size: 16px;
        margin-left: 40px;
        &:nth-child(2){
          width: 171.95px;
          margin-left: 40px;
        }
      }
    }
  }
}