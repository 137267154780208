.dropdown {
  position: relative;
}

.dropdown_options {
  z-index: 2;
  width: 100%;
  position: absolute;
  background: rgba(24, 15, 9, 0.94);
  overflow-y: scroll;
  .option_wrapper {
    padding: 20px 0;
    img {
      width: 100px;
      height: 100px;
    }
  }
  &_buy{
    height: 40vh !important;
  }
  .option_wrapper > div {
    display: flex;
    justify-content: space-between;
  }



  .no_result {
    text-align: center;
    color: #FFC328;
  }

  .option_item {
    cursor: pointer;
    margin-top: 20px;
    color: #ffc328;
    display: flex;
    align-items: center;

    &:first-child {
      margin-top: 0;
    }
  }

  .option_item::before {
    content: url("../../../Image/search.svg");
    margin: 0 30px;
  }
}

@media screen and (max-width: 1024px) {
  .dropdown_options {
    .option_item::before {
      content: url("../../../Image/searchTablet.svg");
      margin: 0 20px;
    }

    .option_item {
      cursor: pointer;
      margin-top: 10px;
      color: #ffc328;
      display: flex;
      align-items: center;
    }

  }
}

@media screen and (max-width: 670px) {
  .dropdown_options {
    .option_item::before {
      margin: 0 15px;
    }

    .option_item {
      margin-top: 0;
    }
  }
  .dropdown{
    width: 100%;
  }
}


@media (min-width: 3800px) {
  .dropdown_options {
    .no_result {
      font-size: 1.4rem;
    }
    .option_item {
      font-size: 1.4rem;
      &::before {
        margin: 0 66px;
        transform: scale(2.7) translateY(-10px);
      }
    }
  }
}