.homeMain{
  overflow: hidden;
  position: relative;
  &:after{
    content: "";
    position: absolute;
    background-image: url("../../../../Image/HomeMainBotLines.svg");
    background-size: 100% 100%;
    width: 100vw;
    height: 17.240vw;
    bottom: -13vw;
    left: 0;
  }
  & button{
    font-weight: 500;
    position: relative;
    bottom: 6vw;
    & img{
      width: 1.979vw;
      height: 1.406vw;
      z-index: 2;
      margin-right: -20px;
    }
  }
}
@media (min-width: 3000px) {
  .homeMain{
    & button{
      bottom: 4vw;
      width: 525px;
      height: 152px;
      & span{
        font-size: 1.250vw !important;
      }
    }
  }
}
@media (min-width: 1024px) {
  .homeMain{
    & button{
      & span{
        font-size: 1.250vw !important;
      }
    }
  }
}
@media (max-width: 1024px) {
  .homeMain{
    & button{
      bottom: 11vw;
      & span{
        font-size: 18px !important;
      }
      & img{
        width: 27.76px;
        height: 19.94px;
        margin-right: -10px;
        margin-left: 10px;
      }
    }
  }
}
@media (max-width: 576px) {
  .homeMain{
    & button{
      bottom: 100px;
    }
  }
}