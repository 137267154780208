a,
p {
  font-family: Poppins, sans-serif;
}

ul li {
  list-style: none;
}

.col-4 {
  width: 33.333%;
}

a,
button {
  cursor: pointer;
}

.metamask-logo {
  margin-left: 13px;
}

.rdw-block-wrapper,
.rdw-dropdown-wrapper {
  color: black;
}

//.section {
//  background-position: initial;
//  background-repeat: no-repeat;
//  background-size: cover;
//  overflow-x: hidden;
//}
.notAvailable-wrapper {
  min-height: 100vh !important;
  overflow-x: hidden;

  .notAvailable {
    display: flex;
    justify-content: center;
    align-items: center;

    .notAvailable-box {
      width: 1099px;
      display: flex;
      flex-direction: column;
      align-items: center;

      & p:nth-child(2) {
        margin: 60px 0 66px;
        width: 949px;
        font-size: 24px;
      }

      & p:nth-child(3) {
        font-size: 16px;
      }

      .notAvailable-title {
        font-family: "Cold Warm", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 50px;
        line-height: 120%;
        text-align: center;
        letter-spacing: 0.02em;

        & span {
          color: #ffc328;
          display: block;
        }
      }
    }
  }
}

.popup-generic-wrapper {
  background: rgba(37, 37, 37, 0.8);
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 9999;

  .popup-generic {
    min-width: 1322px;
    min-height: 502px;
    background: url("../Image/regularPupup.png") no-repeat center/cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    &.no-btn {
      .box-title {
        margin-top: 2rem;
      }
      & > .box-button {
        display: none;
      }
      .box-desc {
        p {
          text-align: center;
          margin: 40px 0;
        }
        .confirmation-buttons {
          display: flex;
          align-items: center;
          justify-content: center;
          .box-button {
            margin: 0 30px;
          }
        }
      }
    }
    &.purchase-success,
    &.fund-error {
      text-align: center;
      &.land-error {
        .box-desc {
          width: 80%;
        }
      }
      a {
        text-decoration: underline;
        font-weight: 700;
        margin: 0 6px;
        color: #000;
      }
    }
    @media (max-width: 1600px) {
      &.purchase-success {
        .box-desc {
          margin: 10px 0;
          width: 65%;
        }
      }
      &.verification{
        .box-desc {
          margin: 20px 0;
        }
      }
      &.no-btn {
        .box-desc {
          margin: 10px 0;
          width: 80%;
          br {
            display: none;
          }
          p {
            margin: 0;
            margin-bottom: 10px;
          }
        }
      }
      &.purchase-error {
        .box-desc {
          margin: 20px 0;
        }
      }

      &.fund-error {
        .box-desc {
          margin: 30px 0;
        }
        &.land-error {
          .box-desc {
            width: 80%;
            margin: 10px 0;
          }
        }
      }
    }

    @media (max-width: 1024px) {
      &.fund-error {
        &.land-error {
          .box-desc {
            width: 80%;
            margin: 50px 0;
          }
        }
      }
      &.no-btn {
        .box-desc {
          margin: 40px 0;
          width: 80%;
          p {
            margin: 0;
            margin-bottom: 40px;
          }
        }
      }
      &.purchase-error {
        .box-desc {
          margin: 60px 0;
        }
      }
      &.purchase-success {
        .box-desc {
          margin: 50px 0;
        }
      }
    }

    @media (max-width: 680px) {
      &.purchase-success {
        .box-desc {
          margin: 90px auto;
          width: 95%;
          text-align: left;
          br {
            display: none;
          }
        }
      }
      &.no-btn {
        .box-desc {
          margin: 140px 0;
          width: 100%;
          p {
            width: 90%;
            text-align: left;
            margin: 20px 0 80px 0;
          }
          .confirmation-buttons {
            width: 90%;
            margin: 0 auto;
            justify-content: space-between;
            .box-button {
              margin: 0;
            }
          }
        }
      }

      &.purchase-error,
      &.fund-error,
      &.purchase-success {
        text-align: left;
        .box-desc {
          text-align: left;
          margin: 120px 0;
          br {
            display: none;
          }
        }
        &.land-error {
          .box-desc {
            width: 95%;
            margin: 90px 0;
          }
        }
      }
    }
    &.verification{
      .box-desc {
        margin: 20px 0;
      }
    }
    .exit-button-wrapper {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      margin-top: 20px;
      font-size: 24px;
      font-family: "Poppins", sans-serif;
    }

    .box-button {
      background: url("../Image/popupButton.svg") no-repeat center/cover;
      width: 251px;
      height: 75px;
      color: #fff;
      font-size: 24px;
      font-family: "Poppins", sans-serif;
    }

    .box-title {
      font-size: 50px;
      color: #000;
    }

    .box-desc {
      margin: 60px 0;
      font-size: 24px;
      color: #000;
      font-family: "Poppins", sans-serif;
      // margin: 110px 0;
    }
  }

  .popup-claim {
    background: url("../Image/heightPopup.png") no-repeat center/cover;
    min-width: 1322px;
    min-height: 763px;
    margin: 50px auto;
    position: relative;
  }

  .popup-subdesc {
    font-weight: 600;
    font-size: 24px;
    line-height: 145%;
    text-align: center;
    color: #000000;
  }

  .popup-claim .box-desc {
    text-align: center;
    width: 1131px;
    margin: 40px auto;
  }

  input.popup-check {
    color: #000;
    cursor: default;
    padding: 10px 0;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    right: 92px;
    font-weight: 600;
    font-size: 24px;
    line-height: 145%;
    width: unset;
  }

  .claim-1-airdrop {
    margin-top: 75px;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 31px 0;
    width: 100%;

    &.success {
      margin-top: 18.24px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;

      a {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-family: "Cold Warm", sans-serif;
        font-size: 53px;
        color: white;
        text-decoration: none;
        cursor: pointer;

        img {
          margin-right: 20px;
        }
      }
    }

    @media (max-width: 680px) {
      &.success {
        flex-direction: column;

        a {
          padding: 10px;

          img {
            margin-right: 10px;
          }

          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .claim-title {
    font-size: 35px;
    line-height: 100%;
    color: #ffc328;
    font-family: "Cold Warm", sans-serif;
  }

  .claim-input {
    background: url("../Image/input-bg.png") no-repeat center/cover;
    width: 654px;
    height: 75px;
    border: none;
    margin-left: 38px;
    font-size: 21px;
    color: #000000;
    padding: 20px 0 15px 49px;
  }

  .checkbox-block {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    .checkbox {
      width: 20px;
      height: 20px;
      background: #ffc328;
      border: 2px solid #000000;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-right: 13px;
      cursor: pointer;
    }

    .label-check {
      color: #000;
      font-weight: 600;
      font-size: 24px;
      margin-right: 92px;
      cursor: pointer;
    }
  }

  .claim-button {
    border: none;
    background-size: contain;
    background-repeat: no-repeat;
    width: 228px;
    height: 53px;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.02em;
    color: #ffffff;
  }

  .left-button {
    background-size: contain !important;
    background-repeat: no-repeat !important;
    width: 228px;
    height: 53px;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.02em;
    color: #ffffff;
    background: url("../Image/claimLeftBtn.png");
  }

  .right-button {
    background-size: contain !important;
    background-repeat: no-repeat !important;
    width: 228px;
    height: 53px;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.02em;
    color: #ffffff;
    background: url("../Image/claimRightBtn.png");
  }

  .claim-timer {
    font-size: 24px;
    font-family: "Poppins", sans-serif;
    line-height: 145%;
    color: #000000;
    margin: 38px 9px 38px 0;

    h2 {
      font-size: 37px;
      display: inline;
    }
  }
}

.section__box {
  max-width: 1920px;
  margin: 0 auto;
}

.section__box p,
ul {
  font-size: 24px;
}

.section__desc {
  width: 924px;
}

.section__subdesc {
  width: 924px;
}

.section__subdesc-list {
  display: inline-block;
  width: 920px;
  margin-bottom: 35px;
}

.section__subdesc-list li {
  position: relative;
  margin-left: 25px;
}

.section__subdesc-list li:before {
  content: "";
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: white;
  left: -20px;
  top: 12px;
}

.section__wrapper {
  padding-top: 85px;
  background-repeat: no-repeat;
  background-position-x: 26px;
}

.section__wrapper div p:nth-child(3) {
  margin: 50px 0 0;
}

.section__wrapper-box {
  display: flex;
  padding-left: 8.4%;
}

.section__wrapper-box div:nth-child(1) {
  z-index: 1;
}

.section__wrapper-box a {
  color: #ffc328;
  font-weight: 700;
  text-decoration: none;
}

.section__title {
  font-weight: 400;
  font-size: 120px;
  line-height: 120px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #ffffff;
  width: 1200px;
}

.section__title span {
  color: #ffc328;
  font-weight: 400;
  font-size: 120px;
  line-height: 120px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

.section__desc {
  line-height: 35px;
  margin-bottom: 51px;
  margin-top: 169px;
}

.section__img-wrapper {
  display: flex;
  margin-top: 52px;
  margin-left: -395px;
  overflow-x: hidden;
}

.section__time-glass {
  position: absolute;
  top: 37px;
  left: 16px;
  z-index: 22;
  bottom: 0;
  right: 0;
  background: url("../Image/glass.png") no-repeat;
}

.section__time-lock {
  position: absolute;
  z-index: 22;
  width: 91px;
  height: 134px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("../Image/Lock.png");
  right: 313px;
  top: 10px;
}

.section__time-wrapper {
  position: relative;
  z-index: 1;
  background-color: #000;
  padding: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../Image/yellow-bg.png");
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 451px;
}

.section__time-wrapper h3 {
  font-style: normal;
  font-weight: normal;
  font-size: 97px;
  line-height: 100%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #000000;
}

.section__time-wrapper .section__time-wrapper-ended {
  font-size: 97px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #e40b0b;
  margin-top: -5px;
  margin-bottom: 25px;
}

.section__time-wrapper .cumminity-claim {
  background-repeat: no-repeat;
  background-size: contain;
  width: 370px;
  text-align: center;
  height: 75px;
  margin-top: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section__time-wrapper .cummunity-ended {
  width: 270px;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 76px;
  background-repeat: no-repeat;
  margin-top: 56px;
  font-size: 24px;
  color: white;
  font-size: 24px;
  font-family: "Poppins", "Open Sans", sans-serif;
  text-decoration: none;
}

.section__time-wrapper .cumminity-claim h2 {
  color: white;
  font-size: 24px;
  font-family: "Poppins", sans-serif;
}

.section__time-wrapper .cumminity-claim h2:nth-child(2) {
  margin-left: 10px;
}

.section__time-wrapper .cumminity-claim h2:nth-child(1) {
  text-transform: initial;
}

.communnity-active div:nth-child(1) {
  margin-left: 5px;
}

.section__time-wrapper .available h2 {
  font-size: 150px;
  line-height: 100%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #000;
  font-style: normal;
  font-weight: normal;
}

.section__metamask-box div {
  width: 453px;
  background-repeat: no-repeat;
  height: 79px;
  display: flex;
  justify-content: center;
  padding-top: 0;
  align-items: center;
}

.section__time-wrapper .communnity-active h2 {
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  text-align: inherit;
  letter-spacing: inherit;
  margin-left: inherit;
  display: inline;
}

.section__time-wrapper .communnity-active {
  font-family: Poppins, sans-serif;
  display: flex;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #000000;
}

.metamask__connected-box img {
  margin-right: 26px;
}

.section__time-wrapper p {
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.02em;
  color: #000000;
  width: unset;
  margin-left: unset;
  font-size: 24px;
}

.coin:not(:last-child) {
  margin-right: 75px;
}

.public-box p {
  margin: 25px 0 35px;
  display: block;
}

.box-coin-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.section .public {
  margin-top: 130px;
  transform: rotate(180deg);
}

.public .section__time-glass {
  transform: rotate(180deg);
  background: url("../Image/Glass-layer.svg") no-repeat;
  right: 12px;
  bottom: 36px;
}

.section .public span {
  transform: rotate(180deg);
  bottom: 21px;
  left: 335px;
  top: unset;
}

.section .public-box {
  transform: rotate(180deg);
  text-align: center;
}

.section__metamask {
  padding-top: 34px;
  margin-bottom: 118px;
  background: rgba(37, 37, 37, 0.48);
}

.section__metamask h5 {
  display: none;
}

.section__metamask button {
  font-size: 24px;
}

.section__metamask h4 {
  font-size: 69px;
  line-height: 100%;
  font-weight: 400;
  /* or 69px */
  letter-spacing: 0.02em;
  text-transform: uppercase;
  text-align: center;
}

.section__metamask h4 span {
  font-weight: 400;
  font-size: 69px;
  line-height: 100%;
  color: #ffc328;
}

.section__metamask-box {
  display: flex;
  justify-content: space-between;
  margin-top: 46px;
}

.section__metamask-wrapper {
  img {
    margin-right: 25px;
  }
}

.section__metamask-box span img {
  width: 38px;
  object-fit: contain;
  height: 35px;
  margin-left: 28px;
}

.section__connecting-metamask h4:nth-child(2) {
  display: none;
}

.walletconnect-modal__mobile__toggle {
  a {
    color: #2f2f2f;
  }
}

.section__connecting-metamask button {
  letter-spacing: 0.02em;
  color: #5b0d00;
  background-repeat: no-repeat;
  background-color: transparent;
  padding: 1px 20px;
  display: flex;
  align-items: center;
  border: none;
  justify-content: center;
  width: 444px;
  height: 75px;
  background-size: cover;
}

.section__connecting-metamask a {
  letter-spacing: 0.02em;
  color: #5b0d00;
  background-repeat: no-repeat;
  background-color: transparent;
  padding: 1px 20px;
  display: flex;
  align-items: center;
  border: none;
  justify-content: center;
  width: 444px;
  height: 75px;
  font-size: 24px;
  text-decoration: none;
}

.section__connecting-metamask button span {
  margin: unset;
  display: flex;
  align-items: center;
}

.section__connecting-metamask a span {
  margin: unset;
  display: flex;
  align-items: center;
}

.section__connecting-metamask a {
  letter-spacing: 0.02em;
  color: #5b0d00;
  background-repeat: no-repeat;
  background-color: transparent;
  padding: 1px 20px;
  display: flex;
  align-items: center;
  border: none;
  justify-content: center;
  width: 444px;
  height: 75px;
  font-size: 24px;
  text-decoration: none;
}

.section__table {
  position: relative;
  z-index: 22;
  border-top: 2px solid #ffc328;
  width: 100%;
  margin-top: -400px;
  padding: 32px 0 30px;
  border-bottom: 2px solid #ffc328;
  background-color: #000;
}

.section__table img {
  margin-bottom: 15px;
  height: 26.5px;
}

@media (min-width: 681px) {
  .section__table img {
    display: none;
  }
}

.section__table-header {
  background-color: rgba(37, 37, 37, 0.77);
  padding: 11px 0;
}

.section__table-container {
  display: flex;
  max-width: 1153px;
  margin: 0 auto;
}

.active__wrapper-mobile {
  display: none;

  @media (max-width: 720px) {
    display: block;
  }

  .active__wrapper-mobileBtns {
    justify-content: center;
    margin: 13px 0 24px;

    button {
      width: 78px;
      margin: unset;
      padding: 2px 0;
      display: block;

      &:not(:last-child) {
        margin-right: 5px;
      }
    }
  }

  div {
    display: flex;
    background: rgba(120, 75, 43, 0.2);
    padding: 0 20px;
    justify-content: center;

    &:nth-child(1) {
      align-items: center;
    }

    &:nth-child(3) {
      background-color: unset;
      margin-bottom: 25px;

      span {
        font-size: 40px;
        font-weight: bold;
      }
    }

    &:nth-child(4) {
      align-items: center;
    }
  }

  input {
    width: 200px !important;
    padding: 18px 37px 18px 32px;
    margin-right: 36px;
  }
}

.get_it_button_wrapper {
  margin-top: 25px;
  display: none;
  box-sizing: border-box;
  width: 100%;
  justify-content: flex-end;
  padding-right: 20px;
  background: none;

  .custom-button {
    margin: 0;
  }

  @media (max-width: 1280px) {
    display: flex;
  }
}

.section__table-body {
  padding: 15px 0;
  background: rgba(37, 37, 37, 0.77);
}

.section__table-margin {
  margin: 21px 0 22px;
}

.section__table h4 {
  font-weight: 400;
  font-size: 30px;
  line-height: 44px;
  letter-spacing: unset;
  margin-bottom: unset;
  text-align: unset;
}

.section__table-body span:nth-child(1) {
  font-family: Poppins, sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 145%;
}

.section__table-info {
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  letter-spacing: 0.03em;
  margin-top: 18px;
}

.section__info {
  padding: 200px 155px 0;
}

.section__info-box {
  padding: 140px 0 10px;
  text-align: center;
}

.section__info-box .tablet {
  display: none;
}

.section__info-box .tablet .section__info-btn {
  text-align: center;
  text-decoration: none;
}

.section__info-box div:nth-child(2) {
  display: flex;
  justify-content: center;
}

.section__info ul {
  margin: 62px 130px 100px;
}

.section__info-title {
  font-weight: 400;
  font-size: 120px;
  line-height: 100%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  width: 1416px;
}

.section__info-title span {
  font-weight: 400;
  font-size: 120px;
  line-height: 100%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #ffc328;
}

.section__info-steps li {
  display: flex;
  align-items: center;
  padding: 15px 0 17px 36px;
  background: linear-gradient(
    90deg,
    #252525 -0.95%,
    rgba(0, 0, 0, 0.85) 94.9%,
    rgba(0, 0, 0, 0) 103.02%
  );
  border-radius: 4px;
}

.section__info-steps li a {
  color: #ffc328;
  text-decoration: none;
  line-height: 145%;
  font-weight: 700;
  margin: 0 5px;
}

.section__info-steps li img {
  margin-right: 40px;
}

.section__info-steps li:not(:last-child) {
  margin-bottom: 19px;
}

.section__info-subtitle {
  font-weight: 400;
  font-size: 97px;
  line-height: 100%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  margin-bottom: 54px;
}

.section__info-subtitle span {
  margin-left: 8px;
  font-weight: 400;
  font-size: 97px;
  line-height: 100%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #ffc328;
}

.section__info-btn {
  background-size: contain !important;
  background-repeat: no-repeat;
  padding: 20px 0;
  font-size: 24px;
  font-family: "Poppins", sans-serif;
  color: #5b0d00;
  font-weight: 500;

  a {
    text-align: center;
    text-decoration: none;
    font-size: 24px;
    border: none;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0.02em;
    color: #5b0d00;
  }
}

.desktop .section__info-btn:focus {
  outline: 1px solid #ffc328;
}

.desktop .section__info-btn:focus {
  outline: 1px solid #ffc328;
}

.section__info-btn img {
  margin-left: 10px;
}

.section__info .section__info-disclaimer {
  font-size: 13px;
  line-height: 145%;
  display: block;
  width: 1010px;
  margin: 247px 160px 147px auto;
}

.section__info .section__info-disclaimer span {
  font-weight: 700;
}

.active__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 46px;
}

.active__wrapper .tablet-btn {
  display: none;
}

.active__wrapper input:nth-child(1) {
  background-size: contain;
  width: 362px;
  padding: 20px 30px 22px 32px;
}

.active__wrapper-form {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(120, 75, 43, 0.2);
  border-radius: 0 35px 0 35px;

  @media (max-width: 1280px) {
    .desktop-get-it-button {
      display: none;
    }
  }

  @media (max-width: 720px) {
    display: none;
  }
}

.active__wrapper-form div:nth-child(2) {
  margin: 0 30px;
}

.active__wrapper-form div:nth-child(2) span:nth-child(1) {
  margin-right: 15px;
}

.active__wrapper-form span:nth-child(4) {
  margin: 0 30px;
}

.active__wrapper span {
  color: #ffc328;
}

.active__wrapper input {
  width: 248px;
  background: transparent no-repeat;
  border: none;
  padding: 20px 5px 22px 70px;
  text-align: center;
  color: #ffc328;
  background-size: contain;
}

.active__wrapper input::placeholder {
  color: #ffc328;
}

.active__wrapper-form button {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.02em;
  width: 288px;
  height: 75px;
  border: none;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
}

.active__wrapper button span {
  color: #5b0d00;
  font-family: "Poppins", "Open Sans", sans-serif;
  font-weight: 500;
}

.active__wrapper-btns {
  margin-left: 40px;
  margin-top: 7px;
  display: flex;

  button {
    &:not(:last-child) {
      margin-right: 3px;
    }

    span {
      font-weight: 500;
      font-size: 18px;
      line-height: 100%;
      font-family: "Poppins", sans-serif;
      color: #5b0d00;
      text-align: center;
      letter-spacing: 0.03em;
    }
  }

  @media (max-width: 720px) {
    display: none;
  }
}

.active__loading {
  width: 960px;
  height: 54px;
  background: #3d3a38;
  display: flex;
  justify-content: inherit;
}

.active__loading-el {
  height: 100%;
  width: 100%;
  background: #f8a300;
  display: flex;
  align-items: center;
}

.active__title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.active__title p {
  width: 1143px;
  text-align: center;
  margin-top: 32px;
}

.active__title h3 {
  font-weight: 400;
  font-size: 69px;
  line-height: 100%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

.active__title h3 span {
  font-family: inherit;
  font-style: normal;
  font-weight: inherit;
  font-size: inherit;
  letter-spacing: inherit;
  text-transform: inherit;
  color: #ffc328;

  .h-white-text {
    color: #fff;
  }
}

.custom-disable {
  filter: saturate(100%);
  opacity: 0.8;
  cursor: default;
}

.active__wrapper {
  .line {
    margin-top: 30px;
  }

  .form-wrapper {
    margin: auto;
  }

  .progress-block {
    margin: 6.5px auto 0 auto;
    max-width: 86.5416666667vw;

    .progress-bar {
      width: 980px;
      max-width: 100%;
      height: 54px;
      background-color: #3d3a38;
      margin: auto;

      &-completion {
        background-color: #f8a300;
        height: 100%;
        width: 0;
      }

      &-arrows-wrapper {
        width: 100%;
        height: 100%;
        margin-top: -54px;
        display: flex;
        align-items: center;
        overflow: hidden;
      }

      &-arrow {
        margin-left: 5.22px;
      }
    }

    .progress-status {
      margin: 43.5px auto 0 auto;
      font-weight: 500;
      color: white;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        margin-right: 20px;
      }

      &,
      p,
      a {
        font-family: "Poppins", sans-serif;
        font-size: 24px;

        @media (max-width: 1280px) {
          font-size: 18px;
        }

        @media (max-width: 720px) {
          font-size: 16px;
        }
      }

      a {
        color: #ffc328;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }
}

.footer-left {
  .footer-copyright {
    span,
    a {
      font-size: 20px;
    }
  }

  .footer-menu {
    &__item {
      font-size: 20px;
    }
  }

  @media (max-width: 1280px) {
    .footer-copyright {
      span,
      a {
        font-size: 16px;
      }
    }

    .footer-menu {
      &__item {
        font-size: 16px;
      }
    }
  }

  @media (max-width: 768px) {
    .footer-copyright {
      span,
      a {
        font-size: 14px;
      }
    }

    .footer-menu {
      &__item {
        font-size: 14px;
      }
    }
  }
}

.header-menu__item-submenu__item.selected {
  color: #ffc328;
  pointer-events: none;
  /* uncomment if line under link is needed   &::after {
    width: 35px;
  } */

  cursor: default;
}
button:focus-visible,
a:focus-visible {
  outline: 1px solid #ffc328;
  outline-offset: 0.035%;
}

.header-socials__item {
  display: inline-flex;

  > svg {
    width: 25px;
    height: 32px;
  }
}


@media (min-width: 3800px) {
  .popup-generic {
    transform: scale(1.6) !important;
  }
}