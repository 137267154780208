.table__row {
  background-color: #1D1D1D;

  &__guild {
    padding: 15px 0 15px 145.54px;
    display: flex;
    align-items: center;
    gap: 15px;
    &__name, &__rating {
      /* Web 7 */

      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 145%;
      /* identical to box height, or 35px */


      /* White */

      color: #FFFFFF;
    }
  }

  &__language {
    padding: 15px 0;
    text-overflow: ellipsis;
    /* Web 3 */

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 145%;
    /* identical to box height, or 35px */

    /* White */

    color: #FFFFFF;
  }

  &__size {
    margin: 15px 0;
    border-radius: 5.84528px;
    padding: 6px 21px;
    /* Web 62 */

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
    /* or 22px */

    width: fit-content;
    /* Panel color darker */

    color: #191919;
  }

  &__socialMedias {
    display: flex;
    gap: 18.71px;
    align-items: center;
    & img{
      height: 22px;
      width: 24px;
      &[alt='Discord']{
        width: 26px;
      }
      &[alt='Website']{
        height: 24px;
      }
    }
  }

  &__guild__logo{
    height: 49px;
    width: 59px;
    &__empty{
      width: 49px;
      height: 49px;
    }
  }
  &__guild__star{
    width: 20.5px;
    height: 19.57px;
  }
}
@media (min-width: 3000px) {
  .table__row {
    &__guild {
      font-size: 1.2vw ;
      &__name, &__rating {
        font-size: 1.2vw ;
      }
    }
    &__language {
      font-size: 1.2vw ;
    }
    &__size {
      font-size: 1.2vw ;
      border-radius: 20px;
    }
  }

  .table__row {
    &__socialMedias {
      gap: 1vw;
      & img{
        height: 1.8vw;
        width: 2vw;
        &[alt='Discord']{
          width: 2.1vw;
        }
        &[alt='Website']{
          width: 1.8vw;
          height: 1.8vw;
        }
      }
    }

    &__guild__logo{
      height: 1.8vw;
      width: 2vw;
      &__empty{
        width: 2.3vw;
        height: 2.3vw;
      }
    }
    &__guild__star{
      width: 1.8vw;
      height: 1.8vw;
    }
  }


}
@media (max-width: 1024px) {
  .table__row {
    &__guild {
      font-size: 18px ;
      &__name, &__rating {
        font-size: 18px ;
      }
    }
    &__language {
      font-size: 18px ;
    }
    &__size {
      font-size: 18px ;
    }
  }
}
@media (max-width: 576px) {
  .table__row{

    &__guild {
      padding: 5px 0 5px 20px;
    }
    &__size {
    margin: 10px 0;
    padding: 6px 11px;
    font-size: 15px;
    line-height: 120%;
    /* or 22px */

    width: fit-content;
    /* Panel color darker */

    color: #191919;
  }
    &__language {
      font-size: 16px ;
    }
  }
}